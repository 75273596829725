<template>
  <el-tooltip
    effect="dark"
    placement="right"
  >
    <section class="track-switch_container" :class="{ 'yulin_container' : currentMode == 'yulong' }">

      <i
        class="iconfont pointer"
        :class="{'icon-trackOn':isShowTrack,'icon-trackOff':!isShowTrack}"
        @click="isShowTrack = !isShowTrack"
      ></i>

    </section>
    <!-- 这是提示语 -->
    <div
      class="tip-text"
      slot="content"
    >
      <p>{{
            isShowTrack ? '关闭轨迹' : '开启轨迹'
          }}
      </p>

    </div>
  </el-tooltip>
</template>

<script>
export default {
  computed: {
    // 轨迹开关
    // isShowTrack
    isShowTrack: {
      get() {
        return this.$store.getters.isShowTrack;
      },
      set(val) {
        // setIsShowTrack
        this.$store.dispatch('map/setIsShowTrack', val);
        // this.$store.commit('setIsShowTrack',val);
      }
      //return this.$store.state.isShowTrack;
    },
    // 当前环境变量 CURRENTMODE
    currentMode() {
      return process.env.VUE_APP_CURRENTMODE;
    },
  }
}
</script>

<style lang="less" scoped>
.track-switch_container {
  width: 46px;
  height: 46px;
  background-color: var(--page-background-color);
  border-radius: 4px;
  background: #2e2d2d;
  position: fixed;
  top: 155px;
  left: 438px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1;
  .iconfont {
    font-size: 24px;
  }

  &:hover {
    // 颜色变浅
    background-color: #808080;
  }

  &:active {
    // 颜色变亮
    background-color: #696969;
  }
}
.yulin_container{
  top: 100px;
}
</style>