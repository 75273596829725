import layer from "./layer.js";
export default {
    mixins: [layer],
    data() {
        return {
            // 存储旧数据的数组
            oldData: [],
            djiDroneTrajectoryList: [],
            djiDroneIconList: []
        };
    },
    computed: {
        // 获取列表
        getAllResList() {
            return this.$store.getters.getAllResList;
        },
        // 选中资源  
        selectedResInfo() {
            return this.getAllResList.find((item) => item.id == this.resId)
                ? this.getAllResList.find((item) => item.id == this.resId)
                : {};
        },
        // 当前选中index
        resId() {
            return this.$store.getters.resId;
        },
        // 获取orthoImageLayerId
        orthoImageLayerId() {
            return this.$store.getters.orthoImageLayerId;
        },
        // isShowTrack
        isShowTrack() {
            return this.$store.getters.isShowTrack;
        }
    },
    watch: {
        selectedResInfo: {
            deep: true,
            handler() {
                // 未选中目标时不做处理
                if (this.resId == -1) return;
                this.resourcesLabel();
            },
        },
        // 监听isShowTrack，执行updateTrackColor
        isShowTrack(val) {
            this.updateTrackColor();
        }
    },
    mounted() { },
    created() {
        window.orthophotoMapList = []; // 初始化正射影像图对象
        // 资源加载完成
        this.$EventBus.$on("resLoad", (executed, unitName) => {
            this.controlMapDisplay(executed, unitName);
        });

        // 资源的定位功能
        this.$EventBus.$on("resPosition", (info) => {
            this.resPosition(info);
        });

        // 初始化资源相关
        this.$EventBus.$on("cleanMap", () => {
            this.cleanMap();
        });
        // 清除资源弹窗
        this.$EventBus.$on("hideResLabel", () => {
            this.hideResLabel();
        });
    },
    beforeDestroy() {

        this.$EventBus.$off("resLoad");
        this.$EventBus.$off("resPosition");
        this.$EventBus.$off("cleanMap");
        this.$EventBus.$off("hideResLabel");
        this.cleanMap();
    },
    methods: {
        // 显示弹窗 隐藏 title
        showCanvasLabel(guid, show) {
            const mark = zr3d.markGet({ guid }) || realTimeTrajectoryList.find(item => item.id === guid)?.flyEntity;
            if (mark) mark.canvasLabel.show = show;
        },
        // 控制地图资源的显示
        controlMapDisplay(isResourceLocated, unitName) {
            // 判断unitName是否存在，如果存在，就是单元的资源，对资源进行过滤，获取当前单元的资源，进行渲染
            let resList = unitName ? this.getAllResList.filter(item => item.unitName == unitName) : this.getAllResList;
            // 对比旧数据，如果数据没有变化，就不做处理

            if (JSON.stringify(resList) == JSON.stringify(this.oldData)) return false;

            // 1.找出新增or删除的数据
            let addList = resList.filter(item => !this.oldData.find(item2 => item2.id == item.id));
            let delList = this.oldData.filter(item => !resList.find(item2 => item2.id == item.id));
            // 2.找出状态变化的数据,其中任何一个值的改变都需要对数据进行处理

            let changesList = resList.filter(item => {
                let oldItem = this.oldData.find(item2 => item2.id == item.id);
                if (!oldItem) return false;

                if (item.resourceStatus !== oldItem.resourceStatus) {
                    item.hasChanges = true;
                    item.reason = "resourceStatus";
                    return true;
                }

                // 处理app播放地址变化
                if (item?.appPlayStreamUrl !== oldItem?.appPlayStreamUrl) {
                    item.hasChanges = true;
                    item.reason = "resourceStatus";
                    return true;
                }

                
                if (
                    item.lng !== oldItem.lng ||
                    item.lat !== oldItem.lat ||
                    item.altitude !== oldItem.altitude ||
                    item.verticalSpeed !== oldItem.verticalSpeed ||
                    item.elevation !== oldItem.elevation ||
                    item.height !== oldItem.height ||
                    item.subLng !== oldItem.subLng ||
                    item.subLat !== oldItem.subLat ||
                    item.subAltitude !== oldItem.subAltitude ||
                    item.gimbal_roll !== oldItem.gimbal_roll ||
                    item.gimbal_pitch !== oldItem.gimbal_pitch ||
                    item.gimbal_yaw !== oldItem.gimbal_yaw ||
                    item.attitude_head !== oldItem.attitude_head ||
                    item.horizontal_speed !== oldItem.horizontal_speed
                ) {
                    
                    item.hasChanges = true;
                    item.reason = "other";
                    return true;
                }

                return false;
            });

            // 如果addList长度大于0，说明有新增的数据，需要对新增的数据进行处理
            if (addList.length > 0) {

                let coordinatesList = []; // 用于存储所有资源的坐标
                // 循环遍历addList，对新增的数据进行处理，渲染到地图上
                addList.forEach(item => {
                    const { resourceStatus, lat, lng, subLng, subLat, targetLat, targetLng } = item

                    if (resourceStatus == 3 || lat < 1 || lng < 1) return false; // 离线的资源不显示，无效的坐标不显示

                    coordinatesList.push({ lat, lng }); // 存储所有资源的坐标

                    if (subLng && subLng > 1 && subLat && subLat > 1) {
                        coordinatesList.push({ lat: subLat, lng: subLng }) // 存储所有资源的坐标
                    }

                    if (targetLat && targetLat > 0 && targetLng && targetLng > 0) {
                        coordinatesList.push({ lat: targetLat, lng: targetLng }) // 存储所有资源的坐标
                    }

                    this.createResource(item); // 创建资源
                });
                // if (!isResourceLocated) this.lockCenterPoint(coordinatesList); // 锁定中心点以及视距
            }

            // 如果delList长度大于0，说明有删除的数据，需要对删除的数据进行处理
            if (delList.length > 0) {

                // 循环遍历delList，对删除的数据进行处理，清理地图上的资源图标
                delList.forEach(item => {
                    this.destroyResource(item); // 销毁资源
                }
                );
            }
            // 如果statusList长度大于0，说明有状态变化的数据，需要对状态变化的数据进行处理
            if (changesList.length > 0) {

                changesList.forEach(item => {
                    if (item.hasChanges) {
                        if (item.reason === "resourceStatus") {
                            this.destroyResource(item); // 销毁资源
                            this.createResource(item); // 创建资源
                        } else {

                            this.updateResource(item); // 更新资源
                        }
                    }
                });
            }

            // 进入页面限制执行一次
            this.oldData = resList;

        },
        // 创建机库飞行器轨迹
        createHangarTrack(info) {
            const { resourceName, id, resourceStatus, resourceType, lng, lat, subLng, subLat, subDeviceSn, subAltitude, attitude_head } = info
            // 判断状态是否为任务中
            if (resourceStatus == 1) return false;
            if (lng < 1 || lat < 1) return false;

            try {
                let obj = zr3d.flyRealTraceCreate({
                    clampToGround: false, //是否贴地
                    text: `${resourceName}的飞行器`, //文字
                    topOffset: 30, // 文字距离顶部的距离
                    fontSize: 22, //字体大小
                    fontColor: '#ffffff', //字体颜色
                    showBackground: true, //是否显示文字背景
                    "background-color": '#1A1C20',// 背景色
                    showOutLine: true,
                    outlineColor: '#d90800',
                    padding: new Cesium.Cartesian2(20, 10), // 文字背景的padding
                    onScreen: true, //是否显示在屏幕上
                    farScale: .3,
                    farDis: 80000,
                    id: id,
                    lng: subLng,
                    lat: subLat,
                    lineColor: this.getLineColor(),
                    lineWidth: 4,
                    flyColor: '#FF000050',
                    canvasLabel: true,
                    size: 48,
                    useModel: true, //是否使用模型
                    type: "typeLive",
                    resourceType: resourceType,
                    resourceName: resourceName,
                    // unitName: unitName,
                    deviceSn: subDeviceSn,
                });

                obj.id = id;
                obj.start();
                this.djiDroneTrajectoryList.push(obj);

                //更新位置
                obj.updatePosition({
                    x: subLng,
                    y: subLat,
                    z: subAltitude,
                    heading: attitude_head,
                    // speed: data.horizontalSpeed,
                });

            } catch (error) {
                console.log('资源载入失败', error);
            }
        },
        // 更新机库飞行器轨迹
        updateHangarTrack(info) {
            const { id, subLng, subLat, subAltitude, attitude_head } = info;
            let index = this.djiDroneTrajectoryList.findIndex(item => item.id == id);
            if (index > -1) {
                this.djiDroneTrajectoryList[index].updatePosition({
                    x: subLng,
                    y: subLat,
                    z: subAltitude,
                    heading: attitude_head,
                });
                // 更新轨迹颜色
                this.djiDroneTrajectoryList[index].updateStyle({ lineColor: this.getLineColor() })
            }
        },
        // 销毁机库飞行器轨迹
        destroyHangarTrack(info) {
            const { id } = info;
            let index = this.djiDroneTrajectoryList.findIndex(item => item.id == id);
            if (index > -1) {
                this.djiDroneTrajectoryList[index].clear();
                this.djiDroneTrajectoryList.splice(index, 1);
            }
        },
        // 创建机库图标
        createHangar(info) {
            const {
                lng,
                lat,
                resourceName,
                id,
                resourceStatus,
                resourceType,
                subLng,
                subLat,
                subAltitude,
                attitude_head,
                subDeviceSn
            } = info;

            let inserted = false; // 标记是否已执行插入操作

            // 判断经纬度是否有效
            if (lng > 0 && lat > 0) {
                // 机库图标
                let hangarInfo = {
                    x: lng, // 纬度
                    y: lat, // 经度
                    z: 0, // 高度
                    id: id, // 资源id
                    clampToGround: true, // 贴地
                    text: resourceName, // 文字
                    // layer: "resourceListManagementLayer", // 图层
                    color: '#ffffff', // 文字颜色
                    showBackground: true, // 是否显示文字背景
                    "background-color": '#1A1C20',// 背景色
                    labelOffsetY: -70, // 文字距离顶部的距离
                    fontSize: 20, // 字体大小
                    type: "resList", // 类型
                    image: require(`@/assets/map/airport${resourceStatus == 1 ? 0 : 1}.png`), // 图标
                    farScale: 0.6, // 最远显示比例
                    farDis: 10000, // 最远显示距离
                    canvasLabel: true, // 是否使用canvas绘制文字
                    showOutLine: true, // 是否显示文字轮廓
                    outlineColor: this.getColorByStatus(resourceStatus), // 文字轮廓颜色
                    prop: {
                        lng: lng,
                        lat: lat,
                    }
                }
                zr3d.markAdd(hangarInfo);
                this.djiDroneIconList.push({ id });
                inserted = true;
            }

            // 对飞行器的经纬度进行判断
            if (resourceStatus !== 2) {
                //    如果飞行器的经纬度无效，取遥控器坐标，并且对遥控器坐标进行处理
                if (subLng < 1 || subLat < 1) return false;
                let newLng = subLng;
                let newLat = subLat;
                // 如果飞行器经纬度与机库经纬度相同，对飞行器坐标进行处理
                if (subLng == lng && subLat == lat) {
                    newLng = subLng + 0.0001;
                    newLat = subLat + 0.0001;
                }

                // 飞行器图标
                let djiDroneInfo = {
                    x: newLng,
                    y: newLat,
                    z: subAltitude || 0,
                    heading: attitude_head,
                    id: "plane" + id,
                    text: `${resourceName}的飞行器`,
                    clampToGround: true, // 贴地
                    color: '#ffffff',
                    image: require(`@/assets/map/plane.png`),
                    type: "typeLive",
                    showBackground: true,
                    "background-color": '#1A1C20',// 背景色
                    labelOffsetY: -70,
                    fontSize: 20, // 字体大小
                    farScale: 0.6,
                    farDis: 10000,
                    canvasLabel: true,
                    prop: {
                        resourceType: resourceType,
                        resourceName: resourceName,
                        // unitName: unitName,
                        subDeviceSn: subDeviceSn,
                    }
                }

                zr3d.markAdd(djiDroneInfo);
                if (!inserted) {
                    this.djiDroneIconList.push({ id });
                    inserted = true;
                }

            }
        },
        // 更新机库图标
        updateHangar(info) {

            const {
                lng,
                lat,
                resourceName,
                id,
                resourceStatus,
                subLng,
                subLat,
                subAltitude,
                attitude_head,
            } = info;

            let hangarInfo = { guid: id, x: lng, y: lat, z: 0, text: resourceName, outlineColor: this.getColorByStatus(resourceStatus) }
            let planeInfo = { guid: "plane" + id, x: subLng, y: subLat, z: subAltitude, heading: attitude_head }
            zr3d.markUpdate(hangarInfo)
            zr3d.markUpdate(planeInfo)
        },
        // 销毁机库图标
        destroyHangar(info) {
            const { id } = info;
            let index = this.djiDroneIconList.findIndex(item => item.id == id);
            if (index > -1) {
                zr3d.markRemove({ guid: id });
                zr3d.markRemove({ guid: "plane" + id });
                this.djiDroneIconList.splice(index, 1);
            }
        },
        // 视锥构建
        createDroneView(info) {
            // {
            //     "id": "920386273930317825",
            //     "lng": 113.338325963,
            //     "lat": 23.143686945,
            //     "altitude": 28.60580062866211,
            //     "resourceName": "DJI Dock",
            //     "resourceType": 1,
            //     "resourceStatus": 1,
            //     "dockStatus": 1,
            //     "nextProjectName": "成果列表",
            //     "nextJobName": "一键起飞 2023-11-15 11:18:33",
            //     "subDeviceStatus": 1,
            //     "subDeviceId": "920386273930317827",
            //     "subDeviceName": "",
            //     "subLng": 113.33832283356932,
            //     "subLat": 23.143688503751417,
            //     "subAltitude": 28.741107940673828,
            //     "subDeviceSn": "1581F5BMD23230012L30",
            //     "sortIndex": 1,
            //     "actionIndex": 0,
            //     "attitude_head": -154
            // }
            // const { id, attitude_head } = info;
            // // const { attitudeHead, gimbalYaw, photoWidth, photoHeight, fov, subLng, subLat, elevation, gimbalPitch, gimbalRoll, horizontalSpeed } = data;

            // let heading = attitude_head;

            // if (gimbalRoll == 180) {
            //     if (gimbalYaw < 90) {
            //         heading = gimbalYaw + 180;
            //     } else if (gimbalYaw > 90) {
            //         heading = gimbalYaw - 180;
            //     }
            // }
            // let obj = zr3d.flyManagerCreate({
            //     aspectRatio: photoWidth / photoHeight, //宽高比
            //     fov: fov,
            //     viewMode: 0,
            //     color: "#00ff7f40",
            //     outlineColor: '#00ff7f'
            // });

            // obj.id = resourceId;
            // this.djiDroneViewList.push(obj);

            // obj.updatePosition({
            //     x: subLng,
            //     y: subLat,
            //     z: elevation,
            //     heading: heading,
            //     pitch: gimbalPitch,
            //     roll: gimbalRoll,
            //     speed: Number(horizontalSpeed.toFixed(1)),
            // });
        },
        // 视锥更新
        updateDroneView(info) { },
        // 视锥销毁
        destroyDroneView(info) { },
        // 创建资源
        createResource(item) {

            const {
                lat,
                lng,
                resourceType,
                resourceStatus,
                subLng,
                subLat,
                subAltitude,
                id,
                resourceName,
                attitude_head,
                targetLat,
                targetLng,
                unitName,
                subDeviceSn,
                altitude,
                livePlayUrl,
                targetName,
                elevation,
                //小程序新增字段
                deviceUserType,
                playStreamUrl,
                appPlayStreamUrl
            } = item;

            switch (resourceType) {
                case 1:

                    // 创建机库飞行器轨迹
                    this.createHangarTrack(item);
                    // 创建机库图标
                    this.createHangar(item);
                    // 
                    if (resourceStatus == 2) this.createDroneView(item);
                    break;
                case 2:
                    let remoteInfo = {
                        x: lng, // 纬度
                        y: lat, // 经度
                        z: 0, // 高度
                        id, // 资源id
                        clampToGround: true, // 贴地
                        text: resourceName, // 文字
                        layer: "resMark", // 图层
                        color: '#ffffff', // 文字颜色
                        showBackground: true, // 是否显示文字背景
                        "background-color": '#1A1C20',// 背景色
                        labelOffsetY: -70, // 文字距离顶部的距离
                        fontSize: 20, // 字体大小
                        type: "resList", // 类型
                        image: require(`@/assets/map/remote_control${resourceStatus == 1 ? 0 : 1}.png`), // 图标
                        farScale: 0.3, // 最远显示比例
                        farDis: 10000, // 最远显示距离
                        canvasLabel: true, // 是否使用canvas绘制文字
                        showOutLine: true, // 是否显示文字轮廓
                        outlineColor: this.getColorByStatus(resourceStatus), // 文字轮廓颜色
                        prop: {
                            resourceType, // 资源类型
                            resourceName, // 资源名称
                            lng,
                            lat,
                        }
                    }
                    // 遥控器的位置
                    zr3d.markAdd(remoteInfo);

                    if (subLat < 1 || subLng < 1) return false; // 无效的坐标不显示
                    // 写入一个飞行实例
                    if (resourceStatus == 2) {
                        let djiDrone = {
                            id,
                            subLng,
                            subLat,
                            subAltitude: elevation,
                            color: this.getColorByStatus(resourceStatus),
                            text: `${resourceName}的飞行器`,
                            attitude_head,
                            type: "typeLive",
                            resourceType,
                            resourceName,
                            unitName,
                            subDeviceSn,
                            clampToGround: false,
                            onScreen: false,
                        }

                        this.createResourceTrack(djiDrone); // dji飞行器

                        this.djiOrthographic(item); // 开启视锥
                    } else {

                        // 飞行器图标
                        let djiDroneInfo = {
                            x: subLng,
                            y: subLat,
                            z: subAltitude,
                            id: "plane" + id,
                            text: `${resourceName}的飞行器`,
                            clampToGround: true, // 贴地
                            layer: "resMark",
                            color: '#ffffff',
                            image: require(`@/assets/map/plane.png`),
                            type: "typeLive",
                            showBackground: true,
                            "background-color": '#1A1C20',// 背景色
                            labelOffsetY: -70,
                            fontSize: 20, // 字体大小
                            farScale: 0.3,
                            farDis: 10000,
                            canvasLabel: true,
                            prop: {
                                resourceType,
                                resourceName,
                                unitName,
                                subDeviceSn,
                                lng: subLng,
                                lat: subLat,
                            }
                        }

                        zr3d.markAdd(djiDroneInfo);

                    }
                    break;
                case 3:
                    // 地勤人员
                    if (resourceStatus == 2) {

                        let groundCrew = {
                            id,
                            subLng: lng,
                            subLat: lat,
                            subAltitude: 0,
                            color: this.getColorByStatus(resourceStatus),// 背景色
                            text: resourceName,
                            resourceName,
                            image: require(`@/assets/map/ground${resourceStatus == 1 ? 0 : 1}.png`),
                            type: "typeLive",
                            deviceUserType,
                            livePlayUrl: appPlayStreamUrl,
                            resourceType: resourceType,
                            clampToGround: true,
                            onScreen: true,
                        }

                        this.createResourceTrack(groundCrew);
                    } else {
                        let groundCrewInfo = {
                            x: lng,
                            y: lat,
                            z: 0,
                            id,
                            clampToGround: true,
                            showBackground: true,
                            "background-color": '#1A1C20',// 背景色
                            labelOffsetY: -70,
                            text: resourceName,
                            layer: "resMark",
                            color: '#ffffff',
                            type: "typeLive",
                            image: require(`@/assets/map/ground${resourceStatus == 1 ? 0 : 1}.png`),
                            fontSize: 20, // 字体大小
                            farScale: 0.3,
                            farDis: 10000,
                            canvasLabel: true,
                            showOutLine: true,
                            outlineColor: this.getColorByStatus(resourceStatus),
                            prop: {
                                resourceType, // 资源类型
                                resourceName, // 资源名称
                                deviceUserType,//二级类型
                                appPlayStreamUrl,//推流地址
                                lng,
                                lat,
                            }
                        }

                        zr3d.markAdd(groundCrewInfo);

                    }
                    break;
                case 4:

                    // 纵横无人机
                    let jouovDrone = {
                        id,
                        subLng: lng,
                        subLat: lat,
                        subAltitude: altitude,
                        text: `${resourceName}飞行器`,
                        attitude_head: attitude_head,
                        type: "typeLive",
                        resourceType,
                        livePlayUrl,
                        clampToGround: false,
                        color: this.getColorByStatus(resourceStatus),// 背景色
                        onScreen: false,
                    }

                    this.createResourceTrack(jouovDrone);

                    this.jouovOrthographic(item); // 开启视锥
                    if (targetLat < 1 || targetLng < 1) return;
                    // 可疑目标
                    let targetInfo = {
                        id: 'target' + id,
                        subLng: targetLng,
                        subLat: targetLat,
                        subAltitude: 0,
                        image: require(`@/assets/map/target.png`),
                        text: targetName,
                        attitude_head,
                        type: "target",
                        clampToGround: true,
                        color: '#EF6767',// 背景色
                        onScreen: true,
                        lng,
                        lat,
                        targetLineColor: '#EF6767'
                    }

                    this.createResourceTrack(targetInfo);

                    break;
            }

            // 找出显示弹窗的资源 隐藏title
            if (id && id === this.resId) {
                this.showCanvasLabel(id, false);
            }

        },
        // 更新轨迹颜色显示
        updateTrackColor() {
            if (realTimeTrajectoryList.length == 0) return;
            realTimeTrajectoryList.forEach(item => {
                // 如果id是target开头的，就是可疑目标，给定一个颜色
                if (item.id.indexOf('target') > -1) {
                    item.updateStyle({
                        lineColor: this.isShowTrack ? '#EF6767' : '#EF676700',
                    })
                } else {
                    // item.setFrustumColor("#00ff7f40")
                    item.updateStyle({
                        lineColor: this.isShowTrack ? '#00ffff' : '#00ffff00',
                    })
                }
            })
            // wusixiaodu_ship_target_list
            wusixiaodu_ship_target_list.forEach(item => {
                item.updateStyle({
                    lineColor: this.isShowTrack ? '#00ffff' : '#00ffff00',
                })
            }
            )
        },
        // 资源销毁
        destroyResource(item) {
            const { id, resourceType } = item;

            switch (resourceType) {
                case 1:
                    this.destroyHangarTrack(item); // 机库飞行器轨迹销毁
                    this.destroyHangar(item); // 机库图标销毁
                    this.destroyDroneView(item); // 飞行器视锥销毁
                    break;
                case 2:
                    let remote = { guid: id }
                    let plane = { guid: "plane" + id }

                    zr3d.markRemove(plane)
                    zr3d.markRemove(remote)
                    this.destroyResourceTrack(id);
                    this.clearOrthophotoMap(id);
                    break;
                case 3:
                    let ground = { guid: id }

                    zr3d.markRemove(ground)
                    this.destroyResourceTrack(id);
                    break;
                case 4:
                    this.clearOrthophotoMap(id);
                    this.destroyResourceTrack(id);
                    this.destroyResourceTrack({ id: 'target' + id });
            }
        },
        // 资源的更新
        updateResource(item) {
            const { id, resourceType, resourceStatus, lng, lat, subLng, subLat, subAltitude, targetLng, targetLat, attitude_head, altitude, elevation, appPlayStreamUrl } = item;
            switch (resourceType) {
                case 1:
                    this.updateHangarTrack(item); // 机库飞行器轨迹更新
                    this.updateHangar(item); // 机库图标更新

                    if (resourceStatus == 2) this.updateDroneView(item); // 飞行器视锥更新
                    break;
                case 2:
                    let remote = { guid: id, x: lng, y: lat, z: 0 }
                    zr3d.markUpdate(remote)

                    if (resourceStatus == 2) {
                        let plane = {
                            id,
                            subLng,
                            subLat,
                            subAltitude: elevation,
                            attitude_head,
                            resourceType,
                        }

                        this.updateResourceTrack(plane);//轨迹线的更新
                        this.djiOrthographic(item); // 开启视锥
                    } else {
                        let plane = { guid: "plane" + id, x: subLng, y: subLat, z: subAltitude }
                        zr3d.markUpdate(plane)
                    }

                    break;
                case 3:

                    if (resourceStatus == 2) {
                        let ground = {
                            id,
                            subLng: lng,
                            subLat: lat,
                            subAltitude: 0,
                            attitude_head: 0,
                            resourceType,
                        }
                        this.updateResourceTrack(ground);
                    } else {
                        let ground = { guid: id, x: lng, y: lat, z: 0 }
                        zr3d.markUpdate(ground)
                    }
                    break;
                case 4:

                    let jouovDrone = {
                        id,
                        subLng: lng,
                        subLat: lat,
                        subAltitude: altitude,
                        attitude_head: attitude_head,
                        resourceType
                    }

                    this.updateResourceTrack(jouovDrone);

                    let targetInfo = {
                        id: 'target' + id,
                        subLng: targetLng,
                        subLat: targetLat,
                        subAltitude: 0,
                        attitude_head: 0,
                        resourceType
                    }

                    this.updateResourceTrack(targetInfo);
                    this.jouovOrthographic(item);

                    break;
            }
        },
        // 清除正射影像图
        clearOrthophotoMap(id) {

            let obj = orthophotoMapList.find((item) => item && item.id == id);
            this.clearVideoElement();
            if (obj) {
                obj.stopPath();
                obj.clear();
                obj = null;

                orthophotoMapList.splice(orthophotoMapList.findIndex((item) => item && item.id == id), 1);

            }
        },
        // 开启正射 、、loadCamera
        async jouovOrthographic(item) {

            const { id, lat, lng, altitude, attitude_head, gimbal_tilt, gimbal_roll, ground_speed, gimbal_pan } = item
            // return false;
            // 打印解构出来的数据
            let obj = orthophotoMapList.find((item) => item && item.id == id);
            if (obj) {

                if (this.orthoImageLayerId == '') {
                    //  执行视锥视频元素的的清除
                    this.clearVideoElement();
                } else if (this.orthoImageLayerId == id) {
                    // 有值
                    this.createVideoElement(item);
                }

                obj.updatePosition({
                    x: lng,
                    y: lat,
                    z: altitude,
                    heading: (gimbal_pan / 10000.0) * 180 / Math.PI,
                    pitch: (gimbal_tilt / 10000.0) * 180 / Math.PI,
                    roll: (gimbal_roll / 10000.0) * 180 / Math.PI,
                    speed: ground_speed / 10 || 20,
                    // flyheading: (attitude_head / 10000.0) * 180 / Math.PI,
                    flyheading: attitude_head,

                });

                // 新增一个条件判断
                if (!videoEntity || this.orthoImageLayerId == '') {
                    obj.setFrustumColor("#00ff7f40")
                    return false;
                }
                let that = this;
                obj.positionUpdateCallback = function (position, hpr) {
                    if (!videoEntity || that.orthoImageLayerId == '') return false;
                    obj.setFrustumColor("#00ff7f00")
                    if (that.orthoImageLayerId == id) {
                        var lnglat = convertXYZToLLH(position.x, position.y, position.z);
                        var points = obj.frustumEntity.calculateFarPlane(
                            position,
                            hpr,
                            lnglat.height
                        );

                        videoEntity.setPositions(points, hpr.heading);
                    }

                };

            } else {
                obj = zr3d.flyManagerCreate({
                    aspectRatio: Math.tan(2727 / 10000.0) / Math.tan(1540 / 10000.0), //宽高比
                    viewMode: 0,
                    color: "#00ff7f40",
                    far: 2000,
                    outlineColor: '#00ff7f'
                });

                obj.id = id;
                orthophotoMapList.push(obj);
            }

        },
        //创建视锥内部的视频元素
        async createVideoElement(item) {
            //执行删除
            this.clearVideoElement();
            const { id } = item;
            let video = null;
            const liveId = `tcPlayer${id}`;

            const element = document.getElementById(liveId).children[0];

            if (element.hasChildNodes()) {
                video = element.children[0].children[0];
            } else {
                video = element
            }

            videoEntity = zr3d.videoEntityCreate({
                element: video, //或者图片
            });
        },
        // 清除视锥内部的视频元素
        clearVideoElement() {
            if (videoEntity) {
                videoEntity.clear();
                videoEntity = null;
            }
        },
        // 大疆
        async djiOrthographic(item) {

            const { id, subLat, subLng, elevation, attitude_head, fov, gimbal_pitch, gimbal_roll, gimbal_yaw, photoHeight, photoWidth, horizontal_speed } = item

            let obj = orthophotoMapList.find((item) => item && item.id == id);


            if (obj) {
                let heading = attitude_head;

                if (gimbal_roll == 180) {
                    if (gimbal_yaw < 90) {
                        heading = gimbal_yaw + 180;
                    } else if (gimbal_yaw > 90) {
                        heading = gimbal_yaw - 180;
                    }
                }


                if (this.orthoImageLayerId == '') {
                    //  执行视锥视频元素的的清除
                    this.clearVideoElement();
                } else if (this.orthoImageLayerId == id) {
                    // 有值
                    this.createVideoElement(item);
                }

                obj.updatePosition({
                    x: subLng,
                    y: subLat,
                    z: elevation,
                    heading: heading,
                    pitch: gimbal_pitch,
                    roll: gimbal_roll,
                    speed: Number(horizontal_speed.toFixed(1)),
                });
                // 新增一个条件判断
                if (!videoEntity || this.orthoImageLayerId == '') {
                    obj.setFrustumColor("#00ff7f40")
                    return false;
                }
                let that = this;
                obj.positionUpdateCallback = function (position, hpr) {
                    if (!videoEntity || that.orthoImageLayerId == '') return false;
                    obj.setFrustumColor("#00ff7f00")
                    if (that.orthoImageLayerId == id) {
                        var lnglat = convertXYZToLLH(position.x, position.y, position.z);
                        var points = obj.frustumEntity.calculateFarPlane(
                            position,
                            hpr,
                            lnglat.height
                        );

                        videoEntity.setPositions(points, hpr.heading);
                    }

                };

            } else {
                obj = zr3d.flyManagerCreate({
                    aspectRatio: photoWidth / photoHeight, //宽高比
                    // fov: fov, //视域角
                    fov: fov,
                    viewMode: 0,
                    color: "#00ff7f40",
                    outlineColor: '#00ff7f'
                });

                obj.id = id;
                orthophotoMapList.push(obj);
            }

        },
        // 清除所有视锥
        orthophotoMapListDelAll() {

            if (orthophotoMapList.length == 0) return;

            for (let i = 0; i < orthophotoMapList.length; i++) {

                orthophotoMapList[i].stopPath();
                orthophotoMapList[i].clear();
                orthophotoMapList[i] = null;

                if (i == orthophotoMapList.length - 1) {
                    orthophotoMapList = [];
                }
            }
        },
        // 文字颜色
        getColorByText(index) {
            let color = "#ffffff";
            switch (index) {
                case 1:
                    color = "#ffd700";
                    break;
                case 2:
                    color = "#ef6767";
                    break;
                case 3:
                    color = "#f1948a";
                    break;
            }
            return color;
        },
        // 根据状态返回颜色
        getColorByStatus(status) {
            let color = "#165DFF"; // 默认颜色
            if (status == 2) color = "#EF6767";
            return color;
        },
        // 获取lineColor
        getLineColor() {
            const isShowTrack = this.isShowTrack;
            let color = '#EF676700'
            if (isShowTrack) {
                color = '#EF6767'
            }
            return color;
        },
        // 资源实时行动轨迹：添加
        createResourceTrack(info) {
            let lineColor = '#00ffff';
            const {
                id,
                subLng,
                subLat,
                subAltitude,
                text,
                image,
                attitude_head,
                color,
                type,
                resourceType,
                livePlayUrl,
                unitName,
                subDeviceSn,
                resourceName,
                clampToGround,
                onScreen,
                lng,
                lat,
                targetLineColor,
                deviceUserType,//小程序二级类型
            } = info;

            if (targetLineColor) lineColor = targetLineColor;


            if (subLng < 1 || subLat < 1) return false;

            try {

                let obj = zr3d.flyRealTraceCreate({
                    clampToGround: clampToGround, //是否贴地
                    image: image, //图片
                    text: text, //文字
                    topOffset: 30, // 文字距离顶部的距离
                    fontSize: 22, //字体大小
                    fontColor: '#ffffff', //字体颜色
                    type: type, //类型
                    subLng: subLng, //经度
                    subLat: subLat, //纬度
                    resourceType: resourceType, //资源类型
                    livePlayUrl: livePlayUrl, //直播地址
                    unitName: unitName, //单位名称
                    deviceUserType,//二级类型
                    subDeviceSn: subDeviceSn, //  设备sn
                    resourceName: resourceName, //资源名称
                    useModel: resourceType == 2 || resourceType == 4 ? true : false, //是否使用模型
                    showBackground: true, //是否显示文字背景
                    "background-color": '#1A1C20',// 背景色
                    showOutLine: true,
                    outlineColor: color,
                    size: 48, //模型大小
                    padding: new Cesium.Cartesian2(20, 10), // 文字背景的padding
                    onScreen: onScreen || false, //是否显示在屏幕上
                    farScale: 0.3,
                    farDis: 10000,
                    id,
                    lng,
                    lat,
                    lineColor: this.isShowTrack ? lineColor : `${lineColor}00`,
                    lineWidth: 4,
                    flyColor: color,
                    canvasLabel: true,
                });
                obj.id = id;
                obj.start();
                realTimeTrajectoryList.push(obj);
                //更新位置
                obj.updatePosition({
                    x: subLng,
                    y: subLat,
                    z: subAltitude,
                    heading: (resourceType == 2 || resourceType == 4) ? (attitude_head || 0) : -(attitude_head > 0 ? attitude_head : attitude_head + 360) || 0,
                });

            } catch (error) {
                console.log('资源载入失败', error);
            }


        },
        // 更新资源实时行动轨迹
        updateResourceTrack(info) {
            const {
                id,
                subLng,
                subLat,
                subAltitude,
                attitude_head,
                resourceType,
            } = info;

            if (subLng < 1 || subLat < 1) return false;
            let obj = realTimeTrajectoryList.find((item) => item && item.id == id);

            if (obj) {

                obj.updatePosition({
                    x: subLng,
                    y: subLat,
                    z: subAltitude,
                    //根据resourceType字段判断是纵横无人机还是大疆无人机
                    heading: resourceType == 2 || resourceType == 4 ? attitude_head || 0 : -(attitude_head > 0 ? attitude_head : attitude_head + 360) || 0,

                });

            }

        },
        // 资源实时行动轨迹：清除
        destroyResourceTrack(id) {

            if (realTimeTrajectoryList.length == 0) return;

            for (let i = 0; i < realTimeTrajectoryList.length; i++) {

                if (realTimeTrajectoryList[i] && realTimeTrajectoryList[i].id == id) {
                    realTimeTrajectoryList[i].clear();

                    realTimeTrajectoryList.splice(i, 1);
                }
            }
        },
        // 全部清理
        ListDelAll() {

            if (realTimeTrajectoryList.length == 0) return;

            for (let i = 0; i < realTimeTrajectoryList.length; i++) {

                realTimeTrajectoryList[i].clear();

                if (i == realTimeTrajectoryList.length - 1) {
                    realTimeTrajectoryList = [];
                }
            }
        },
        // 锁定中心点以及视距
        lockCenterPoint(pointList) {

            if (pointList.length == 0) return false;

            if (pointList.length == 1) {
                this.resPosition(pointList[0]);
                return false;
            }

            let xArr = [];
            let yArr = [];

            pointList.forEach((item) => {
                xArr.push(
                    item.x
                        ? Number(item.x.toFixed(7))
                        : Number(Number(item.lng).toFixed(7))
                );
                yArr.push(
                    item.y
                        ? Number(item.y.toFixed(7))
                        : Number(Number(item.lat).toFixed(7))
                );
            });

            xArr.sort(function (a, b) {
                return a - b;
            });

            yArr.sort(function (a, b) {
                return a - b;
            });

            let minX = xArr[0];
            let maxX = xArr[xArr.length - 1];
            let minY = yArr[0];
            let maxY = yArr[yArr.length - 1];
            let centerX = (minX + maxX) / 2;
            let centerY = (minY + maxY) / 2;

            let x = Math.pow(maxX - minX, 2);
            let y = Math.pow(maxY - minY, 2);
            let z = Math.sqrt(x + y) * 111000 * 4;

            if (z < 2000) z = 2000;

            zr3d.locationSet({
                x: centerX, //位置x [必填]
                y: centerY, //位置y  [必填]
                z: 0, //位置z  [必填]
                dis: z, //距离
                heading: 0, //偏转角
                pitch: -90, //俯仰角
                flyTime: 2,
            });
        },
        // 单个资源定位
        resPosition(info) {

            zr3d.locationSet({
                x: info.lng, //位置x [必填]
                y: info.lat, //位置y  [必填]
                z: 0, //位置z  [必填]
                dis: 2000, //距离
                heading: 0, //偏转角
                pitch: -90, //俯仰角
                flyTime: 2,
            });
        },
        // 显示资源信息弹窗
        resourcesLabel() {
            // resourceStatus	资源状态，0：全部；1：空闲；2：执行中；3：离线
            const { altitude, lng, lat, resourceStatus } = this.selectedResInfo
            if (lng < 1 || lat < 1) return;

            if (resourceStatus == 1 || resourceStatus == 2) {
                if (divLabel) {
                    divLabel.setLngLat(
                        this.selectedResInfo.lng,
                        this.selectedResInfo.lat,
                        altitude || 0
                    );
                    if (!divLabel.show) divLabel.show = true;
                } else {
                    divLabel = new DivLabel(zr3d.getViewer(), {
                        x: lng,
                        y: lat,
                        z: altitude || 0,
                        show: true,
                        div: document.getElementById("resourcesInfo"),
                        displayDis: 10000,
                        offsetY: 42,
                        offsetX: 0,
                    });
                }
            } else {
                if (divLabel) {
                    divLabel.show = false;
                    this.$store.commit("resource/SET_RES_ID", -1); // 清理选中值
                }
            }
        },
        // 隐藏弹窗
        hideResLabel() {
            if (divLabel) {
                divLabel.show = false;
                this.$store.commit("resource/SET_RES_ID", -1); // 清理选中值
            }
        },
        // 清理地图所有的图标信息
        cleanAll() {
            // 循环this.oldData,移除所有的图标
            this.oldData.forEach(item => {
                this.destroyResource(item); // 销毁资源
            }
            );
            this.oldData = [];
        },
        // 地图资源相关清理
        cleanMap() {
            this.orthophotoMapListDelAll();
            this.hideResLabel();
            this.ListDelAll();
            this.cleanAll();
            this.wusixiaoduDestroyAll();
        },
    },
};
