import store from "@/store/index"
import { setInterval } from "core-js";
class videoMarker {
    constructor() {
        this.blackHeightMargin = 0;
        this.blackWidthMargin = 0;
        this.textureTool = null;

        this.video = null;
        this.canvas = null;
        this.ctx = null;
        this.count = 0 //计数器
        this.cameraInfo = null
        this.pointList = [] // 打点位置列表
        // 定时器id
        this.timer = null
    }
    // 设置画布大小
    initCanvasSize(playerType, elementId, callback) {
        const element = document.getElementById("largeWindow");
        const containerAspectRatio = element.clientWidth / element.clientHeight;


        if (playerType == "tcplayer") {
            this.video = element.children[0];
        } else if (playerType == "agora") {
            this.video = element.children[0].children[0].children[0];
        } else if (playerType == "webrtc") {
            this.video = element;
        }

        this.canvas = document.getElementById(elementId); //canvas dom
        this.ctx = this.canvas.getContext("2d");

        const videoAspectRatio = this.video.videoWidth / this.video.videoHeight;
        let h = 0;
        let w = 0;
        console.log("videoAspectRatio", videoAspectRatio);
        console.log("containerAspectRatio", containerAspectRatio);
        if (videoAspectRatio > containerAspectRatio) {
            console.log(" 宽度铺满");
            const proportion = this.video.clientWidth / this.video.videoWidth;

            h = this.video.videoHeight * proportion;
            w = this.video.clientWidth;

            this.blackHeightMargin = (this.video.clientHeight - h) / 2;
        } else {
            console.log("高度铺满");
            const proportion = this.video.clientHeight / this.video.videoHeight;

            h = this.video.clientHeight;
            w = this.video.videoWidth * proportion;
            this.blackWidthMargin = (this.video.clientWidth - w) / 2;
        }

        this.canvas.width = w;
        this.canvas.height = h;

        if (typeof callback === "function") {
            callback(); // 触发加载开始的回调
        }


    }
    initVideoSizeChange() {
        // 如果未设置video，则不执行

        if (!this.video) return false;
        this.removeTextureTool()
        const element = document.getElementById("largeWindow");
        const containerAspectRatio = element.clientWidth / element.clientHeight;

        const videoAspectRatio = this.video.videoWidth / this.video.videoHeight;
        let h = 0;
        let w = 0;

        if (videoAspectRatio > containerAspectRatio) {
            console.log(" 宽度铺满");
            const proportion = this.video.clientWidth / this.video.videoWidth;

            h = this.video.videoHeight * proportion;
            w = this.video.clientWidth;

            this.blackHeightMargin = (this.video.clientHeight - h) / 2;
        } else {
            console.log("高度铺满");
            const proportion = this.video.clientHeight / this.video.videoHeight;

            h = this.video.clientHeight;
            w = this.video.videoWidth * proportion;
            this.blackWidthMargin = (this.video.clientWidth - w) / 2;
        }

        this.canvas.width = w;
        this.canvas.height = h;

        if (typeof callback === "function") {
            callback(); // 触发加载开始的回调
        }


    };
    // 开启视频打点功能
    startVideoMarker(info) {
        this.cameraInfo = info
        this.canvas.addEventListener("click", (e) => {
            this.drawVideoMarker(e);
        });

        this.initTextureTool()
    }
    // 视频打点_绘制事件
    drawVideoMarker(e) {

        const pos = {
            x: this.blackWidthMargin > 0 ? e.offsetX - this.blackWidthMargin : e.offsetX,
            // y: e.offsetY
            y: this.blackHeightMargin > 0 ? e.offsetY - this.blackHeightMargin : e.offsetY
        };

        var uv = {
            u: pos.x / this.canvas.width,
            v: pos.y / this.canvas.height
        };

        this.setPostionAndPosture(uv);
    }
    // 设置打点位置
    setPostionAndPosture(uv) {
        const droneInfo = store.getters.droneInfo;
        const { longitude, latitude, elevation, attitude_head } = droneInfo;
        const gimbal = droneInfo[Object.keys(droneInfo)[0]];
        const { gimbal_pitch, gimbal_roll } = gimbal;

        this.textureTool.setPostionAndPosture({
            x: longitude, //飞机相机位置
            y: latitude,
            z: elevation,
            pitch: gimbal_pitch, //飞机相机俯仰角
            heading: attitude_head, //飞机相机航向角
            roll: gimbal_roll, //飞机相机翻滚角
        });

        //计算得到照片位置对应的地面的空间位置
        uv.v = 1 - uv.v;//设置点y轴相反
        var point = this.textureTool.calculatePointCoordbyUV(uv, true, true);

        // 缓存打点位置
        this.pointList.push(point);
        this.drawMark(point);
        clearInterval(this.timer)
        this.startDrawing();
        this.timer = setInterval(() => {

            this.startDrawing();
        }, 1000)
    }
    // 视频打点_绘制实例
    drawMark(uv) {
        let markOptions = {
            id: "videoMarker" + this.count, //唯一ID
            type: "typeVideoMarker",
            layer: "videoMarkerLayer",
            x: uv.x, //经度
            y: uv.y, //纬度
            z: uv.z, //高度
            image: require("@/assets/map/selected_item.png"), //图标
            scale: 0.8,
            text: '标点' + this.count,
            clampToGround: true, // 贴地
            color: '#ffffff', // 文字颜色
            showBackground: true, // 是否显示文字背景
            "background-color": '#1A1C20',// 背景色
            labelOffsetY: -70, // 文字距离顶部的距离
            fontSize: 20, // 字体大小
            farScale: 0.3, // 最远显示比例
            farDis: 10000, // 最远显示距离
            canvasLabel: true, // 是否使用canvas绘制文字
            showOutLine: true, // 是否显示文字轮廓
            outlineColor: '#00ff7f', // 文字轮廓颜色
            prop: {
                // 经度
                longitude: uv.x,
                // 纬度
                latitude: uv.y,
            },
        };

        zr3d.markAdd(markOptions);
        this.count++;

    }
    // 在canvas上绘制打点
    drawMarkOnCanvas(uv) {
        if (!uv) return false;
        uv.v = 1 - uv.v; //写入点y轴相反
        this.ctx.beginPath();
        this.ctx.arc(uv.u * this.canvas.width, uv.v * this.canvas.height, 10, 0, 2 * Math.PI);
        this.ctx.fillStyle = "red";
        this.ctx.fill();
        this.ctx.closePath();
    }
    // 初始化打点工具
    initTextureTool() {
        zr3d.layerAdd({
            name: "videoMarkerLayer",
        });
        this.textureTool = zr3d.textureToolCreate();
        const { fov, photoWidth, photoHeight } = this.cameraInfo;
        this.textureTool.setCameraParam({
            width: photoWidth, //原始照片宽度
            height: photoHeight, //原始照片高度
            fov: fov, //视场角
        });
    }
    // 设置一个定时器，循环检测pointList的值，调用calculateUVCoordbyPoint
    // 计算打点位置
    startDrawing() {

        const pointList = this.pointList;
        const droneInfo = store.getters.droneInfo;
        const { longitude, latitude, elevation, attitude_head } = droneInfo;
        const gimbal = droneInfo[Object.keys(droneInfo)[0]];
        const { gimbal_pitch, gimbal_roll } = gimbal;

        this.textureTool.setPostionAndPosture({
            x: longitude, //飞机相机位置
            y: latitude,
            z: elevation,
            pitch: gimbal_pitch, //飞机相机俯仰角
            heading: attitude_head, //飞机相机航向角
            roll: gimbal_roll, //飞机相机翻滚角
        });
        // 清空画布
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        pointList.forEach((point, index) => {
            const uv = this.calculateUVCoordbyPoint(point);
            if (uv) this.drawMarkOnCanvas(uv);
        })

    }
    // 反算打点位置
    calculateUVCoordbyPoint(point) {
        return this.textureTool.calculateUVCoordbyPoint(point);
    }
    // 移除打点工具
    removeTextureTool() {
        clearInterval(this.timer)
        zr3d.layerRemove("videoMarkerLayer");
        if (this.ctx) this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        if (this.canvas) this.canvas.removeEventListener("click", this.drawVideoMarker);
        if (this.textureTool) this.textureTool = null;
    }
    // 销毁
    destroy() {
        this.removeTextureTool();

        this.blackHeightMargin = 0;
        this.blackWidthMargin = 0;

        this.video = null;
        this.canvas = null;
        this.ctx = null;
        this.count = 0 //计数器
        this.cameraInfo = null
    }
}
export default videoMarker;
