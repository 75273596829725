export default {
    bind(el, binding) {
      let width = '', height = '';
      function isReize() {
        const style = document.defaultView.getComputedStyle(el);
        if (width !== style.width || height !== style.height) {
        binding.value(style) //传入整个style
        }
        width = style.width;
        height = style.height;

       
       }
       el.__timer__ = setInterval(isReize, 300); // 周期性监听元素是否改变
    },
    unbind(el) {
      clearInterval(el.__timer__);
    }
  }