import request from '@/utils/request.js'
// 获取项目所属资源列表
export function projectResourceList(data) {
    return request({
        url: '/api/manage/v1/resource/simpleProjectResourceList',
        method: 'post',
        data
    })
}

// 获取所有资源列表
export function resourceList(data) {
    return request({
        url: '/api/manage/v1/resource/list',
        method: 'post',
        data
    })
}
// 查询相机参数
export function getCameraParams(params) {
    return request({
        url: '/api/manage/v1/payload/getCameraParams',
        method: 'get',
        params
    })
}
