<template>
  <div class="menu_mine-content">
    <div
      class="menu_btn pointer"
      :class="{ menu_btn__active: item.path == activePath }"
      v-for="item in menuList"
      :key="item.path"
      @click="jump(item)"
    >
      <p>{{ item.text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activePath: "",
      menuList: [
        {
          text: "首页",
          // path: "/overview/index",
          path:'/statistics/index'
        },
        {
          text: "无人机专题",
          path: "/zxrob/index",
        },
        {
          text: "雷达监测专题",
          path: "/radar/index",
        },
        {
          text: "安环监测专题",
          path: "/monitor/index",
        },
        {
          text: "安全生产专题",
          path: "/safety/index",
        },
        {
          text: "灾险评估治理",
          path: "/riskAssessment/index",
        },
      ],
    };
  },
  watch: {
    $route: {
      handler(route) {
        const { path } = route;

        if (path.indexOf("overview") > -1) {
          this.activePath = "/overview/index";
        } else if (path.indexOf("radar") > -1) {
          this.activePath = "/radar/index";
        } else if (path.indexOf("monitor") > -1) {
          this.activePath = "/monitor/index";
        } else if (path.indexOf("safety") > -1) {
          this.activePath = "/safety/index";
        } else if (path.indexOf("riskAssessment") > -1) {
          this.activePath = "/riskAssessment/index";
        } else if (path.indexOf("statistics") > -1) {
          this.activePath = "/statistics/index";
        } else {
          this.activePath = "/zxrob/index";
        }
      },
      immediate: true,
    },
  },
  methods: {
    jump(item) {
      if (item.path == this.activePath) return;
      this.activePath = item.path;
      if (item.path == "/zxrob/index") {
        // this.$router.push("/statistics/index");
        this.$router.push("/command/index");
        return;
      }
      this.$router.push(item.path);
    },
  },
};
</script>

<style lang="less" scoped>
.menu_mine-content {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
  justify-content: center;
  .menu_btn {
    width: 144px;
    height: 40px;
    // background: #393c43;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    justify-content: center;
   

    &:first-child {
      height: 0;
      border-bottom: 40px solid #393c43;
      border-right: 40px solid transparent;
      position: relative;
      line-height: 40px;
      &:hover {
        border-bottom: 40px solid var(--btn-hover-color);
      }
    }
    &:first-child.menu_btn__active {
      border-bottom: 40px solid var(--btn-active-color);
    }
    &:nth-child(n+2) {
      margin: 15px;
      background-color: #393c43;
      text-align: center;
      transform: skewX(45deg);
      p {
        color: white;
        transform: skewX(-45deg);
        line-height: 40px;
      }
      &:hover {
        background: var(--btn-hover-color);
      }
    }
    &:nth-child(n+2).menu_btn__active{
      background-color: var(--btn-active-color) !important;
    }
  }

}
</style>
