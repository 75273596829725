const state = {
    selectedAiIndex: -1, // 当前选中的ai索引
    // 报警间隔
    alarmInterval: 20,
    personId: '',
    // 是否推送
    isPush: false,
    // ai图片列表
    aiImgList: [],
    carCount: 0,
}

const mutations = {
    // selectedAiIndex
    SET_SELECTED_AI_INDEX: (state, index) => {
        state.selectedAiIndex = index
    },
    // alarmInterval
    SET_ALARM_INTERVAL: (state, alarmInterval) => {
        state.alarmInterval = alarmInterval
    },
    // personId
    SET_PERSON_ID: (state, personId) => {
        state.personId = personId
    },
    // isPush
    SET_IS_PUSH: (state, isPush) => {
        state.isPush = isPush
    },
    // aiImgList push
    PUSH_AI_IMG_LIST: (state, aiImg) => {
        state.aiImgList.push(aiImg)
    },
    // carCount
    SET_CAR_COUNT: (state, carCount) => {
        state.carCount = carCount
    }

}

const actions = {
    writeSelectedAiIndex({ commit }, index) {
        commit('SET_SELECTED_AI_INDEX', index)
    },
    writeAlarmInterval({ commit }, alarmInterval) {
        commit('SET_ALARM_INTERVAL', alarmInterval)
    },
    writePersonId({ commit }, personId) {
        commit('SET_PERSON_ID', personId)
    },
    writeIsPush({ commit }, isPush) {
        commit('SET_IS_PUSH', isPush)
    },
    writeAiImgList({ commit }, aiImg) {
        commit('PUSH_AI_IMG_LIST', aiImg)
    },
    clearAiImgList({ commit }) {
        commit('PUSH_AI_IMG_LIST', [])
    },
    // SET_CAR_COUNT
    writeCarCount({ commit }, carCount) {
        commit('SET_CAR_COUNT', carCount)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
