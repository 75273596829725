<template>
  <section :class="{ mt250: schematicAddShow }">
    <schematicAdd v-if="schematicAddShow" @cancel="$emit('cancel')">
      <section class="tab-menu" v-if="resourceType == 3">
        <div class="tab-menu_item tab-right is_active">示意性任务</div>
      </section>
      <section class="tab-menu" v-else>
        <div
          class="tab-menu_item tab-left pointer"
          :class="{ is_active: standardAddShow }"
          @click="tabChange(1)"
        >
          标准性任务
        </div>
        <div
          class="tab-menu_item tab-right pointer"
          :class="{ is_active: schematicAddShow }"
          @click="tabChange(2)"
        >
          示意性任务
        </div>
      </section>
    </schematicAdd>

    <airlineAdd v-if="standardAddShow" @cancel="$emit('cancel')">
      <section class="tab-menu" v-if="resourceType == 1">
        <div class="tab-menu_item tab-left is_active">标准性任务</div>
      </section>
      <section class="tab-menu" v-else>
        <div
          class="tab-menu_item tab-left pointer"
          :class="{ is_active: standardAddShow }"
          @click="tabChange(1)"
        >
          标准性任务
        </div>
        <div
          class="tab-menu_item tab-right pointer"
          :class="{ is_active: schematicAddShow }"
          @click="tabChange(2)"
        >
          示意性任务
        </div>
      </section>
    </airlineAdd>
  </section>
</template>

<script>
// import CommonDialog from "@/components/CommonDialog/index.vue";
import schematicAdd from "@/views/task/components/mark/add.vue";
import airlineAdd from "@/views/task/components/airline/add.vue";
export default {
  components: {
    // CommonDialog,
    schematicAdd,
    airlineAdd,
  },
  props: {
    resourceType: {
      type: [Number, String],
      require: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.resourceType == 3) {
        this.schematicAddShow = true;
        this.standardAddShow = false;
      }
    });
  },
  data() {
    return {
      schematicAddShow: false,
      standardAddShow: true,
    };
  },
  methods: {
    tabChange(type) {
      if (type === 1) {
        this.schematicAddShow = false;
        this.standardAddShow = true;
      } else {
        this.schematicAddShow = true;
        this.standardAddShow = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tab-menu {
  height: 56px;
  border-bottom: 1px solid #93c8e4;
  display: flex;
  .tab-menu_item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: #ffffff;
  }
  .is_active {
    color: #a3ecfb;
  }
}
/deep/ .add-task_container {
  height: 576px;
}
/deep/ .title {
  display: none;
}
.mt250 {
  /deep/ .btn-group {
    margin-top: 196px !important;
  }
}
</style>