
<template>
  <el-tooltip effect="dark" placement="right">
    <section class="track-switch_container" @click="openLive">
      <i class="iconfont icon-achievement-video pointer"></i>
    </section>
    <!-- 这是提示语 -->
    <div class="tip-text" slot="content">
      <p>{{ "智联直播" }}</p>
    </div>
  </el-tooltip>
</template>

<script>
export default {
  methods: {
    openLive() {
      // this.$showMediaModal({
      //   type: 'video',
      //   src: 'webrtc://liveplay.zxrob.com/live/zhilian',
      //   sharing:true
      //   })
      this.$EventBus.$emit("typeLiveShow", {
        playStreamUrl: "webrtc://liveplay.zxrob.com/live/zhilian",
        resourceType: 3,
        resourceName: "智联无人机",
        id: "20231215",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.track-switch_container {
  width: 46px;
  height: 46px;
  background-color: var(--page-background-color);
  border-radius: 4px;
  background: #2e2d2d;
  position: fixed;
  top: 253px;
  left: 438px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1;
  .iconfont {
    font-size: 24px;
  }

  &:hover {
    // 颜色变浅
    background-color: #808080;
  }

  &:active {
    // 颜色变亮
    background-color: #696969;
  }
}
</style>