<template>
  <div class="global_modal">
    <el-tooltip effect="dark" placement="bottom" v-if="showClose">
      <i class="el-icon-close pointer" @click="closeModal"></i>
      <div class="tip-text" slot="content">
        <p>关闭</p>
      </div>
    </el-tooltip>
    <div class="img_modal" v-if="isImage">
      <img :src="src" alt="" />
    </div>
    <div class="video-popup" v-if="isVideo">
      <previewLive
        aiMode
        :liveOptions="videoInfo"
        :playerOptions="playerOptions"
        :showDroneInfo="false"
        @closeLive="closeModal()"
        :class="{ disable_sharing: !sharing }"
      />
    </div>
    <div class="pannellum-popup" v-if="isPannellum">
      <div id="panorama"></div>
    </div>
  </div>
</template>

<script>
import previewLive from "@/views/resourcesDetails/components/previewLive/index.vue";
export default {
  components: {
    previewLive,
  },
  props: {
    // 类型 ： image 图片 video 视频 pannellum 全景图
    type: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    // 是否显示分享按钮
    sharing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      videoInfo: {
        playerType: "tcplayer",
        playerId: "largeWindow",
        liveUrl: this.src,
      },
      playerOptions: {
        controls: true, // 隐藏菜单栏
        autoplay: true, // 自动播放
        muted: true,
      },
      isVideo: false,
      isImage: false,
      isPannellum: false,
    };
  },
  computed: {
    showClose() {
      return this.isImage || this.isPannellum;
    },
  },
  mounted() {
    document.body.appendChild(this.$el); // 在组件挂载后将根元素添加到 body 中
    // 判断展示的组件
    switch (this.type) {
      case "image":
        this.isImage = true;
        break;
      case "video":
        this.isVideo = true;
        break;
      case "pannellum":
        this.isPannellum = true;
        // this.initPannellum();
        this.$nextTick(() => {
          this.initPannellum();
        });
        break;
    }
  },
  methods: {
    closeModal() {
      this.$el.remove(); // 移除组件的根元素
    },
    // 初始化全景图
    initPannellum() {
      const viewer = pannellum.viewer("panorama", {
        type: "equirectangular",
        panorama: this.src,
        autoLoad: true, // 自动加载
      });

      viewer.on("scenechange", (e) => {
        console.log(`点击场景点`, e);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.global_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  min-width: 200px;
  min-height: 200px;
  max-height: 80vh;
  max-width: 80vw;
  background: black;
  display: flex;
  border: 4px solid black;
  border-radius: 4px;

  .el-icon-close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    background-color: var(--btn-default-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: #ffffff;
    z-index: 1;

    &:hover {
      background-color: var(--btn-hover-color);
    }

    &:active {
      background-color: var(--btn-active-color);
    }
  }

  .img_modal {
    border: 1px solid black;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .video-popup {
    pointer-events: auto;
    width: 872px;
    height: 580px;
    background: black;
    display: flex;
    border: 4px solid black;
  }

  .pannellum-popup {
    pointer-events: auto;
    width: 872px;
    height: 580px;
    background: black;
    display: flex;
    border: 4px solid black;

    #panorama {
      width: 100%;
      height: 100%;
    }
  }
}
.disable_sharing {
  /deep/ .sharing {
    display: none !important;
  }
}
</style>