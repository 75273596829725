<template>
  <section class="search-container">
    <div class="position-box" v-if="searchMode == 1">
      <input
        id="tipinput"
        type="text"
        autocomplete="off"
        v-model.trim="key"
        placeholder="请输入地点搜索"
        @input="search"
        @compositionstart="handleCompositionStart"
        @compositionend="handleCompositionEnd"
        class="tip-input"
      />
      <span class="bottom"></span>
      <span class="right"></span>
      <span class="top"></span>
      <span class="left"></span>
      <ul class="tip-list">
        <li
          class="pointer item"
          v-for="(item, index) in tips"
          :key="index"
          @click="check(item)"
        >
          {{ item.name }}
        </li>
      </ul>
      <i
        class="el-icon-error pointer"
        @click="clearLocation"
        v-if="clearShow"
      ></i>
      <el-tooltip
        class="item"
        effect="light"
        content="切换为经纬度搜索"
        placement="right-start"
      >
        <span class="toggle-box pointer" @click="toggleSearchMode">
          <i class="el-icon-refresh"></i
        ></span>
      </el-tooltip>
    </div>
    <!-- 经纬度搜索 -->
    <div class="position-box" v-else-if="searchMode == 2">
      <div class="input-box">
        <div class="longitude">
          <input type="number" placeholder="经度" v-model="lng" />
        </div>
        <div class="latitude">
          <input
            type="number"
            placeholder="纬度（回车确认）"
            v-model="lat"
            @keyup.enter="locationByLngLat"
          />
        </div>
      </div>
      <el-tooltip
        class="item"
        effect="light"
        content="切换为地点搜索"
        placement="right-start"
      >
        <span class="toggle-box pointer" @click="toggleSearchMode">
          <i class="el-icon-refresh"></i
        ></span>
      </el-tooltip>
    </div>
  </section>
</template>

<script>
import gcoord from "gcoord";
import { getKeyCoordinate } from "@/api/map";
export default {
  data() {
    return {
      key: "",
      isComposing: false,
      tips: [],
      clearShow: false,
      //搜索模式
      searchMode: 1,
      // lng: "113.3388259",
      // lat: "23.1431399",
      lng: "",
      lat: "",
    };
  },
  beforeDestroy() {
    this.markRemove("searchBox");
  },
  methods: {
    // 切换搜索模式
    toggleSearchMode() {
      this.searchMode = this.searchMode == 1 ? 2 : 1;
      this.key = "";
      this.clearShow = false;
      this.tips = [];
      this.markRemove("searchBox");
    },
    // 根据lat和lng进行经纬度定位
    locationByLngLat() {
      // 对lat和lng进行校验，长度不能为0
      if (this.lng.length == 0 || this.lat.length == 0) return;
      // 移除旧的标记
      this.markRemove("searchBox");
      // 定位
      zr3d.locationSet({
        x: this.lng, //位置x [必填]
        y: this.lat, //位置y  [必填]
        z: 1000, //位置z  [必填]
        dis: 2000, //距离
        heading: 0, //偏转角
        pitch: -90, //俯仰角
        flyTime: 2,
        // 贴地
      });
      // 标记
      let mark = zr3d.markAdd({
        clampToGround: true,
        x: this.lng,
        y: this.lat,
        z: 0,
        text: "目标点",
        color: "#adff2f",
        id: "searchBox",
        type: "searchMark",
        labelTopOffset: 0,
        image: require("@/assets/map/location_mark.png"),
      });

      mark.snapType = "point";
    },

    async search() {
      if (this.isComposing) return;
      if (this.key === "") return;

      const res = await getKeyCoordinate({
        key: "803fd4e65fc28916f6fa5037f0395976",
        keywords: this.key,
        language: "zh-CN",
      });
      if (res) {
        this.tips = res.tips;
      }
    },
    clearLocation() {
      this.key = "";
      this.clearShow = false;
      this.markRemove("searchBox");
    },
    check(item) {
      this.key = item.name;
      this.clearShow = true;
      this.tips = [];

      if (!item.location || (item.location && item.location.length === 0)) {
        this.$notify({
          title: "消息",
          message: "请选择一个更加具体的地点",
          type: "warning",
          offset: 150,
          duration: 3000,
        });
      } else {
        this.markRemove("searchBox"); // 移除该标记

        var result = gcoord.transform(
          [item.location.split(",")[0], item.location.split(",")[1]], // 经纬度坐标
          gcoord.AMap,
          gcoord.WGS84
        );
        // 定位
        zr3d.locationSet({
          x: result[0], //位置x [必填]
          y: result[1], //位置y  [必填]
          z: 1000, //位置z  [必填]
          dis: 3000, //距离
          heading: 0, //偏转角
          pitch: -90, //俯仰角
          flyTime: 2,
        });

        // 标记
        let mark = zr3d.markAdd({
          clampToGround: true,
          x: result[0],
          y: result[1],
          z: 0,
          text: item.name,
          color: "#adff2f",
          id: "searchBox",
          type: "searchMark",
          labelTopOffset: 0,
          image: require("@/assets/map/location_mark.png"),
        });

        mark.snapType = "point";
      }
    },

    markRemove(id) {
      zr3d.markRemove({
        guid: id,
      });
    },
    // 监听输入法开始
    handleCompositionStart() {
      this.isComposing = true;
    },
    // 监听输入法输入完毕事件
    handleCompositionEnd(event) {
      this.isComposing = false;
      // 触发input事件
      this.search(event);
    },
  },
};
</script>

<style lang="less" scoped>
.search-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 80px;
  z-index: 2;
  .position-box {
    position: relative;
    .toggle-box {
      position: absolute;
      top: 50%;
      right: -56px;
      transform: translateY(-50%);
      color: #a3ecfb;
      font-size: 20px;
      border-radius: 50%;
      border: 1px solid #a3ecfb;
      height: 44px;
      width: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--page-background-color);
      &:hover{
        background-color: var(--btn-hover-color);
      }
    }
  }
  .input-box {
    width: 612px;
    height: 58px;
    border: 1px solid #a3ecfb;
    border-radius: 6px;
    background: var(--page-background-color);
    display: flex;
    .longitude,
    .latitude {
      flex: 1;
      input {
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        background: transparent;
        font-size: 20px;
        color: #a8eefc;
        text-indent: 20px;
        &::placeholder {
          // color: #a3ecfb;
          color: #999;
        }
      }
    }
    .longitude {
      border-right: 1px solid #a3ecfb;
    }
  }
  .tip-input {
    border: none;
    outline: none;
    width: 612px;
    height: 58px;
    background: var(--page-background-color);
    border-radius: 6px;
    border: 1px solid #a3ecfb;
    font-size: 20px;
    color: #a8eefc;
    text-indent: 40px;
    border: 1px solid transparent;
    &::placeholder {
      // color: #a3ecfb;
      color: #999;
    }
  }
  //
  input:focus {
    outline: none;
  }

  input::placeholder {
    color: hsla(0, 0%, 100%, 0.6);
  }

  span {
    position: absolute;
    background-color: #a3ecfb;
    transition: transform 0.1s ease;
  }

  .bottom,
  .top {
    height: 1px;
    left: 0;
    right: 0;
    transform: scaleX(0);
  }

  .left,
  .right {
    width: 1px;
    top: 0;
    bottom: 0;
    transform: scaleY(0);
  }

  .bottom {
    bottom: 0;
    transform-origin: bottom right;
  }

  input:focus ~ .bottom {
    transform-origin: bottom left;
    transform: scaleX(1);
  }

  .right {
    right: 0;
    transform-origin: top right;
    transition-delay: 0.05s;
  }

  input:focus ~ .right {
    transform-origin: bottom right;
    transform: scaleY(1);
  }

  .top {
    top: 0;
    transform-origin: top left;
    transition-delay: 0.15s;
  }

  input:focus ~ .top {
    transform-origin: top right;
    transform: scaleX(1);
  }

  .left {
    left: 0;
    transform-origin: bottom left;
    transition-delay: 0.25s;
  }

  input:focus ~ .left {
    transform-origin: top left;
    transform: scaleY(1);
  }
  //

  .el-icon-error {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: #a3ecfb;
    font-size: 20px;
  }
  .tip-list {
    color: white;
    background: rgba(7, 10, 13, 0.8);
    margin-top: 10px;
    border-radius: 6px;
    position: absolute;
    width: 100%;
    .item {
      text-indent: 40px;
      line-height: 30px;
      &:hover {
        color: #a3ecfb;
      }
    }
  }
}
</style>