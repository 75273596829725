// 引入getters
const state = {
    // 经度
    longitude: 0,
    // 纬度
    latitude: 0,
    // 高度
    height: 0,
    // 飞行速度
    speed: 10,
    // 任务距离
    distance: 0,
    // 任务时间
    time: 0,
    // 是否展示飞行目标弹窗
    showFlyTarget: false,
};

const mutations = {
    // 经度
    SET_LONGITUDE: (state, longitude) => {
        state.longitude = longitude;
    },
    // 纬度
    SET_LATITUDE: (state, latitude) => {
        state.latitude = latitude;
    },
    // 高度
    SET_HEIGHT: (state, height) => {
        state.height = height;
    },
    // 飞行速度
    SET_SPEED: (state, speed) => {
        state.speed = speed;
    },
    // 任务距离
    SET_DISTANCE: (state, distance) => {
        state.distance = distance;
    },
    // 任务时间
    SET_TIME: (state, time) => {
        state.time = time;
    },
    // 是否展示飞行目标弹窗
    SET_SHOW_FLY_TARGET: (state, showFlyTarget) => {
        state.showFlyTarget = showFlyTarget;
    },

};

const actions = {
    // 写入经纬度和高度
    writeLocation({ commit }, location) {
        commit('SET_LONGITUDE', location.x);
        commit('SET_LATITUDE', location.y);
        commit('SET_HEIGHT', +location.z.toFixed(1) || 0 );
        commit('SET_SHOW_FLY_TARGET', true);
        // 写入距离
        commit('SET_DISTANCE', location.distance);
        // 根据距离计算时间
        let time = location.distance / state.speed;
        // 对time进行取整
        time.toFixed(0);
        commit('SET_TIME', time);
    },
    // 写入飞行速度
    writeSpeed({ commit }, speed) {
        commit('SET_SPEED', speed);
        if (state.distance > 1 && speed > 0) {
            let time = state.distance / speed;
            // 对time进行取整
            time.toFixed(0);
            commit('SET_TIME', time);
        }


    },
    // 写入任务距离
    writeDistance({ commit }, distance) {
        commit('SET_DISTANCE', distance);
    },
    // 写入任务时间
    writeTime({ commit }, time) {
        commit('SET_TIME', time);
    },
    // 初始化所有数据
    initAllData({ commit }) {
        commit('SET_LONGITUDE', 0);
        commit('SET_LATITUDE', 0);
        commit('SET_HEIGHT', 0);
        commit('SET_SPEED', 10);
        commit('SET_DISTANCE', 0);
        commit('SET_TIME', 0);
        commit('SET_SHOW_FLY_TARGET', false);
    },
    //单个更新经度或者纬度
    updateLocation({ commit, state }, location) {
        // 经纬高可设置为空
        commit('SET_LONGITUDE', location.longitude);
        commit('SET_LATITUDE', location.latitude);
        commit('SET_HEIGHT', location.height);
    },
    // 更新飞行目标弹窗
    updateFlyTarget({ commit }, showFlyTarget) {
        commit('SET_SHOW_FLY_TARGET', showFlyTarget);
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
