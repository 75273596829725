import request from '@/utils/request.js'
// 获取项目列表
export function projectList(data) {
    return request({
        url: '/api/manage/v1/achievement/projectSearchByKey',
        method: 'post',
        data
    })
}

// 获取项目下任务列表
export function taskSearch(data) {
    return request({
        url: '/api/manage/v1/achievement/jobSearch',
        method: 'post',
        data
    })
}
//获取任务简略信息
export function taskThumbInfo(params) {
    return request({
        url: '/api/manage/v1/achievement/jobThumbInfo',
        method: 'get',
        params
    })
}
// 获取单个任务详情
export function jobTrace(params) {
    return request({
        url: '/api/manage/v1/achievement/jobTrace',
        method: 'get',
        params
    })
}