<template>
  <CommonDialog showMask>
    <section class="change-password_container">
      <div class="title">修改密码</div>

      <div class="item-input">
        <label for=""><span class="mandatory">*</span>旧密码</label>
        <div class="input-box">
          <vInput
            v-model.trim="oldPassword"
            type="password"
            @blur="oldPasswordBlur()"
            :err="oldPasswordErr"
            placeholder="请输入旧密码"
          />
        </div>
      </div>

      <div class="item-input">
        <label for=""><span class="mandatory">*</span>新密码</label>
        <div class="input-box">
          <vInput
            v-model.trim="newPassword"
            type="password"
            @blur="newPasswordBlur()"
            :err="newPasswordErr"
            placeholder="请输入新密码"
          />
        </div>
      </div>

      <div class="item-input">
        <label for=""><span class="mandatory">*</span>确认新密码</label>
        <div class="input-box">
          <vInput
            v-model.trim="newPassword2"
            type="password"
            @blur="newPassword2Blur()"
            :err="newPassword2Err"
            placeholder="请再次输入新密码"
          />
        </div>
      </div>

      <div class="btn-group">
        <button class="btn cancel-btn pointer" @click="$emit('cancel')">
          <span>取消</span>
        </button>
        <button class="btn determine-btn pointer" @click="determine">
          <span>确定</span>
        </button>
      </div>
    </section>
  </CommonDialog>
</template>

<script>
import CommonDialog from "@/components/CommonDialog/index.vue";
import vInput from "@/components/CommonInput/index.vue";
import { debounce } from "@/utils/index";
import { update } from "@/api/user";
import { validPassword } from "@/utils/validate";
export default {
  components: {
    CommonDialog,
    vInput,
  },
  data() {
    return {
      oldPassword: "",
      oldPasswordErr: false,
      newPassword: "",
      newPasswordErr: false,
      newPassword2: "",
      newPassword2Err: false,
    };
  },
  watch: {
    oldPassword: {
      handler() {
        this.oldPasswordBlur();
      },
    },
    newPassword: {
      handler() {
        this.newPasswordBlur();
      },
    },

    newPassword2: {
      handler() {
        this.newPassword2Blur();
      },
    },
  },
  methods: {
    check() {
      if (this.oldPassword === "") {
        this.$notify({
          title: "消息",
          type: "info",
          message: "旧密码不可为空",
          offset: 150,
          duration: 3000,
        });
        this.oldPasswordErr = true;
        return false;
      } else if (!validPassword(this.oldPassword)) {
        this.$notify({
          title: "消息",
          type: "info",
          message: "请输入密码8~20位字母、数字、特殊字符至少两种",
          offset: 150,
          duration: 3000,
        });
        this.oldPasswordErr = true;
        return false;
      } else if (this.newPassword === "") {
        this.$notify({
          title: "消息",
          type: "info",
          message: "新密码不可为空",
          offset: 150,
          duration: 3000,
        });
        this.newPasswordErr = true;
        return false;
      } else if (!validPassword(this.newPassword)) {
        this.$notify({
          title: "消息",
          type: "info",
          message: "请输入密码8~20位字母、数字、特殊字符至少两种",
          offset: 150,
          duration: 3000,
        });
        this.newPasswordErr = true;
        return false;
      } else if (this.newPassword2 === "") {
        this.$notify({
          title: "消息",
          type: "info",
          message: "确认新密码不可为空",
          offset: 150,
          duration: 3000,
        });
        this.newPassword2Err = true;
        return false;
      } else if (!validPassword(this.newPassword2)) {
        this.$notify({
          title: "消息",
          type: "info",
          message: "请输入密码8~20位字母、数字、特殊字符至少两种",
          offset: 150,
          duration: 3000,
        });
        this.newPassword2Err = true;
        return false;
      } else if (this.newPassword !== this.newPassword2) {
        this.$notify({
          title: "消息",
          type: "info",
          message: "两次密码不一致，请检查密码",
          offset: 150,
          duration: 3000,
        });
        this.newPassword2Err = true;
        this.newPasswordErr = true;
        return false;
      } else {
        return true;
      }
    },
    determine: debounce(async function () {
      if (!this.check()) return;
      const res = await update({
        newPassword: this.newPassword,
        oldPassword: this.oldPassword,
      });
      if (res) {
        this.$notify({
          title: "提示",
          type: "success",
          message: "修改成功",
          offset: 150,
          duration: 3000,
        });
        this.$emit("changeSuccess");
      }
    }, 300),
    newPassword2Blur() {
      this.newPassword2 === ""
        ? (this.newPassword2Err = true)
        : (this.newPassword2Err = false);
    },

    newPasswordBlur() {
      this.newPassword === ""
        ? (this.newPasswordErr = true)
        : (this.newPasswordErr = false);
    },
    oldPasswordBlur() {
      this.oldPassword === ""
        ? (this.oldPasswordErr = true)
        : (this.oldPasswordErr = false);
    },
  },
};
</script>

<style lang="less" scoped>
.change-password_container {
  width: 504px;
  height: 410px;
  background: var(--person-device-list-bg-color);
  box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.42);
  .title {
    height: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 20px;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.31);
  }

  .item-input {
    padding: 0 50px;
    color: #ffffff;
    font-size: 18px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 10px;
    }
    .mandatory {
      color: #ef6767;
    }
    .input-box {
      height: 34px;
    }
  }
  .btn-group {
    margin-top: 30px;
    padding: 0 50px;
    display: flex;
    justify-content: flex-end;

    .btn {
      width: 116px;
      height: 42px;
      border-radius: 4px;
      font-size: 20px;
      color: #ffffff;
      border: none;
    }
    .cancel-btn {
     background: transparent;
      border: 1px solid #a2bac5;
      margin-right: 30px;
      &:hover {
        border-color: #a3ecfb;
        // color: #a3ecfb;
      }
      &:active {
        border-color: #40e9f2;
        // color: #40e9f2;
      }
    }
    .determine-btn {
     background: var(--btn-default-color);
      border: 1px solid #a2bac5;
      &:hover {
        background-color: var(--btn-active-color);
      }
      &:active {
        background-color: var(--btn-active-color);
      }
    }
  }
}
</style>
