import axios from "axios";
export function debounce(func, wait, immediate) {
    let timeout, _args, context, timestamp, result

    const later = function () {
        // 据上一次触发时间间隔
        const last = +new Date() - timestamp

        // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last)
        } else {
            timeout = null
            // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
            if (!immediate) {
                result = func.apply(context, _args)
                if (!timeout) context = _args = null
            }
        }
    }

    return function (...args) {
        context = this
        timestamp = +new Date()
        _args = args;
        const callNow = immediate && !timeout
        // 如果延时不存在，重新设定延时
        if (!timeout) timeout = setTimeout(later, wait)
        if (callNow) {
            result = func.apply(context, _args)
            context = _args = null
        }

        return result
    }

}

export function throttle(func, delay) {
    let timerId;
    let lastExecTime = 0;
    return function (...args) {
        const currentTime = Date.now();
        if (currentTime - lastExecTime > delay) {
            func.apply(this, args);
            lastExecTime = currentTime;
        } else {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func.apply(this, args);
                lastExecTime = Date.now();
            }, delay);
        }
    };
}


export const downloadImage = (file, successCallback, errorCallback) => {
    const { imageUrl, name } = file;

    fetch(imageUrl)
        .then(response => {
            if (!response.ok) {
                throw new Error('Download failed');
            }
            return response.blob();
        })
        .then(blob => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            link.style.display = 'none';
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);

            URL.revokeObjectURL(url);

            if (successCallback) {
                successCallback(); // 调用成功回调函数
            }
        })
        .catch(error => {
            if (errorCallback) {
                errorCallback(error); // 调用失败回调函数
            }
        });
};

export function download(info) {
    const { url, name } = info
    return new Promise((resolve, reject) => {
        axios(url, {
            method: 'get',
            responseType: 'blob',
            onDownloadProgress: (evt) => {

                if (typeof callback === 'function') {
                    const progress = parseInt((evt.loaded / evt.total) * 100)
                    callback(progress)
                }
            },
        })
            .then(res => {
                const type = res.headers['content-type'] //请求头中文件类型
                const blob = new Blob([res.data])
                const a = document.createElement('a')
                a.download = name + '.' + type?.replace('video/', '')
                a.href = URL.createObjectURL(blob)
                a.click()
                URL.revokeObjectURL(a.href)
                a.remove()
                resolve()
            })
            .catch(err => {
                reject(err)
            })
    })
}
export function downloadKmz(url) {
    if (!url) return
    let link = document.createElement('a') //创建a标签
    link.style.display = 'none'  //使其隐藏
    link.href = url //赋予文件下载地址
    link.setAttribute('download', '') //设置下载属性 以及文件名
    document.body.appendChild(link) //a标签插至页面中
    link.click() //强制触发a标签事件
    document.body.removeChild(link);
}

export function dataURLtoFile(dataURI, type) {
    let binary = atob(dataURI.split(",")[1]);
    let array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: type });
}


export function getPosition(obj) {
    var left = 0;
    var top = 0;
    var first = true;
    while (obj.offsetParent)//如果obj的有最近的父级定位元素就继续
    {

        left += first ? 0 : obj.offsetLeft;//累加
        top += first ? 0 : obj.offsetTop;
        obj = obj.offsetParent;//更新obj,继续判断新的obj是否还有父级定位，然后继续累加
        first = false;
    }
    return { left: left, top: top }//返回json格式
}
// var offset = getPosition(document.getElementById("box"))
// //得到相对容器左上角的相对坐标
// var pos = {
//     x: e.pageX - offset.left,
//     y: e.pageY - offset.top,
// }

export class wait_for_model_class {
    constructor() {
        this.img_first = [];
        this.imgs = [];
        this.raw_imgs = [];
        this.point_first = [];
        this.square = []
    }
}

export class Inused_class {
    constructor() {
        this.img = null;
        this.point_arr = null;
        this.square = []//[x,y,w,h,c,s,newest_pointid]
    }
}


// 转换CSV为JSON数据
export function CSVToJSON(data, csvTitleKbn, delimiter = ',') {


    const titles = data.slice(0, data.indexOf('\n')).split(delimiter);
    const hanleData = data.slice(data.indexOf('\n') + 1).split('\n');

    const json = hanleData.map(v => {

        const values = v.split(delimiter);
        return csvTitleKbn.reduce(
            (obj, title, index) => ((obj[title] = values[index]), obj),
            {}
        );
    });

    return json;
};


export function getUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}


export function calculateDistance(lat1, lon1, lat2, lon2) {
    const earthRadius = 6371; // 地球半径，单位为千米

    // 将经纬度转换为弧度
    const lat1Rad = toRadians(lat1);
    const lon1Rad = toRadians(lon1);
    const lat2Rad = toRadians(lat2);
    const lon2Rad = toRadians(lon2);

    // 使用 Haversine 公式计算两点之间的距离
    const deltaLat = lat2Rad - lat1Rad;
    const deltaLon = lon2Rad - lon1Rad;
    const a =
        Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(lat1Rad) * Math.cos(lat2Rad) *
        Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let distance = earthRadius * c * 1000; // 转换为米

    return distance; // 返回实际距离，单位为米
}

function toRadians(degree) {
    return degree * (Math.PI / 180);
}

export function downloadTxtFile(text, filename) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export const downloadVideoWithProgress = (videoUrl, filename, progressCallback, errorCallback) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', videoUrl, true);
    xhr.responseType = 'blob';

    xhr.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;
            if (progressCallback) {
                progressCallback(percentComplete);
            }
        }
    });

    xhr.addEventListener('load', () => {
        if (xhr.status === 200) {
            const blob = xhr.response;
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } else {
            if (errorCallback) {
                errorCallback(xhr.status);
            }
        }
    });

    xhr.addEventListener('error', () => {
        if (errorCallback) {
            errorCallback(xhr.status);
        }
    });

    xhr.send();
};

// 获取对象key值为数字的key
export function getNumberKey(obj) {
    let arr = [];
    const reg = /^\d+/;
    const newarr = Object.keys(obj);
    newarr.forEach((item) => {
        if (reg.test(item)) {
            arr.push(item);
        }
    });
    return arr[0];
}


function checkIeForNum() {
    return ((navigator.msPointerEnabled == undefined ? true : navigator.msPointerEnabled)
        && (navigator.msDoNotTrack == 1 || window.doNotTrack == 1)
        && ((Number(window.screenX) ? (window.screenLeft - window.screenX != 8) : false)
            || ((navigator.userAgent.indexOf('MSIE 7.0') != -1 || navigator.userAgent.indexOf('MSIE 8.0') != -1) && console.count == undefined)));
}
function checkChromeForNum() {
    var uas = navigator.userAgent.split(' '),
        result = false;
    if (uas[uas.length - 1].indexOf('Safari') == -1) {
        return result;
    }
    for (var key in navigator.plugins) {
        if (navigator.plugins[key].filename == 'np-mswmp.dll') {
            return !result;
        }
    }
    return result;
}
export function isNumberBrowser() {
    if (navigator.userAgent.indexOf('Safari') != -1) {
        return checkChromeForNum();
    } else {
        return checkIeForNum();
    }
}




