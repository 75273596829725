<template>
  <transition name="fade_right">
    <section class="mark-container" v-if="projectMarkInfo.markRes">
      <!-- 点 -->
      <section
        class="pop-ups_point"
        v-if="projectMarkInfo.markRes && projectMarkInfo.markRes.type == 0"
      >
        <div class="pop-ups_head">
          <span>指令信息</span>
          <i class="el-icon-close pointer" @click="close()"></i>
        </div>
        <div class="pop-ups_centent">
          <i class="el-icon-location-outline"></i>
          <input
            class="pop-ups_data"
            placeholder="输入指令信息"
            v-model="pointName"
            type="text"
          />
        </div>
        <div class="pop-color_picker">
          <span class="text">颜色</span>
          <el-color-picker
            class="color-box"
            v-model="pointColor"
            :predefine="predefineColors"
          >
          </el-color-picker>
        </div>
        <!--  -->
        <div class="pop-date_picker">
          <span class="text">日期</span>
          <el-date-picker
            format="yyyy-MM-dd"
            value-format="timestamp"
            class="date-box"
            v-model="taskTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
      </section>
      <!-- 线 -->
      <section
        class="pop-ups_point medium"
        v-if="projectMarkInfo.markRes && projectMarkInfo.markType == 1"
      >
        <div class="pop-ups_head">
          <span>指令信息</span>
          <i class="el-icon-close pointer" @click="close()"></i>
        </div>
        <div class="pop-ups_centent">
          <i class="iconfont icon-line"></i>
          <input
            class="pop-ups_data"
            placeholder="输入指令信息"
            v-model="pointName"
            type="text"
          />
        </div>
        <div class="pop-color_picker">
          <span class="text">颜色</span>
          <el-color-picker
            class="color-box"
            v-model="lineColor"
            :predefine="predefineColors"
          >
          </el-color-picker>
        </div>
        <div class="pop-line_width">
          <span class="text">粗细</span>
          <CommonRange v-model="lineWidth" :min="1" :max="15" />
        </div>
        <div class="pop-date_picker">
          <span class="text">日期</span>
          <el-date-picker
            format="yyyy-MM-dd"
            value-format="timestamp"
            class="date-box"
            v-model="taskTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
      </section>
      <!-- 面 -->
      <section
        class="pop-ups_point large"
        v-if="projectMarkInfo.markRes && projectMarkInfo.markType == 2"
      >
        <div class="pop-ups_head">
          <span>指令信息</span>
          <i class="el-icon-close pointer" @click="close()"></i>
        </div>
        <div class="pop-ups_centent">
          <i class="iconfont icon-surface"></i>
          <input
            class="pop-ups_data"
            placeholder="输入指令信息"
            v-model="pointName"
            type="text"
          />
        </div>
        <!-- filling -->
        <span class="filling">填充</span>
        <div class="pop-color_picker">
          <span class="text">颜色</span>
          <el-color-picker
            class="color-box"
            v-model="surfaceColor"
            :predefine="predefineColors"
          >
          </el-color-picker>
        </div>

        <span class="frame">边框</span>
        <div class="pop-color_picker">
          <span class="text">颜色</span>
          <el-color-picker
            class="color-box"
            v-model="lineColor"
            :predefine="predefineColors"
          >
          </el-color-picker>
        </div>
        <div class="pop-line_width">
          <span class="text">粗细</span>
          <CommonRange v-model="lineWidth" :min="1" :max="15" />
        </div>
        <div class="pop-date_picker">
          <span class="text">日期</span>
          <el-date-picker
            format="yyyy-MM-dd"
            value-format="timestamp"
            class="date-box"
            v-model="taskTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
      </section>

      <div class="item-content" v-if="projectMarkInfo.markRes">
        <div class="item-left">
          <label for="">人员</label>
        </div>
        <ul class="personnel-list">
          <li
            class="personnel-item"
            v-for="(item, index) in memberList"
            :key="index"
          >
            <i
              class="el-icon-remove-outline pointer"
              @click="delMember(index)"
            ></i>
            <span>{{ item.memberName }}</span>
            <span></span>
          </li>
        </ul>
      </div>
      <!-- 人员 -->
      <div class="pop-ups_bottom" v-if="projectMarkInfo.markRes">
        <div class="btn pointer" @click="confirm">派发</div>
      </div>
    </section>
  </transition>
</template>

<script>
import CommonRange from "@/components/CommonRange/index.vue";
import {
  addMark,
  addPersonalElement,
  missionUpdate,
  getCredentials,
  notify,
  newJobNotify,
  uploadWebImageFile,
} from "@/api/dji";
import { getTaskId, addTask } from "@/api/task";
import { list } from "@/api/project";
import { debounce, dataURLtoFile } from "@/utils/index";
import html2canvas from "html2canvas";
export default {
  components: {
    CommonRange,
  },
  computed: {
    projectMarkInfo() {
      return this.$store.getters.projectMarkInfo;
    },
    pointName: {
      get() {
        const { markRes } = this.projectMarkInfo;
        return markRes.style.name;
      },
      set(value) {
        let info = { name: value };
        this.setStyle(info);
      },
    },

    pointColor: {
      get() {
        const { markRes } = this.projectMarkInfo;
        return markRes.style.color;
      },
      set(value) {
        let info = { color: value };
        this.setStyle(info);
      },
    },
    surfaceColor: {
      get() {
        const { markRes } = this.projectMarkInfo;
        return markRes.style.color;
      },
      set(value) {
        let info = { color: `${value}44` };
        this.setStyle(info);
      },
    },
    lineColor: {
      get() {
        const { markRes } = this.projectMarkInfo;
        return markRes.style.lineColor;
      },
      set(value) {
        let info = { lineColor: value };
        this.setStyle(info);
      },
    },
    lineWidth: {
      get() {
        const { markRes } = this.projectMarkInfo;
        return markRes.style.lineWidth;
      },
      set(value) {
        let info = { lineWidth: value };
        this.setStyle(info);
      },
    },
  },
  watch: {
    loading: {
      handler(newV, oldV) {
        if (newV) {
          this.errTip = setTimeout(() => {
            this.$message("标注下发失败，请点击重试");
            this.loading = false;
          }, 10000);
        }
      },
    },
  },
  data() {
    return {
      predefineColors: [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "rgb(255, 120, 0)",
        "hsv(51, 100, 98)",
        "hsl(181, 100%, 37%)",
      ],
      loading: false,
      errTip: null,
      memberList: [],
      imgFile: null, // 图片对象
      djiCredentials: null, //大疆凭证
      pictureUrl: "", //截图对象
      marKData: [], // 接口用数据
      projectId: "", // 项目id
      projectOptions: [], //项目列表
      taskTime: "", // 任务时间
    };
  },
  created() {
    this.$EventBus.$on("actionPersonalLabel", (newMember) => {
      // 机场无法派发点线面任务
      if (!newMember.resourceType || newMember.resourceType == 1) return false;
      const isAlreadyExist = this.memberList.some((member) => {
        return member.memberId === newMember.memberId;
      });

      if (isAlreadyExist) {
        // 如果新数据已经存在于 memberList 数组中，则不进行插入操作
      } else {
        // 如果新数据不存在于 memberList 数组中，则进行插入操作
        this.memberList.push(newMember);
      }
    });
  },
  mounted() {
    this.getProjectList();
  },
  beforeDestroy() {
    this.$EventBus.$off("actionPersonalLabel");
    this.$store.dispatch("map/setIsActionStarted", 0);
  },
  methods: {
    // 获取项目列表
    async getProjectList() {
      const res = await list({ pageNum: 1, pageSize: 100, projectStatus: 2 });
      if (res) {
        this.projectOptions = []; //项目列表
        res.data.forEach((element) => {
          this.projectOptions.push({
            value: element.id,
            label: element.projectName,
          });
        });
      }
    },
    setStyle(info) {
      projectMarkExample.setStyle(info);
    },
    delMember(index) {
      this.memberList.splice(index, 1);
    },

    close() {
      this.$store.dispatch("map/setIsActionStarted", 0);
      //向外抛出事件

      this.$EventBus.$emit("closeProjectMark");
      projectMarkExample.activeEdit(false); //结束绘制
      this.memberList = [];
      //清理项目id
      this.projectId = "";

      this.$store.dispatch("projectMark/clearInfo").then(() => {
        projectMarkExample.clear();
        projectMarkExample = null;
      });
    },
    confirm: debounce(function () {
      this.submit();
    }, 300),
    // 截图
    async screenshots() {
      await html2canvas(document.body, {
        useCORS: true,
        allowTaint: true,
      }).then((canvas) => {
        let imgUrl = null;
        imgUrl = canvas.toDataURL("image/png");
        this.imgFile = dataURLtoFile(imgUrl, "image/png");
      });
    },
    // 上传大疆文件库
    async save2Dji() {
      const form = new FormData();

      let file = new File([this.imgFile], `image/${new Date().valueOf()}.png`);

      form.append("file", file);
      const result = await uploadWebImageFile(form);
      this.pictureUrl = result.data.url;
    },
    // 初始化本地数据组
    async initMarkData() {
      const { markRes } = this.projectMarkInfo;

      this.marKData.push({
        name: markRes.style.name,
        resource: convertJson2Geojson(markRes),
      });
      // });
    },
    // 获取可用任务id

    // 添加任务
    async TaskSchematicAdd(item) {
      const { memberId, resourceType } = item;
      const taskId = await getTaskId();
      let newTaskId = null;
      if (taskId) {
        newTaskId = taskId.data;
      }

      let data = {
        executor: memberId, //任务执行者，机库id或飞手id或地巡人员id
        jobCategory: 2, //任务分类，1:标准任务；2:示意性任务
        jobAnnotation: JSON.stringify(this.marKData), //航线json数据
        jobType: 2, //任务类型，1:立即执行；2：定时执行；3：重复执行
        name: this.marKData[0].name, //任务名称
        // projectId: this.projectId, //项目id
        id: newTaskId, //任务id
        jobExecType: resourceType, // 资源类型
        pictureUrl: this.pictureUrl, //
        jobTime: this.taskTime, //任务时间
        targetType: 1, //任务目标类型：1：标注任务；2：追踪任务
      };

      const res = await addTask(data);
      if (res) {
        // 消息的派发
        this.taskIssue([memberId]);
        this.notify(memberId);
        this.newMark(memberId);
        // end
      }
    },
    async taskIssue(list) {
      const res = await missionUpdate(list);
    },
    async newMark(id) {
      const res = await newJobNotify({ resourceId: id });
    },
    // 下发任务
    async notify(id) {
      const res = await notify({ resourceId: id });
    },
    // 派发任务
    async submit() {
      //如果未选择人员，则不进行下发操作，给与用户提示
      if (this.memberList.length === 0) {
        this.$message({
          message: "请选择人员",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      //提示，标注派发中
      this.$message({
        message: "标注派发中",
        type: "info",
      });
      debugger
      await this.screenshots();
      await this.save2Dji();
      await this.initMarkData();
      //循环下发任务,根据menberList的长度
      for (let i = 0; i < this.memberList.length; i++) {
        await this.TaskSchematicAdd(this.memberList[i]);
      }
      // await this.TaskSchematicAdd();

      const { markRes } = this.projectMarkInfo;
      let newMark = convertJson2Geojson(markRes);

      let markInfo = {
        name: markRes.name,
        resource: newMark,
      };

      if (this.memberList.length > 0) {
        markInfo.ids = this.memberList.map((item) => item.memberId);
        const res = await addPersonalElement(markInfo);

        if (res) {
          clearTimeout(this.errTip);
          this.errTip = null;
          this.loading = false;
          this.memberList = [];
          this.marKData = [];
          this.taskTime = "";
          this.$message({
            message: "标注下发成功",
            type: "success",
          });

          this.close();
          this.$emit("success");
          this.$EventBus.$emit("closeProjectMark");
          // 初始化数据
        }
      } else {
        const res = await addMark(markInfo);
        if (res) {
          clearTimeout(this.errTip);
          this.errTip = null;
          this.loading = false;
          this.memberList = [];
          this.taskTime = "";
          this.$message({
            message: "标注下发成功",
            type: "success",
          });
          this.$EventBus.$emit("closeProjectMark");
          this.close();
          this.$emit("success");
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mark-container {
  position: absolute;
  right: 66px;
  top: 64px;
  z-index: 1;
  border-radius: 4px;
  background-color: var(--page-background-color);
  border-radius: 6px;

  .item-content {
    display: flex;
    margin-bottom: 16px;
    padding-left: 20px;

    label {
      width: 62px;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: var(--command-info-label-color);
    }

    .input-name {
      margin-right: 20px;
      border-color: white;
      background-color: transparent;
      width: 211px;

      /deep/ .el-input__inner {
        background-color: transparent;
        color: white;
        height: 34px;
      }
    }

    .select-box {
      margin-right: 20px;
      border-color: white;
      background-color: transparent;
      width: 211px;

      /deep/ .el-input__inner {
        background-color: transparent;
        color: white;
        height: 34px;
      }
    }

    .item-left {
      width: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #ffffff;
      height: 100%;
    }

    .personnel-list {
      margin-right: 20px;
      flex: 1;
      overflow-y: auto;
      max-height: 200px;

      .personnel-item {
        border-bottom: 1px solid white;
        padding-bottom: 10px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        box-sizing: content-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        .el-icon-remove-outline {
          font-size: 20px;
          color: #ef6767;
        }

        .icon-selected {
          color: #165dff;
          background: white;
          border-radius: 50%;
        }
      }
    }
  }

  .pop-ups_point {
    width: 294px;
    .pop-ups_head {
      height: 48px;
      font-size: 18px;

      font-weight: 400;
      color: white;
      display: flex;
      align-items: center;
      padding-left: 14px;
      padding-right: 20px;
      justify-content: space-between;
    }

    .pop-ups_centent {
      height: 70px;
      color: var(--command-info-label-color);
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 32px;

      .el-icon-location-outline,
      .iconfont {
        font-size: 24px;
      }

      .pop-ups_data {
        margin-left: 36px;
        width: 156px;
        height: 34px;
        border-radius: 2px;
        border: 1px solid #ffffff;
        outline: none;
        text-indent: 8px;
        color: white;
        background-color: var(--command-info-input-bg-color);
        &::placeholder {
          color: white;
        }
      }
    }
    .pop-date_picker {
      padding-left: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      .text {
        font-size: 16px;
        font-weight: 400;
        color: var(--command-info-label-color);
      }
      .el-input {
        width: 156px;
        height: 34px;
      }
      /deep/.el-input__inner {
        height: 100%;
        background-color: var(--command-info-input-bg-color);
        line-height: 1;
        color: white;
        border-color: white;
      }
      /deep/.el-input__inner::placeholder {
        color: white;
      }
      /deep/.el-input__prefix,
      /deep/.el-input__suffix {
        color: white;
      }
      .date-box {
        margin-left: 28px;
      }
    }
    .pop-color_picker {
      padding-left: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      .text {
        font-size: 16px;
        font-weight: 400;
        color: var(--command-info-label-color);
      }

      .color-box {
        margin-left: 28px;
      }
    }

    .frame,
    .filling {
      font-size: 12px;
      font-weight: 400;
      color: #d1cbcb;
      display: flex;
      padding-left: 20px;
      margin-bottom: 10px;
    }

    .pop-line_width {
      padding-left: 20px;
      display: flex;
      align-items: center;
      height: 22px;
      margin-bottom: 18px;

      .text {
        font-size: 16px;
        font-weight: 400;
        color: var(--command-info-label-color);
        width: 50px;
      }

      .form-group-container {
        padding-top: 0;
        padding-left: 10px;
        padding-right: 0;
        margin-bottom: 0;

        /deep/ .form-number {
          width: 40px;
          height: 34px;
        }
      }
    }
  }

  .pop-ups_bottom {
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      width: 172px;
      height: 34px;
      background-color: var(--btn-active-color);
      border-radius: 2px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: var(--command-info-btn-hover-color);
      }
      &:active {
        background-color: var(--command-info-btn-active-color);
      }
    }
  }
}
</style>
