const state = {
    playResInfo: null, // 所选资源
    playList: [], // 当前播放的资源
    orthoImageLayerId: '', // 正射影像图画面id
    liveStatus: [],//列表飞行器的数据
}

const mutations = {
    // 当前拖拽信息
    SET_PLYA_RES_INFO: (state, playResInfo) => {
        state.playResInfo = playResInfo
    },
    // playList
    SET_PLAY_LIST: (state, playList) => {
        state.playList = playList
    },
    // 正射影像图画面id
    SET_ORTHO_IMAGE_LAYER_ID: (state, orthoImageLayerId) => {
        state.orthoImageLayerId = orthoImageLayerId
    },
    // 资源列表飞行器数据
    SET_LIVE_STATUS_LIST: (state, info) => {
        state.liveStatus = info
    }
}

const actions = {
    // SET_PLAY_LIST
    setPlayList({ commit }, playList) {
        commit('SET_PLAY_LIST', playList)
    },
    // 修改影像图画面id
    setOrthoImageLayerId({ commit }, orthoImageLayerId) {
        commit('SET_ORTHO_IMAGE_LAYER_ID', orthoImageLayerId)
    },
    // SET_PLYA_RES_INFO
    setPlayResInfo({ commit }, playResInfo) {
        commit('SET_PLYA_RES_INFO', playResInfo)
    },

    // 设置直播列表数据
    setLiveStatusList({ commit }, info) {
        commit('SET_LIVE_STATUS_LIST', info)
    },
    // // removePlayList
    // removePlayList({ commit }, playResInfo) {
    //     //移除当前播放的资源
    //     let playList = state.playList
    //     let index = playList.findIndex(item => item.id === playResInfo.id)
    //     if (index > -1) {
    //         playList.splice(index, 1)
    //     }
    //     commit('SET_PLAY_LIST', playList)

    //     // commit('SET_PLYA_RES_INFO', playResInfo)
    // }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}