import { getMark } from "@/api/dji";
export default {
  computed: {

  },
  watch: {},
  created() {
    this.$EventBus.$on("getAllMark", () => {
      this.getAllMark();
    });

    this.$EventBus.$on("clearResDetMark", () => {
      this.clearResDetMark();
    });
  },

  beforeDestroy() {
    this.$EventBus.$off("getAllMark");
    this.$EventBus.$off("clearResDetMark");
  },
  methods: {
    // 获取项目标注
    async getAllMark() {
      const res = await getMark();
      if (res) {
        let jobAnnotation = [];
        // 如果res.data的长度等于0，说明没有标注
        if (res.data.length == 0) return;
        res.data[0].elements.forEach((el) => {
          let nweMark = null;
          nweMark = convertGeojson2Json(el);
          nweMark.id = el.id;
          jobAnnotation.push(nweMark);
        });
        //如果res.data的长度大于2，说明有私有标注 需要处理后续的数据
        if (res.data.length > 2) {
          //私有标注，遍历res.data除开第一个和第二个的数据
          for (let i = 2; i < res.data.length; i++) {
            res.data[i].elements.forEach((el) => {
              let nweMark = null;
              nweMark = convertGeojson2Json(el);
              nweMark.id = el.id;
              jobAnnotation.push(nweMark);
            });
          }
        }

        this.displayResDetails(jobAnnotation);
      }
    },
    // 项目标注的显示
    displayResDetails(list) {

      list.forEach((item) => {
        const { name, positions, type, style, id } = item;

        let shareMapData = null;
        switch (type) {
          case 0:
            shareMapData = zr3d.drawerToolCreate({
              style: {
                pointScale: 1.5,
                fontColor: "#ffffffff",
                pointSize: 20,
                fontSize: 20,
                name: name,
                color: style.color,
              },
            });

            break;
          case 1:
            shareMapData = zr3d.drawerToolCreate({
              style: {
                pointScale: 1.5,
                fontColor: "#ffffff",
                fontSize: 20,
                name: name,
                color: style.color,
                lineWidth: style.lineWidth || 1,
                lineColor: style.lineColor || "#5faee3",
              },
            });

            break;
          case 2:
            shareMapData = zr3d.drawerToolCreate({
              style: {
                pointScale: 1.5,
                fontColor: "#ffffff",
                fontSize: 20,
                name: name,
                color: style.color,
                lineWidth: style.lineWidth || 1,
                lineColor: style.lineColor || "#5faee3",
              },
            });
            break;
        }

        resMarkList.push(shareMapData);
        shareMapData.startDraw(type);

        shareMapData.load({
          name: name,
          type,
          positions,
          style: {
            pointScale: 1.5,
            fontColor: "#0000ff",
            fontSize: 20,
            name: name,
            color: style.color + "44",
            lineWidth: style.lineWidth || 1,
            lineColor: style.lineColor || "#5faee3",
          },
        });

        shareMapData.id = id;

      });
    },
    // 清除所有项目标注
    clearResDetMark() {
      for (let i = 0; i < resMarkList.length; i++) {
        const item = resMarkList[i];
        item.clear();
        if (i == resMarkList.length - 1) {
          resMarkList = [];
        }
      }
    },
  },
};
