import Vue from 'vue';
import {
    Icon,
    Notification,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Select,
    Option,
    Pagination,
    Checkbox,
    DatePicker,
    Radio,
    TimePicker,
    ColorPicker,
    Image,
    Loading,
    Message,
    Empty,
    Dialog,
    Button,
    MessageBox,
    Input,
    Tooltip,
    Switch,
    Tabs,
    TabPane,
    Progress,
    Tag,
    Slider,
    RadioButton,
    RadioGroup,
    Card,
    Table,
    TableColumn,
    Collapse,
    CollapseItem,
    Row,
    Col,
    Form,
    FormItem,
} from 'element-ui';

Vue.use(Icon)
Vue.use(Dropdown)
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Select)
Vue.use(Option)
Vue.use(Pagination)
Vue.use(Checkbox)
Vue.use(DatePicker)
Vue.use(Radio)
Vue.use(TimePicker)
Vue.use(ColorPicker)
Vue.use(Image)
Vue.use(Loading)
Vue.use(Empty)
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Input)
Vue.use(Tooltip)
Vue.use(Switch)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Progress)
Vue.use(Tag)
Vue.use(Slider)
Vue.use(RadioButton)
Vue.use(RadioGroup)
Vue.use(Card)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Form)
Vue.use(FormItem)

Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;