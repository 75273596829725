import { getJob2Id } from "@/api/task";
import Moment from "moment";
const state = {
    basicInfo: {
        executor: '', // 执行者id
        jobExecType: NaN, //资源类型
        projectId: '',  //项目id
        name: '', // 任务名称
        resourceName: '', //资源名称
    },
    markInfo: {
        markType: NaN, //标注类型
        markResList: [], //标注集合列表
        markIndex: NaN,// 下标
    },
    markStrategy: {
        jobType: 1, //任务类型，1:立即执行；2：定时执行；3：重复执行  
        jobDate: '',//执行日期
        jobTime: '', //执行时间
    }
}

const mutations = {
    SET_BASIC_INFO: (state, basicInfo) => {
        const { projectId, executor, name, jobExecType, resourceName } = basicInfo

        if (projectId || projectId == '') state.basicInfo.projectId = projectId
        if (executor || executor == '') state.basicInfo.executor = executor
        if (name || name == '') state.basicInfo.name = name
        if (jobExecType || typeof jobExecType == 'number') state.basicInfo.jobExecType = jobExecType
        if (resourceName || resourceName == '') state.basicInfo.resourceName = resourceName

    },
    // 设置mark信息
    SET_MARK_INFO: (state, markInfo) => {
        const { markType, markResList, markIndex } = markInfo

        if (markType || typeof markType == 'number') state.markInfo.markType = markType
        if (markIndex || typeof markIndex == 'number') state.markInfo.markIndex = markIndex
        if (markResList) state.markInfo.markResList = markResList

    },
    // 新增数据
    PUSH_MARK_RES_LIST: (state, info) => {
        state.markInfo.markResList.push(info)
    },
    // 删除
    DEL_MARK_RES_LIST: (state, index) => {
        state.markInfo.markResList.splice(index, 1)
    },
    // 根据下标替换数据
    UP_MARK_RES_LIST: (state, newDate) => {
        state.markInfo.markResList.splice(state.markInfo.markIndex, 1, newDate)
    },

    // 策略相关
    SET_STRATEGY_INFO: (state, markStrategy) => {
        const { jobType, jobDate, jobTime, } = markStrategy

        if (jobDate || jobDate == '') state.markStrategy.jobDate = jobDate;
        if (jobType) state.markStrategy.jobType = jobType;
        if (jobTime || jobTime == '') state.markStrategy.jobTime = jobTime;

    },
}

const actions = {
    // 添加任务基础信息
    addBasicInfo({ commit }, info) {
        return new Promise((resolve) => {
            commit('SET_BASIC_INFO', info);
            resolve()
        })
    },
    // 基础任务信息的初始化
    clearBasicInfo({ commit }) {
        let basicInfo = {
            executor: '', // 执行者id
            jobExecType: NaN, //资源类型
            projectId: '',  //项目id
            name: '', // 任务名称
            resourceName: '',
        }
        commit('SET_BASIC_INFO', basicInfo);
    },
    // 绘制完成回调
    drawFinishCallback({ commit }, data) {
        return new Promise((resolve) => {
            commit('PUSH_MARK_RES_LIST', data)

            let info = {
                markType: NaN,
                markIndex: state.markInfo.markResList.length - 1 == -1 ? 0 : state.markInfo.markResList.length - 1
            }
            commit('SET_MARK_INFO', info);
            resolve()
        })
    },
    // 样式设置的回调
    setStyleCallback({ commit }, data) {
        commit('UP_MARK_RES_LIST', data)
    },
    // 标记信息的初始化
    clearMarkInfo({ commit }) {
        let markInfo = {
            markType: NaN, //标注类型
            markResList: [], //标注集合列表
            markIndex: NaN,// 下标
        }
        commit('SET_MARK_INFO', markInfo);
    },
    // 策略信息的初始化
    clearStrategy({ commit }) {

        let markStrategy = {
            jobType: 1, //任务类型，1:立即执行；2：定时执行；3：重复执行  
            jobDate: '',//执行日期
            jobTime: '', //执行时间
        }
        commit('SET_STRATEGY_INFO', markStrategy);
    },
    // 清除所有信息
    clearAllInfo({ dispatch }) {
        // dispatch('clearBasicInfo')
        dispatch('clearMarkInfo')
        dispatch('clearStrategy')
    },
    // 
    editMarkInfo({ commit }, taskId) {
        return new Promise((resolve, reject) => {
            getJob2Id({ id: taskId }).then(res => {

                const { jobExecutorId, jobExecType, projectId, name, nickName, jobAnnotation, jobType, jobTime } = res.data

                let basicInfo = {
                    executor: jobExecutorId, // 执行者id
                    jobExecType: jobExecType, //资源类型
                    projectId: projectId,  //项目id
                    name: name, // 任务名称
                    resourceName: nickName, //资源名称
                }

                let list = []
                jobAnnotation.forEach((el) => {
                    let nweMark = null;
                    nweMark = convertGeojson2Json(el);
                    list.push(nweMark);
                });

                let markInfo = {
                    markResList: list, //标注集合列表
                }
                let markStrategy = {
                    jobType: jobType, //任务类型，1:立即执行；2：定时执行；3：重复执行  
                    jobTime: jobTime, //执行时间
                }

                if (markStrategy.jobType == 2) markStrategy.jobDate = Moment(jobTime).format("YYYY-MM-DD");

                commit('SET_BASIC_INFO', basicInfo);
                commit('SET_MARK_INFO', markInfo);
                commit('SET_STRATEGY_INFO', markStrategy)
                resolve();
            }).catch(error => {
                reject(error)
            })
        })
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}