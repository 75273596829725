<template>
  <section class="annotation-window_container">
    <el-tooltip effect="dark" placement="left">
      <li
        class="draw-tool_item"
        :class="{
          'is-active': projectMarkInfo.markType == 0,
        }"
      >
        <i @click="standard(0)" class="iconfont pointer icon-point"></i>
      </li>

      <div class="tip-text" slot="content">
        <p>点标记</p>
      </div>
    </el-tooltip>
    <el-tooltip effect="dark" placement="left">
      <li
        class="draw-tool_item"
        :class="{
          'is-active': projectMarkInfo.markType == 1,
        }"
      >
        <i @click="standard(1)" class="iconfont pointer icon-line"></i>
      </li>
      <div class="tip-text" slot="content">
        <p>线标记</p>
      </div>
    </el-tooltip>

    <el-tooltip effect="dark" placement="left">
      <li
        class="draw-tool_item"
        :class="{
          'is-active': projectMarkInfo.markType == 2,
        }"
      >
        <i @click="standard(2)" class="iconfont pointer icon-surface"></i>
      </li>
      <div class="tip-text" slot="content">
        <p>面标记</p>
      </div>
    </el-tooltip>

    <!-- <el-tooltip effect="dark" placement="left">
      <li
        class="draw-tool_item"
        :class="{ 'is-active': isTarget && !projectMarkInfo.markType }"
      >
        <i class="el-icon-aim pointer" @click="trackTaskProgress"></i>
      </li>
      <div class="tip-text" slot="content">
        <p>追踪标记</p>
      </div>
    </el-tooltip> -->
  </section>
</template>

<script>
export default {
  computed: {
    projectMarkInfo() {
      return this.$store.getters.projectMarkInfo;
    },
    // 获取markType
    markType() {
      return this.projectMarkInfo.markType;
    },
  },
  mounted() {
    window.projectMarkExample = null;
  },
  created() {
    this.$EventBus.$on("closeProjectMark", () => {
      this.isTarget = false;
    });
  },
  beforeDestroy() {
    if (projectMarkExample) {
      projectMarkExample.endDraw(true);
      projectMarkExample.activeEdit(false); //结束绘制
      projectMarkExample.clear();
      projectMarkExample = null;
    }
    this.$store.dispatch("projectMark/clearInfo");
    this.$EventBus.$off("closeProjectMark");
  },
  data() {
    return {
      isTarget: false,
    };
  },
  methods: {
    // 追踪标记
    trackTaskProgress() {
      this.isTarget = !this.isTarget;
      this.$EventBus.$emit("trackTaskProgress", this.isTarget);
      if (projectMarkExample) {
        this.close();
      }
    },
    // 标准标记
    standard(type) {
      this.$store.dispatch("map/setIsActionStarted", 3);

      // 如果存在标记，关闭标记
      if (!isNaN(this.markType)) {
        // 如果type和markType相同，关闭标记
        if (this.markType == type) {
          this.close();
        } else {
          this.close(type);
        }
      } else {
        // 如果不存在标记，直接打开标记
        this.isTarget = false;
        this.$EventBus.$emit("trackTaskProgress", this.isTarget);

        this.$store.commit("projectMark/SET_MARK_INFO", { markType: type }); // 写入类型
        this.drawMark(type);
      }
    },
    // 标记完成回调
    close(type) {
      projectMarkExample.endDraw(false);
      projectMarkExample.clear();
      projectMarkExample = null;
      this.$store.dispatch("projectMark/clearInfo");

      if (type || type === 0) {
        this.$store.commit("projectMark/SET_MARK_INFO", { markType: type });
        this.drawMark(type);
      }
    },
    // 点线面绘制
    drawMark(index) {
      if (projectMarkExample) projectMarkExample.endDraw(true);

      projectMarkExample = null;

      switch (index) {
        case 0:
          projectMarkExample = zr3d.drawerToolCreate({
            drawFinishCallback: this.drawFinishCallback,
            drawModifyCallback: this.drawModifyCallback,
            setStyleCallback: this.setStyleCallback,
            style: {
              pointScale: 1.5,
              fontColor: "#ffffffff",
              pointSize: 20,
              fontSize: 20,
              name: "任务",
              color: "#F10909",
              snapType: "point",
            },
          });

          break;
        case 1:
          projectMarkExample = zr3d.drawerToolCreate({
            drawFinishCallback: this.drawFinishCallback,
            drawModifyCallback: this.drawModifyCallback,
            setStyleCallback: this.setStyleCallback,
            style: {
              pointScale: 1.5,
              fontColor: "#ffffff",
              fontSize: 20,
              name: "任务",
              color: "#5faee3",
              lineWidth: 6,
              lineColor: "#5faee3",
              snapType: "point",
            },
          });

          break;
        case 2:
          projectMarkExample = zr3d.drawerToolCreate({
            drawFinishCallback: this.drawFinishCallback,
            drawModifyCallback: this.drawModifyCallback,
            setStyleCallback: this.setStyleCallback,
            style: {
              pointScale: 1.5,
              fontColor: "#ffffff",
              fontSize: 20,
              name: "任务",
              color: "#5faee344",
              lineWidth: 1,
              lineColor: "#5faee3",
              snapType: "point",
            },
          });

          break;
      }
      projectMarkExample.startDraw(index); //开启绘制功能
    },
    // 绘制完成回调
    drawFinishCallback(res) {
      this.$store.dispatch("projectMark/drawFinishCallback", res).then(() => {
        this.endDraw();
      });
    },
    // 移动回调
    drawModifyCallback(event) {
      this.$store.dispatch("projectMark/setStyleCallback", event);
      // 根据下标用进行数据替换
    },
    setStyleCallback(event) {
      this.$store.dispatch("projectMark/setStyleCallback", event);
    },
    // 结束绘制
    endDraw() {
      projectMarkExample.activeEdit(false); // 结束绘制
    },
  },
};
</script>

<style lang="less" scoped>
.annotation-window_container {
  width: 54px;
  height: 170px;
  background: var(--page-background-color);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  font-size: 30px;
  color: white;
  z-index: 1;

  top: 68px;
  right: 30px;
  margin-bottom: 18px;
  .draw-tool_item {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: var(--btn-hover-color);
    }
  }
  .iconfont {
    font-size: 26px;
  }

  .is-active {
    background-color: var(--btn-active-color) !important;
  }

  .gray {
    color: gray;
  }
}
</style>