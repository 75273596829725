const state = {
    remoteInfo: {}
}

const mutations = {
    SET_REMOTE_INFO: (state, remoteInfo) => {
        state.remoteInfo = remoteInfo
    },
}

const actions = {
    // 遥控器坐标信息
    remoteInfoWrit({ commit }, info) {
        const { latitude, longitude,transmission_signal_quality,capacity_percent } = info.data
        commit('SET_REMOTE_INFO', { latitude, longitude,transmission_signal_quality,capacity_percent })
    },
    clearAllInfo({ commit }) {

        commit('SET_REMOTE_INFO', {})
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
