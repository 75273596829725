<template>
  <CommonDialog showMask>
    <section class="add-task_container">
      <slot />
      <div class="title">创建新任务</div>
      <div class="content">
        <div class="item-input">
          <label for=""><span class="mandatory">*</span>任务名称</label>
          <div class="input-box">
            <vInput
              v-model.trim="taskName"
              @blur="taskNameBlur()"
              :err="taskNameErr"
              :max="20"
              placeholder="请输入任务名称"
            />
          </div>
        </div>

        <div class="item-input">
          <label for=""><span class="mandatory">*</span>选择警种</label>
          <div class="input-box" :class="{ err: projectIdErr }">
            <el-select
              :disabled="isDisableProject"
              v-model="projectId"
              placeholder="请选择"
              @visible-change="projectVisibleChange"
              @change="projectIdChange"
            >
              <el-option
                v-for="item in projectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="item-input">
          <label for=""><span class="mandatory">*</span>选择人员</label>
          <div class="input-box" :class="{ err: resourcesErr }">
            <el-select
              :disabled="isResource"
              v-model="executor"
              placeholder="请选择"
              @visible-change="resourcesVisibleChange"
              @change="resourcesChange"
            >
              <el-option
                v-for="item in resourcesOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="btn-group">
          <button class="btn cancel-btn pointer" @click="cancel">
            <span>取消</span>
          </button>
          <button class="btn determine-btn pointer" @click="determine">
            <span>确定</span>
          </button>
        </div>
      </div>
    </section>
  </CommonDialog>
</template>

<script>
import vInput from "@/components/CommonInput/index.vue";
import CommonDialog from "@/components/CommonDialog/index.vue";
import { debounce } from "@/utils/index";
import { validTaskName } from "@/utils/validate";
import { projectResourceList } from "@/api/resources";
import { list, getProjectList } from "@/api/project";
export default {
  components: {
    CommonDialog,
    vInput,
  },
  computed: {
    basicInfo() {
      return this.$store.getters.basicInfo;
    },
  },
  data() {
    return {
      taskNameErr: false,
      projectIdErr: false,
      resourcesErr: false,
      resourcesOptions: [],
      projectOptions: [], //项目资源列表
      taskName: "", //任务名称
      projectId: "",
      executor: "",
      resourceName: "",
      jobExecType: NaN,
      isResource: false, //判断是否为资源查询项目
      isDisableProject: false, //判断该资源是否有项目
    };
  },
  created() {
    // 该组件仅适用新增
    const { executor, jobExecType, resourceName } = this.basicInfo;

    if (executor !== "" && this.$route.path != "/task/index") {
      this.isResource = true;
      this.executor = executor;
      this.jobExecType = jobExecType;
      this.resourceName = resourceName;
      this.getProjectItem();
    } else {
      this.getProjectList();
    }
  },
  methods: {
    verify() {
      if (this.isDisableProject) {
        this.$notify({
          title: "消息",
          type: "info",
          message: "该资源暂无项目",
          offset: 150,
          duration: 3000,
        });
        return false;
      } else if (this.taskName === "") {
        this.$notify({
          title: "消息",
          type: "info",
          message: "任务名称不可为空",
          offset: 150,
          duration: 3000,
        });
        this.taskNameErr = true;
        return false;
      } else if (!validTaskName(this.taskName)) {
        this.$notify({
          title: "消息",
          type: "info",
          message: "请输入1~20位字符,仅支持中文、字母、数字",
          offset: 150,
          duration: 3000,
        });
        this.taskNameErr = true;
        return false;
      } else if (this.projectId === "") {
        this.$notify({
          title: "消息",
          type: "info",
          message: "请选择警种",
          offset: 150,
          duration: 3000,
        });
        this.projectIdErr = true;
        return false;
      } else if (this.executor === "") {
        this.$notify({
          title: "消息",
          type: "info",
          message: "请选择人员",
          offset: 150,
          duration: 3000,
        });
        this.resourcesErr = true;
        return false;
      } else {
        return true;
      }
    },
    cancel() {
      this.$emit("cancel");
    },

    determine: debounce(function () {
      if (!this.verify()) return;
      let info = {
        executor: this.executor, // 执行者id
        jobExecType: this.jobExecType, //资源类型
        projectId: this.projectId, //项目id
        name: this.taskName, // 任务名称
        resourceName: this.resourceName,
      };

      this.$store.dispatch("mark/addBasicInfo", info).then(() => {
        this.cancel();
        this.$router.push({ path: "/markTask" });
      });
    }),
    // 名称检测
    taskNameBlur() {
      this.taskName === ""
        ? (this.taskNameErr = true)
        : (this.taskNameErr = false);
    },
    // 下拉框出现时触发
    projectVisibleChange(value) {
      if (value) return;
      if (this.projectId === "") {
        this.projectIdErr = true;
      } else {
        this.projectIdErr = false;
      }
    },
    // 项目出现变更时触发
    projectIdChange() {
      if (this.isResource) return;
      this.getProjectResourceList(); //获取项目下资源列表
      this.executor = "";
    },

    // 获取项目资源列表
    async getProjectResourceList() {
      const res = await projectResourceList({ projectId: this.projectId });
      if (res) {
        this.resourcesOptions = [];
        res.data.data.forEach((element) => {
          // 资源类型，资源类型，0：全部；1-机场；2-飞手；3：地巡
          if (element.resourceType === "1") return;

          this.resourcesOptions.push({
            value: element.resourceId,
            label: element.resourceName,
            jobExecType: element.resourceType,
          });
        });
      }
    },
    // 获取项目列表
    async getProjectList() {
      const res = await list({ pageNum: 1, pageSize: 100, projectStatus: 2 });
      if (res) {
        this.projectOptions = []; //项目列表
        res.data.forEach((element) => {
          this.projectOptions.push({
            value: element.id,
            label: element.projectName,
          });
        });
      }
    },

    // 获取资源列表
    async getProjectItem() {
      const { executor, resourceName, jobExecType } = this.basicInfo;

      this.resourcesOptions = []; //资源列表
      this.resourcesOptions.push({
        value: executor,
        label: resourceName,
        jobExecType,
      });

      const res = await getProjectList({ resourceId: executor }); //获取相关资源的项目

      if (res) {
        this.projectOptions = []; //置空项目列表

        if (res.data.length === 0) {
          this.projectOptions.push({
            value: 0,
            label: "该资源暂无项目",
          });

          this.projectId = 0;
          this.isDisableProject = true;
          return false;
        }

        // 资源所属项目赋值
        res.data.forEach((element) => {
          this.projectOptions.push({
            value: element.projectId,
            label: element.projectName,
          });
        });
      }
    },

    resourcesVisibleChange(value) {
      if (value) return;
      if (this.executor === "") {
        this.resourcesErr = true;
      } else {
        this.resourcesErr = false;
      }
    },
    // 资源变更触发
    resourcesChange(value) {
      const resourcesObj = this.resourcesOptions.find(
        (item) => item.value === value
      );

      this.jobExecType = resourcesObj.jobExecType;
      this.resourceName = resourcesObj.label;
    },
  },
};
</script>

<style lang="less" scoped>
.add-task_container {
  width: 590px;
  background: var(--person-device-list-bg-color);
  box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  /deep/ .el-input__inner {
    background: transparent;
    color: white;
    font-size: 16px;
    height: 34px;
    line-height: 34px;
    border: 1px solid #989da5;
  }
  /deep/ .el-select {
    display: flex;
  }
  .title {
    height: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 20px;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.31);
  }
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 94px;

    .item-input {
      color: #ffffff;
      font-size: 18px;
      font-weight: 300;
      padding-top: 16px;
      display: flex;

      flex-direction: column;
      label {
        margin-bottom: 10px;
      }
      .mandatory {
        color: #ef6767;
      }
      .input-box {
        height: 34px;
      }
    }

    .btn-group {
      margin-top: 30px;
      margin-bottom: 30px;
      display: flex;
      justify-content: flex-end;

      .btn {
        width: 116px;
        height: 42px;
        border-radius: 4px;
        font-size: 20px;
        color: #ffffff;
        border: none;
      }
      .cancel-btn {
        background: transparent;
        border: 1px solid #a2bac5;
        margin-right: 30px;
        &:hover {
          border-color: #a3ecfb;
        }
        &:active {
          border-color: #40e9f2;
        }
      }
      .determine-btn {
        background: var(--btn-default-color);
        border: 1px solid #a2bac5;
        &:hover {
          background-color: var(--btn-active-color);
        }
        &:active {
          background-color: var(--btn-active-color);
        }
      }
    }
  }
}
.err {
  /deep/ .el-input__inner {
    border-color: red;
  }
}

::v-deep .el-input.is-disabled .el-input__inner {
  background: transparent;
  color: white;
  font-size: 16px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #989da5;
}
</style>