// 全局事件弹窗
import Vue from 'vue'
import alarmModal from './index.vue'
let alarmModalInstance = null; // 保存弹窗实例的变量

Vue.prototype.$showAlarmReminder = (data)=>{
    if(alarmModalInstance){
        alarmModalInstance.$el.remove()
        alarmModalInstance.$destroy()
        alarmModalInstance = null
    }

    const ModalComponent = Vue.extend(alarmModal)
    alarmModalInstance = new ModalComponent({
        propsData :{
            alarmInfo:data
        }
    })

    alarmModalInstance.$mount(); // 手动挂载组件实例到 DOM
    document.body.appendChild(alarmModalInstance.$el); // 将组件的根元素添加到 body 中
}

Vue.prototype.$removeAlarmReminder = () => {
    if (alarmModalInstance) {
        alarmModalInstance.$el.remove(); // 移除弹窗元素
        alarmModalInstance.$destroy(); // 销毁弹窗实例
        alarmModalInstance = null; // 重置弹窗实例变量
    }
};