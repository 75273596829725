<template>
  <agora
    v-if="liveOptions.playerType === 'agora'"
    :liveOptions="liveOptions"
    @canplay="$emit('canplay')"
    @removePlay="$emit('removePlay')"
    @windowResize="$emit('windowResize')"
  />
  <tcPlayer
    v-else-if="liveOptions.playerType === 'tcplayer'"
    :liveOptions="liveOptions"
    :playerOptions="playerOptions"
    @canplay="$emit('canplay')"
    @removePlay="$emit('removePlay')"
    @windowResize="$emit('windowResize')"
  />
  <webRTCPlayer
    v-else-if="liveOptions.playerType === 'webrtc'"
    :liveOptions="liveOptions"
    :playerOptions="playerOptions"
    :key="liveOptions.liveUrl"
    @canplay="$emit('canplay')"
    @removePlay="$emit('removePlay')"
    @windowResize="$emit('windowResize')"
  />
  
</template>

<script>
// 引入tcplayer组件
import tcPlayer from "./tcPlayer.vue";
import agora from "./agora.vue";
import webRTCPlayer from "./webRTCPlayer.vue";
export default {
  components: {
    tcPlayer,
    agora,
    webRTCPlayer
  },
  props: {
    liveOptions: {
      type: Object,
      require: true,
    },
    playerOptions: {
      type: Object,
      require: false,
    },
    name: {
      type: String,
      default: "实时",
      require: false,
    },
  },
  provide: function () {
    return {
      msg: () => this.name,
    };
  },
};
</script>

<style>

  :deep(video) {
    object-fit: cover !important;
  }
</style>
