<template>
  <section
    class="coordinate-info_container"
    :class="{
      'coordinate-info_task': path == '/task/index',
      'in-task_details': inDetails,
    }"
  >
    <div class="item">经度：{{ geographic.longitude.toFixed(7) }}°</div>
    <div class="item">纬度：{{ geographic.latitude.toFixed(7) }}°</div>
    <div class="item">海拔：{{ geographic.height.toFixed(2) }} m</div>
    <div class="item">坐标系：WGS84</div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    path() {
      return this.$route.path;
    },
    inDetails() {
      const currentRoute = this.$route.path;
      return currentRoute === "/courseTasks/index";
    },
  },
  props: {
    geographic: {
      type: Object,
      default: () => {
        return {
          latitude: 0,
          longitude: 0,
          height: 0,
        };
      },
    },
  },
};
</script>
<style lang="less" scoped>
.coordinate-info_container {
  height: 48px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  left: 356px;
  right: 0;
  color: white;
  display: flex;
  align-items: center;
  z-index: 1;
  padding-left: 30px;
  .item {
    margin-right: 92px;
    width: 180px;
  }
}
.coordinate-info_task {
  left: 354px;
}
.in-task_details {
  left: 414px;
}
</style>
