<template>
  <section
    class="preview-live_container"
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="AI识别功能启用中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <defaultPage v-if="liveOptions.playerType == 'none'" />
    <video
      :src="liveOptions.liveUrl"
      v-else-if="liveOptions.playerType == 'video'"
      class="default-video"
      autoplay
    ></video>
    <livePlayer
      v-else
      class="live-player"
      :liveOptions="liveOptions"
      :playerOptions="playerOptions"
      :name="name"
      @canplay="onPlaybackSuccess"
      @removePlay="onPlayerRemove"
      @windowResize="onPlayerResize"
    />
    <modelMenuList
      v-if="aiMode"
      v-loading="!aiRecognitionEnabled"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :myModelList="myModelList"
      @selectModel="selectModel"
      ref="modelMenuListRef"
    />
    <!-- 视频打点功能隐藏 -->
    <!-- <div
      v-if="videoMarkMode"
      class="video_mask"
      :class="{ is_active: markersEnabled }"
      @click.stop="toggleVideoMarkers"
    >
      <i
        v-loading="!videoMarkerEnabled"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        class="icon-mark iconfont"
      ></i>
      <p class="tip-text">视频打点</p>
    </div> -->
    <!-- 新增总数统计 -->
    <div class="class-tag_total" v-if="modelActive == 2">
      <el-tag type="danger"> 车辆总数:{{ vehicleCount }} </el-tag>
    </div>

    <liveStart v-if="!isObjectEmpty(droneInfo) && showDroneInfo" />
    <!--AI识别的画布-->
    <canvas id="ai-recognition-canvas"></canvas>
    <!-- end -->
    <canvas
      id="cameraAdjustmentCanvas"
      ref="cameraCanvas"
      @dblclick="cameraDblclick"
    ></canvas>
    <el-tooltip effect="dark" placement="bottom">
      <i class="el-icon-close pointer" @click="closeLive"></i>
      <div class="tip-text" slot="content">
        <p>关闭直播</p>
      </div>
    </el-tooltip>
    <!-- 插槽 -->
    <slot></slot>
  </section>
</template>

<script>
import defaultPage from "./components/defaultPage.vue";
import livePlayer from "@/components/CommonLive/index.vue";
import modelMenuList from "./components/modelMenuList.vue";
import liveStart from "@/views/resourcesDetails/components/drone/liveStart.vue";
import { getMyModelList } from "@/api/model";
import { getCameraParams } from "@/api/resources";
import aiIdentification from "./aiIdentification.js";
import videoMarker from "./videoMarker.js";
export default {
  components: {
    defaultPage,
    livePlayer,
    modelMenuList,
    liveStart,
  },
  computed: {
    // 无人机信息集合
    droneInfo() {
      return this.$store.getters.droneInfo;
    },
    modeCode() {
      return this.droneInfo.mode_code;
    },
    carCount() {
      return this.$store.getters.carCount;
    },
    // 机场信息
    airportInfo() {
      return this.$store.getters.airportInfo;
    },
    // 当前选中的模型
    selectedAiModel() {
      const BOX_DEVICE = "boxDevice";
      const MANAGE_BOX_ENTITY = "manage_box_entity";
      const BOX_MODELS_NAME = "box_models_name";

      return (
        this.airportInfo?.[BOX_DEVICE]?.[MANAGE_BOX_ENTITY]?.[
          BOX_MODELS_NAME
        ] || ""
      );
    },
  },
  props: {
    liveOptions: {
      type: Object,
      require: true,
    }, // 直播参数

    playerOptions: {
      type: Object,
      require: false,
    }, // 播放器参数

    name: {
      type: String,
      require: false,
    }, // 直播名称

    showDroneInfo: {
      type: Boolean,
      default: true,
    }, // 是否显示飞行器信息
    aiMode: {
      type: Boolean,
      default: false,
    }, // 是否开启ai识别
    // 是否显示视频打点
    videoMarkMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myModelList: [], // 我的模型列表
      aiInstance: null, // ai识别实例
      aiRecognitionEnabled: false, //ai识别功能是否可用
      videoMarkerEnabled: false,
      fullscreenLoading: false,
      // 视频打点实例
      videoMarkers: null,
      markersEnabled: false,
      cameraInfo: null,
      modelActive: -1,
      vehicleCount: 0,
      isDoubleClick: false,
    };
  },
  created() {
    if (this.aiMode) {
      this.aiInstance = new aiIdentification();
      this.aiInstance.setCallback(this.totalVehicles);
      this.fetchModelList();
    }
    if (this.videoMarkMode) this.videoMarkers = new videoMarker();
    this.$EventBus.$on("videoZizeChange", () => {
      this.onPlayerResize();
    });
  },
  mounted() {},
  beforeDestroy() {
    this.$EventBus.$off("videoZizeChange");

    if (this.aiInstance) this.aiInstance.destroy();
    if (this.videoMarkers) this.videoMarkers.destroy();
  },
  methods: {
    totalVehicles(total) {
      this.vehicleCount = total;
    },
    isObjectEmpty(obj) {
      // 使用 Object.keys() 检查对象是否有任何属性
      return Object.keys(obj).length === 0;
    },
    // 获取相机配置
    async cameraParams() {
      const newarr = Object.keys(this.droneInfo);

      let type = newarr[0];
      const res = await getCameraParams({ cameraType: type });
      if (res) {
        this.cameraInfo = res.data;
      }
    },
    // 检测飞行器状态
    checkDroneStatus() {
      const isFlying = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(
        this.modeCode
      );

      if (!isFlying)
        this.$message({
          message: "飞行器未起飞，暂时无法使用该功能",
          type: "info",
        });

      return isFlying;
    },
    // 切换视频打点
    async toggleVideoMarkers() {
      if (!this.checkDroneStatus()) return false;

      if (this.markersEnabled) {
        this.videoMarkers.removeTextureTool();
        this.$message({
          message: "视频打点已关闭",
          type: "success",
        });
      } else {
        await this.cameraParams();
        this.videoMarkers.startVideoMarker(this.cameraInfo);
        this.$message({
          message: "视频打点已开启,请点击视频进行标记",
          type: "success",
        });
      }
      this.markersEnabled = !this.markersEnabled;
    },
    // 关闭直播
    closeLive() {
      this.$emit("closeLive");
      this.$EventBus.$emit("closeLive");
      if (this.aiInstance) this.aiInstance.destroy();
      if (this.videoMarkers) this.videoMarkers.destroy();
    },
    // 播放器加载成功
    onPlaybackSuccess() {
      const { playerType } = this.liveOptions;
      //
      if (this.aiMode) this.initialize();
      //
      if (this.aiInstance)
        this.aiInstance.initCanvasSize(playerType, "ai-recognition-canvas");
      //
      if (this.videoMarkers)
        this.videoMarkers.initCanvasSize(
          playerType,
          "cameraAdjustmentCanvas",
          this.onCanvasInitialized
        );
    },
    // 视频打点画布初始化完成回调事件
    onCanvasInitialized() {
      this.videoMarkerEnabled = true;
    },
    // 鼠标双击事件
    cameraDblclick(event) {
      // 获取鼠标点击的坐标
      this.handleDoubleClick();
      const canvas = this.$refs.cameraCanvas;
      const rect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;
      // 计算点击位置在 canvas 中的比例坐标
      const scaleX = mouseX / rect.width;
      const scaleY = mouseY / rect.height;
      // 在控制台打印鼠标点击的坐标
      this.$EventBus.$emit("camera_aim", { x: scaleX, y: scaleY });
    },
    handleDoubleClick() {
      this.isDoubleClick = true;

      // 在这里根据需要修改鼠标样式
      document.body.classList.add("custom-cursor");

      // 一段时间后恢复默认鼠标样式
      setTimeout(() => {
        this.isDoubleClick = false;
        document.body.classList.remove("custom-cursor");
      }, 2000);
    },
    // 播放器尺寸变化
    onPlayerResize(isFullScreen = false) {
      if (this.aiInstance) this.aiInstance.initVideoSizeChange(isFullScreen);
      if (this.videoMarkers) this.videoMarkers.initVideoSizeChange();
      // 对上诉两个函数执行进行条件判断
    },
    // 播放器移除
    onPlayerRemove() {
      if (this.aiInstance) {
        this.aiInstance.clearAI();
        this.$refs.modelMenuListRef.selectModel(0);
      }
      if (this.videoMarkers) this.videoMarkers.removeTextureTool();
    },
    // 获取我的模型列表
    async fetchModelList() {
      const res = await getMyModelList({
        expire: "1",
        modelCategoryId: "0",
      });
      if (res) {
        this.myModelList = [
          {
            model_name: "关闭",
          },
        ];
        res.data.forEach((item) => {
          const { model_name, model_url, model_config, icon } = item;
          this.myModelList.push({
            model_name,
            model_url,
            model_config,
            icon,
          });
        });
      }
    },
    // 定义异步函数，用于同时执行 downloadModel 和 openCvReady
    async initialize() {
      await Promise.all([
        this.aiInstance.downloadModel(this.myModelList),
        this.aiInstance.openCvReady(),
      ]);

      this.aiRecognitionEnabled = true;
      this.$EventBus.$emit("initFinish");
    },
    // 选择模型
    selectModel(index) {
      if (index > 0) this.openFullScreen();
      this.modelActive = index;
      this.aiInstance.selectModel(index, this.closeFullScreen);
    },
    // 开启全屏loading动画
    openFullScreen() {
      this.fullscreenLoading = true;
    },
    // 关闭全屏loading动画
    closeFullScreen() {
      this.fullscreenLoading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.preview-live_container {
  flex: 1;
  display: flex;
  background: black;
  position: relative;
  .el-icon-close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    background-color: var(--btn-default-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: #ffffff;
    z-index: 1;
    &:hover {
      background-color: var(--btn-hover-color);
    }
    &:active {
      background-color: var(--btn-active-color);
    }
  }
  .default-video {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
  .live-player {
    /deep/ .sharing {
      position: absolute;
      right: 50px;
      z-index: 3;
      font-size: 22px;
      top: 10px;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      background-color: var(--btn-default-color);
      display: flex;

      .qr-box {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 30px;
      }
      &:hover {
        background-color: var(--btn-hover-color);
      }
    }
  }
  /deep/video {
    object-fit: contain !important;
  }
  #ai-recognition-canvas,
  #cameraAdjustmentCanvas {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // border: red 1px solid;
  }
  .video_mask {
    position: absolute;
    left: 22px;
    top: 111px;
    cursor: pointer;
    z-index: 3;
    color: #fff;
    .icon-mark {
      box-sizing: border-box;
      width: 56px;
      height: 56px;
      background: #113f41;
      border-radius: 4px;
      border: 1px solid #93c8e4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 32px;
    }
    .tip-text {
      text-align: center;
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .is_active {
    color: #40e9f2 !important;
  }
  .class-tag_total {
    position: absolute;
    bottom: 20px;
    left: 20px;
    /deep/.el-tag {
      font-size: 18px;
    }
  }
}
</style>
