<template>
  <!-- 资源弹窗 -->
  <section class="resources-popup" id="resourcesInfo">
    <div class="title" :title="selectedResInfo.resourceName">
      {{ selectedResInfo.resourceName | stringCutting(11) }}
    </div>
    <div class="resources-popup_line"></div>
    <div class="info">
      <span class="name">单位：</span>
      <span class="content" v-if="selectedResInfo.unitName">
        {{ selectedResInfo.unitName }}
      </span>
      <span v-else class="content">无单位信息</span>
    </div>
    <div class="info">
      <span class="name">职级：</span>
      <span class="content" v-if="selectedResInfo.jobGrade">
        {{ selectedResInfo.jobGrade }}
      </span>
      <span class="content" v-else>无职级信息</span>
    </div>
    <div class="info">
      <span class="name">联系电话：</span>
      <span class="content" v-if="selectedResInfo.phoneNumber">
        {{ selectedResInfo.phoneNumber }}</span
      >
      <span v-else>无电话信息</span>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    // 获取列表
    getAllResList() {
      return this.$store.getters.getAllResList;
    },
    // 选中资源
    selectedResInfo() {
      return this.getAllResList.find((item) => item.id == this.resId)
        ? this.getAllResList.find((item) => item.id == this.resId)
        : {};
    },
    // 当前选中index
    resId() {
      return this.$store.getters.resId;
    },
  },
  data() {
    return {};
  },
  methods: {
    addTask() {
      const { resourceType } = this.selectedResInfo;

      this.describeDataStorage();

      this.$emit("addTask", resourceType);

      this.$store.commit("resource/SET_RES_ID", -1); // 清理选中值
    },
    // 数据写入
    describeDataStorage() {
      const { resourceType, id, resourceName } = this.selectedResInfo;

      //mark任务数据写入
      const markInfo = () => {
        const info = {
          executor: id, // 执行者|资源id
          jobExecType: resourceType, //资源类型
          resourceName,
        };

        this.$store.dispatch("mark/addBasicInfo", info);
      };

      //航线任务数据写入
      const airlineInfo = () => {
        // taskName: '', //任务名称
        // projectId: '', //项目id
        // executor: '', // 执行者|资源id
        // nickName: '', // 执行人名称
        // jobExecType: NaN, //资源类型
        // airlineType: '', // 航线类型waypoint：航点飞行；mapping2d：建图航拍；mapping3d：倾斜摄影；mappingStrip：航带飞行
        const info = {
          executor: id, // 执行者|资源id
          jobExecType: resourceType, //资源类型
          nickName: resourceName,
        };

        this.$store.dispatch("airline/addTaskInfo", info);
      };
      // 地勤
      const hangar = () => {
        airlineInfo();
      };
      // 飞手
      const pilot = () => {
        airlineInfo();
        markInfo();
      };
      // 地勤
      const groundCrew = () => {
        markInfo();
      };

      // 创建策略Map
      const strategyMap = new Map();
      strategyMap.set(1, hangar);
      strategyMap.set(2, pilot);
      strategyMap.set(3, groundCrew);

      strategyMap.get(resourceType)();
    },
  },
};
</script>

<style lang="less" scoped>
.resources-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 202px;
  height: 145px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  display: none;
  // background: url("@/assets/map/resources-popup_bg.png") no-repeat center;
  background: #1a1c20;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #165dff;
  color: #fff9e4;
  .title {
    height: 42px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #fff;
  }

  .info {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
  }
  .resources-popup_line {
    background: #ffffff;
    height: 1px;
    width: calc(100% - 16px);
    margin: auto;
    margin-bottom: 6px;
  }
}
</style>