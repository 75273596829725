class AiIdentification {
    constructor() {
        this.h = null; //
        this.w = null;
        this.input = null;
        this.wait_class = new wait_for_model_class();
        this.using_class = new Inused_class();
        this.settime_processvideo = null;
        this.settime_Kal_process = null;
        this.canvas = null;
        this.ctx = null;
        this.video = null;
        this.confidenceThreshold = 0.3;
        this.iouThreshold = 0.3;
        this.timer = null;
        this.qualityLevel = 0.03;
        this.minDistance = 1;
        this.blockSize = 3;
        this.useHarrisDetector = false;
        this.maxCorners = 5000;
        this.maxLevel = 4;
        this.winSize = new cv.Size(5, 5);
        this.criteria = new cv.TermCriteria(
            cv.TERM_CRITERIA_EPS | cv.TERM_CRITERIA_COUNT,
            10,
            0.03
        );
        this.color = [];
        this.st = new cv.Mat();
        this.FPS = 100;
        this.guang_w = 256;
        this.guang_h = 128;
        this.allModelsDownloaded = false;
        this.opencvInitialized = false;
        this.modelConfig = [];
        this.modelActive = NaN; // 选中目标
        this.loading = false;
        this.loadingFinishedCallback = null;
        this.openai = false;
        // 车辆总数回调
        this.totalVehicles = null;

    };
    //
    async downloadModel(myModelList) {
        const modelUrls = myModelList
            .filter((item) => item.model_url)
            .map((item) => item.model_url);


        this.modelConfig = myModelList.filter((item) => item.model_url).map((item) => item.model_config);

        if (modelUrls.length === 0) {
            // 没有需要加载的模型
            this.allModelsDownloaded = true;
            return;
        }

        const loadedModels = await Promise.all(
            modelUrls.map(async (url) => {
                // 检测模型是否已经存在
                if (this.isModelDownloaded(url)) {
                    return null; // 模型已存在，返回null
                }
                return tf.loadGraphModel(url);
            })
        );

        // 过滤掉已存在的模型和null值
        const newModels = loadedModels.filter((model) => model !== null);

        if (newModels.length === 0) {
            // 没有需要加载的新模型
            this.allModelsDownloaded = true;
            return;
        }

        modelList.push(...newModels);
        this.allModelsDownloaded = true;
    }
    // 检测模型是否已经存在
    isModelDownloaded(modelUrl) {
        return window.modelList.some((model) => model.modelUrl === modelUrl);
    };
    // openCv初始化
    openCvReady() {
        this.color.push(
            new cv.Scalar(parseInt(255), parseInt(0), parseInt(0), 255)
        );
        this.color.push(
            new cv.Scalar(parseInt(0), parseInt(255), parseInt(0), 255)
        );
        for (let i = 0; i < this.maxCorners; i++) {
            this.color.push(
                new cv.Scalar(
                    parseInt(Math.random() * 255),
                    parseInt(Math.random() * 255),
                    parseInt(Math.random() * 255),
                    255
                )
            );
        }

        this.opencvInitialized = true;
    };
    selectModel(index, callback) {
        this.modelActive = index;
        this.clearAI();

        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }

        if (index > 0) {
            this.openai = true;
            this.loadingFinishedCallback = callback;
            this.loading = true;

            const animationIntervalDuration = 16; // 动画刷新间隔（毫秒）

            this.timer = setInterval(() => {
                this.refresh();
            }, animationIntervalDuration);
        }
    };
    refresh_last_corners() {
        let temp_frame = new cv.Mat(
            this.video.height,
            this.video.width,
            cv.CV_8UC4
        );
        let frame = new cv.Mat(this.guang_h, this.guang_w, cv.CV_8UC4);
        let cap = new cv.VideoCapture(this.video);
        cap.read(temp_frame);
        cv.resize(temp_frame, frame, frame.size());
        delete_arr(this.wait_class.imgs);
        this.wait_class.imgs = [];
        this.wait_class.imgs.push(new cv.Mat());
        cv.cvtColor(frame, this.wait_class.imgs[0], cv.COLOR_RGB2GRAY);
        frame.delete();
        temp_frame.delete();
        this.wait_class.point_first = new cv.Mat();
        let none = new cv.Mat();
        cv.goodFeaturesToTrack(
            this.wait_class.imgs[0],
            this.wait_class.point_first,
            this.maxCorners,
            this.qualityLevel,
            this.minDistance,
            none,
            this.blockSize
        );
        none.delete();
        delete_arr(this.wait_class.raw_imgs);
        this.wait_class.raw_imgs = [];
        this.wait_class.square = [];
    };
    async refresh() {
        if (!this.openai) return;
        let newst_time = Date.now();

        const modelIndex = this.modelActive - 1;
        const numClasses = this.modelConfig[modelIndex].names.length;

        this.refresh_last_corners();
        this.input = tf.tidy(() => {
            let temp = tf.browser.fromPixels(this.video);

            let result_img = tf.image
                .resizeBilinear(temp, [
                    this.modelConfig[modelIndex].model_input_height,
                    this.modelConfig[modelIndex].model_input_width,
                ])
                .div(255.0)
                .expandDims(0);
            tf.dispose(temp);
            return result_img;
        });
        // //运行预测函数
        const res = await modelList[modelIndex].execute(this.input);

        if (this.loading) {
            this.loading = false;

            if (typeof this.loadingFinishedCallback === 'function') {
                this.loadingFinishedCallback(); // 调用回调函数
            }
        }
        // const res = await model.execute(input);
        // 模型输出结果的数据
        const data = res.dataSync();

        const result = postProcessYOLOv8(
            data,
            numClasses,
            this.confidenceThreshold,
            this.iouThreshold
        );

        // 输出结果
        const boxes_data = result.boxes;
        const scores_data = result.scores;
        const classes_data = result.classes;
        const valid_detections_data = classes_data.length;

        let classCounts = {}; // 定义分类计数器

        //开始将比例尺寸还原到与canvas大小的尺寸，并且显示函数
        for (let i = 0; i < valid_detections_data; ++i) {
            let dx = 0;
            let dy = 0;
            let center_x = 0;
            let center_y = 0;
            let count_tt = 0;
            let [x1, y1, x2, y2] = convertToBoundingBoxCoordinates(...boxes_data[i]);
            x1 =
                (x1 / this.modelConfig[modelIndex].model_input_width) *
                this.canvas.width;
            x2 =
                (x2 / this.modelConfig[modelIndex].model_input_width) *
                this.canvas.width;
            y1 =
                (y1 / this.modelConfig[modelIndex].model_input_height) *
                this.canvas.height;
            y2 =
                (y2 / this.modelConfig[modelIndex].model_input_height) *
                this.canvas.height;
            const width = x2 - x1;
            const height = y2 - y1;
            const klass = this.modelConfig[modelIndex].names[classes_data[i]];
            const score = scores_data[i].toFixed(2);



            if (modelIndex == 1) {
                // 更新分类计数器
                // 过滤掉不需要的分类
                // ["车", "人", "自行车", "摩托车", "巴士", "卡车"]
                // if (klass === "人" || klass === "自行车" || klass === "摩托车") {
                //     // 跳出
                //     continue;
                // }
                if (klass === "车" || klass === "巴士" || klass === "卡车") {
                    // 更新分类计数器
                    if (klass in classCounts) {
                        classCounts[klass]++;
                    } else {
                        classCounts[klass] = 1;
                    }
                }

            }
            //-------------计算光流点最开始相对于最开始位置差值----------------
            let in_arr = []; //获取在框里面的光流点id
            for (let j = 0; j < this.wait_class.point_first.rows; j++) {
                let x =
                    (this.wait_class.point_first.floatAt(j, 0) / this.guang_w) * this.w;
                let y =
                    (this.wait_class.point_first.floatAt(j, 1) / this.guang_h) * this.h;
                if ((x - x1) * (x - x2) < 0 && (y - y1) * (y - y2) < 0) {
                    in_arr.push(j);
                    center_x += x;
                    center_y += y;
                    count_tt += 1;
                }
            }
            dx = parseInt((x1 + x2) / 2 - center_x / count_tt);
            dy = parseInt((y1 + y2) / 2 - center_y / count_tt);
            //这里可以加入相同框则追踪参数不变
            this.wait_class.square.push([
                x1,
                y1,
                width,
                height,
                klass,
                score,
                in_arr,
                dx,
                dy,
                new xuezhangFilter(x1, y1, newst_time),
            ]);
        }
        tf.dispose(this.input);
        tf.dispose(res);
        this.catch_newest_point_from_imgs();
        clearTimeout(this.settime_Kal_process);
        clearTimeout(this.settime_processvideo);

        if (modelIndex == 1) {
            let total = 0;

            Object.keys(classCounts).forEach((label) => {
                if (label === "车" || label === "巴士" || label === "卡车") {
                    total += classCounts[label];
                }
            });
            this.totalVehicles(total)
            //    total;
            // 设置一个回调函数，将整个total返回给调用实例
        }
        if (boxes_data.length > 0) {
            this.processVideo();
        }
    };

    setCallback(callback) {
        this.totalVehicles = callback;
    }
    catch_newest_point_from_imgs() {
        let newest_time = Date.now();
        let newest_point = this.wait_class.point_first;
        let err = new cv.Mat();
        //这里有点问题
        for (let ttt = 0; ttt < this.wait_class.imgs.length - 1; ttt++) {

            //获取相邻帧光流点，存在out_points
            let out_point = new cv.Mat();
            cv.calcOpticalFlowPyrLK(
                this.wait_class.imgs[ttt],
                this.wait_class.imgs[ttt + 1],
                newest_point,
                out_point,
                this.st,
                err,
                this.winSize,
                this.maxLevel,
                this.criteria
            );
            let goodNew = [];

            let count = 0;
            let color_duizhao_dict = {};
            let this_tow_zui_arr = {};
            for (let i = 0; i < out_point.rows; i++) {
                color_duizhao_dict[i] = 3;
                if (this.st.data[i] === 1) {
                    //这里应该是上一个接下一个
                    this_tow_zui_arr[i] = count;
                    goodNew.push(
                        new cv.Point(out_point.data32F[i * 2], out_point.data32F[i * 2 + 1])
                    );
                    count += 1;
                    continue;
                }
                this_tow_zui_arr[i] = 0;
            }
            out_point.delete();
            //这个可以先不要
            let corners_model_temp = new cv.Mat(goodNew.length, 1, cv.CV_32FC2);
            for (let i = 0; i < goodNew.length; i++) {
                corners_model_temp.data32F[i * 2] = goodNew[i].x;
                corners_model_temp.data32F[i * 2 + 1] = goodNew[i].y;
            }

            for (let i = 0; i < this.wait_class.square.length; i++) {
                let center_x = 0;
                let center_y = 0;
                let x;
                let y;
                let point_num;
                let count_tt = 0;
                let tt = [];
                if (this.wait_class.square[i][6].length > 0) {
                    for (let j = 0; j < this.wait_class.square[i][6].length; j++) {
                        point_num = this.wait_class.square[i][6][j];
                        point_num = this_tow_zui_arr[point_num];
                        if (point_num) {
                            tt.push(point_num);
                            color_duizhao_dict[point_num] = i;
                            // if (st.data[point_num] === 1) {
                            x = goodNew[point_num].x;
                            y = goodNew[point_num].y;
                            center_x += x;
                            center_y += y;
                            count_tt += 1;
                        }
                    }
                    center_x = center_x / count_tt;
                    center_y = center_y / count_tt;
                    //从这里一步一步来
                    this.wait_class.square[i][0] =
                        parseInt(center_x - this.wait_class.square[i][2] / 2) +
                        this.wait_class.square[i][7];
                    this.wait_class.square[i][1] =
                        parseInt(center_y - this.wait_class.square[i][3] / 2) +
                        this.wait_class.square[i][8];
                    this.wait_class.square[i][6] = tt;
                    this.wait_class.square[i][9].update(
                        this.wait_class.square[i][0],
                        this.wait_class.square[i][1],
                        newest_time
                    );
                }
            }
            // delete_arr(goodNew)
            newest_point = corners_model_temp;
            this.wait_class.point_first = corners_model_temp;
        }

        this.using_class.point_arr = this.wait_class.point_first;
        this.using_class.square = this.wait_class.square;

        if (this.using_class.img != null) {
            try {
                this.using_class.img.delete();
            } catch (e) { }
        }
        this.using_class.img =
            this.wait_class.imgs[this.wait_class.imgs.length - 1];
        err.delete();
    };
    //  用光流绘制不断插帧绘制
    async processVideo(show_square_id = "ai-recognition-canvas") {
        if (!this.openai) return false;
        try {
            clearTimeout(this.settime_Kal_process);
            clearTimeout(this.settime_processvideo);

            let newest_time = Date.now();
            let frameGray_temp = new cv.Mat();
            let err = new cv.Mat();
            let frame = new cv.Mat(this.video.height, this.video.width, cv.CV_8UC4);
            let frame_temp = new cv.Mat(this.guang_h, this.guang_w, cv.CV_8UC4);
            let p1 = new cv.Mat();
            let cap = new cv.VideoCapture(this.video);
            cap.read(frame);
            cv.resize(frame, frame_temp, frame_temp.size());
            frame.delete();
            await cv.cvtColor(frame_temp, frameGray_temp, cv.COLOR_RGBA2GRAY);
            await cv.calcOpticalFlowPyrLK(
                this.using_class.img,
                frameGray_temp,
                this.using_class.point_arr,
                p1,
                this.st,
                err,
                this.winSize,
                this.maxLevel,
                this.criteria
            );
            err.delete();
            let goodNew = [];
            let count = 0;
            let color_duizhao_dict = {};
            let this_tow_zui_arr = {};
            for (let i = 0; i < p1.rows; i++) {
                color_duizhao_dict[i] = 3;
                if (this.st.data[i] === 1) {
                    //这里应该是上一个接下一个
                    this_tow_zui_arr[i] = count;
                    goodNew.push(new cv.Point(p1.data32F[i * 2], p1.data32F[i * 2 + 1]));
                    count += 1;
                    continue;
                }
                this_tow_zui_arr[i] = 0;
            }
            p1.delete();
            let corners_model_temp = new cv.Mat(goodNew.length, 1, cv.CV_32FC2);
            for (let i = 0; i < goodNew.length; i++) {
                corners_model_temp.data32F[i * 2] = goodNew[i].x;
                corners_model_temp.data32F[i * 2 + 1] = goodNew[i].y;
            }
            try {
                this.using_class.point_arr.delete();
            } catch (e) {
                console.log(e);
            }
            if (this.using_class.img != null) {
                try {
                    this.using_class.img.delete();
                } catch (e) { }
            }
            this.using_class.point_arr = corners_model_temp;

            this.wait_class.imgs.push(frameGray_temp);
            this.wait_class.raw_imgs.push(frame_temp);
            let ttt = new cv.Mat();
            frameGray_temp.copyTo(ttt);
            this.using_class.img = ttt;

            for (let i = 0; i < this.using_class.square.length; i++) {
                let center_x = 0;
                let center_y = 0;
                let x;
                let y;
                let point_num;
                let count_tt = 0;
                let tt = [];
                if (this.using_class.square[i][6].length > 0) {
                    for (let j = 0; j < this.using_class.square[i][6].length; j++) {
                        point_num = this_tow_zui_arr[this.using_class.square[i][6][j]];
                        if (point_num) {
                            tt.push(point_num);
                            color_duizhao_dict[point_num] = i;
                            x =
                                (this.using_class.point_arr.data32F[point_num * 2] /
                                    this.guang_w) *
                                this.w;
                            y =
                                (this.using_class.point_arr.data32F[point_num * 2 + 1] /
                                    this.guang_h) *
                                this.h;
                            center_x += x;
                            center_y += y;
                            count_tt += 1;
                        }
                    }
                    center_x = center_x / count_tt;
                    center_y = center_y / count_tt;
                    this.using_class.square[i][0] =
                        parseInt(center_x - this.using_class.square[i][2] / 2) +
                        this.using_class.square[i][7];
                    this.using_class.square[i][1] =
                        parseInt(center_y - this.using_class.square[i][3] / 2) +
                        this.using_class.square[i][8];
                    this.using_class.square[i][6] = tt;
                    this.using_class.square[i][9].update(
                        this.using_class.square[i][0],
                        this.using_class.square[i][1],
                        newest_time
                    );
                }
            }

            //这里设置显示框的id

            this.Kal_process("ai-recognition-canvas");


            this.settime_processvideo = setTimeout(() => {
                this.processVideo();
            }, 0);

        } catch (err) {
            console.log(err);
        }
    };
    //  卡尔曼滤波绘制函数
    async Kal_process(show_square_id = "ai-recognition-canvas") {
        if (!this.openai) return;
        clearTimeout(this.settime_Kal_process);
        let begin = Date.now();
        let temp_square = this.using_class.square.slice();
        for (let i = 0; i < this.using_class.square.length; i++) {
            try {
                let temp_pre = this.using_class.square[i][9].predict(begin + 10);
                temp_square[i][0] = temp_pre[0];
                temp_square[i][1] = temp_pre[1];
            } catch (e) {
                console.log("错误");
            }
        }
        //这里设置显示框的id
        this.draw_square(temp_square, "#ffff00");
        this.settime_Kal_process = setTimeout(() => {
            this.Kal_process()
        }, 1000 / this.FPS - (Date.now() - begin))
    };
    // 
    draw_square(square_newest, strockcolor) {
        if (!this.openai) return false;
        this.ctx.clearRect(0, 0, this.w, this.h);
        this.ctx.strokeStyle = strockcolor;
        this.ctx.lineWidth = 2;
        this.ctx.setLineDash([6, 3]);
        for (let i = 0; i < square_newest.length; ++i) {
            let [x1, y1, width, height, klass, score] = square_newest[i];

            if (x1 + width / 2 > this.w || y1 + height / 2 > this.h) {
                continue;
            }
            //绘制框
            this.ctx.strokeRect(x1, y1, width, height);
            // Draw the label background.
            const textWidth = this.ctx.measureText(klass).width;
            const textHeight = 10; // base 10
            this.ctx.fillStyle = "#82a5a5";
            this.ctx.font = `12px 微软雅黑`;
            this.ctx.fillRect(x1, y1, textWidth, textHeight - 3);
            this.ctx.fillStyle = "#000000";
            this.ctx.fillText(klass + ":" + score, x1, y1 + 8);
            this.ctx.fillText(klass, x1, y1 + 8);
        }
    };
    // 停止识别，并且清空画布
    clearAI() {
        this.openai = false;
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
        if (this.input) {
            tf.dispose(this.input);
            this.input = null;
        }

        if (this.ctx) {
            this.ctx.clearRect(0, 0, this.w, this.h);
        }
    };

    // 初始化视频大小变更，需要重新计算画布的大小
    initVideoSizeChange(isFullScreen) {

        this.clearAI()
        const element = document.getElementById("largeWindow");
        // 输出元素的宽高

        // 判空
        if (!element) return;
        console.log('视频播放元素大小', element.clientWidth, element.clientHeight);

        const containerAspectRatio = element.clientWidth / element.clientHeight;
        if (!this.video) return;
        // 输出视频元素真实宽高
        console.log('视频元素真实大小', this.video.clientWidth, this.video.clientHeight);
        // 如果video.videoWidth不存在，说明视频还没有加载完成，不进行任何操作
        if (!this.video.videoWidth) return;

        const videoAspectRatio = this.video.videoWidth / this.video.videoHeight;

        console.log("videoAspectRatio", videoAspectRatio);
        console.log("containerAspectRatio", containerAspectRatio);
        if (videoAspectRatio == containerAspectRatio) return false;

        if (videoAspectRatio > containerAspectRatio) {

            const proportion = this.video.clientWidth / this.video.videoWidth;

            this.h = this.video.videoHeight * proportion;
            this.w = this.video.clientWidth;

        } else {

            const proportion = this.video.clientHeight / this.video.videoHeight;

            this.h = this.video.clientHeight;
            this.w = this.video.videoWidth * proportion;

        }

        this.video.width = this.video.clientWidth;
        this.video.height = this.video.clientHeight;
        if (!isFullScreen) {
            let large_window_preview = document.getElementsByClassName(
                "large-window_preview"
            )[0];
            if (large_window_preview) {
                if (large_window_preview.clientWidth - this.w - 8 < 5 && large_window_preview.clientHeight - this.h - 8 < 5) return;
                large_window_preview.style.width = this.w + 8 + "px";
                large_window_preview.style.height = this.h + 8 + "px";
            }
            // video-popup
            let video_popup = document.getElementsByClassName("video-popup")[0];
            if (video_popup) {
                if (video_popup.clientWidth - this.w - 8 < 5 && video_popup.clientHeight - this.h - 8 < 5) return;
                video_popup.style.width = this.w + + 8 + "px";
                video_popup.style.height = this.h + + 8 + "px";
            }
        }


        this.canvas.width = this.w;
        this.canvas.height = this.h;

        setTimeout(() => {
            this.selectModel(this.modelActive, this.loadingFinishedCallback);
        }, 800);

    };
    // 修改元素的大小
    initElementSizeChange() {

    }
    //修改ai识别画布的大小，匹配到视频的大小
    initCanvasSize(playerType, elementId) {
        const element = document.getElementById("largeWindow");
        const containerAspectRatio = element.clientWidth / element.clientHeight;

        if (playerType == "tcplayer") {
            this.video = element.children[0];
        } else if (playerType == "agora") {
            this.video = element.children[0].children[0].children[0];
        } else if (playerType == "webrtc") {
            this.video = element;
        }

        // if (this.video && !this.video.videoWidth) {
        //     this.video = element.children[0].children[0].children[1];
        // }



        this.video.setAttribute("crossOrigin", "anonymous");


        // document.getElementById("ai-recognition-canvas").children[0].children[0].children[0].videoWidth;


        this.canvas = document.getElementById(elementId); //canvas dom
        this.ctx = this.canvas.getContext("2d");

        if (!this.video) return;
        // 如果video.videoWidth不存在，说明视频还没有加载完成，不进行任何操作
        if (!this.video.videoWidth) return;


        const videoAspectRatio = this.video.videoWidth / this.video.videoHeight;

        if (videoAspectRatio > containerAspectRatio) {

            const proportion = this.video.clientWidth / this.video.videoWidth;

            this.h = this.video.videoHeight * proportion;
            this.w = this.video.clientWidth;

        } else {

            const proportion = this.video.clientHeight / this.video.videoHeight;

            this.h = this.video.clientHeight;
            this.w = this.video.videoWidth * proportion;

        }

        this.video.width = this.video.clientWidth;
        this.video.height = this.video.clientHeight;

        //通过class名称获取large-window_preview这个元素，然后给她更新宽高
        let large_window_preview = document.getElementsByClassName(
            "large-window_preview"
        )[0];
        if (large_window_preview) {
            if (large_window_preview.clientWidth - this.w - 8 < 5 && large_window_preview.clientHeight - this.h - 8 < 5) return;
            large_window_preview.style.width = this.w + 8 + "px";
            large_window_preview.style.height = this.h + 8 + "px";
        }
        // video-popup
        let video_popup = document.getElementsByClassName("video-popup")[0];
        if (video_popup) {

            if (video_popup.clientWidth - this.w - 8 < 5 && video_popup.clientHeight - this.h - 8 < 5) return;
            video_popup.style.width = this.w + + 8 + "px";
            video_popup.style.height = this.h + + 8 + "px";
        }

        this.canvas.width = this.w;
        this.canvas.height = this.h;
    };
    // 销毁
    destroy() {
        clearInterval(this.timer);
        this.clearAI(); // 清除AI相关操作（根据实际情况实现）
        // 将属性置为初始状态或null
        this.h = null;
        this.w = null;
        this.input = null;
        this.wait_class = null;
        this.using_class = null;
        this.settime_processvideo = null;
        this.settime_Kal_process = null;
        this.canvas = null;
        this.ctx = null;
        this.video = null;
        this.timer = null;
        this.color = [];
        this.st = null;
        this.modelConfig = [];
        this.modelActive = NaN;
        this.loading = false;
        this.loadingFinishedCallback = null;
    }
}

export default AiIdentification;
