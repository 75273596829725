import store from '@/store'
import request from "@/utils/request"

// 上传航线文件
export function uploadWebWaylineFile(data) {
    return request({
        url: '/dji/oss/files/uploadWebWaylineFile',
        method: 'post',
        headers: {
            "x-auth-token": store.getters.token,
        },
        data
    })
}

// 上传航线信息至大疆机库
export function getCredentials(params) {
    return request({
        url: '/dji/manage/v1/storage/sts',
        method: 'get',
        headers: {
            "x-auth-token": store.getters.token
        },
        params
    })
}

// 航线下发--新增任务浮窗通知
export function missionUpdate(data) {
    return request({
        url: '/dji/wayline/v1/floatWindow/missionUpdate',
        method: 'post',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}
// 航线下发--删除任务浮窗通知
export function missionDelUpdate(data) {
    return request({
        url: '/dji/wayline/v1/floatWindow/missionDelUpdate',
        method: 'post',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}
// 添加标注
export function addMark(data) {
    return request({
        url: '/dji/map/v1/element/add',
        method: 'post',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}
// 删除地图元素
export function delMark(data) {
    return request({
        url: '/dji/map/v1/element/delete',
        method: 'post',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

// 
export function getMark(params) {
    return request({
        url: '/dji/map/v1/element/get',
        method: 'get',
        headers: {
            "x-auth-token": store.getters.token
        },
        params
    })
}

// 提示
export function notify(data) {
    return request({
        url: '/dji/map/v1/element/notify',
        method: 'post',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}


// 示意性任务创建
export function newJobNotify(data) {
    return request({
        url: '/dji/map/v1/element/newJobNotify',
        method: 'post',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}


// 远程调试
export function debug(data) {
    return request({
        url: '/dji/control/v1/devices/debug',
        method: 'post',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

// 开启FPV直播
export function stateFpvLive(data) {
    return request({
        url: '/dji/manage/v1/live/startFpvLive',
        method: 'post',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

// 关闭FPV直播
export function stopFpvLive(data) {
    return request({
        url: '/dji/manage/v1/live/stopFpvLive',
        method: 'post',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

// 
export function liveLensChange(data) {
    return request({
        url: '/dji/manage/v1/live/liveLensChange',
        method: 'post',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

// 
export function playParams(params) {
    return request({
        url: '/dji/manage/v1/live/playParams',
        method: 'get',
        headers: {
            "x-auth-token": store.getters.token
        },
        params
    })
}
// 
export function addPersonalElement(data) {
    return request({
        url: '/dji/map/v1/element/addPersonalElement',
        method: 'post',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

// 小程序路径消息派发
export function postWebsocketNotify(data) {
    return request({
        url: '/dji/miniApp/websocketNotify',
        method: 'post',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}
// 一键起飞
export function takeoffToPoint(data) {
    return request({
        url: '/dji/control/v1/devices/takeoffToPoint',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

export function PostCameraFocalLengthSet(data) {
    return request({
        url: '/dji/control/v1/devices/payload/cameraFocalLengthSet',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

export function PostEnter(data) {
    return request({
        url: '/dji/control/v1/dcr/enter',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

// /dji/control/v1/dcr/exit
export function PostExit(data) {
    return request({
        url: '/dji/control/v1/dcr/exit',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}
// /dji/control/v1/dcr/droneEmergencyStop
export function PostDroneEmergencyStop(data) {
    return request({
        url: '/dji/control/v1/dcr/droneEmergencyStop',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

// 自研机库云台俯仰角度控制
export function PostGimbalPitch(data) {
    return request({
        url: '/dji/control/v1/devices/gimbalPitch',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

// 自研机库云台回中 垂直向下
export function PostGimbalPitchDown(data) {
    return request({
        url: '/dji/control/v1/devices/gimbalReset',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

// /dji/control/v1/devices/payload/gimbalReset
export function PostGimbalReset(data) {
    return request({
        url: '/dji/control/v1/devices/payload/gimbalReset',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

// /dji/control/v1/devices/payload/cameraAim
export function PostCameraAim(data) {
    return request({
        url: '/dji/control/v1/devices/payload/cameraAim',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

// 开启直播
export function PostStartLive(data) {
    return request({
        url: '/dji/manage/v1/live/startLive',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}
// /dji/manage/v1/live/stopLive
export function PostStopLive(data) {
    return request({
        url: '/dji/manage/v1/live/stopLive',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

export const PostCameraRecordingStop = (data) => {
    return request({
        url: '/dji/control/v1/devices/payload/cameraRecordingStop',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

export const PostCameraRecordingStart = (data) => {
    return request({
        url: '/dji/control/v1/devices/payload/cameraRecordingStart',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

export const PostCameraPhotoTake = (data) => {
    return request({
        url: '/dji/control/v1/devices/payload/cameraPhotoTake',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

export const PostCameraModeSwitch = (data) => {
    return request({
        url: '/dji/control/v1/devices/payload/cameraModeSwitch',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

// /dji/control/v1/devices/flyToPoint
export const PostFlyToPoint = (data) => {
    return request({
        url: '/dji/control/v1/devices/flyToPoint',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

/**
 * 航线暂停或者恢复
 * @param {*} deviceSn  机场sn码
 * @param {*} status  航线状态，0：航线暂停；1：航线恢复
 * @returns 
 */
export const airLinePauseOrResume = (data) => {
    return request({
        url: '/dji/wayline/status/updateWaylineStatus',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

// 2024/02/27 增加MSDK控制云台 
/**
 * 明海对接云台控制通道
 * @param {*} data 
 * @returns 
 */
export function postChannel(data) {
    return request({
        url: '/dji/channel/send',
        method: 'POST',
        headers: {
            "x-auth-token": store.getters.token
        },
        data
    })
}

/**
 * web端上传图片文件 （私有云）
 * 
 */
export function uploadWebImageFile(data) {
    return request({
        url: '/dji/oss/files/uploadWebImageFile',
        method: 'post',
        headers: {
            "x-auth-token": store.getters.token,
        },
        data
    })
}