<template>
  <section class="webRTC-player_container">
    <video
      v-if="showVideo"
      class="jswebrtc"
      :id="liveOptions.playerId"
      :controls="playerOptions.controls"
    ></video>
    <!-- <div class="sharing" v-if="channelInfo">
      <QrCodeLive :liveType="0" :livedata="channelInfo" />
    </div> -->
    <div class="mask_layer" v-if="show">
      <i
        v-if="state !== '加载中...'"
        class="el-icon-video-play pointer"
        @click="startPlay"
      >
      </i>
      <p>{{ state }}</p>
    </div>
  </section>
</template>

<script>
import { playParams, PostStartLive } from "@/api/dji";
import { debounce } from "@/utils/index";
export default {
  props: {
    liveOptions: {
      type: Object,
      require: true,
    },
    playerOptions: {
      type: Object,
      require: true,
      default: () => {
        return {
          controls: false, // 隐藏菜单栏
          autoplay: true, // 自动播放
          muted: true,
          timer: null,
        };
      },
    },
  },

  data() {
    return {
      channelInfo: null, // 频道信息
      player: null,
      firstDisabled: true,
      show: true,
      state: "加载中...",
      showVideo: true,
    };
  },
  created() {
    if (this.liveOptions.deviceSn) {
      this.getToken();
    } else {
      this.$nextTick(() => {
        this.initClientNoToken();
      });
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }
  },
  methods: {
    // 开启播放
    startPlay() {
      this.showVideo = true;
      const { deviceSn } = this.liveOptions;
      PostStartLive({ deviceSn });
    },
    // 请求playParams
    async getToken() {
      const { deviceSn, cameraType } = this.liveOptions;
      const res = await playParams({
        deviceSn,
        cameraType,
      });
      if (res) {
        const { data } = res;
        this.channelInfo = data;
        this.initClient();
      }
    },
    // 初始化客户端
    initClient() {
      const { live_play_url } = this.channelInfo;
      const { playerId } = this.liveOptions;

      const video = document.getElementById(playerId);
      this.player = new JSWebrtc.Player(live_play_url, {
        video: video,
        autoplay: true,
        onPlay: (obj) => {
          clearTimeout(this.timer);
          this.show = false;
          this.state = "加载中...";
          this.playerState();
        },
        onPause: (obj) => {
          console.log("webRTC播放器暂停");
        },
      });

      if (this.showPlayBtn) this.showPlayBtn();
    },
    // 显示播放按钮
    showPlayBtn() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.show = true;
        this.state = "视频加载失败，请检查设备网络情况，或点击重播按钮";
      }, 8000);
    },

    // 初始化客户端：不需要token
    initClientNoToken() {
      const { playerId, liveUrl } = this.liveOptions;

      const video = document.getElementById(playerId);
      this.player = new JSWebrtc.Player(liveUrl, {
        video: video,
        autoplay: true,
        onPlay: (obj) => {
          this.show = false;
          clearTimeout(this.timer);
          this.state = "加载中...";
          this.playerState();
        },
        onPause: (obj) => {
          console.log("webRTC播放器暂停");
        },
      });

      if (this.showPlayBtn) this.showPlayBtn();
    },
    //监听播放器的状态
    playerState() {
      const { playerId } = this.liveOptions;
      const player = document.getElementById(playerId);
      player.addEventListener("playing", () => {
        console.log("webRTC播放器已播放");
        this.$emit("canplay");
      });

      player.addEventListener(
        "resize",
        debounce(() => {
          console.log("webRTC播放器已resize");

          if (this.firstDisabled) {
            this.firstDisabled = false;
            return false;
          }

          this.$emit("windowResize");
        }, 300)
      );

      player.addEventListener("pause", () => {
        console.log("webRTC播放器暂停");

        this.show = true;
        this.state = "视频中断，请检查设备网络情况，或点击重播按钮";
      });

      player.addEventListener("ended", () => {
        console.log("webRTC播放器播放结束");

        this.show = true;
        this.state = "视频中断，请检查设备网络情况，或点击重播按钮";

        this.$emit("removePlay");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.webRTC-player_container {
  flex: 1;
  display: flex;
  position: relative;
  .jswebrtc {
    flex: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .mask_layer {
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 14px;
    z-index: 1;
    .el-icon-video-play {
      font-size: 42px;
      &:hover {
        color: #4475ff;
      }
      &:active {
        color: #204fd6;
      }
    }
  }
}
</style>
