<template>
  <div>
    <section class="analysis-tool_container">
      <!--  -->
      <el-tooltip effect="dark" placement="left">
        <div
          class="analysis-tool__item"
          :class="{ 'line-item_active': isBuffer }"
        >
          <i class="iconfont icon-buffer pointer" @click="startBuffer"></i>
        </div>
        <div class="tip-text" slot="content">
          <p>缓冲区分析</p>
        </div>
      </el-tooltip>
      <!-- 最优路径 -->
      <el-tooltip effect="dark" placement="left">
        <div
          class="analysis-tool__item"
          :class="{ 'line-item_active': isPath }"
        >
          <i class="iconfont icon-route pointer" @click="startRoute"></i>
        </div>
        <div class="tip-text" slot="content">
          <p>最优路径</p>
        </div>
      </el-tooltip>

      <!--  -->
      <el-tooltip effect="dark" placement="left">
        <div @click="toggleNotes" class="analysis-tool__item">
          <i class="el-icon-open pointer" v-if="isOpen"></i>
          <i class="el-icon-turn-off pointer" v-else></i>
        </div>
        <div class="tip-text" slot="content">
          <p>{{ isOpen ? "隐藏标注" : "显示标注" }}</p>
        </div>
      </el-tooltip>
    </section>

    <!-- 路径弹窗 -->
    <transition name="fade_right">
      <div class="path_dialog" v-if="isPath">
        <div class="title">
          <p>最优路径</p>
          <i
            class="el-icon-close pointer title_icon"
            @click="isPath = false"
          ></i>
        </div>
        <!-- 底部 -->
        <div class="boby_box">
          <!-- tabs -->
          <div class="tabbar">
            <div class="cart pointer" @click="modeSwitch('car')">驾车</div>
            <div class="pedestrian pointer" @click="modeSwitch('foot')">
              步行
            </div>
            <!-- 标签条 -->
            <div class="tabs" :class="{ active: isRun }"></div>
          </div>
          <!-- 人员 -->
          <div class="item-content">
            <div class="item-left">
              <label for="">人员</label>
            </div>
            <ul class="personnel-list">
              <li
                class="personnel-item"
                v-for="(item, index) in memberList"
                :key="index"
              >
                <i
                  class="el-icon-remove-outline pointer"
                  @click="delMember(index)"
                ></i>
                <span>{{ item.memberName }}</span>
                <span></span>
              </li>
            </ul>
          </div>
          <!-- 路线规划 -->
          <!-- <div class="project_btn pointer" @click="routePlanning">路径规划</div> -->
          <div class="project_btn pointer slicing" @click="distribute">
            派发
          </div>
        </div>
      </div>
    </transition>
    <!-- 缓冲区弹窗 -->
    <transition name="fade_right">
      <div class="buffer_dialog" v-if="isBuffer">
        <div class="title">
          <p>缓冲区</p>
          <i
            class="el-icon-close pointer title_icon"
            @click="isBuffer = false"
          ></i>
        </div>
        <div class="boby_box">
          <div class="radius">
            <p>缓冲半径 :</p>
            <div class="input">
              <input type="text" v-model.number="bufferDistance" />
              <span></span>
            </div>
            <p>米</p>
          </div>
          <div class="draw">
            <p>绘制方式 :</p>
            <div
              class="btn pointer slicing"
              :class="{ active: type == 0 }"
              @click="changType(0)"
            >
              点
            </div>
            <div
              class="btn pointer slicing"
              :class="{ active: type == 1 }"
              @click="changType(1)"
            >
              线
            </div>
            <div
              class="btn pointer slicing"
              :class="{ active: type == 2 }"
              @click="changType(2)"
            >
              面
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { list } from "@/api/project";
import { postWebsocketNotify } from "@/api/dji";
export default {
  computed: {},
  data() {
    return {
      isPath: false, //路径
      isBuffer: false, //缓冲区
      isRun: false,
      startPoint: null, //最优-起点
      startData: null,
      endPoint: null, //最优-终点
      endData: null,
      bufferDistance: 100, //缓冲区半径
      type: null, //缓冲区类型
      pathData: null, //最优路径数据
      projectOptions: [], //项目列表
      memberList: [], //人员列表
      isOpen: true,
    };
  },
  created() {
    window.bufferTool = null;
    // 资源列表筛选
    this.getProjectList();
    this.$EventBus.$on("pathPlanning", (newMember) => {
      const isAlreadyExist = this.memberList.some((member) => {
        return member.memberId === newMember.memberId;
      });

      if (isAlreadyExist) {
        // 如果新数据已经存在于 memberList 数组中，则不进行插入操作
      } else {
        // 如果新数据不存在于 memberList 数组中，则进行插入操作
        this.memberList.push(newMember);
      }
    });
    // resetOpen
    this.$EventBus.$on("resetOpen", (isOpen) => {
      this.isOpen = true;
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("resetOpen");
    this.$EventBus.$off("pathPlanning");

    zr3d.routePlannerClear(); //清除航线规划

    this.$store.dispatch("map/setIsActionStarted", 0);

    this.reset();
  },
  watch: {
    // 侦听清除路径
    isPath: {
      handler(val) {
        if (!val) {
          zr3d.routePlannerClear(); //清除航线规划
          this.startPoint = null;
          this.startData = null;
          this.endPoint = null;
          this.endData = null;
          this.projectOptions = [];
          this.memberList = [];
          this.$store.dispatch("map/setIsActionStarted", 0);
          return;
        }
        this.$store.dispatch("map/setIsActionStarted", 4);
        zr3d.routePlannerDraw({
          type: this.isRun ? "foot" : "car",
          success: (data) => {
            this.pathData = data;
          },
        });
      },
    },
    isBuffer: {
      handler(val) {
        if (!val) {
          if (!bufferTool) return;
          bufferTool.clear();
          bufferTool = null;
          this.bufferDistance = null;
          this.type = null;
        }
      },
    },
    bufferDistance: {
      handler(val) {
        if (window.bufferTool) {
          // 修改缓冲区范围
          bufferTool.setBufferDistance(val);
        }
      },
    },
  },
  methods: {
    // 重置
    reset() {
      if (!bufferTool) return;
      bufferTool.clear();
      window.bufferTool = null;
    },
    toggleNotes() {
      if (this.isOpen) {
        this.$EventBus.$emit("clearResDetMark");
      } else {
        this.$EventBus.$emit("getAllMark");
      }
      this.isOpen = !this.isOpen;
    },
    /**
     * 开启或关闭最优路径分析
     */
    startRoute() {
      if (this.isBuffer) this.isBuffer = false;
      if (this.isPath) {
        this.isPath = false;
        return;
      }
      this.isPath = true;
    },
    /**
     * 开启或关闭缓冲区分析
     */
    startBuffer() {
      if (this.isPath) this.isPath = false;
      if (this.isBuffer) {
        this.isBuffer = false;
        return;
      }
      this.isBuffer = true;
    },
    /**
     * 过滤函数--经纬度
     */
    filterLatOrLng(val) {
      return `${val.lng.toFixed(6)}°, ${val.lat.toFixed(6)}°`;
    },
    //#region 最优路径
    //获取起点
    getStartPoint() {
      zr3d.routePlannerPick({
        isStart: true, //获取起点
        success: (startPoint) => {
          //得到起点坐标

          this.startPoint = startPoint;
          this.startData = this.filterLatOrLng(startPoint); //展示信息
        },
      });
    },
    //获取终点
    getEndPoint() {
      zr3d.routePlannerPick({
        isStart: false, //获取终点
        success: (endPoint) => {
          //得到终点坐标

          this.endPoint = endPoint;
          this.endData = this.filterLatOrLng(endPoint); //展示信息
        },
      });
    },
    // 路线开始规划
    routePlanning() {
      if (!this.startPoint) {
        // 请选择起点
        this.$message({
          message: "请选择起点",
          type: "warning",
          duration: 1500,
        });
        return;
      }
      if (!this.endPoint) {
        this.$message({
          message: "请选择终点",
          type: "warning",
          duration: 1500,
        });
        return;
      }
      // 开始规划航线
      zr3d.routePlannerExecute({
        type: this.isRun ? "foot" : "car", //car/foot
        start: this.startPoint,
        stop: this.endPoint,
        style: {
          color: "#00ffff", //路线颜色
          fontColor: "#ff0000", //字体颜色
        },
      });
    },
    modeSwitch(type) {
      if (type === "car") this.isRun = false;
      if (type === "foot") this.isRun = true;
      zr3d.routePlannerClear(); //清除航线规划
      zr3d.routePlannerDraw({
        type: this.isRun ? "foot" : "car",
        success: (data) => {
          this.pathData = data;
        },
      });
    },
    //#endregion

    //#region 缓冲区
    changType(num) {
      if (!this.bufferDistance) {
        this.$message({
          message: "请输入缓冲区半径",
          type: "warning",
          duration: 1500,
        });
        return;
      }
      if (this.bufferDistance <= 0) {
        this.$message({
          message: "半径不能是负值",
          type: "warning",
          duration: 1500,
        });
        return;
      }
      this.type = num;
      if (!bufferTool) {
        bufferTool = zr3d.bufferToolCreate();
      }
      // 开始绘制
      // bufferTool.setBufferDistance(this.bufferDistance);
      bufferTool.startDraw({
        type: this.type, //缓冲区类型（0-点，1-线，2-面）
        bufferDistance: this.bufferDistance, //缓冲区距离
      });
    },
    //#endregion

    //#region 资源列表获取
    // 获取项目列表
    async getProjectList() {
      const res = await list({ pageNum: 1, pageSize: 100, projectStatus: 2 });
      if (res) {
        this.projectOptions = []; //项目列表
        res.data.forEach((element) => {
          this.projectOptions.push({
            value: element.id,
            label: element.projectName,
          });
        });
      }
    },
    // 删除人员
    delMember(index) {
      this.memberList.splice(index, 1);
    },
    // 派发标注
    async distribute() {
      if (this.memberList.length <= 0 || !this.pathData) {
        this.$message({
          message: "路线未规划完成",
          type: "error",
        });
        return;
      }

      for (const key of this.memberList) {
        const res = await postWebsocketNotify({
          to: key.memberId,
          method: "path_planning",
          data: {
            start: this.pathData.start,
            stop: this.pathData.stop,
          },
        });
        if (res) {
          this.isPath = false;
          this.$message({
            message: "最优路径下发成功",
            type: "success",
          });
        }
      }
    },
    //#endregion
  },
};
</script>

<style lang="less" scoped>
.analysis-tool_container {
  text-align: center;
  position: absolute;
  // top: 220px;
  top:284px;
  right: 30px;
  background: var(--page-background-color);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  z-index: 2;
  width: 54px;
  height: 180px;
  color: #ffffff;

  .analysis-tool__item {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: var(--btn-hover-color);
    }
  }

  .iconfont,
  .el-icon-open,
  .el-icon-turn-off {
    font-size: 26px;
  }

  .line-item_active {
    background-color: var(--btn-active-color) !important;
  }
}
.path_dialog {
  width: 288px;
  position: absolute;
  top: 71px;
  right: 130px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  background-color: #1a1c20;
  padding: 15px 18px 0;
  border-radius: 6px 6px 6px 6px;
  .title {
    height: 32px;
    display: flex;
    align-items: center;
    position: relative;
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
    .title_icon {
      position: absolute;
      color: #ffffff;
      right: 0;
    }
  }
  .boby_box {
    flex: 1;
    .tabbar {
      height: 33px;
      display: flex;
      align-items: center;
      margin: 0 19px;
      color: #ffffff;
      position: relative;
      .cart {
        flex: 1;
        text-align: center;
      }
      .pedestrian {
        flex: 1;
        text-align: center;
      }
      .tabs {
        position: absolute;
        width: 93px;
        height: 2px;
        background: #40e9f2;
        bottom: 0;
        transition: all 0.3s;
        &.active {
          transform: translateX(110px);
        }
      }
    }
    .item-content {
      display: flex;
      margin: 20px 0 0 0;
    }
    .item-left {
      width: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #ffffff;
      height: 100%;
    }
    .personnel-list {
      margin-right: 20px;
      overflow-y: auto;
      max-height: 200px;
      flex: 1;

      .personnel-item {
        border-bottom: 1px solid white;
        padding-bottom: 10px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        box-sizing: content-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        .el-icon-remove-outline {
          font-size: 20px;
          color: #ef6767;
        }

        .icon-selected {
          color: #165dff;
          background: white;
          border-radius: 50%;
        }
      }
    }

    .project_btn {
      width: 168px;
      height: 32px;
      background: #215bff;
      border-radius: 2px 2px 2px 2px;
      color: #ffffff;
      text-align: center;
      line-height: 32px;
      margin: 20px auto;
      margin-top: 15px;
    }
  }
}
.buffer_dialog {
  position: absolute;
  top: 71px;
  right: 130px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 288px;
  background-color: #1a1c20;
  padding: 15px 18px 20px 18px;
  border-radius: 6px 6px 6px 6px;
  .title {
    height: 32px;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 18px;
    p {
      color: #ffffff;
    }
    .title_icon {
      position: absolute;
      color: #ffffff;
      right: 0;
    }
  }
  .boby_box {
    flex: 1;
    color: #9e9ea3;
    display: flex;
    flex-direction: column;
    .radius {
      display: flex;
      margin-top: 20px;
      p {
        margin-right: 5px;
      }
      .input {
        height: 22px;
        width: 129px;
        position: relative;
        margin-right: 15px;
        input {
          width: 130px;
          height: 26px;
          color: white;
          background-color: #111212;
          border: 0;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .draw {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .btn {
        margin: 0 4px;
        width: 50px;
        height: 32px;
        background: #35373e;
        border-radius: 2px 2px 2px 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        &.active {
          background: #215bff !important;
        }
      }
    }
  }
}
</style>