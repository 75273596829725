const state = {
    // 无人机状态
    droneStatus: {
        // 在线
        online: 0,
        // 空闲
        idle: 0,
        // 任务中
        tasking: 0,
        // 故障
        fault: 0,
    },
    // 飞手状态
    pilotStatus: {
        // 在线
        online: 0,
        // 空闲
        idle: 0,
        // 任务中
        tasking: 0,
        // 离线
        offline: 0,
    },
    // 警察状态
    policeStatus: {
        // 在线
        online: 0,
        // 空闲
        idle: 0,
        // 任务中
        tasking: 0,
        // 离线
        offline: 0,
    },
    dateType: '',

}

const mutations = {
    // 无人机状态
    SET_DRONE_STATUS: (state, droneStatus) => {
        state.droneStatus = droneStatus
    },
    // 飞手状态
    SET_PILOT_STATUS: (state, pilotStatus) => {
        state.pilotStatus = pilotStatus
    },
    // 警察状态
    SET_POLICE_STATUS: (state, policeStatus) => {
        state.policeStatus = policeStatus
    },
    //设置日期类型
    SET_DATE_TYPE: (state, dateType) => {
        state.dateType = dateType
    }


}

const actions = {
    // 无人机状态
    setDroneStatus({ commit }, droneStatus) {
        commit('SET_DRONE_STATUS', droneStatus)
    },
    // 飞手状态
    setPilotStatus({ commit }, pilotStatus) {
        commit('SET_PILOT_STATUS', pilotStatus)
    },
    // 警察状态
    setPoliceStatus({ commit }, policeStatus) {
        commit('SET_POLICE_STATUS', policeStatus)
    },
    //设置日期类型
    setDateType({ commit }, dateType) {
        commit('SET_DATE_TYPE', dateType)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}