import request from '@/utils/request.js'

// 
export function getMyModelList(data) {
    return request({
        url: '/api/manage/v1/model/getMyModelList',
        method: 'post',
        data
    })
}

export function getModelList(data) {
    return request({
        url: "/api/manage/v1/model/getModelList",
        method: 'post',
        data
    })
}


export function getModelCategoryList(params) {
    return request({
        url: "/api/manage/v1/model/getModelCategoryList",
        method: 'get',
        params
    })
}