var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"annotation-window_container"},[_c('el-tooltip',{attrs:{"effect":"dark","placement":"left"}},[_c('li',{staticClass:"draw-tool_item",class:{
        'is-active': _vm.projectMarkInfo.markType == 0,
      }},[_c('i',{staticClass:"iconfont pointer icon-point",on:{"click":function($event){return _vm.standard(0)}}})]),_c('div',{staticClass:"tip-text",attrs:{"slot":"content"},slot:"content"},[_c('p',[_vm._v("点标记")])])]),_c('el-tooltip',{attrs:{"effect":"dark","placement":"left"}},[_c('li',{staticClass:"draw-tool_item",class:{
        'is-active': _vm.projectMarkInfo.markType == 1,
      }},[_c('i',{staticClass:"iconfont pointer icon-line",on:{"click":function($event){return _vm.standard(1)}}})]),_c('div',{staticClass:"tip-text",attrs:{"slot":"content"},slot:"content"},[_c('p',[_vm._v("线标记")])])]),_c('el-tooltip',{attrs:{"effect":"dark","placement":"left"}},[_c('li',{staticClass:"draw-tool_item",class:{
        'is-active': _vm.projectMarkInfo.markType == 2,
      }},[_c('i',{staticClass:"iconfont pointer icon-surface",on:{"click":function($event){return _vm.standard(2)}}})]),_c('div',{staticClass:"tip-text",attrs:{"slot":"content"},slot:"content"},[_c('p',[_vm._v("面标记")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }