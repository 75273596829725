const state = {
    droneInfo: {}, //无人机数据集
    droneName: '' //无人机名称
}

const mutations = {
    // 无人机信息写入
    SET_DRONE_INFO: (state, droneInfo) => {
        state.droneInfo = droneInfo
    },
    // 资源名称写入
    SET_DRONE_NAME: (state, droneName) => {
        state.droneName = droneName
    },
}

const actions = {
    writeDroneInfo({ commit }, info) {

        const { data, resource_name } = info;
        data.resource_status = info.resource_status
        commit('SET_DRONE_INFO', data)
        commit('SET_DRONE_NAME', resource_name)
    },
    // 信息初始化
    clearAllInfo({ commit }) {
        commit('SET_DRONE_INFO', {})
        commit('SET_DRONE_NAME', '')
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
