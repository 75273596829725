import Vue from 'vue'
import VueRouter from 'vue-router'
// staticRouter
// import { staticRouter } from "@/router/modules/staticRouter";
// initDynamicRouter
// import { initDynamicRouter } from "@/router/modules/dynamicRouter";
import { getToken } from '@/utils/auth'
import store from '@/store'

Vue.use(VueRouter)

import Layout from '@/layout'


// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


// 路由配置
const routes = [
    {
        path: '/login',
        component: () => import('@/views/login/index'),
    },
    {
        path:'/',
        redirect:'/statistics'
    },
    {
        path: '/command',
        component: Layout,
        redirect: '/command/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/command/index.vue'),
                name: 'Command',

            }
        ],

    },

    {
        path: '/task',
        component: Layout,
        redirect: '/task/index',

        children: [
            {
                path: 'index',
                component: () => import('@/views/task/index'),
                name: 'Task',
            }
        ]
    },
    // 
    {
        path: '/equipment',
        component: Layout,
        redirect: '/equipment/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/equipment/index'),
                name: 'Equipment',
            }
        ]
    },
    // results
    {
        path: '/results',
        component: Layout,
        redirect: '/results/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/results/index'),
                name: 'Results',
            }
        ]
    },
    // algorithm
    {
        path: '/algorithm',
        component: Layout,
        redirect: '/algorithm/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/algorithm/index'),
                name: 'Algorithm',
            }
        ]
    },
    {
        path: '/personnel',
        component: Layout,
        redirect: '/personnel/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/personnel/index'),
                name: 'Personnel',
            }
        ]
    },
    // 资源详情
    {
        path: '/resourcesDetails',
        component: Layout,
        redirect: '/resourcesDetails/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/resourcesDetails/index'),
                name: 'ResourcesDetails',
            }
        ]
    },
    // 航线任务详情
    {
        path: '/courseTasks',
        component: Layout,
        redirect: to => {
            return { path: '/courseTasks/index', query: { taskId: to.query.id } }
        },

        children: [
            {
                path: 'index',
                component: () => import('@/views/courseTasks/index.vue'),
                name: 'CourseTasks',
            }
        ]
    },
    // 标注任务详情
    {
        path: '/markTask',
        component: Layout,
        redirect: '/markTask/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/markTask/index.vue'),
                name: 'MarkTask',
            }
        ]
    },
    // 成果详情
    {
        path: '/resultsInfo',
        component: Layout,
        redirect: '/resultsInfo/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/resultsInfo/index.vue'),
                name: 'ResultsInfo',
            }
        ]
    },
    // 统计页面
    {
        path: '/statistics',
        component: Layout,
        redirect: '/statistics/index',
        children: [
            {
                path: 'index',
                component: () => {

                    const env = process.env.VUE_APP_CURRENTMODE
                    if(env == 'xiwan'){
                        return import('@/views/statistics_XiWan/index.vue')
                    }

                    


                    return import('@/views/statistics/index.vue')
                },
                name: 'Statistics',
            }
        ]
    },
    // command
    {
        path: '/command',
        component: Layout,
        redirect: '/command/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/command/index.vue'),
                name: 'Command',
            }
        ]
    },
    // 主页
    {
        path: '/home',
        component: Layout,
        redirect: '/home/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/home/index.vue'),
                name: 'Home',
            }
        ]
    },
    // iframe
    {
        path: '/dataShow',
        component: Layout,
        redirect: '/dataShow/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/dataShow/index.vue'),
                name: 'DataShow',
            }
        ]
    },
    // twinNumbers
    {
        path: '/twinNumbers',
        component: Layout,
        redirect: '/twinNumbers/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/twinNumbers/index.vue'),
                name: 'TwinNumbers',
            }]
    },
    //   dataScreen
    {
        path: '/dataScreen',
        component: () => import('@/views/dataScreen/index.vue'),
        name: "dataScreen",
    },
    // 系统融合
    // 概览
    {
        path: '/overview',
        component: Layout,
        redirect: '/overview/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/minesView/overview/index.vue'),
                name: 'Overview',
            }]
    },
    // 雷达
    {
        path: '/radar',
        component: Layout,
        redirect: '/radar/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/minesView/radar/index.vue'),
                name: 'Radar',
            }]
    },
    // 安环监测
    {
        path: '/monitor',
        component: Layout,
        redirect: '/monitor/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/minesView/monitor/index.vue'),
                name: 'Monitor',
            }]
    },
    // 安全生产
    {
        path: '/safety',
        component: Layout,
        redirect: '/safety/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/minesView/safety/index.vue'),
                name: 'Safety',
            }]
    },
    // 灾险评估治理
    {
        path: '/riskAssessment',
        component: Layout,
        redirect: '/riskAssessment/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/minesView/riskAssessment/index.vue'),
                name: 'RiskAssessment',
            }]
    },
    // 图片识别
    {
        path: '/imageRecognition',
        component: Layout,
        redirect: '/imageRecognition/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/imageRecognition/index.vue'),
                name: 'ImageRecognition',
            }]
    },
]


const router = new VueRouter({
    // routes: [...staticRouter]
    routes: [...routes]
})

router.beforeEach(async (to, from, next) => {
    Vue.prototype.$removeMediaModal() // 关闭全局弹窗
    // initDynamicRouter()

    const hasToken = store.getters.token || getToken()
    // const userStore = useUserStore()
    // const authStore = useAuthStore()
    // 1.NProgress 开始
    // NProgress.start()

    // 2.动态设置标题
    // const title = import.meta.env.VITE_GLOB_APP_TITLE
    // document.title = to.meta.title ? `${to.meta.title} - ${title}` : title

    // 3.判断是访问登陆页，有 Token 就在当前页面，没有 Token 重置路由到登陆页
    if (to.path.toLocaleLowerCase() === '/login') {
        if (hasToken) return next(from.fullPath)
        // resetRouter()
        return next()
    }

    // 5.判断是否有 Token，没有重定向到 login 页面
    if (!hasToken) {
        const oldPath = to.fullPath
        return next({ path: `/login?&rollback=${oldPath}`, replace: true })
    }

    // 6.如果没有菜单列表，就重新请求菜单列表并添加动态路由
    // if (!authStore.authMenuListGet.length) {
    // await initDynamicRouter()
    // return next({ ...to, replace: true })
    // }

    // 7.存储 routerName 做按钮权限筛选
    // authStore.setRouteName(to.name);
    // 8.正常访问页面
    next()
})
// export const resetRouter = () => {
//     const authStore = useAuthStore()
//     authStore.flatMenuListGet.forEach((route) => {
//         const { name } = route
//         if (name && router.hasRoute(name)) router.removeRoute(name)
//     })
// }

export default router
