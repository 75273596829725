import request from '@/utils/request.js'
// 获取一个随机数id
export function getTaskId(params) {
    return request({
        url: '/api/manage/v1/job/id',
        method: 'get',
        params
    })
}
// 添加任务
export function addTask(data) {
    return request({
        url: '/api/manage/v1/job/add',
        method: 'post',
        data
    })
}
// 机型负载查询
export function loadList(data) {
    return request({
        url: '/api/wayline/v1/droneParams/list',
        method: 'post',
        data
    })
}
// 获取任务列表
export function taskList(data) {
    return request({
        url: '/api/manage/v1/job/list',
        method: 'post',
        data
    })
}
// 删除任务
export function deleteList(data) {
    return request({
        url: '/api/manage/v1/job/delete',
        method: 'post',
        data
    })
}
// 更新任务
export function updateTask(data) {
    return request({
        url: '/api/manage/v1/job/update',
        method: 'post',
        data
    })
}
// 获取子任务
export function getSubJobs(data) {
    return request({
        url: '/api/manage/v1/job/getSubJobs',
        method: 'post',
        data
    })
}


// 复制任务
export function copyTaskInfo(data) {
    return request({
        url: '/api/manage/v1/job/copy',
        method: 'post',
        data
    })
}

// 查询指定任务详情
export function getJob2Id(params) {
    return request({
        url: '/api/manage/v1/job/get',
        method: 'get',
        params
    })
}

// 
export function downloadTask(params) {
    return request({
        url: '/api/manage/v1/job/download',
        method: 'get',
        params
    })
}

// 拼图任务列表
export function getPuzzleJobs(params) {
    return request({
        url: '/api/manage/v1/job/getPuzzleJobs',
        method: 'get',
        params
    })
}