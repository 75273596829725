<template>
  <section class="video-surveillance_container">
    <ul class="video-surveillance_list" v-if="currentComponent == 1">
      <li
        class="video-surveillance_item"
        v-for="(item, index) in playerList"
        v-resize="($event) => resize($event, item)"
        :key="index"
        :class="{
          'video-surveillance_item--disabled':
            item.id !== selectId && isDragging,
        }"
        :id="'video-player' + item.id"
        :style="{
          left: `${item.left}px`,
          top: `${item.top}px`,
        }"
        @mousedown="startDrag(item, $event)"
        @mousemove="drag(item, $event)"
        @mouseup="stopDrag()"
      >
        <liveStatus
          :id="item.id"
          v-if="
            item.playerType === 'agora' &&
            item.showStatus &&
            item.resourceType != 1
          "
        />
        <livePlayer
          :liveOptions="{
            playerType: item.playerType,
            liveUrl: item.livePlayUrl || '',
            playerId: `tcPlayer${item.id}`,
            deviceSn: item.subDeviceSn || '',
            cameraType: 1,
          }"
          :name="item.name"
        />
        <el-tooltip effect="dark" placement="bottom">
          <i
            class="iconfont icon-delete pointer"
            @click.stop="closeVideoSurveillance(item, index)"
          ></i>
          <div class="tip-text" slot="content">
            <p>关闭直播</p>
          </div>
        </el-tooltip>

        <!-- <el-tooltip
          effect="dark"
          placement="bottom"
          v-if="item.resourceType != 3"
        >
          <i
            class="iconfont pointer icon-orthographic"
            @click="openOrthographic(item)"
          ></i>
          <div class="tip-text" slot="content">
            <p>正射影像图</p>
          </div>
        </el-tooltip> -->

        <p class="video-surveillance_name">{{ item.name }}</p>
      </li>
    </ul>
  </section>
</template>

<script>
import livePlayer from "@/components/CommonLive/index.vue";
import liveStatus from "@/components/CommonLive/liveStatus.vue";

export default {
  components: {
    livePlayer,
    liveStatus,
  },
  data() {
    return {
      playerList: [], // 播放器列表
      isDragging: false, // 是否正在拖拽
      mouseOffset: { left: 0, bottom: 0 },
      selectId: NaN, // 当前选中的播放器id
    };
  },
  created() {
    this.$EventBus.$on("typeLiveShow", (data) => {
      this.addPlayer(data);
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("typeLiveShow");
  },
  computed: {
    currentComponent() {
      return this.$store.getters.currentComponent;
    },
    // 获取orthoImageLayerId
    orthoImageLayerId() {
      return this.$store.state.live.orthoImageLayerId;
    },
  },
  methods: {
    // 开启正射影像图
    openOrthographic(item) {
      const { id } = item;
      // 新增规则。如果orthoImageLayerId的值与当前的id相同，则清除该值
      if (this.orthoImageLayerId === id) {
        this.$store.dispatch("live/setOrthoImageLayerId", "");
        return;
      } else {
        this.$store.dispatch("live/setOrthoImageLayerId", id);
      }
    },
    // 获取元素的react
    getRect(el) {
      const element = document.querySelector(el);
      const rect = element.getBoundingClientRect();
      return rect;
    },
    // 鼠标按下
    startDrag(item, event) {
      // 如果当前鼠标落下位置x和当前元素的left的距离小于10，y和top的距离小于10，就认为是在拖拽

      if (
        event.target.clientHeight - event.offsetY < 14 &&
        event.target.clientWidth - event.offsetX < 14
      )
        return false;

      this.isDragging = true;
      this.selectId = item.id;
      this.mouseOffset.left = event.clientX - item.left; // 鼠标点击位置
      this.mouseOffset.top = event.clientY - item.top;
    },
    // 鼠标移动
    drag(item, event) {
      if (!this.isDragging) return;

      const newLeft = event.clientX - this.mouseOffset.left;
      const newTop = event.clientY - this.mouseOffset.top;

      item.left = newLeft;
      item.top = newTop;
    },
    // 鼠标抬起
    stopDrag() {
      this.isDragging = false;
      this.selectId = NaN;
    },
    // 关闭视频监控
    closeVideoSurveillance(item, index) {
      const { id } = item;
      // 如果当前关闭的视频监控是正射影像图，则清除该值
      if (this.orthoImageLayerId === id) {
        this.$store.dispatch("live/setOrthoImageLayerId", "");
      }
      this.playerList.splice(index, 1);
    },
    // 添加一个新的播放器
    addPlayer(data) {
      const {
        livePlayUrl,
        text,
        resourceName,
        resourceType,
        unitName,
        subDeviceSn,
        id,
        playStreamUrl,
      } = data;

      let top = 80;
      let left = 0;

      if (this.playerList.length == 9) {
        this.$message({
          message: "最多只能添加9个视频播放窗口",
          type: "warning",
        });
        return;
      }

      for (let i = 0; i < this.playerList.length; i++) {
        const element = this.playerList[i];
        // 先判断类型，在判断是否存在
        if (
          (element.resourceType == 4 && element.livePlayUrl == livePlayUrl) ||
          (element.resourceType == 3 && element.livePlayUrl == playStreamUrl)
        ) {
          this.$message({
            message: "该视频播放窗口已存在",
            type: "warning",
          });
          return;
        } else if (
          (element.resourceType == 2 && element.subDeviceSn == subDeviceSn) ||
          (element.resourceType == 1 && element.subDeviceSn == subDeviceSn)
        ) {
          this.$message({
            message: "该视频播放窗口已存在",
            type: "warning",
          });
          return;
        }
      }

      //根据播放器数量来设置播放器的位置。012调整top值，345调整left值，top值初始化为80
      switch (this.playerList.length) {
        case 1:
          top = 178 + 90;
          break;
        case 2:
          top = 178 + 278;
          break;
        case 3:
          top = 80;
          left = 278;
          break;
        case 4:
          top = 178 + 90;
          left = 278;
          break;
        case 5:
          top = 178 + 278;
          left = 278;
          break;
        // 三个为一个轮循
        case 6:
          top = 80;
          left = 556;
          break;
        case 7:
          top = 178 + 90;
          left = 556;
          break;
        case 8:
          top = 178 + 278;
          left = 556;
          break;
      }

      if (resourceType == 4) {
        this.playerList.push({
          id,
          playerType: "tcplayer",
          name: text,
          livePlayUrl,
          top: top,
          left: left,
          resourceType,
          showStatus: false,
        });
      } else if (resourceType == 2) {
        //对subDeviceSn做检测，如果subDeviceSn中有@符号，判断为演示数据
        if (subDeviceSn.indexOf("aa") != -1) {
          this.playerList.push({
            id,
            name: `${resourceName}(${unitName})`,
            livePlayUrl: `webrtc://liveplay.zxrob.com/zxrob/${subDeviceSn}-CAMERA`,
            top: top,
            left: left,
            resourceType: 4,
            playerType: "tcplayer",
            showStatus: false,
          });
        } else {
          this.playerList.push({
            id,
            playerType: "agora",
            name: `${resourceName}(${unitName})`,
            subDeviceSn,
            top: top,
            left: left,
            resourceType,
            showStatus: false,
          });
        }
      } else if (resourceType == 3) {
        this.playerList.push({
          id,
          playerType: "tcplayer",
          name: resourceName,
          livePlayUrl: playStreamUrl,
          top: top,
          left: left,
          resourceType,
          showStatus: false,
        });
      } else if (resourceType == 1) {
        let playerType;
        const env = process.env.VUE_APP_CURRENTMODE;
        if (env === "xiwan") {
          playerType = "webrtc";
        } else {
          playerType = "agora";
        }

        // 自研机库播放代码
        if (subDeviceSn.indexOf("9999F6Q8D236L0010999") != -1) {
          this.playerList.push({
            id,
            name: `${resourceName}(飞行器)`,
            livePlayUrl: `webrtc://liveplay.zxrob.com/live/zxdock-drone`,
            top: top,
            left: left,
            resourceType,
            playerType: "tcplayer",
            showStatus: false,
          });
        } else if (subDeviceSn.includes("9999F6Q8D236L0010998")) {
          this.playerList.push({
            id,
            name: `${resourceName}(飞行器)`,
            livePlayUrl: `webrtc://liveplay.zxrob.com/live/9QCDL5R0000998`,
            top: top,
            left: left,
            resourceType,
            playerType: "tcplayer",
            showStatus: false,
          });
        }else if(subDeviceSn.includes("1581F5FJC245400D4C8P")){
          this.playerList.push({
            id,
            name: `${resourceName}(飞行器)`,
            livePlayUrl: `webrtc://liveplay.zxrob.com/live/9999F6Q8D236L0010997`,
            top: top,
            left: left,
            resourceType,
            playerType: "tcplayer",
            showStatus: false,
          });
        } else {
          this.playerList.push({
            id,
            name: `${resourceName}`,
            subDeviceSn,
            // livePlayUrl: `webrtc://liveplay.zxrob.com/zxrob/${subDeviceSn}-CAMERA`,
            top: top,
            left: left,
            resourceType: 1,
            playerType: playerType,
            showStatus: false,
          });
        }
      }
    },
    // 监听元素大小 判断是否显示仪表盘
    resize(e, data) {
      if (
        !parseFloat(e.width.match(/\d+/)) ||
        !parseFloat(e.height.match(/\d+/))
      )
        return;
      const width = parseFloat(e.width.match(/\d+/)[0]);
      const height = parseFloat(e.height.match(/\d+/)[0]);

      if (width >= 650 && height >= 400) {
        data.showStatus = true;
        return;
      }
      data.showStatus = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .altitude_box {
  height: 50%;
  padding: 0;
  padding-bottom: 4%;
}
.video-surveillance_container {
  position: absolute;
  left: 451px;
  top: 0;
  z-index: 3;
  display: flex;
  .video-surveillance_list {
    position: relative;
  }

  .video-surveillance_item {
    position: absolute;
    width: 268px;
    height: 178px;
    top: 0;
    background: #000000;
    border: 1px solid #ffc400;
    box-sizing: content-box;
    z-index: 1;
    display: flex;
    resize: both;
    overflow: auto;
    cursor: move;
    &:nth-child(3) {
      margin-right: 0;
    }
    .icon-delete {
      position: absolute;
      color: white;
      right: 10px;
      top: 10px;
      font-size: 22px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      z-index: 2;
      background-color: var(--btn-default-color);
      &:hover {
        background-color: var(--btn-hover-color);
      }
    }
    // 正射
    .icon-orthographic {
      position: absolute;
      color: white;
      right: 50px;
      top: 10px;
      font-size: 22px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      z-index: 2;
      background-color: var(--btn-default-color);
      &:hover {
        background-color: var(--btn-hover-color);
      }
    }
    /deep/ .sharing {
      position: absolute;
      right: unset;
      z-index: 3;
      font-size: 22px;
      top: 10px;
      left: 10px;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      background-color: var(--btn-default-color);
      display: flex;

      .qr-box {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 30px;
      }
      &:hover {
        background-color: var(--btn-hover-color);
      }
    }
    .video-surveillance_name {
      height: 40px;
      background: #0a1421;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      color: #ffc400;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  //禁止点击
  .video-surveillance_item--disabled {
    pointer-events: none !important;
    z-index: 0 !important;
    opacity: 0.5;
  }
}
</style>
