const state = {
    projectMarkInfo: {
        markType: NaN, //标注类型
        markRes: null, //标注结果
    },
}

const mutations = {
    // 设置信息
    SET_MARK_INFO: (state, projectMarkInfo) => {
        const { markType, markRes } = projectMarkInfo

        if (markType || typeof markType == 'number') state.projectMarkInfo.markType = markType
        if (markRes || markRes == null) state.projectMarkInfo.markRes = markRes

    },
}

const actions = {

    clearInfo({ commit }) {
        let projectMarkInfo = {
            markType: NaN, //标注类型
            markRes: null, //标注结果
        }
        commit('SET_MARK_INFO', projectMarkInfo)
    },
    // 绘制完成回调
    drawFinishCallback({ commit }, data) {
        return new Promise((resolve) => {

            let info = {
                markRes: data
            }
            commit('SET_MARK_INFO', info);
            resolve()
        })
    },
    // 样式设置的回调
    setStyleCallback({ commit }, data) {
        let info = {
            markRes: data
        }
        commit('SET_MARK_INFO', info);
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}