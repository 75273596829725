<template>
  <section class="player-container">
    <video
      :id="liveOptions.playerId"
      class="player"
      ref="player"
      preload="auto"
    ></video>
    <div class="sharing" v-if="liveOptions.liveUrl">
      <QrCodeLive
        :liveType="1"
        :livedata="{ url: liveOptions.liveUrl }"
        :key="liveOptions.liveUrl"
      />
    </div>
  </section>
</template>

<script>
import TCPlayer from "tcplayer.js";
import QrCodeLive from "@/components/CommonLive/QrCodeLive.vue";
import "tcplayer.js/dist/tcplayer.min.css";
import { debounce } from "@/utils/index";

export default {
  components: {
    QrCodeLive,
  },
  data() {
    return {
      player: null,
      video: null,
      // dataUrl: require("../../../src/views/resultsInfo/player/bandicam 2023-07-24 22-36-10-163.mp4"),
    };
  },
  props: {
    liveOptions: {
      type: Object,
      require: true,
      firstDisabled: true,
    },
    playerOptions: {
      type: Object,
      require: true,
      default: () => {
        return {
          controls: false, // 隐藏菜单栏
          autoplay: true, // 自动播放
          muted: true,
        };
      },
    },
  },
  mounted() {
    this.initPlayer();
  },
  beforeDestroy() {
    this.destroyPlayer();
  },
  methods: {
    initPlayer() {
      // appID String 无 通过 fileID 播放点播媒体文件时必选，为对应腾讯云账号的 appID
      // fileID String 无 通过 fileID 播放点播媒体文件时必选，为点播媒体文件的 ID
      // sources Array 无 播放器播放地址，格式：[{ src: '//path/to/video.mp4', type: 'video/mp4' }]
      // width String/Number 无 播放器区域宽度，单位像素，按需设置，可通过 CSS 控制播放器尺寸。
      // height String/Number 无 播放器区域高度，单位像素，按需设置，可通过 CSS 控制播放器尺寸。
      // controls Boolean true 是否显示播放器的控制栏。
      // poster String 无 设置封面图片完整地址（如果上传的视频已生成封面图，优先使用生成的封面图，详细请参见 云点播 - 管理视频）。
      // autoplay Boolean false 是否自动播放。
      // playbackRates Array [0.5，1，1.25，1.5，2] 设置变速播放倍率选项，仅 HTML5 播放模式有效。
      // loop Boolean false 是否循环播放。
      // const options = {
      //   controls: false, // 隐藏菜单栏
      //   autoplay: true, // 自动播放
      //   muted: true,
      // };

      const { playerId, liveUrl } = this.liveOptions;
      this.player = TCPlayer(playerId, this.playerOptions); // player-container-id 为播放器容器 ID，必须与 html 中一致
      this.player.src(liveUrl); // url 播放地址
      // this.player.src(this.dataUrl); // url 播放地址
      this.video = this.$refs.player;

      this.player.on("error", (err) => {
        // console.log(err);
        //播放失败 做一些事情
        const dom = document.querySelector(".vjs-errors-content-container");
        dom.innerHTML = "视频打开失败，请检查网络或推流设置";
      });

      this.subscribePlayerEvents();
    },

    destroyPlayer() {
      if (this.player) {
        this.player.dispose();
      }
    },

    subscribePlayerEvents() {
      const { playerId } = this.liveOptions;
      const player = document.getElementById(playerId).children[0];
      player.crossOrigin = "anonymous";
      if (!player) return false;
      // player.setAttribute("crossOrigin", "anonymous");
      player.addEventListener("playing", () => {
        this.$emit("canplay");
      });

      player.addEventListener(
        "resize",
        debounce(() => {
          if (this.firstDisabled) {
            this.firstDisabled = false;
            return false;
          }

          this.$emit("windowResize");
        }, 500)
      );

      player.addEventListener("pause", () => {
        // console.log("播放器已暂停");
      });

      player.addEventListener("ended", () => {
        console.log("播放已结束");
        this.$emit("removePlay");
        this.$EventBus.$emit("disableOrthoImage");
        //
      });
    },
  },
};
</script>

<style lang="less" scoped>
.player-container {
  flex: 1;
  display: flex;
  position: relative;
  .sharing {
    position: absolute;
    right: 5%;
    top: 45px;
    z-index: 3;
    font-size: 20px;
  }
  .player {
    width: 100%;
    height: 100%;
  }
}
</style>
