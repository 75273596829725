import axios from "axios";
import { MessageBox, Message } from 'element-ui';
import store from '@/store';

let timer = null;
let isMessageBoxOpen = false; // 标识弹窗是否已经打开

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000
});

service.interceptors.request.use(
  config => {
    // 上传图片接口超时时间设置为60s
    if(config.url.endsWith('uploadWebImageFile')){
      config.timeout = 60000
    }


    if (isMessageBoxOpen) {
      // 如果弹窗已经打开，则取消后续请求
      return Promise.reject(new Error('Request canceled due to expired login information'));
    }
    
    const token = store.getters.token;
    if (token) {
      config.headers['x-auth-token'] = token;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// ...

// code
// : 
// 401
// message
// : 
// "登录已过期，请重新登录"
// result
// : 
// -1

// ...

service.interceptors.response.use(
  response => {
    const res = response.data;
    if ((res.code === 200 && res.result === 0) || res.status === '1' || res.ipLoc) {
      return res;
    } else if (res.message == "success") {
      return res;
    } else if (res.code === 401 && res.result == -1) {
      if (!isMessageBoxOpen) {
        isMessageBoxOpen = true; // 标识弹窗已经打开
        MessageBox.alert('登录信息过期，请重新登录', '确认登出', {
          confirmButtonText: '确定',
          showClose: false
        }).then(() => {
          clearTimeout(timer);
          timer = null;
          isMessageBoxOpen = false; // 标识弹窗已关闭
          store.dispatch('user/logout').then(() => {
            location.reload();
          });
        });
      }

      timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        isMessageBoxOpen = false; // 标识弹窗已关闭
        store.dispatch('user/logout').then(() => {
          location.reload();
        });
      }, 5000);

    } else if (response.config.url.includes('/radarapi')) {
      // 矿山接口处理
      return res;
    }
    else {
      Message.error(res.message);
      return Promise.reject(new Error(res.info || 'Error'));
    }
  },
  error => {
    return Promise.reject(error);
  }
);

// ...

export default service;