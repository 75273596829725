<template>
  <section
    class="common-dialog"
    :class="{ mask: showMask }"
    @click="$emit('clickBg')"
  >
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "CommonDialog",
  props: {
    showMask: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="less">
.common-dialog {
  background: transparent;
  z-index: 2000; /* 最高 */
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mask {
  background: rgba(0, 0, 0, 0.65);
}
</style>
