// 雷达数据处理模块
const state = {
    token: '6c70546cc2544c6fe5dce4dddfc2835e97e70d54f49ba096748ed9a3f8776d12',
    taskList:[],
    radarIndex:'0001',
}

const mutations = {
    // 写入任务批次数据
    SET_TASK_LIST: (state, taskList) => {
        state.taskList = taskList
    },
    // 写入雷达索引
    SET_RADAR_INDEX: (state, radarIndex) => {
        state.radarIndex = radarIndex
    },
}

const actions = {
    // 写入任务批次数据
    setTaskList: ({ commit }, taskList) => {
        commit('SET_TASK_LIST', taskList)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}