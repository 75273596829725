import { login, logout, getInfo, getAuthorityList } from '@/api/user'
import { getToken, setToken, removeToken, getUserId, setUserId, removeUserId } from '@/utils/auth'
import store from '@/store/index.js'
const state = {
    token: getToken() || '', // token
    name: '', // 用户名
    avatar: '', // 头像
    id: getUserId(), // 用户id
    roleId: NaN, // 角色id
    workspaceId: NaN, // 工作区id
    routeList: [], // 路由列表
    unitId: NaN, // 单位id
    homepage: '', // 首页
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    // roleId
    SET_ROLE_ID: (state, roleId) => {
        state.roleId = roleId
    },
    // id
    SET_ID: (state, id) => {
        state.id = id
    },
    // unitId
    SET_UNIT_ID: (state, unitId) => {
        state.unitId = unitId
    },
    // workspaceId
    SET_WORKSPACE_ID: (state, workspaceId) => {
        state.workspaceId = workspaceId
    },
    SET_ROUTE_LIST: (state, routeList) => {
        state.routeList = routeList
    },
    SET_HOMEPAGE: (state, homepage) => {
        state.homepage = homepage
    }
}

const actions = {
    // user login
    login({ commit }, userInfo) {

        const { account, password } = userInfo

        let formData = new FormData();
        formData.append('username', account);
        formData.append('password', password);
        formData.append('userType', 0);
        return new Promise((resolve, reject) => {
            login(formData).then(response => {
                const { accessToken, workspaceId, id, nickName, unitId, homepage } = response.data
                commit('SET_HOMEPAGE', homepage)
                commit('SET_WORKSPACE_ID', workspaceId);
                commit('SET_NAME', nickName);
                commit('SET_ID', id);
                commit('SET_TOKEN', accessToken);
                commit('SET_UNIT_ID', unitId);
                // 写入cookie
                setToken(accessToken);
                setUserId(id);

                resolve();

            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            logout().then(() => {
                store.dispatch("live/setPlayList", []);//清除直播存储信息
                commit('SET_ROUTE_LIST', [])
                commit('SET_HOMEPAGE', '')
                commit('SET_TOKEN', '')
                commit('SET_NAME', '');
                commit('SET_AVATAR', '');
                commit('SET_WORKSPACE_ID', NaN);
                commit('SET_ROLE_ID', NaN);
                commit('SET_ID', '');
                commit('SET_UNIT_ID', NaN);
                removeToken()
                removeUserId()

                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    getInfo({ commit }) {
        return new Promise((resolve, reject) => {
            getInfo().then(response => {
                const { nickName, avatar, roleId, id, workspaceId, unitId,homepage } = response.data
                commit('SET_HOMEPAGE', homepage)
                commit('SET_WORKSPACE_ID', workspaceId);
                commit('SET_NAME', nickName);
                commit('SET_AVATAR', avatar);
                commit('SET_ROLE_ID', roleId);
                commit('SET_ID', id);
                commit('SET_UNIT_ID', unitId);

                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    getAuthMenuList({ commit }) {
        return new Promise((resolve, reject) => {
            getAuthorityList({ userId: state.id }).then(response => {
                const { modelAuthority, homepage } = response.data
                commit('SET_ROUTE_LIST', modelAuthority)
                commit('SET_HOMEPAGE', homepage)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}