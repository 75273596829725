<template>
  <section
    class="default-page"
    v-if="liveOptions.playerType == 'none'"
  >
    <div class="img-box"></div>
    <p class="tip">当前没有视频画面</p>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.default-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6px;
  justify-content: space-around;
  .img-box {
    width: 422px;
    height: 319px;
    background: url("@/assets/live/default-big.png") no-repeat center;
  }
  .tip {
    font-size: 20px;
    font-weight: 500;
    color: #40e9f2;
  }
}
</style>