<template>
  <div class="parent-box">
    <input
      :class="{ err: err , disabled:disabled}"
      :type="type"
      :placeholder="placeholder"
      ref="input"
      @input="handleInput"
      @blur="$emit(`blur`)"
      @keyup.enter="$emit(`keyup`)"
      :maxlength="max"
      :disabled="disabled"
    />

    <i
      class="el-icon-circle-close pointer"
      v-if="showRemove"
      v-show="value.length > 0"
      @click="clearValue"
    ></i>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      default: "请输入查询条件",
      type: String,
    },
    type: {
      default: "text",
      type: String,
    },
    showRemove: {
      default: false,
      type: Boolean,
    },
    err: {
      default: false,
      type: Boolean,
    },
    max: {
      type: Number,
      default: 100,
    },
    value: {
      type: [Number, String],
      required: true,
    },
    disabled:{
      type: Boolean,
      default: false,
    }
  },
  watch: {
    value: {
      handler() {
        this.setInputValue();
      },
    },
  },
  computed: {
    // 获取props的值
    getPropsValue() {
      return this.value === null || this.value === undefined
        ? ""
        : String(this.value);
    },
  },
  mounted() {
    this.setInputValue();
  },
  methods: {
    clearValue() {
      this.$emit("input", "");
      this.$nextTick(this.setInputValue);
    },
    // 值的设置
    setInputValue() {
      const input = this.$refs.input;
      if (!input) return;
      if (input.value === this.getPropsValue) return;
      input.value = this.getPropsValue;
    },
    handleInput(event) {
      if (event.target.value === this.setInputValue) return;
      // 双向绑定
      this.$emit("input", event.target.value);
      this.$nextTick(this.setInputValue);
    },
  },
};
</script>

<style lang="less" scoped>
.parent-box {
  position: relative;
  height: inherit;
  display: flex;
  font-size: 20px;

  .el-icon-circle-close {
    position: absolute;
    top: 50%;
    color: #706c6c;
    transform: translateY(-50%);
    right: 6px;
  }
}
// 错误提示
.err {
  border-color: red !important;
}
input {
  width: 100%;
  height: 100%;
  flex: 1;
  background: transparent;
  color: white;
  font-size: 16px;
  padding: 0 12px;
  border: 1px solid #989da5;
  border-radius: 4px;
}

input:focus {
  outline: none;
  border-color: #409eff;
}

input::placeholder {
  font-size: 16px;
  font-family: PingFang SC-Light, PingFang SC;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.59);
}
.disabled{
  cursor: not-allowed;
}
</style>