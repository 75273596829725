<template>
  <div id="app">
    <!-- <keep-alive> -->
      <router-view />
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    const favicon = document.getElementById('favicon');
    favicon.href = process.env.VUE_APP_ICON_PATH;
  }
};
</script>

<style lang="less" scoped>
#app {
  height: 100%;
  width: 100%;
  display: flex;
}
</style>
