export default {

    mounted() {
        this.showOptions()
    },
    watch: {
        '$route': 'showOptions'
    },
    methods: {
        showOptions() {
            const pathList = ["/command/index", "/task/index", "/courseTasks/index", "/markTask/index", "/resourcesDetails/index"]; // 需要展示信息组件的页面
            const { path } = this.$route
            if (pathList.indexOf(path) > -1) {
                this.footerInfoShow = true;
                const { resourceType } = this.$route.query
                if (resourceType && resourceType != 2) this.footerInfoShow = false;

            } else {
                this.footerInfoShow = false;
            }


            if ('/command/index'.indexOf(path) > -1) {
                this.analysisToolShow = true;
                this.suspiciousTargetShow = true;
                this.videoSurveillanceShow = true;
                this.markShow = true;
                this.trackSwitchShow = true;
            } else {
                this.analysisToolShow = false;
                this.suspiciousTargetShow = false;
                this.videoSurveillanceShow = false;
                this.markShow = false;
                this.trackSwitchShow = false;
            }
        }
    }
};