<template>
  <section class="controls-container" v-if="isStatistics">
    <ul class="controls-container_list">
      <el-tooltip
        effect="dark"
        placement="left"
        v-for="(item, index) in menuList"
        :key="index"
      >
        <li
          class="item pointer"
          :class="{ 'is-active': isActive === index }"
          @click="featureSelection(index)"
        >
          <i
            v-if="index === 0"
            class="iconfont"
            :class="[
              { 'icon-mode-3D': index === 0 && is3D },
              { 'icon-mode-2D': index === 0 && !is3D },
              ,
            ]"
          ></i>
          <i v-else class="iconfont" :class="item"></i>
        </li>

        <!-- 这是提示语 -->
        <div class="tip-text" slot="content">
          <p v-if="index === 0 && is3D">3D地图</p>
          <p v-else-if="index === 0 && !is3D">2D地图</p>
          <p v-else-if="index === 1">搜索</p>
          <p v-else-if="index === 2">图层</p>
          <p v-else-if="index === 3">测量工具</p>
        </div>
      </el-tooltip>
    </ul>

    <transition name="fade_right">
      <section class="layer-list" v-if="isActive === 2">
        <li class="layer-list_item y-scroll">
          <!-- 海图 -->
          <!-- <div class="layer-conten_box pointer" @click="nauticalChartChange">
            <div class="img-box" :class="{ is_check: nauticalChart }">
              <img src="img/basemap/tdt_hy.png" />
            </div>
            <p
              class="layer-name"
              :class="{ 'layer-name_active': nauticalChart }"
            >
              海图
            </p>
          </div> -->
          <div class="layer-conten_box pointer" @click="baiduChange">
            <div class="img-box" :class="{ is_check: baidu }">
              <img src="img/basemap/baidu.png" />
            </div>
            <p class="layer-name" :class="{ 'layer-name_active': baidu }">
              百度影像
            </p>
          </div>
          <!-- <div class="layer-conten_box pointer" @click="googleChange">
            <div class="img-box" :class="{ is_check: google }">
              <img src="img/basemap/tdt_img.png" />
            </div>
            <p class="layer-name" :class="{ 'layer-name_active': google }">
              谷歌影像
            </p>
          </div> -->

          <div class="layer-conten_box pointer" @click="flatChange">
            <div class="img-box" :class="{ is_check: flat }">
              <img src="img/basemap/tdt_vec.png" />
            </div>
            <p class="layer-name" :class="{ 'layer-name_active': flat }">
              平面地图
            </p>
          </div>

          <div class="layer-conten_box pointer" @click="zhongheChange">
            <div class="img-box" :class="{ is_check: zhonghe }">
              <img src="img/basemap/minesBG.png" />
            </div>
            <p class="layer-name" :class="{ 'layer-name_active': zhonghe }">
              矿山模型
            </p>
          </div>
        </li>
        <li class="layer-list_item y-scroll">
          <!-- 第二层 -->
          <div
            v-for="(item, i) in onemaps"
            :key="i"
            class="layer-conten_box pointer"
            :title="item.name"
          >
            <div class="img-box" :class="{ is_check: onemapIndex === i }">
              <img :src="`${item.image}`" @click="changeMap(item, i)" />
            </div>
            <p
              class="layer-name"
              :class="{ 'layer-name_active': onemapIndex === i }"
            >
              {{ item.name | stringCutting(4) }}
            </p>
          </div>
        </li>
        <li class="layer-list_item">
          <!-- 第三层 -->
          <div
            v-for="(item, i) in getViewpoints"
            :key="i"
            class="layer-conten_box pointer"
          >
            <div class="img-box" :class="{ is_check: viewpointIndex === i }">
              <img
                :src="`${item.image}`"
                @click="gotoViewpoint(item.center, i)"
              />
            </div>
            <p
              class="layer-name"
              :class="{ 'layer-name_active': viewpointIndex === i }"
            >
              {{ item.name }}
            </p>
          </div>
        </li>
        <li class="bottom-bar">
          <el-switch
            v-model="isShowTerrain"
            class="switch-style"
            @change="switchHeightMode"
            active-text="显示地形"
          ></el-switch>

          <el-switch
            v-model="road"
            class="switch-style"
            @change="roadChange"
            active-text="路网信息"
          ></el-switch>

          <!-- noFly -->
          <el-switch
            v-model="noFly"
            class="switch-style"
            @change="flyChange"
            active-text="禁飞区"
          ></el-switch>
        </li>
      </section>
    </transition>
    <transition name="fade_right">
      <div v-if="isActive === 3" class="ranging-box">
        <div
          class="pointer item left"
          :class="{ 'is-active': ranging == 0 }"
          @click="rule()"
        >
          测距
        </div>
        <div
          class="pointer item right"
          :class="{ 'is-active': ranging == 1 }"
          @click="measureArea()"
        >
          测面
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import {
  map_list,
  layer_get,
  job_trace,
  plotting_get,
  loadImportLayer,
  loadPlottingLayer,
  loadTaskLayer,
} from "@/api/onemap";
export default {
  data() {
    return {
      menuList: [
        "icon-mode-mode-3D",
        "icon-search",
        "icon-layer",
        "icon-ranging",
      ],
      isActive: -1,
      road: true,
      noFly: false,
      ranging: -1,
      viewpoints: [],
      onemaps: [],
      onemapIndex: -1,
      viewpointIndex: -1,
      nauticalChart: false, //海图
      baidu: true, //百度影像
      google: false, //谷歌影像
      flat: false, //平面地图
      zhonghe: false, //中禾正射
    };
  },
  computed: {
    getViewpoints() {
      return this.$store.getters.viewpoints;
    },
    // 当前路由是否是/statistics/index
    isStatistics() {
      const pathList = [
        "/overview/index",
        "/radar/index",
        "/monitor/index",
        "/safety/index",
        "/statistics/index",
        "/riskAssessment/index",
      ];

      if (
        this.$route.path.includes("statistics") &&
        this.currentMode == "xiwan"
      ) {
        return true;
      }
      return !pathList.includes(this.$route.path);
    },
    isShowTerrain: {
      get() {
        return this.$store.getters.isShowTerrain;
      },
      set(val) {
        this.$store.dispatch("map/setIsShowTerrain", val);
      },
    },
    is3D() {
      return this.$store.getters.is3D;
    },
    currentMode() {
      return process.env.VUE_APP_CURRENTMODE;
    },
  },
  mounted() {
    //加载一张图
    map_list().then((res) => {
      if (res.data) {
        this.onemaps = res.data;
        this.onemaps.forEach((map) => {
          map.image =
            map.image == "" || map.image == null ? "img/onemap.jpg" : map.image;
        });
      }
    });

    //从缓存里面读取保存的视点
    var vps = localStorage.getItem("vps");
    var viewpoints = [];
    if (vps != null && vps != "") {
      viewpoints = JSON.parse(vps);
    }
    if (viewpoints.length == 0) {
      viewpoints.push({
        name: "添加视点",
        image: "img/addviewpoint.png",
        center: {},
      });
    }
    this.$store.commit("map/SET_VIEW_POINTS", viewpoints);
  },
  methods: {
    switchHeightMode() {
      zr3d.terrainControl({
        show: this.isShowTerrain,
      });
    },
    //侧面
    measureArea() {
      this.ranging = 1;
      zr3d.measureArea();
    },
    //测距
    rule() {
      this.ranging = 0;
      zr3d.measureLine();
    },

    // 路网
    roadChange() {
      // this.road = !this.road;
      zr3d.layerControl({
        name: "影像标注",
        show: this.road,
      });
    },
    // 禁飞区
    flyChange() {
      loadDjiData(zr3d, this.noFly);
    },

    // 初始化所有的图层
    setOneTrue(key) {
      switch (key) {
        case "baidu":
          this.nauticalChart = false;
          zr3d.layerControl({
            name: "海图",
            show: this.nauticalChart,
          });

          this.google = false;
          zr3d.layerControl({
            name: "谷歌影像",
            show: this.google,
          });

          this.flat = false;
          zr3d.layerControl({
            name: "矢量图层",
            show: this.flat,
          });

          break;

        case "nauticalChart":
          this.baidu = false;
          zr3d.layerControl({
            name: "basemap",
            show: this.baidu,
          });

          this.google = false;
          zr3d.layerControl({
            name: "谷歌影像",
            show: this.google,
          });

          this.flat = false;
          zr3d.layerControl({
            name: "矢量图层",
            show: this.flat,
          });
          break;
        case "google":
          this.nauticalChart = false;
          zr3d.layerControl({
            name: "海图",
            show: this.nauticalChart,
          });

          this.baidu = false;
          zr3d.layerControl({
            name: "basemap",
            show: this.baidu,
          });

          this.flat = false;
          zr3d.layerControl({
            name: "矢量图层",
            show: this.flat,
          });
          break;
        case "flat":
          this.nauticalChart = false;
          zr3d.layerControl({
            name: "海图",
            show: this.nauticalChart,
          });

          this.baidu = false;
          zr3d.layerControl({
            name: "basemap",
            show: this.baidu,
          });

          this.google = false;
          zr3d.layerControl({
            name: "谷歌影像",
            show: this.google,
          });
          break;
      }
    },
    //模式切换
    nauticalChartChange() {
      if (this.nauticalChart) return;

      this.nauticalChart = true;
      zr3d.layerControl({
        name: "海图",
        show: this.nauticalChart,
      });
      if (this.nauticalChart) this.setOneTrue("nauticalChart");
    },
    googleChange() {
      if (this.google) return;

      this.google = true;
      zr3d.layerControl({
        name: "谷歌影像",
        show: this.google,
      });
      if (this.google) this.setOneTrue("google");
    },
    flatChange() {
      // this.flat = !this.flat;
      if (this.flat) return;

      this.flat = true;
      zr3d.layerControl({
        name: "矢量图层",
        show: this.flat,
      });
      if (this.flat) this.setOneTrue("flat");
    },

    zhongheChange() {
      this.zhonghe = !this.zhonghe;
      zr3d.layerControl({
        name: "倾斜模型",
        show: this.zhonghe,
      });
    },

    baiduChange() {
      // this.baidu = !this.baidu;
      if (this.baidu) return;
      this.baidu = true;
      zr3d.layerControl({
        name: "basemap",
        show: this.baidu,
      });

      if (this.baidu) this.setOneTrue("baidu");
    },
    featureSelection(index) {
      if (index == 0) {
        // 模式切换
        let newMode = this.is3D ? "2D" : "3D";

        zr3d.sceneModeSet({
          mode: newMode,
        });
        this.$store.dispatch("map/setIs3D", !this.is3D);
      } else if (this.isActive === 3) {
        zr3d.measureClear(); // 清除测量信息
        this.isActive = -1;
        this.ranging = -1;
      } else if (this.isActive === index) {
        if (this.isActive === 1) this.$emit("closeSearch"); // 关闭搜索栏
        this.isActive = -1;
      } else {
        this.isActive = index;
        if (this.isActive === 1) this.$emit("openSearch"); // 开启搜索栏
      }
    },
    gotoViewpoint(vp, index) {
      if (vp == null || vp.x == null) {
        //保存视点
        var canvas = zr3d.getViewer().canvas;
        var img = getMapImage(canvas, canvas.width * 0.5, canvas.height * 0.5);
        zr3d.viewpointGet((data) => {
          var vp = {
            name: "视点",
            center: data.viewpoint,
            image: img.src,
          };
          var viewpoints = this.$store.getters.viewpoints;
          viewpoints.splice(viewpoints.length - 1, 1);
          if (viewpoints.length >= 3) {
            viewpoints.splice(0, 1);
          }
          viewpoints.push(vp);
          viewpoints.push({
            name: "添加视点",
            image: "img/addviewpoint.png",
            center: {},
          });

          this.$store.commit("map/SET_VIEW_POINTS", viewpoints);
          localStorage.setItem("vps", JSON.stringify(viewpoints));
        });

        return;
      }
      this.viewpointIndex = index;
      zr3d.viewpointSet({
        viewpoint: vp,
      });
    },
    //切换一张图
    changeMap(map, index) {
      if (this.onemapIndex == index) {
        this.onemapIndex = -1;

        this.switchOneMap({
          id: map.id,
          center: map.center,
          close: true,
        });
        return;
      }
      this.onemapIndex = index;
      this.switchOneMap({
        id: map.id,
        center: map.center,
      });
    },

    //切换一张图
    switchOneMap(map) {
      var mid = map.id;

      //清除一张图内容再加载新的一张图

      var layers = zr3d.layerList({ type: "onemap" });

      layers.forEach((layer) => {
        zr3d.layerRemove({ name: layer.id });
      });
      if (map.close != null && map.close) return;
      //获取图层
      layer_get({ id: mid }).then((res) => {
        if (res.data == null) {
          return;
        }
        //解析图层配置
        var config = JSON.parse(res.data.layerConfig);

        //遍历任务
        var taskIds = config.taskIds;
        if (taskIds) {
          taskIds.forEach((id) => {
            //获取单个任务成果
            job_trace({ jobId: id }).then((res) => {
              if (res.data == null) {
                return;
              }
              loadTaskLayer(zr3d, res.data);
            });
          });
        }
        //遍历图层，加载图层
        var layers = config.importLayers;
        if (layers) {
          layers.forEach((layerOption) => {
            loadImportLayer(zr3d, layerOption);
          });
        }
      });
      //获取标绘列表
      plotting_get({ mapId: mid }).then((res) => {
        if (res.data == null) {
          return;
        }
        loadPlottingLayer(zr3d, res.data);
      });

      //定位到视点
      if (map.center != null && map.center != "") {
        var center = JSON.parse(map.center);

        var vp = {
          x: center.lng,
          y: center.lat,
          z: center.alt,
          heading: center.heading,
          pitch: center.pitch,
          roll: 0,
        };
        zr3d.viewpointSet({
          viewpoint: vp,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.controls-container {
  width: 54px;
  height: 212px;
  background: var(--page-background-color);
  border-radius: 4px;
  position: absolute;
  right: 30px;
  bottom: 60px;
  display: flex;
  z-index: 1;

  .controls-container_list {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      &:hover {
        background-color: var(--btn-hover-color);
      }
      .iconfont {
        color: white;
        font-size: 26px;
      }
    }

    .is-active {
      background-color: var(--btn-active-color) !important
      // .iconfont {
      //   color: #40e9f2;
      // }
    }
  }

  .layer-list {
    position: absolute;
    background: var(--page-background-color);
    border-radius: 4px;
    border: var(--page-border);
    right: 88px;
    bottom: 0;
    width: 372px;
    height: 378px;
    display: flex;
    align-content: center;
    flex-direction: column;

    .layer-list_item {
      height: 33.33333%;
      display: flex;
      align-items: center;
      padding-left: 24px;
      .layer-conten_box {
        margin-right: 18px;

        .img-box {
          width: 68px;
          height: 66px;
          background: #ffffff;
          border: 1px solid white;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .is_check {
          border-color: #a3ecfb;
          border-width: 2px;
          background: #159ea5;
        }

        .layer-name {
          font-size: 14px;
          font-weight: 300;
          color: #ffffff;
          height: 20px;
          margin-top: 8px;
          text-align: center;
        }

        .layer-name_active {
          color: #40e9f2;
        }
      }

      .layer-conten_box_active {
        background: rgba(64, 233, 242, 0.14);
      }

      &:nth-child(2n) {
        border-top: 1px solid #a3ecfb;
        border-bottom: 1px solid #a3ecfb;
      }

      &:last-child {
        &:before,
        &:after {
          position: absolute;
          bottom: 70px;
          right: -14px;
          content: "";
          width: 0;
          height: 0;
          border-right: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-left: 7px solid #93c8e4;
          border-top: 7px solid transparent;
        }

        &:after {
          right: -13px;
          border-left: 10px solid rgba(0, 0, 0, 0.8);
        }
      }
    }
    .bottom-bar {
      height: 60px;
      background: rgba(25, 50, 70);
      display: flex;
      align-items: center;
      padding-left: 26px;
      color: white;
      /deep/.el-switch__label {
        color: white;
      }
      .switch-style {
        margin-right: 8px;
      }
    }
    .y-scroll {
      overflow-x: auto;

      &::-webkit-scrollbar {
        width: 2px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #d1d7dc;
      }
    }
  }

  .ranging-box {
    position: absolute;
    bottom: 0;
    right: 130px;
    display: flex;

    .item {
      background: rgba(0, 0, 0, 0.8);
      border-radius: 4px;
      overflow: hidden;
      margin-right: 10px;
      font-size: 16px;
      font-family: PingFang SC-Light, PingFang SC;
      font-weight: 300;
      color: #ffffff;
      padding: 10px;
      text-align: center;
      width: 80px;
      &:active {
        color: #40e9f2;
      }
    }
    .is-active {
      color: #40e9f2;
    }
  }
}
</style>
