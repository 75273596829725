<template>
  <section class="suspicious-target_container">
    <!-- 创建目标弹窗 -->
    <transition name="fade_right">
      <section class="lock-target_list" v-if="isTargetShow">
        <h1 class="title">
          可疑目标
          <i class="el-icon-close pointer" @click="closeShow"></i>
        </h1>
        <div class="content">
          <div class="item-content">
            <label for="">名称</label>
            <!-- <el-input
              class="input-name"
              v-model="targetName"
              placeholder="请输入可疑目标"
            ></el-input> -->

            <input
              class="input-name"
              placeholder="请输入可疑目标"
              v-model="targetName"
              type="text"
            />
          </div>

          <div class="item-content">
            <div class="item-left">
              <label for="">人员</label>
            </div>
            <ul class="personnel-list">
              <li
                class="personnel-item"
                v-for="(item, index) in memberList"
                :key="index"
              >
                <i
                  class="el-icon-remove-outline pointer"
                  @click="delMember(index)"
                ></i>
                <span>{{ item.memberName }}</span>

                <span></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer">
          <div class="btn-save pointer" @click="save">确定</div>
        </div>
      </section>
    </transition>
  </section>
</template>

<script>
import { getCurrentActionList } from "@/api/executeAction";
import { missionUpdate, notify, newJobNotify } from "@/api/dji";
import { debounce } from "@/utils/index.js";
import { getTaskId, addTask } from "@/api/task";
export default {
  data() {
    return {
      isTarget: false, // 是否锁定可疑目标
      selectId: NaN, // 选中的目标id
      actionName: "", //行动名称
      targetName: "", //可疑目标名称
      memberList: [], //行动人员列表
      isTargetShow: false, // 用于判断是否展开可疑目标列表
      timer: null, //定时器
      //目标经纬
      targetLng: "",
      targetLat: "",
      //飞机经纬
      planeLng: "",
      planeLat: "",
    };
  },
  computed: {
    // 资源列表
    resourceList() {
      return this.$store.getters.getAllResList;
    },
    // 获取行动状态
    isActionStarted() {
      return this.$store.getters.isActionStarted;
    },
  },
  created() {
    // 锁定可疑目标
    this.$EventBus.$on("targetShow", (data) => {
      if (!this.isTarget) return;
      this.enableBuf(data);
    });

    this.$EventBus.$on("selectRes", (newMember) => {
      const isAlreadyExist = this.memberList.some((member) => {
        return member.memberId === newMember.memberId;
      });

      if (isAlreadyExist) {
        // 如果新数据已经存在于 memberList 数组中，则不进行插入操作
      } else {
        // 如果新数据不存在于 memberList 数组中，则进行插入操作
        this.memberList.push(newMember);
      }
    });
    this.$EventBus.$on("trackTaskProgress", (status) => {
      this.openLockTarget(status);
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("targetShow");
    this.$EventBus.$off("selectRes");
    this.$EventBus.$off("trackTaskProgress");
    this.$store.dispatch("map/setIsActionStarted", 0);
    zr3d.layerRemove({
      name: this.selectId,
    });
  },
  methods: {
    async getActionList() {
      const res = await getCurrentActionList();
      if (res) {
        this.actionList = res.data;
      }
    },

    closeShow() {
      this.$EventBus.$emit("closeProjectMark");
      this.timer && clearTimeout(this.timer);
      this.isTarget = false;
      this.initActionData(); // 初始化创建行动的相关数据
      this.isTargetShow = false;
      zr3d.layerRemove({
        name: this.selectId,
      });
    },

    save: debounce(function () {
      // 创建一个校验函数,用于校验目标名称和人员列表是否为空
      const validate = () => {
        if (!this.targetName) {
          this.$message({
            message: "请输入可疑目标名称",
            type: "warning",
          });
          return false;
        }

        if (this.memberList.length === 0) {
          this.$message({
            message: "请选择行动人员",
            type: "warning",
          });
          return false;
        }
        return true;
      };

      if (!validate()) return false;
      //根据状态判断去执行新增和编辑方法

      this.addTaskList();
    }, 300),
    // 循环this.memberList,根据人数去调用接口，等待所有接口返回成功后给与后续操作
    addTaskList() {
      // 定义一个用于存储所有请求 Promise 的数组
      const requestPromises = [];

      // 遍历数据数组，发起请求并将返回的 Promise 存入数组
      this.memberList.forEach((data) => {
        requestPromises.push(this.TaskSchematicAdd(data));
      });
      Promise.all(requestPromises)
        .then((responses) => {
          // 所有请求成功返回，执行成功提示
          this.$message({
            message: "行动创建成功",
            type: "success",
          });
          this.initActionData();
        })
        .catch((error) => {
          // 某个请求失败，执行失败提示
          console.error("请求失败：", error);
        });
    },

    // 添加任务
    async TaskSchematicAdd(item) {
      const { memberId, resourceType } = item;
      const taskId = await getTaskId();
      let newTaskId = null;

      if (taskId) {
        newTaskId = taskId.data;
      }

      const marKData = [
        {
          name: "飞机位置",
          resource: {
            type: 0,
            content: {
              type: "Feature",
              properties: {
                name: "飞机位置",
                clampToGround: true,
                color: "#F10909",
                pointScale: 1.5,
                pointSize: 20,
              },
              geometry: {
                type: "Point",
                coordinates: [this.planeLng, this.planeLat],
              },
            },
          },
        },
        {
          name: "目标位置",
          resource: {
            type: 0,
            content: {
              type: "Feature",
              properties: {
                name: "目标位置",
                clampToGround: true,
                color: "#F10909",
                pointScale: 1.5,
                pointSize: 20,
              },
              geometry: {
                type: "Point",
                coordinates: [this.targetLng, this.targetLat],
              },
            },
          },
        },
      ];
      let data = {
        executor: memberId, //任务执行者，机库id或飞手id或地巡人员id
        jobCategory: 2, //任务分类，1:标准任务；2:示意性任务
        jobAnnotation: JSON.stringify(marKData), //航线json数据
        jobType: 2, //任务类型，1:立即执行；2：定时执行；3：重复执行
        name: this.actionName, //任务名称
        id: newTaskId, //任务id
        jobExecType: resourceType, // 资源类型
        pictureUrl: this.pictureUrl, //
        targetType: 2, //任务目标类型：1：标注任务；2：追踪任务
        deviceId: this.selectId.slice(6),
      };

      const res = await addTask(data);
      if (res) {
        // 消息的派发
        this.taskIssue([memberId]);
        this.notify(memberId);
        this.newMark(memberId);
      }
    },
    async taskIssue(list) {
      const res = await missionUpdate(list);
    },
    async newMark(id) {
      const res = await newJobNotify({ resourceId: id });
    },
    // 下发任务
    async notify(id) {
      const res = await notify({ resourceId: id });
    },
    // 初始化创建行动的相关数据
    initActionData() {
      this.actionId = "";
      this.actionName = "";
      this.targetName = "";
      this.memberList = [];

      this.isTargetShow = false;
      this.isTarget = false;
      if (this.isActionStarted == 1)
        this.$store.dispatch("map/setIsActionStarted", 0);

      this.timer && clearTimeout(this.timer);
      zr3d.layerRemove({
        name: this.selectId,
      });

      this.selectId = NaN;
    },
    delMember(index) {
      this.memberList.splice(index, 1);
    },

    //开启缓冲区
    enableBuf(data) {
      const { id, text, lat, lng, subLat, subLng } = data;

      this.targetLng = subLng;
      this.targetLat = subLat;
      this.planeLng = lng;
      this.planeLat = lat;

      if (this.selectId === id) return false; // 如果选中的目标id和当前的id相同，不做任何操作
      // 如果选中的目标id和当前的id不相同，关闭上一个目标的缓冲区
      this.timer && clearTimeout(this.timer);
      zr3d.layerRemove({
        name: this.selectId,
      });
      this.isTargetShow = true; // 显示目标弹窗
      this.targetName = text; // 目标名称
      this.actionName = text; // 行动名称
      this.selectId = id; // 选中的目标id
      this.$store.dispatch("map/setIsActionStarted", 1); // 设置状态为创建行动状态

      // 通过id去匹配资源列表
      // 构建一个targetId来接受id截取'target'后的值
      const targetId = id.slice(6);

      // 使用定时器周期性的更新缓冲区
      this.timer = setInterval(() => {
        this.updateBuf(targetId, id);
      }, 2000);
    },
    // 周期性的更新缓冲区
    updateBuf(targetId, id) {
      const target = this.resourceList.find((item) => item.id === targetId);
      zr3d.layerRemove({
        name: this.selectId,
      });

      zr3d.layerAdd({
        name: id,
      }); // 添加图层
      const { targetLng, targetLat } = target;

      zr3d.primitiveAddCircle({
        id: id,
        center: { x: targetLng, y: targetLat, z: 10 },
        radius: 1000,
        layer: id,
        clampToGround: true,
        mode: 2,
        lineWidth: 2, //线宽 默认1像素
        color: "#f63e3e", //颜色 只支持#33333333格式，分别为红绿蓝透明度，默认#FFFFFFFF
        brush: "#f63e3e20", //填充 只支持#33333333格式，分别为红绿蓝透明度，默认#FFFFFFFF
      }); // 添加圆形

      zr3d.primitiveAddCircle({
        id: id + `1`,
        center: { x: targetLng, y: targetLat, z: 10 },
        radius: 2000,
        layer: id,
        clampToGround: true,
        mode: 2,
        lineWidth: 2, //线宽 默认1像素
        color: "#FF0000", //颜色 只支持#33333333格式，分别为红绿蓝透明度，默认#FFFFFFFF
        brush: "#FF000028", //填充 只支持#33333333格式，分别为红绿蓝透明度，默认#FFFFFFFF
      }); //  添加圆形
    },
    // 开启or关闭目标锁定功能
    openLockTarget(status) {
      this.isTarget = status;
      if (status) {
        this.$message({
          message: "请选择目标",
          type: "success",
        });
      } else {
        this.initActionData(); // 初始化创建行动的相关数据
      }
    },
  },
};
</script>

<style lang="less" scoped>
.suspicious-target_container {
  position: absolute;
  top: 212px;
  right: 30px;
  width: 64px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  .tip {
    font-size: 10px;
  }
  .lock-target {
    width: 64px;
    height: 67px;
    background: var(--page-background-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: white;
    border: 1px solid #93c8e4;
    flex-direction: column;
  }

  .transfer {
    margin-top: 10px;
    width: 64px;
    height: 67px;
    background: var(--page-background-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: white;
    border: 1px solid #93c8e4;
    flex-direction: column;
    border-radius: 6px 6px 6px 6px;
  }
  .is-active {
    color: #40e9f2 !important;
  }
  .lock-target_list {
    width: 294px;
    background: var(--page-background-color);
    border-radius: 4px;
    position: absolute;
    top: -142px;
    left: -330px;

    .title {
      height: 46px;
      color: white;
      font-size: 18px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      margin-top: 15px;
      // background-color: var(--btn-hover-color);
    }
    .content {
      padding-top: 16px;
      .item-content {
        display: flex;
        margin-bottom: 16px;
        label {
          width: 62px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #ffffff;
        }
        .input-name {
          width: 210px;
          height: 34px;
          border-radius: 2px;
          border: 1px solid #ffffff;
          outline: none;
          text-indent: 8px;
          color: white;
          background-color: var(--command-info-input-bg-color);
          &::placeholder {
            color: white;
          }
        }

        .item-left {
          width: 62px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #ffffff;
          height: 100%;
        }
        .personnel-list {
          margin-right: 20px;
          flex: 1;
          overflow-y: auto;
          max-height: 200px;
          .personnel-item {
            border-bottom: 1px solid white;
            padding-bottom: 10px;
            height: 22px;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            box-sizing: content-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            .el-icon-remove-outline {
              font-size: 20px;
              color: #ef6767;
            }
            .icon-selected {
              color: #165dff;
              background: white;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .footer {
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-save {
        width: 154px;
        height: 32px;
        background: var(--btn-active-color);
        color: #ffffff;
        border-radius: 2px;
        text-align: center;
        line-height: 32px;
        &:hover {
          background-color: var(--command-info-btn-hover-color);
        }
        &:active {
          background-color: var(--command-info-btn-active-color);
        }
      }
    }
  }
  .transfer-team_members {
    width: 294px;
    background: rgba(0, 0, 0, 0.79);
    border-radius: 4px;
    opacity: 0.9;
    border: 1px solid #93c8e4;
    position: absolute;
    top: -142px;
    left: -304px;
    height: 246px;
  }
}
</style>