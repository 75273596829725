var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"video-surveillance_container"},[(_vm.currentComponent == 1)?_c('ul',{staticClass:"video-surveillance_list"},_vm._l((_vm.playerList),function(item,index){return _c('li',{directives:[{name:"resize",rawName:"v-resize",value:(($event) => _vm.resize($event, item)),expression:"($event) => resize($event, item)"}],key:index,staticClass:"video-surveillance_item",class:{
        'video-surveillance_item--disabled':
          item.id !== _vm.selectId && _vm.isDragging,
      },style:({
        left: `${item.left}px`,
        top: `${item.top}px`,
      }),attrs:{"id":'video-player' + item.id},on:{"mousedown":function($event){return _vm.startDrag(item, $event)},"mousemove":function($event){return _vm.drag(item, $event)},"mouseup":function($event){return _vm.stopDrag()}}},[(
          item.playerType === 'agora' &&
          item.showStatus &&
          item.resourceType != 1
        )?_c('liveStatus',{attrs:{"id":item.id}}):_vm._e(),_c('livePlayer',{attrs:{"liveOptions":{
          playerType: item.playerType,
          liveUrl: item.livePlayUrl || '',
          playerId: `tcPlayer${item.id}`,
          deviceSn: item.subDeviceSn || '',
          cameraType: 1,
        },"name":item.name}}),_c('el-tooltip',{attrs:{"effect":"dark","placement":"bottom"}},[_c('i',{staticClass:"iconfont icon-delete pointer",on:{"click":function($event){$event.stopPropagation();return _vm.closeVideoSurveillance(item, index)}}}),_c('div',{staticClass:"tip-text",attrs:{"slot":"content"},slot:"content"},[_c('p',[_vm._v("关闭直播")])])]),_c('p',{staticClass:"video-surveillance_name"},[_vm._v(_vm._s(item.name))])],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }