import Moment from "moment";
import { getJob2Id } from "@/api/task";
// 航线任务数据相关
const state = {
    // 任务信息
    taskInfo: {
        taskName: '', //任务名称
        projectId: '', //项目id
        executor: '', // 执行者|资源id
        nickName: '', // 执行人名称
        jobExecType: NaN, //资源类型
        airlineType: '', // 航线类型waypoint：航点飞行；mapping2d：建图航拍；mapping3d：倾斜摄影；mappingStrip：航带飞行
        puzzleSwitch: 1, //拼图开关 1：关闭 2：开启
    },
    // 任务预测结果
    statisticsInfo: {
        routeLength: 0,//航线长度
        waypointCount: 0, //航点数量
        photos: 0, // 照片数量
        pictureResolution: 0, //图片分辨率
        flightTime: 0, //飞行时间
    },
    //航线信息
    lineInfo: {
        speed: 10, // 飞行速度
        altitude: 100, //飞行高度
        lineWidth: 50,// 航带宽度
        overlapNum: 80, //航向重叠率
        sidelapNum: 70, //旁向重叠度
        courseAngle: 0, //航向角度
        waypointHeadingMode: 'followWayline', //飞行器偏航角模式
        globalWaypointTurnMode: 'toPointAndStopWithDiscontinuityCurvature', //全局航点类型（全局航点转弯模式）
        finishAction: 'goHome', //完成动作
        exitOnRCLost: 'executeLostAction', //航线飞行中失控
        executeRCLostAction: 'goBack', // 失控动作类型
        centerPointList: [], // 航线中心点，用于航带和面状航线
        localRouteData: [], //本地航线数据
        localRouteDataOid: -1, //选中航点索引
        flyHomePosition: {}, // 返航点
        selectedActionNodeIndex: -1, // 选中动作节点索引
    },
    // 配置信息
    loadSet: {
        droneModelKey: '', //航线文件飞机参数
        payloadModelKeys: '', //航线文件负载参数
        localDroneModelKey: {}, //航线文件飞机本地对象
        localPayloadModel: {}, //本地航线文件负载参数
        wideAnglePhotographs: true, //广角
        zoomPictures: false, //变焦
        infraredPhotographs: false, //红外
        shootCd: 0, //拍摄间隔
    },
    // 策略信息
    strategyInfo: {
        jobType: 1, //任务类型，1:立即执行；2：定时执行；3：重复执行  
        jobTime: '', //执行时间
        executionPeriod: [], //本地时间
        startTime: [''], //重复定时任务的执行时间，如：08:00:00
        frequency: 1, //执行频率
        startDate: '', //重复任务 开始日期
        endDate: '',//重复任务 结束日期
    },
    userInputting: false, //用户输入状态
}
const mutations = {
    // userInputting
    SET_USER_INPUTTING: (state, userInputting) => {
        state.userInputting = userInputting
    },
    // 写入任务信息
    SET_TASK_INFO: (state, taskInfo) => {

        const { taskName, projectId, executor, nickName, jobExecType, airlineType, puzzleSwitch } = taskInfo

        if (taskName || taskName == '') state.taskInfo.taskName = taskName
        if (projectId || projectId == '') state.taskInfo.projectId = projectId
        if (executor || executor == '') state.taskInfo.executor = executor
        if (nickName || nickName == '') state.taskInfo.nickName = nickName
        if (jobExecType || typeof jobExecType == 'number') state.taskInfo.jobExecType = jobExecType
        if (airlineType || airlineType == '') state.taskInfo.airlineType = airlineType
        if (puzzleSwitch || typeof puzzleSwitch == 'number') state.taskInfo.puzzleSwitch = puzzleSwitch

    },
    // 写入配置信息
    SET_LOAD_SET: (state, loadSet) => {

        const { droneModelKey, payloadModelKeys, localDroneModelKey, localPayloadModel, wideAnglePhotographs, zoomPictures, shootCd, infraredPhotographs } = loadSet;

        if (droneModelKey || droneModelKey == '') state.loadSet.droneModelKey = droneModelKey;
        if (payloadModelKeys || payloadModelKeys == '') state.loadSet.payloadModelKeys = payloadModelKeys;
        if (localDroneModelKey) state.loadSet.localDroneModelKey = localDroneModelKey;
        if (localPayloadModel) state.loadSet.localPayloadModel = localPayloadModel;
        if (typeof wideAnglePhotographs === 'boolean') state.loadSet.wideAnglePhotographs = wideAnglePhotographs;
        if (typeof zoomPictures === 'boolean') state.loadSet.zoomPictures = zoomPictures;
        if (typeof infraredPhotographs === 'boolean') state.loadSet.infraredPhotographs = infraredPhotographs;
        if (shootCd || shootCd == 0) state.loadSet.shootCd = shootCd;
    },
    // 航线详细信息
    SET_LINE_INFO: (state, lineInfo) => {
        const { speed, altitude, lineWidth, overlapNum, sidelapNum, courseAngle, waypointHeadingMode, globalWaypointTurnMode, finishAction, exitOnRCLost,
            executeRCLostAction, centerPointList, localRouteData, localRouteDataOid, flyHomePosition, selectedActionNodeIndex } = lineInfo

        if (altitude) state.lineInfo.altitude = altitude;
        if (speed) state.lineInfo.speed = speed;
        if (lineWidth) state.lineInfo.lineWidth = lineWidth;
        if (overlapNum) state.lineInfo.overlapNum = overlapNum;
        if (sidelapNum) state.lineInfo.sidelapNum = sidelapNum;
        if (courseAngle || courseAngle == 0) state.lineInfo.courseAngle = courseAngle;
        if (waypointHeadingMode) state.lineInfo.waypointHeadingMode = waypointHeadingMode;
        if (globalWaypointTurnMode) state.lineInfo.globalWaypointTurnMode = globalWaypointTurnMode;
        if (finishAction) state.lineInfo.finishAction = finishAction;
        if (exitOnRCLost) state.lineInfo.exitOnRCLost = exitOnRCLost;
        if (executeRCLostAction) state.lineInfo.executeRCLostAction = executeRCLostAction;
        if (centerPointList) state.lineInfo.centerPointList = centerPointList;
        if (localRouteData) state.lineInfo.localRouteData = localRouteData;
        if (localRouteDataOid || localRouteDataOid == 0) state.lineInfo.localRouteDataOid = localRouteDataOid;
        if (flyHomePosition) state.lineInfo.flyHomePosition = flyHomePosition;
        if (typeof selectedActionNodeIndex === 'number') state.lineInfo.selectedActionNodeIndex = selectedActionNodeIndex;
    },
    // 策略相关
    SET_STRATEGY_INFO: (state, strategyInfo) => {
        const { jobType, jobTime, executionPeriod, frequency } = strategyInfo


        if (jobType) state.strategyInfo.jobType = jobType;
        if (jobTime || jobTime == '') state.strategyInfo.jobTime = jobTime;
        if (executionPeriod && executionPeriod.length == 0) {
            state.strategyInfo.startDate = '';
            state.strategyInfo.endDate = '';
            state.strategyInfo.executionPeriod = [];
            state.strategyInfo.startTime = [''];
        } else if (executionPeriod && executionPeriod.length == 2) {
            state.strategyInfo.startDate = Moment(executionPeriod[0]).format("YYYY-MM-DD");
            state.strategyInfo.endDate = Moment(executionPeriod[1]).format("YYYY-MM-DD");
            state.strategyInfo.executionPeriod = executionPeriod;
        }
        if (frequency) state.strategyInfo.frequency = frequency;
    },
    // 策略时间>添加
    SET_START_TIME_ADD: (state) => {
        state.strategyInfo.startTime.push('')
    },
    // 策略时间>删除
    SET_START_TIME_DEL: (state, index) => {
        state.strategyInfo.startTime.splice(index, 1)
    },
    // 策略时间>更新
    SET_START_TIME_UP: (state, info) => {
        state.strategyInfo.startTime.splice(info.index, 1, info.newDate)
    },
    // statisticsInfo
    SET_STATISTICS_INFO: (state, info) => {

        const { routeLength, waypointCount, photos, pictureResolution, flightTime } = info

        if (routeLength || routeLength == 0) state.statisticsInfo.routeLength = routeLength;
        if (photos || photos == 0) state.statisticsInfo.photos = photos;
        if (waypointCount || waypointCount == 0) state.statisticsInfo.waypointCount = waypointCount;
        if (pictureResolution || pictureResolution == 0) state.statisticsInfo.pictureResolution = pictureResolution;
        if (flightTime || flightTime == 0) state.statisticsInfo.flightTime = flightTime;

    },
    // 航点内部信息变更
    SET_LOCAL_ROUTE_DATA_CHANGE: (state, newDate) => {
        const localRouteDataOid = state.lineInfo.localRouteDataOid;
        let { height, lng, lat, waypointHeadingAngle } = newDate;
        if (height) state.lineInfo.localRouteData[localRouteDataOid].height = Number(height.toFixed(0));
        if (lng) state.lineInfo.localRouteData[localRouteDataOid].lng = lng;
        if (lat) state.lineInfo.localRouteData[localRouteDataOid].lat = lat;
        if (waypointHeadingAngle || waypointHeadingAngle == 0) state.lineInfo.localRouteData[localRouteDataOid].waypointHeadingAngle = waypointHeadingAngle;
        if (typeof selectedActionNodeIndex === 'number') state.lineInfo.localRouteData[localRouteDataOid].selectedActionNodeIndex = selectedActionNodeIndex;
    },
    // insert
    SET_INSERT_LOCAL_ROUTE_DATA: (state, info) => {
        console.log(info);
        const { index, newDate } = info
        state.lineInfo.localRouteData.splice(index, 0, newDate) // 插入

    },
    SET_DEL_LOCAL_ROUTE_DATA: (state, index) => {
        state.lineInfo.localRouteData.splice(index, 1);
        state.lineInfo.localRouteDataOid = state.lineInfo.localRouteData.length - 1;
    },
    SET_LOCAL_ROUTE_DATA_OID: (state, localRouteDataOid) => {
        state.lineInfo.localRouteDataOid = localRouteDataOid
    },
    // 替换
    SET_UP_ACTIONE: (state, info) => {
        const { index, newDate } = info
        state.lineInfo.localRouteData[state.lineInfo.localRouteDataOid].actionList.splice(index, 1, newDate)
    },
    // 删除动作
    SET_DEL_ACTIONE: (state, index) => {
        state.lineInfo.localRouteData[state.lineInfo.localRouteDataOid].actionList.splice(index, 1)
    },
    // 添加动作
    SET_ADD_ACTIONE: (state, newAction) => {
        state.lineInfo.localRouteData[state.lineInfo.localRouteDataOid].actionList.push(newAction)
    },
    // 修改速度
    SET_SPEED: (state, speed) => {
        state.lineInfo.speed = speed
    },
}
const actions = {
    // SET_USER_INPUTTING
    setUserInputting({ commit }, userInputting) {
        commit('SET_USER_INPUTTING', userInputting)
    },
    // 添加任务
    addTaskInfo({ dispatch, commit }, info) {

        return new Promise((resolve) => {
            dispatch("clearStatisticsInfo");
            dispatch("clearLoadSet");
            dispatch("clearStrategyInfo");
            commit('SET_TASK_INFO', info);
            resolve()
        })
    },
    // 编辑任务
    editTaskInfo({ commit }, info) {
        return new Promise((resolve) => {
            commit('SET_TASK_INFO', info);
            resolve()
        })
    },
    // 初始化任务信息
    clearTaskInfo({ commit }) {
        const info = {
            taskName: '', //任务名称
            projectId: '', //项目id
            executor: '', // 执行者|资源id
            nickName: '', // 执行人名称
            jobExecType: NaN, //资源类型
            airlineType: '', // 航线类型waypoint：航点飞行；mapping2d：建图航拍；mapping3d：倾斜摄影；mappingStrip：航带飞行
            puzzleSwitch: 1, //拼图开关
        }
        commit('SET_TASK_INFO', info);
    },
    // 初始化任务预测结果
    clearStatisticsInfo({ commit }) {

        let info = {
            routeLength: 0,//航线长度
            waypointCount: 0, //航点数量
            photos: 0, // 照片数量
            pictureResolution: 0,
        }

        commit('SET_STATISTICS_INFO', info)
    },
    // 初始化航线相关信息
    clearLineInfo({ commit }) {
        const info = {
            speed: 10, // 飞行速度
            altitude: 100, //飞行高度
            lineWidth: 50,// 航带宽度
            overlapNum: 80, //航向重叠率
            sidelapNum: 70, //旁向重叠度
            courseAngle: 0, //航向角度
            waypointHeadingMode: 'followWayline', //飞行器偏航角模式
            globalWaypointTurnMode: 'toPointAndStopWithDiscontinuityCurvature', //航点类型
            finishAction: 'goHome', //完成动作
            exitOnRCLost: 'executeLostAction', //航线飞行中失控
            executeRCLostAction: 'goBack', // 失控动作类型
            centerPointList: [], // 航线中心点，用于航带和面状航线
            localRouteData: [], //本地航线数据
            localRouteDataOid: -1, //选中航点索引
            selectedActionNodeIndex: -1, // 选中动作节点索引

        }
        commit('SET_LINE_INFO', info);
    },
    // 初始化配置信息
    clearLoadSet({ commit }) {
        const info = {
            droneModelKey: '', //航线文件飞机参数
            payloadModelKeys: '', //航线文件负载参数
            localDroneModelKey: {}, //航线文件飞机本地对象
            localPayloadModel: {}, //本地航线文件负载参数
            wideAnglePhotographs: true, //广角
            infraredPhotographs: false, //红外
            zoomPictures: false, //变焦
            shootCd: 0, //拍摄间隔
        }
        // commit('')
        commit('SET_LOAD_SET', info);
    },
    // 初始化策略信息
    clearStrategyInfo({ commit }) {
        let info = {
            jobType: 1, //任务类型，1:立即执行；2：定时执行；3：重复执行  
            jobTime: '', //执行时间
            executionPeriod: [], //本地时间
            startTime: [''], //重复定时任务的执行时间，如：08:00:00
            frequency: 1, //执行频率
            startDate: '', //重复任务 开始日期
            endDate: '',//重复任务 结束日期
        }
        commit('SET_STRATEGY_INFO', info)
    },
    // 清除所有航线任务相关信息
    clearAllInfo({ dispatch }) {
        return new Promise((resolve) => {
            dispatch('clearTaskInfo') // 初始化任务信息
            dispatch('clearStatisticsInfo') //初始化任务预测结果
            dispatch('clearLineInfo')
            dispatch('clearLoadSet')
            dispatch('clearStrategyInfo')
            dispatch('setUserInputting', false)
            resolve()
        })
    },
    //仅显示航线
    displayAirLine({ commit }, info) {
        const { name, nickName, jobData } = info

        return new Promise((resolve) => {

            let taskInfo = {
                taskName: name,
                airlineType: jobData.templateTypes || jobData.airlineType,
                nickName
            }

            let statisticsInfo = {
                photos: jobData.photos,
                routeLength: jobData.routeLength,
                waypointCount: jobData.waypointCount,
                flightTime: jobData.flightTime,
            }

            let lineInfo = {
                speed: jobData.taskConfig.speed, // 飞行速度
                altitude: jobData.taskConfig.altitude, //飞行高度
                waypointHeadingMode: jobData.taskConfig.waypointHeadingMode, //飞行器偏航角模式
                globalWaypointTurnMode: jobData.taskConfig.globalWaypointTurnMode, //航点类型
                finishAction: jobData.taskConfig.finishAction,//完成动作
                exitOnRCLost: jobData.taskConfig.exitOnRCLost, //航线飞行中失控
                executeRCLostAction: jobData.taskConfig.executeRCLostAction, // 失控动作类型
                centerPointList: jobData.taskConfig.centerPointList, // 航线中心点，用于航带和面状航线
                localRouteData: jobData.taskCoordinate, //本地航线数据
                flyHomePosition: jobData.taskConfig.flyHomePosition
            }

            let loadSet = {
                localPayloadModel: jobData.localPayloadModel //本地航线文件负载参数
            }

            if (taskInfo.airlineType !== "waypoint") {
                lineInfo.overlapNum = jobData.taskConfig.overlapNum;
                lineInfo.sidelapNum = jobData.taskConfig.sidelapNum;
            }


            if (taskInfo.airlineType === "mappingStrip") {
                lineInfo.lineWidth = jobData.taskConfig.lineWidth;
            }
            if (taskInfo.airlineType === "mapping2d") {
                lineInfo.courseAngle = jobData.taskConfig.courseAngle;
            }

            commit('SET_TASK_INFO', taskInfo);
            commit('SET_STATISTICS_INFO', statisticsInfo);
            commit('SET_LINE_INFO', lineInfo)
            commit('SET_LOAD_SET', loadSet)
            resolve();
        })
    },
    // 编辑航线
    editAirLine({ commit }, taskId) {
        return new Promise((resolve, reject) => {
            getJob2Id({ id: taskId }).then(res => {
                const { data } = res

                const { name, projectId, nickName, jobExecType, jobData, jobExecutorId, jobType, jobTime, startDate, endDate, startTime, frequency,puzzleSwitch } = data
                const { taskConfig, taskCoordinate, templateTypes, airlineType, routeLength, waypointCount, photos,
                    pictureResolution, droneModelKey, payloadModelKeys, localDroneModelKey, localPayloadModel,
                    wideAnglePhotographs, zoomPictures, infraredPhotographs, flightTime } = jobData
                const { speed, altitude, waypointHeadingMode, globalWaypointTurnMode, finishAction, exitOnRCLost, executeRCLostAction,
                    centerPointList, overlapNum, sidelapNum, shootCd, lineWidth, courseAngle, flyHomePosition } = taskConfig

                let taskInfo = {
                    taskName: name, //任务名称
                    projectId: projectId, //项目id
                    executor: jobExecutorId, // 执行者|资源id
                    nickName: nickName, // 执行人名称
                    jobExecType: jobExecType, //资源类型
                    airlineType: templateTypes || airlineType, // 航线类型waypoint：航点飞行；mapping2d：建图航拍；mapping3d：倾斜摄影；mappingStrip：航带飞行
                    puzzleSwitch: puzzleSwitch, //拼图开关
                }

                let statisticsInfo = {
                    routeLength: routeLength,//航线长度
                    waypointCount: waypointCount, //航点数量
                    photos: photos, // 照片数量
                    pictureResolution: pictureResolution, //图片分辨率
                    flightTime: flightTime, //飞行时间
                }

                let lineInfo = {
                    speed: speed, // 飞行速度
                    altitude: altitude, //飞行高度
                    waypointHeadingMode: waypointHeadingMode, //飞行器偏航角模式
                    globalWaypointTurnMode: globalWaypointTurnMode, //航点类型
                    finishAction: finishAction, //完成动作
                    exitOnRCLost: exitOnRCLost, //航线飞行中失控
                    executeRCLostAction: executeRCLostAction, // 失控动作类型
                    centerPointList: centerPointList, // 航线中心点，用于航带和面状航线
                    localRouteData: taskCoordinate, //本地航线数据
                    localRouteDataOid: -1, //选中航点索引
                    selectedActionNodeIndex: -1, // 选中动作节点索引
                    flyHomePosition: flyHomePosition
                }

                let loadSet = {
                    droneModelKey: droneModelKey, //航线文件飞机参数
                    payloadModelKeys: payloadModelKeys, //航线文件负载参数
                    localDroneModelKey: localDroneModelKey, //航线文件飞机本地对象
                    localPayloadModel: localPayloadModel, //本地航线文件负载参数
                    wideAnglePhotographs: wideAnglePhotographs, //广角
                    infraredPhotographs: infraredPhotographs, //红外
                    zoomPictures: zoomPictures, //变焦
                }

                let strategyInfo = {
                    jobType: jobType, //任务类型，1:立即执行；2：定时执行；3：重复执行  
                }
                if (templateTypes === 'waypoint' || airlineType === 'waypoint') {
                    lineInfo.localRouteDataOid = taskCoordinate.length - 1;
                    lineInfo.selectedActionNodeIndex = taskCoordinate[taskCoordinate.length - 1].actionList.length - 1;
                }
                if (taskInfo.airlineType !== "waypoint") {
                    lineInfo.overlapNum = overlapNum;
                    lineInfo.sidelapNum = sidelapNum;
                    loadSet.shootCd = shootCd; //拍摄时间间隔
                }

                if (taskInfo.airlineType === "mappingStrip") {
                    lineInfo.lineWidth = lineWidth;
                }
                if (taskInfo.airlineType === "mapping2d") {
                    lineInfo.courseAngle = courseAngle;
                }


                switch (strategyInfo.jobType) {
                    case '1':
                        // 时间问题
                        break;
                    case '2':

                        strategyInfo.jobTime = jobTime;
                        break;
                    case '3':
                        strategyInfo.startDate = startDate;
                        strategyInfo.endDate = endDate;
                        strategyInfo.startTime = startTime;
                        strategyInfo.frequency = frequency;
                        break;
                }

                commit('SET_TASK_INFO', taskInfo);
                commit('SET_STATISTICS_INFO', statisticsInfo)
                commit('SET_LINE_INFO', lineInfo)
                commit('SET_LOAD_SET', loadSet)
                commit('SET_STRATEGY_INFO', strategyInfo)
                resolve();
            }).catch(error => {
                reject(error)
            })
        })
    },
    //单独修改速度
    editSpeed({ commit }, speed) {
        commit('SET_SPEED', speed)
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}