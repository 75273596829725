import { resourceList } from "@/api/resources.js"
// 引入store
import store from '@/store/index.js'

const state = {
    resList: [], //资源列表
    resId: -1, // 锁定id
    //当前展示的组件
    currentComponent: 1,
}

const mutations = {
    //参数由外部传入，内部只做请求处理，
    // 资源列表
    SET_RES_LIST: (state, resList) => {
        state.resList = resList
    },
    // resId
    SET_RES_ID: (state, resId) => {
        state.resId = resId
    },
    // 当前展示的组件
    SET_CURRENT_COMPONENT: (state, currentComponent) => {
        state.currentComponent = currentComponent
    }
}

const actions = {
    // 获取所有资源信息
    getAllResList({ commit }, listInfo) {

        return new Promise((resolve, reject) => {
            const { id } = store.state.user

            resourceList(listInfo).then(res => {
                // 判断res.data是否有数据
                if (res.data.length === 0) {
                    commit('SET_RES_LIST', []); //清空数据
                    resolve();
                    return;
                }
                let bufferList = [] //缓冲数组
                res.data.forEach(el => {
                    if (el.specialSkill) el.specialSkill = el.specialSkill.split(',');
                    // mock数据
                    if (el.lat < 1) {
                        el.lat = 23.14364735805969
                        el.lng = 113.3383865670139
                    }
                    // 开启缓冲区过滤数据
                    if (window.bufferTool) {
                        if (bufferTool.isPointInBuffer({ lng: el.lng, lat: el.lat })) {
                            bufferList.push(el)
                        }
                    }
                    if (id == '913606337476829184') {
                        // 进行数据过滤。从res.data中删除机场数据
                        // resourceType==1
                        if (el.resourceType == 1) {
                            res.data.splice(res.data.indexOf(el), 1);
                        }

                    }
                });
                window.bufferTool ? commit('SET_RES_LIST', bufferList) : commit('SET_RES_LIST', res.data);

                resolve(res); //返回数据
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 设置当前展示的组件
    setCurrentComponent({ commit }, currentComponent) {
        commit('SET_CURRENT_COMPONENT', currentComponent)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}