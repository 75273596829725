import request from '@/utils/request.js'
// 获取项目列表
export function list(data) {
    return request({
        url: '/api/manage/v1/project/list',
        method: 'post',
        data
    })
}
// 添加警种
export function add(data) {
    return request({
        url: '/api/manage/v1/project/add',
        method: 'post',
        data
    })
}
// 获取单个项目详情
export function getProjectInfo(params) {
    return request({
        url: '/api/manage/v1/project/get',
        method: 'get',
        params
    })
}
// 更新项目信息
export function update(data) {
    return request({
        url: '/api/manage/v1/project/update',
        method: 'post',
        data
    })
}

// 删除项目
export function delProject(data) {
    return request({
        url: '/api/manage/v1/project/delete',
        method: 'post',
        data
    })
}

/**
 * 资源的项目列表
 * @param { 机库、飞手或地巡的id } resourceId
 * @returns 
 */
export function getProjectList(params) {
    return request({
        url: "/api/manage/v1/resource/resourceListById",
        method: "get",
        params
    })
}