<template>
  <section class="draw-mark_container">
    <drawTool class="task-assignment"> </drawTool>
    <drawMark @success="addProjectSuccess" />
  </section>
</template>

<script>
import drawMark from "@/views/resourcesDetails/components/mark/index.vue";
import drawTool from "@/views/resourcesDetails/components/drawTool/index.vue";
export default {
  components: {
    drawMark,
    drawTool,
  },

  created() {
    this.$EventBus.$on("refreshMap", this.addProjectSuccess);
  },
  mounted() {
    this.$EventBus.$emit("getAllMark"); // 获取该项目的标注
  },
  beforeDestroy() {
    this.$EventBus.$emit("clearResDetMark"); // 移除项目标注
    this.$EventBus.$off("refreshMap", this.addProjectSuccess);
  },
  methods: {
    // 项目标注下发成功回调
    addProjectSuccess() {
      this.$EventBus.$emit("resetOpen"); // 获取该项目的标注
      this.$EventBus.$emit("clearResDetMark"); // 移除项目标注
      this.$nextTick(() => {
        this.$EventBus.$emit("getAllMark"); // 获取该项目的标注
      });
    },
  },
};
</script>

<style lang="less" scoped>
.draw-mark_container {
  position: absolute;
  right: 30px;
  top: 10px;
  z-index: 2;
  .task-assignment {
    margin-bottom: 0;
    // top: 0;
    top: 64px;
    right: 0;
  }
}
</style>