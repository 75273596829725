import gcoord from "gcoord";
export default {
    data() {
        return {
            oldShipTarget: []
        };
    },
    created() {

        this.$EventBus.$on("wusixiaodu_ship_target", (res) => {

            this.wusixiaoduDisplay(res);
        });

        window.wusixiaodu_ship_target_list = [];
        // window.wusixiaoduShipTargetList = null;

    },
    beforeDestroy() {
        this.$EventBus.$off("wusixiaodu_ship_target");
        this.wusixiaoduDestroyAll();
    },
    methods: {
        // 控制地图资源的显示
        wusixiaoduDisplay(res) {

            if (JSON.stringify(res) == JSON.stringify(this.oldShipTarget)) return false;

            // 1.找出新增or删除的数据
            let addList = res.filter(item => !this.oldShipTarget.find(item2 => item2.id == item.id));
            let delList = this.oldShipTarget.filter(item => !res.find(item2 => item2.id == item.id));
            // 2.找出状态变化的数据,其中任何一个值的改变都需要对数据进行处理

            let changesList = res.filter(item => {
                let oldItem = this.oldShipTarget.find(item2 => item2.id == item.id);
                if (!oldItem) return false;

                // if (item.resourceStatus !== oldItem.resourceStatus) {
                //     item.hasChanges = true;
                //     item.reason = "resourceStatus";
                //     return true;
                // }


                if (
                    item.lng !== oldItem.lng ||
                    item.lat !== oldItem.lat ||
                    item.head !== oldItem.head ||
                    item.status !== oldItem.status ||
                    item.speed !== oldItem.speed

                ) {
                    item.hasChanges = true;
                    item.reason = "other";
                    return true;
                }

                return false;
            });

            // 如果addList长度大于0，说明有新增的数据，需要对新增的数据进行处理
            if (addList.length > 0) {

                // 循环遍历addList，对新增的数据进行处理，渲染到地图上
                addList.forEach(item => {

                    this.wusixiaoduCreate(item); // 创建资源
                });
            }

            // 如果delList长度大于0，说明有删除的数据，需要对删除的数据进行处理
            if (delList.length > 0) {

                // 循环遍历delList，对删除的数据进行处理，清理地图上的资源图标
                delList.forEach(item => {
                    // this.destroyResource(item); // 销毁资源
                    this.wusixiaoduDestroy(item.id); // 销毁资源
                }
                );
            }
            // 如果statusList长度大于0，说明有状态变化的数据，需要对状态变化的数据进行处理
            if (changesList.length > 0) {

                changesList.forEach(item => {
                    if (item.hasChanges) {
                        if (item.reason === "resourceStatus") {

                        } else {

                            // this.updateResource(item); // 更新资源
                            this.wusixiaoduUpdate(item);
                        }
                    }
                });
            }

            // 进入页面限制执行一次
            this.oldShipTarget = res;

        },
        // 创建资源
        wusixiaoduCreate(item) {
            // 解构赋值
            let {
                id,
                lng,
                lat,
                head,
                status,
                speed,
                username
            } = item;
            // 0离线、1静止、2行驶
            // 构建一个text 根据status的值来判断显示的内容
            let text = "";
            if (status == 0) {
                // 把名称拼接上去并且换行
                text = `名称：${username}\n状态：离线`;
            } else if (status == 1) {
                text = `名称：${username}\n状态：静止`;
            } else if (status == 2) {
                text = `名称：${username}\n状态：行驶`;
            }

            if (lng < 1 || lat < 1) return false;

            var result = gcoord.transform(
                [lng, lat], // 经纬度坐标
                gcoord.BD09,
                gcoord.WGS84
            );

            try {

                let obj = zr3d.flyRealTraceCreate({
                    clampToGround: true, //是否贴地
                    text, //文字
                    topOffset: 30, // 文字距离顶部的距离
                    fontSize: 22, //字体大小
                    fontColor: '#ffffff', //字体颜色
                    type: 'wusixiaodu', //类型
                    resourceType: 'wusixiaodu_ship_target', //资源类型
                    useModel: true, //是否使用模型
                    showBackground: true, //是否显示文字背景
                    "background-color": '#1A1C20',// 背景色
                    showOutLine: true,
                    outlineColor: '#d90800',
                    //outlineColor: '#EF6767',
                    size: 20, //模型大小
                    padding: new Cesium.Cartesian2(20, 10), // 文字背景的padding
                    onScreen: true, //是否显示在屏幕上
                    farScale: .3,
                    farDis: 80000,
                    id,
                    lng: result[0],
                    lat: result[1],
                    lineColor: '#00ffff00',
                    lineWidth: 4,
                    flyColor: '#FF000050',
                    canvasLabel: true,
                    offsetHeading: 90,
                    // model: require('../../../public/Cesium/res/model/fly.glb'),
                    model: './Cesium/res/model/wusixiaodu_ship_target.glb'
                    // model:'../../assets/3d/wusixiaodu_ship_target.glb',
                });
                obj.id = id;
                obj.start();
                wusixiaodu_ship_target_list.push(obj);
                //更新位置
                obj.updatePosition({
                    x: result[0],
                    y: result[1],
                    z: 0,
                    heading: head < 180 ? Number(head) : Number(head) - 360,
                    speed: speed,
                    // offsetHeading: -90
                });

            } catch (error) {
                console.log('资源载入失败', error);
            }
        },
        // 销毁资源
        wusixiaoduDestroy(id) {
            if (wusixiaodu_ship_target_list.length == 0) return;

            for (let i = 0; i < wusixiaodu_ship_target_list.length; i++) {

                if (wusixiaodu_ship_target_list[i] && wusixiaodu_ship_target_list[i].id == id) {
                    wusixiaodu_ship_target_list[i].clear();

                    wusixiaodu_ship_target_list.splice(i, 1);
                }
            }
        },
        // 更新资源
        wusixiaoduUpdate(item) {
            let {
                id,
                lng,
                lat,
                head,
                speed,
                status,
                username
            } = item;

            if (lng < 1 || lat < 1) return false;

            var result = gcoord.transform(
                [lng, lat], // 经纬度坐标
                gcoord.BD09,
                gcoord.WGS84
            );

            let obj = wusixiaodu_ship_target_list.find((item) => item && item.id == id);

            if (obj) {
                // 如果状态发生变化，需要更新状态
                let text = "";
                if (status == 0) {
                    // 把名称拼接上去并且换行
                    text = `名称：${username}\n状态：离线`;
                } else if (status == 1) {
                    text = `名称：${username}\n状态：静止`;
                } else if (status == 2) {
                    text = `名称：${username}\n状态：行驶`;
                }
                obj.updateStyle({
                    text
                });

                obj.updatePosition({
                    x: result[0],
                    y: result[1],
                    z: 0,
                    //根据resourceType字段判断是纵横无人机还是大疆无人机
                    heading: head < 180 ? Number(head) : Number(head) - 360,
                    speed: speed,
                    text

                });

            }
        },
        // 销毁所有的资源
        wusixiaoduDestroyAll() {

            if (wusixiaodu_ship_target_list.length == 0) return;
            for (let i = 0; i < wusixiaodu_ship_target_list.length; i++) {
                if (wusixiaodu_ship_target_list[i]) {
                    wusixiaodu_ship_target_list[i].clear();
                }
            }
            wusixiaodu_ship_target_list = [];
            this.oldShipTarget = [];

        },

    }
}