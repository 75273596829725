import Moment from "moment";
export function stringCutting(str, length) {
    if (!str) return '';
    if (str.length > length) {
        return `${str.substr(0, length)}...`;
    } else {
        return str;
    }
}
export function timeShift(time, format) {
    if (!time) return '';
    return time !== '' ? Moment(Number(time)).format(format) : '';
}

// toFixed(2);
export function toFixed(n, num) {
    if (!n) return 0;
    // return time !== '' ? Moment(Number(time)).format(num) : '';
    return n.toFixed(1)
}