import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}
// User-Id
const UserId = 'User-Id'

export function getUserId() {
    return Cookies.get(UserId)
}

export function setUserId(token) {
    return Cookies.set(UserId, token)
}

export function removeUserId() {
    return Cookies.remove(UserId)
}


