import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/common.less' // global css
import '@/style/animation.less' // 全局动画 css
import 'element-ui/lib/theme-chalk/base.css';
import "./element-ui.js"
// import "./permission.js" // 权限文件
import Directives from './directives/index';
import '@/components/CommonMediaModal/index.js' // 全局弹窗函数
import '@/components/ComonAlarmReminder/index.js' //全局警报弹窗
import Echarts from 'vue-echarts'
import virtualList from 'vue-virtual-scroll-list';

// Vue.use(vuescroll);
Vue.component('v-chart', Echarts)
Vue.component('virtual-list', virtualList)

// 指令使用
Vue.use(Directives);

import * as filters from './filters/filters' // global filters

import ws from "./utils/ws"
Vue.prototype.ws = ws;

import EventBus from './utils/bus'
Vue.prototype.$EventBus = EventBus

//ECharts引入
import echarts from "./utils/echarts";
Vue.prototype.$echarts = echarts


// import VueDND from 'awe-dnd'

// Vue.use(VueDND)

Vue.config.productionTip = false

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
