import store from '@/store'
import { Notification } from 'element-ui'
import EventBus from '@/utils/bus'
var socket = null; //WebSocket实例
var heart = null; // 心跳时间
var droneId = null; // 无人机信息清除定时器
var airportId = null; // 机场信息清除定时器
var reconnectId = null; // 断线重连定时器id
var lastTime = 0; // 上次推理结果时间

const pass_method = ['drone_open', 'device_reboot', 'cover_close', 'cover_open', 'charge_open',
    'charge_close', 'drone_format', 'device_format', 'putter_close', 'putter_open', 'debug_mode_open', 'debug_mode_close',
    'supplement_light_open', 'supplement_light_close', 'battery_maintenance_switch', 'air_conditioner_mode_switch',
    'alarm_state_switch', 'battery_store_mode_switch', 'drone_close', 'sdr_workmode_switch'];

// 心跳函数
const heartbeat = () => {
    const identifier = "ping"; // 心跳包标识符

    if (socket.readyState === WebSocket.OPEN) {
        socket.send(identifier); // 发送心跳包
    }
    heart = setInterval(() => {

        if (socket.readyState === WebSocket.OPEN) {
            socket.send(identifier); // 发送心跳包
        }
    }, 3000); // 每 3 秒发送一次心跳包

}

// 关闭心跳发送
const closeHeart = () => {
    clearInterval(heart);
    heart = null;
}

// 重连函数
const reconnect = (resourceId) => {
    if (socket) {
        socket.close(); // 关闭 WebSocket 连接
        socket = null;
        closeHeart(); // 停止心跳包定时器
    }
    // 清除定时器
    if (droneId) {
        clearTimeout(droneId);
        droneId = null;
    }
    if (airportId) {
        clearTimeout(airportId);
        airportId = null;
    }

    // 尝试重新连接

    if (reconnectId) clearTimeout(reconnectId);
    reconnectId = setTimeout(() => {
        socketConnect(resourceId);
    }, 5000);
};
//发送cmd请求
const sendCmd = (info) => {
    const { cmd, message, type, method, data } = info;

    let msgid = `${cmd}_${new Date().getTime()}`
    var json
    if (type) {
        json = {
            type,
            method,
            data
        }
    } else {
        json = {
            msgid: msgid,
            message,
            cmd
        }
    }


    send(JSON.stringify(json));
}

// websocket初始化
const socketConnect = (resourceId) => {
    const token = store.getters.token;
    let reconnectCount = 0; // 重连次数计数器
    // let resourceId = resourceId;
    let URL = `ws://${location.host}/api/v1/ws?x-auth-token=${token}&resourceId=${resourceId}`;

    if (process.env.VUE_APP_WITH_CREDENTIALS == "1")
        // URL = `wss://${location.host}/api/v1/ws?x-auth-token=${token}&resourceId=${resourceId}`;
        URL = `wss://${location.host}/api/v1/ws?x-auth-token=${token}&resourceId=${resourceId}`;

    socket = new WebSocket(URL)

    console.log(`socket`,socket);

    socket.addEventListener("open", (event) => {
        reconnectCount = 0; // 连接成功时重置重连次数计数器
        heartbeat(); // 启动心跳包定时器
    });

    socket.addEventListener("message", (event) => { onmessageWS(event) });

    // 监听 WebSocket 连接关闭事件
    socket.addEventListener("close", (event) => {
        // console.log("WebSocket 连接关闭close：", event);
        closeHeart(); // 停止心跳包定时器
        if(event.code == 3004) return
        if (reconnectCount < 10) {
            reconnect(resourceId);
            reconnectCount++;
        } else {
            console.log("已达到重连次数上限，停止重连");
        }
    });

    // 监听 WebSocket 连接错误事件
    socket.addEventListener("error", (event) => {
        console.error("WebSocket 连接出错：", event);
        if (reconnectCount < 10) {
            reconnect(resourceId);
            reconnectCount++;
        } else {
            console.log("已达到重连次数上限，停止重连");
        }
    });

}

const closeWs = () => {
    console.log("关闭ws")
    if (socket) {
        socket.close(3004); // 关闭 WebSocket 连接 定义断开code 3004: 服务端主动断开
        socket = null;
        closeHeart(); // 停止心跳包定时器
    }
    // 清除定时器
    if (droneId) {
        clearTimeout(droneId)
        droneId = null
    }
    if (airportId) {
        clearTimeout(airportId);
        airportId = null;
    }
}

//WS数据接收统一处理
const onmessageWS = (e) => {

    let res = null

    if (e.data) res = JSON.parse(e.data)

    // 0：飞机；1：负载；2：遥控器；3：机场
    if (res.device_type == 0) {
        if (droneId) clearTimeout(droneId)
        // 无人机
        store.dispatch('drone/writeDroneInfo', res)
        droneId = setTimeout(() => {
            store.dispatch('drone/clearAllInfo')
            clearTimeout(droneId)
            droneId = null
        }, 10000);
    } else if (res.device_type == 2) {
        // 遥控器
        store.dispatch("remote/remoteInfoWrit", res);
    } else if (res.device_type == 3 && res.method == 'OSD') {
        if (airportId) clearTimeout(airportId)
        //机场 
        store.dispatch("airport/airportInfoWrit", res)
        airportId = setTimeout(() => {
            store.dispatch('airport/clearAirportInfoAll')
            clearTimeout(airportId)
            airportId = null
        }, 10000);

    } else if (res?.resource_type == 1 && res?.method == "flighttask_progress") {
        // resource_type: 1 为机场上报数据
        store.commit('airport/SET_AIRPORT_AIRLINE_INFO', res.data?.output)
    } else if (res.device_type == 4) {
        // 地面人员
        store.dispatch("ground/groundInfoWrit", res)
    } else if (pass_method.includes(res.method)) {
        // 推送指令状态
        store.dispatch("airport/airportStateWrit", res)
    } else if (res.method == 'infer_event') {
        // 推送推理结果
        if (store.getters.isPush) {
            const alarmInterval = store.getters.alarmInterval * 1000;
            if (Date.now() - lastTime > alarmInterval) {
                EventBus.$emit('infer_event', res.data.url)
                lastTime = Date.now();
            }
        }

    }else if(res.method == 'car_count_event'){
        console.log('车辆统计', res.carCount);
        store.dispatch('aiState/writeCarCount', res.carCount)

    } else if (res?.data?.error_msg || res?.data?.msg || res?.messageType == 'error') {
        // 任务失败状态码接收
        Notification({
            message: res.data.msg || res.data.error_msg,
            type: 'error',
            title: res.data.error_code ? `错误码:${res.data.error_code}` : "错误",
            offset: 150,
            duration: 3000,
        });

        if (res.data.error_code == -1) {
            EventBus.$emit('disengageFlightControl')
        }
    }
}

//发送数据
const send = (message) => {
    if (socket !== null && socket.readyState === 3) {
        socketConnect()
    } else if (socket !== null && socket.readyState === 1) {
        socket.send(message);
    }
}

export default {
    socketConnect,
    closeWs,
    sendCmd,
}