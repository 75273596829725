export default {
    computed: {
        markInfo() {
            return this.$store.getters.markInfo;
        },
    },
    created() {
        this.$EventBus.$on("drawMark", (type) => {
            this.drawMark(type)
        });

        this.$EventBus.$on('markLocationSet', (arr) => {
            this.markLocationSet(arr)
        })

        this.$EventBus.$on('displayMark', () => {
            this.displayMark()
        })

        this.$EventBus.$on('clearAllMark', () => {
            this.clearAllMark()
        })
    },
    beforeDestroy() {
        this.$EventBus.$off("drawMark");
        this.$EventBus.$off("markLocationSet");
        this.$EventBus.$off("displayMark");
        this.$EventBus.$off("clearAllMark");
    },
    methods: {
        // 示意性任务>点线面绘制
        drawMark(index) {
            if (markExample) markExample.endDraw(true);

            markExample = null;

            switch (index) {
                case 0:
                    markExample = zr3d.drawerToolCreate({
                        drawFinishCallback: this.drawFinishCallback,
                        drawModifyCallback: this.drawModifyCallback,
                        setStyleCallback: this.setStyleCallback,
                        style: {
                            pointScale: 1.5,
                            fontColor: "#ffffffff",
                            pointSize: 20,
                            fontSize: 20,
                            name: "采集这里的数据",
                            color: "#F10909",
                        },
                    });

                    break;
                case 1:
                    markExample = zr3d.drawerToolCreate({
                        drawFinishCallback: this.drawFinishCallback,
                        drawModifyCallback: this.drawModifyCallback,
                        setStyleCallback: this.setStyleCallback,
                        style: {
                            pointScale: 1.5,
                            fontColor: "#ffffff",
                            fontSize: 20,
                            name: "采集这里的数据",
                            color: "#5faee3",
                            lineWidth: 3,
                            lineColor: "#5faee3",
                        },
                    });

                    break;
                case 2:
                    markExample = zr3d.drawerToolCreate({
                        drawFinishCallback: this.drawFinishCallback,
                        drawModifyCallback: this.drawModifyCallback,
                        setStyleCallback: this.setStyleCallback,
                        style: {
                            pointScale: 1.5,
                            fontColor: "#ffffff",
                            fontSize: 20,
                            name: "采集这里的数据",
                            color: "#5faee344",
                            lineWidth: 1,
                            lineColor: "#5faee3",
                        },
                    });

                    break;
            }
            markExample.startDraw(index); //开启绘制功能
        },
        // 示意性任务绘制完成回调
        drawFinishCallback(res) {
            // 写入实例
            markExampleList.push(markExample)
            // 写入返回值
            this.$store.dispatch("mark/drawFinishCallback", res).then(() => { this.endDraw(); });
        },
        // 移动回调
        drawModifyCallback(event) {

            // this.$store.dispatch("callout/drawModifyCallback", event);
            this.$store.dispatch("mark/setStyleCallback", event);
            // 根据下标用进行数据替换
        },
        setStyleCallback(event) {

            this.$store.dispatch("mark/setStyleCallback", event);
        },
        // 结束绘制
        endDraw() {
            const { markIndex } = this.markInfo

            markExampleList[markIndex].activeEdit(false); // 结束绘制
            this.$store.commit("mark/SET_MARK_INFO", { markIndex: NaN }); //初始化下标
        },
        // 标注任务定位
        markLocationSet(arr) {
            if (arr.length == 1) {
                zr3d.locationSet({
                    x: arr[0].x, //位置x [必填]
                    y: arr[0].y, //位置y  [必填]
                    z: arr[0].z, //位置z  [必填]
                    dis: 15000, //距离
                    heading: 0, //偏转角
                    pitch: -90, //俯仰角
                    flyTime: 2,
                });
            } else {
                this.lockCenterPoint(arr);
            }
        },
        // 编辑回显
        displayMark() {
            const { markResList } = this.markInfo;
            let arr = [];

            markResList.forEach((item) => {
                let { name, positions, type, style } = item;

                arr.push(...positions);

                let shareMapData = null;
                switch (type) {
                    case 0:
                        shareMapData = zr3d.drawerToolCreate({
                            style: {
                                pointScale: 1.5,
                                fontColor: "#ffffffff",
                                pointSize: 20,
                                fontSize: 20,
                                name: name,
                                color: style.color,
                            },
                        });

                        break;
                    case 1:
                        shareMapData = zr3d.drawerToolCreate({
                            style: {
                                pointScale: 1.5,
                                fontColor: "#ffffff",
                                fontSize: 20,
                                name: name,
                                color: style.color,
                                lineWidth: style.lineWidth || 1,
                                lineColor: style.lineColor || "#5faee3",
                            },
                        });

                        break;
                    case 2:
                        shareMapData = zr3d.drawerToolCreate({
                            style: {
                                pointScale: 1.5,
                                fontColor: "#ffffff",
                                fontSize: 20,
                                name: name,
                                color: style.color,
                                lineWidth: style.lineWidth || 1,
                                lineColor: style.lineColor || "#5faee3",
                            },
                        });
                        break;
                }

                markExampleList.push(shareMapData);
                shareMapData.startDraw(type);

                shareMapData.load({
                    name: name,
                    type,
                    positions,
                    style: {
                        pointScale: 1.5,
                        fontColor: "#0000ff",
                        fontSize: 20,
                        name: name,
                        color: style.color + "44",
                        lineWidth: style.lineWidth || 1,
                        lineColor: style.lineColor || "#5faee3",
                    },
                });
            });
            if (arr.length < 2) {
                zr3d.locationSet({
                    x: arr[0].x, //位置x [必填]
                    y: arr[0].y, //位置y  [必填]
                    z: 10, //位置z  [必填]
                    dis: 15000, //距离
                    heading: 0, //偏转角
                    pitch: -90, //俯仰角
                    flyTime: 2,
                });
            } else {
                this.lockCenterPoint(arr);
            }
        },

        clearAllMark() {
            for (let i = 0; i < markExampleList.length; i++) {
                const item = markExampleList[i];
                item.clear();
                if (i == markExampleList.length - 1) {
                    markExampleList = [];
                }
            }
            markExample = null; //示意性任务>当前实例对象
            this.$store.dispatch("mark/clearAllInfo");
        },
    }
};