const state = {
    groundeInfo: {
        lat: NaN,
        lng: NaN,
        resourceStatus: NaN, // 资源状态
    },
};

const mutations = {
    // 信息写入
    SET_INFO: (state, groundeInfo) => {
        state.groundeInfo = groundeInfo;
       
    },
};

const actions = {
    groundInfoWrit({ commit }, info) {
        const { lat, lng, resource_status } = info.data;
        let newInfo = {
            lat,
            lng,
            resourceStatus: resource_status,
        };
        commit("SET_INFO", newInfo);
    },
    initInfo({ commit }) {
        let newInfo = {
            lat: NaN,
            lng: NaN,
            resourceStatus: NaN, // 资源状态
        };
        commit("SET_INFO", newInfo);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
