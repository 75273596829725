<template>
  <!-- 隐藏该弹窗 -->
  <section
    v-if="isHidden"
    class="chat-tip_container pointer"
    :class="{ 'new-message_tip': newMessage }"
    @click="openList"
  >
    <i class="el-icon-chat-line-round"></i>
  </section>
  <!-- 聊天部分 -->
  <section
    v-else
    class="common-chat_container"
    :style="{ height: elementHeight + 'px' }"
    @mousedown="mouseDown"
    @mousemove="mouseMove"
    @mouseup="mouseUp"
  >
    <!-- 历史记录列表 -->
    <ul class="chat-history_list">
      <li
        v-for="(item, index) in chatHistoryList"
        :key="index"
        class="chat-history_item pointer"
        :class="{ 'item-active': selectedId == item.id }"
        @click="switchChatUser(item)"
        :title="item.resourceName"
      >
        {{ item.resourceName | stringCutting(6) }}
      </li>
    </ul>
    <!-- 内容区域 -->
    <section class="main-content">
      <div class="common-chat_head">
        <span class="common-chat_title">
          {{
            messageQueue.unitName
              ? `${messageQueue.resourceName}(${messageQueue.unitName})`
              : messageQueue.resourceName
          }}
        </span>
        <span class="common-chat_close pointer"
          ><i class="el-icon-minus" @click="hideChat"></i
        ></span>
      </div>
      <div class="message-history" ref="scrollArea">
        <p class="tip-time">{{ Date.now() | timeShift("YYYY年MM月DD日") }}</p>

        <div v-for="(item, index) in messageQueue.messageList" :key="index">
          <!-- 对方的消息-->
          <div class="other-messages" v-if="item.messageSource == 'other'">
            <div class="other-messages_left">
              <span class="avatar-img"></span>
            </div>
            <div class="other-messages_right">
              <!-- 文字消息 -->
              <div class="message-content" v-if="item.messageType == 2">
                {{ item.message }}
              </div>
              <!-- 图片消息 -->
              <div
                class="message-content"
                v-else-if="item.messageType == 3"
                @click="showImg(item.message)"
              >
                <img class="thumbnail pointer" :src="item.message" />
              </div>
              <!-- 视频消息 -->
              <div class="message-content" v-else-if="item.messageType == 4">
                <video :src="item.message" controls width="160"></video>
              </div>
              <div class="send-time">
                {{ item.timestamp | timeShift("HH:mm:ss") }}
              </div>
            </div>
          </div>
          <!-- 我的消息 -->
          <div class="my-messages" v-if="item.messageSource == 'me'">
            <div class="my-messages_left">
              <!-- 文字消息 -->
              <div class="message-content" v-if="item.messageType == 2">
                {{ item.message }}
              </div>
              <!-- 图片消息 -->
              <div
                class="message-content"
                v-else-if="item.messageType == 3"
                @click="showImg(item.message)"
              >
                <img class="thumbnail pointer" :src="item.message" />
              </div>
              <!-- 视频消息 -->
              <div class="message-content" v-else-if="item.messageType == 4">
                <video :src="item.message" controls width="160"></video>
              </div>
              <div class="send-time">
                {{ item.timestamp | timeShift("HH:mm:ss") }}
              </div>
            </div>
            <div class="my-messages_right">
              <span class="avatar-img"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="send-message">
        <span class="up-file pointer" @click="uploadFile">
          <i class="el-icon-picture-outline"></i>
        </span>
        <input
          v-model.trim="msg"
          class="send-input"
          @keydown.enter="sendMessage"
        />
        <span class="send-btn pointer" @click="sendMessage">
          <i class="el-icon-s-promotion"></i>
        </span>
      </div>

      <input
        hidden
        type="file"
        accept="image/*,video/*"
        ref="fileInput"
        @change="handleUpload"
      />

      <el-image-viewer
        v-if="showViewer"
        :on-close="closeViewer"
        :url-list="srcList"
      />
    </section>
  </section>
</template>

<script>
import { getUuid } from "@/utils/index";
import { getCredentials } from "@/api/dji";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: {
    ElImageViewer,
  },
  data() {
    return {
      msg: "",
      socket: null,
      messageType: 2, //消息类型
      ossCredentials: null, // oss对象
      showViewer: false, // 预览窗口的开关
      srcList: [], //预览图片列表
      // 新版
      isHidden: true, // 聊天窗口的最小化
      chatHistoryList: [], //聊天对象列表
      selectedId: NaN, //当前聊天对象id
      newMessage: false, //是否有新消息
      intervalId: null, // 定时器 ID
      isConnected: false, // 连接状态
      startY: 0,
      startHeight: 0,
      elementHeight: 318,
      dragging: false,
      currentMode: process.env.VUE_APP_CURRENTMODE,
    };
  },

  computed: {
    getAllResList() {
      return this.$store.getters.getAllResList;
    },
    token() {
      return this.$store.getters.token;
    },
    userId() {
      return this.$store.getters.userId;
    },
    messageQueue() {
      //
      if (this.selectedId && this.chatHistoryList.length > 0) {
        return this.chatHistoryList.find((item) => item.id === this.selectedId);
      } else {
        return {
          resourceName: "暂未选择聊天对象",
        };
      }
    },
  },
  created() {
    this.$EventBus.$on("startChat", (info) => {
      this.startChat(info);
    });
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.disconnect();
    // this.socket && this.socket.close(); // 关闭 WebSocket 连接
    this.$EventBus.$off("startChat");
  },
  methods: {
    mouseDown(event) {
      this.startY = event.clientY;
      this.startHeight = this.elementHeight;
      this.dragging = true;
    },
    mouseMove(event) {
      if (this.dragging) {
        const distance = event.clientY - this.startY;
        this.elementHeight = this.startHeight + distance;
      }
    },
    mouseUp() {
      this.dragging = false;
    },
    openList() {
      if (this.chatHistoryList.length > 0) {
        this.isHidden = false;
        this.newMessage = false;
      } else {
        this.$message("暂无消息记录");
      }
    },
    switchChatUser(item) {
      this.selectedId = item.id; // 缓存所选id
    },
    // 开始聊天
    startChat(info) {
      const { id, resourceName, unitName } = info;

      let isDuplicate = this.chatHistoryList.some((item) => item.id === id); // 验证该资源id是否存在于列表内
      // 如果不存在进行数据写入
      if (!isDuplicate) {
        // 写入数据
        this.chatHistoryList.push({
          id,
          resourceName,
          unitName,
          messageList: [],
        });
      }
      this.isHidden = false; // 展开聊天列表
      this.newMessage = false;
      this.selectedId = id; // 缓存所选id
    },
    // 图片预览
    showImg(url) {
      this.srcList = [url];
      this.showViewer = true;
    },
    // 关闭图片预览弹窗
    closeViewer() {
      this.showViewer = false;
    },
    // 滚动置底
    scrollToBottom() {
      if (!this.isHidden) {
        this.$refs.scrollArea.scrollTop = this.$refs.scrollArea.scrollHeight;
      }
    },
    // 上传文件校验
    isFileTypeValid(fileType) {
      return fileType.startsWith("image/") || fileType.startsWith("video/");
    },
    // 开始进行图片上传处理
    async handleUpload(event) {
      const file = event.target.files[0];

      if (this.isFileTypeValid(file.type)) {
        await this.initBucketParams();
        await this.uploadMedia(file);
      } else {
        this.$message.error("只支持图片和视频的上传");
      }
    },
    // 触发上传文件
    uploadFile() {
      this.$refs.fileInput.click();
    },
    // 媒体文件上传
    async uploadMedia(file) {
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
        region: "oss-" + this.ossCredentials.region,
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: this.ossCredentials.credentials.access_key_id,
        accessKeySecret: this.ossCredentials.credentials.access_key_secret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: this.ossCredentials.credentials.security_token,
        // 填写Bucket名称。
        bucket: this.ossCredentials.bucket,
      });

      let newFile = null;

      if (file.type.startsWith("image/")) {
        newFile = new File([file], `chat/img/${file.name}`, {
          type: file.type,
        });
        this.messageType = 3;
      } else if (file.type.startsWith("video/")) {
        newFile = new File([file], `chat/video/${file.name}`, {
          type: file.type,
        });
        this.messageType = 4;
      }

      const result = await client.put(newFile.name, newFile);
      if (result) {
        this.msg = result.url;
        this.sendMessage();
      }
    },
    // 存储桶信息初始化
    async initBucketParams() {
      if (!this.ossCredentials) {
        const res = await getCredentials();
        if (res) {
          this.ossCredentials = res.data;
        }
      }
    },
    // 隐藏聊天
    hideChat() {
      this.isHidden = true;
    },
    // 发送消息
    sendMessage() {
      if (this.msg == "") {
        this.$message("不能发送空消息哦");
        return false;
      }
      const info = {
        mid: getUuid(),
        topic: `chat/${this.selectedId}/message`,
        from: this.userId,
        to: this.selectedId,
        message: this.msg,
        messageType: this.messageType,
        receiveType: 2,
        timestamp: Date.now(),
      };

      let jsonInfo = JSON.stringify(info);
      this.socket.send(jsonInfo);
      this.msg = "";
      this.messageType = 2;
      const { message, timestamp, messageType } = info;

      const index = this.chatHistoryList.findIndex(
        (item) => item.id === this.selectedId
      );

      this.chatHistoryList[index].messageList.push({
        message,
        timestamp,
        messageType,
        messageSource: `me`,
      });

      this.$nextTick(() => {
        setTimeout(() => {
          this.scrollToBottom();
        }, 200);
      });
    },
    // ws初始化
    init() {
      let URL = `ws://${location.host}/api/v1/ws?scope=all&x-auth-token=${this.token}`;

      if (process.env.VUE_APP_WITH_CREDENTIALS == "1")
        URL = `wss://${location.host}/api/v1/ws?scope=all&x-auth-token=${this.token}`;

      this.socket = new WebSocket(URL); // 创建 WebSocket 对象

      this.socket.addEventListener("open", (event) => {
        this.isConnected = true;
        this.startHeartbeat(); // 启动心跳包定时器
      });

      // 监听 WebSocket 接收到消息事件
      this.socket.addEventListener("message", async (event) => {
        const receivedMessage = JSON.parse(event.data);

        if (receivedMessage == "pong") return false;
        if (receivedMessage.method === "dasiTaskNotify") {
          this.$notify({
            title: receivedMessage.data.to,
            message: receivedMessage.data.message,
            type: "Info",
          });
        }
        const { message, timestamp, messageType, from, method } =
          receivedMessage;

        if (method == "duplicateLogin") {
          if (this.currentMode == "yulong") {
            await this.$confirm("您的账号已在其他地方登录", "提示", {
              confirmButtonText: "重新登录",
              cancelButtonText: "取消",
              type: "warning",
            });
          } else {
            this.$message({
              message: "您的账号已在其他地方登录",
              type: "error",
            });
          }

          this.$store.dispatch("user/logout").then(() => {
            this.$router.push("/login");
          });
        } else if (method && method === "refreshMap") {
          // 刷新地图,通过eventBus传递消息
          // getAllMark

          this.$EventBus.$emit("refreshMap");
          // this.$EventBus.$emit("getAllMark");
          return false; // 服务端返回的消息
        } else if (method && method === "wusixiaodu_ship_target") {
          // 向外派发消息
          // 获取当前路由，如果是指挥页，就执行以下函数
          const { path } = this.$route;
          if (path === "/command/index") {
            this.$EventBus.$emit(
              "wusixiaodu_ship_target",
              receivedMessage.data
            );
          }
          //
          this.$EventBus.$emit("wsShip", receivedMessage.data);
          // this.$EventBus.$emit("wusixiaodu_ship_target", receivedMessage.data);
        } else {
          // 优先检测该资源id是否已经存在于队列内，存在进行数据插入，不存在新增一项数据
          let isDuplicate = this.chatHistoryList.some(
            (item) => item.id === from
          ); // 验证该资源id是否存在于列表内
          if (isDuplicate) {
            const index = this.chatHistoryList.findIndex(
              (item) => item.id === this.selectedId
            );

            this.chatHistoryList[index].messageList.push({
              message,
              timestamp,
              messageType,
              messageSource: `other`,
            });
            // 存在
          } else {
            const index = this.getAllResList.findIndex(
              (item) => item.id === from
            );
            const { resourceName, unitName } = this.getAllResList[index];
            // 不存在
            this.chatHistoryList.push({
              id: from,
              resourceName,
              unitName,
              messageList: [
                {
                  message,
                  timestamp,
                  messageType,
                  messageSource: `other`,
                },
              ],
            });
          }
          this.selectedId = from;
          // 如果当前列表隐藏并且接受到新消息，给与提示
          if (this.isHidden) {
            this.newMessage = true;
          }
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      });

      // 监听 WebSocket 连接关闭事件
      this.socket.addEventListener("close", (event) => {
        this.isConnected = false;
        this.stopHeartbeat(); // 停止心跳包定时器
      });

      // 监听 WebSocket 连接错误事件
      this.socket.addEventListener("error", (event) => {
        console.error("WebSocket 连接出错：", event);
      });
    },
    // 心跳发送
    startHeartbeat() {
      const identifier = "ping"; // 心跳包标识符
      this.intervalId = setInterval(() => {
        if(!this.socket) return;
        if (this.socket.readyState === WebSocket.OPEN) {
          this.socket.send(identifier); // 发送心跳包
        }
      }, 5000); // 每 5 秒发送一次心跳包
    },

    stopHeartbeat() {
      clearInterval(this.intervalId); // 停止心跳包定时器
    },
    disconnect() {
      if (this.socket) {
        this.socket.close(); // 关闭 WebSocket 连接
        this.socket = null;
        this.isConnected = false;
        this.stopHeartbeat(); // 停止心跳包定时器
      }
    },
  },
};
</script>

<style lang="less" scoped>
.common-chat_container {
  width: 470px;
  height: 318px;
  background: #2e2d2d;
  position: fixed;
  top: 150px;
  left: 486px;
  display: flex;
  z-index: 1;
  .chat-history_list {
    flex: 1;
    background: rgba(19, 18, 18, 0.48);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d1d7dc;
    }

    .chat-history_item {
      width: 110px;
      height: 34px;
      background: #2e2d2d;
      border-radius: 4px;
      color: white;
      text-align: center;
      line-height: 34px;
      margin-bottom: 8px;
    }
    .item-active {
      color: #ef6767;
    }
  }
  .main-content {
    width: 346px;
    // height: 318px;
    display: flex;
    flex-direction: column;
  }
  .common-chat_head {
    height: 38px;
    background: rgba(0, 0, 0, 0.48);
    color: white;
    font-size: 16px;
    display: flex;

    .common-chat_title {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 20px;
    }

    .common-chat_close {
      width: 36px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .message-history {
    // height: 228px;
    flex: 1;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d1d7dc;
    }

    .tip-time {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      color: #ffffff;
      text-align: center;
    }

    .other-messages {
      display: flex;
      padding-right: 60px;
      padding-left: 18px;

      .other-messages_left {
        display: flex;
        margin-right: 10px;
        .avatar-img {
          display: block;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          background: url("@/assets/layout/admin.png") no-repeat center;
          background-size: contain;
        }
      }

      .other-messages_right {
        flex: 1;
      }
    }

    .my-messages {
      display: flex;
      padding-right: 18px;
      padding-left: 60px;
      text-align: right;

      .my-messages_left {
        flex: 1;
      }

      .my-messages_right {
        display: flex;
        margin-left: 10px;
        .avatar-img {
          display: block;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          background: url("@/assets/layout/admin.png") no-repeat center;
          background-size: contain;
        }
      }
    }

    .message-content {
      border-radius: 4px;
      padding: 4px 12px;
      background: #ffffff;
      display: inline-block;

      .thumbnail {
        width: 160px;
      }
    }

    .send-time {
      font-size: 12px;
      color: #ffffff;
      padding-top: 4px;
      padding-bottom: 8px;
    }
  }

  .send-message {
    margin: auto;
    width: 312px;
    height: 36px;
    background: rgba(72, 72, 72, 0.67);
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.38);
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 8px;

    .up-file {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-left: 20px;
      color: white;
      font-size: 22px;
      margin-right: 4px;
    }

    .send-input {
      flex: 1;
      height: 100%;
      border: none;
      outline: none;
      font-size: 16px;
      background: transparent;
      caret-color: white;
      text-indent: 6px;
      color: white;

      &:focus {
        outline: none;
      }
    }

    .send-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 26px;
      background: rgba(64, 233, 242, 0.7);
      border-radius: 14px;
      color: white;
      font-size: 22px;
      margin: 0 4px;
    }
  }
}
.chat-tip_container {
  width: 46px;
  height: 46px;
  background-color: var(--page-background-color);
  border-radius: 4px;
  background: #2e2d2d;
  position: fixed;
  top: 137px;
  left: 438px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 26px;
}
.new-message_tip {
  &::after {
    content: "";
    position: absolute;
    top: 8px;
    right: 8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    opacity: 1;
    animation: breathing 2s ease-in-out infinite;
  }
}
@keyframes breathing {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
</style>
