<template>
  <section class="live_start">
    <!-- 中心罗盘 -->
    <div class="instrument_box">
      <div
        class="instrumenti"
        ref="instrumenti"
        :style="{ transform: `rotateZ(${deg}deg)` }"
        :class="{ animation: animation }"
      ></div>
      <div class="pointer"></div>
    </div>
    <!-- 右侧速度条 -->
    <div class="speed_box">
      <el-progress
        :percentage="speedPercentage"
        color="#338B36"
        :show-text="false"
      ></el-progress>
      <span class="scale">0</span>
      <span class="scale">10</span>
      <span class="scale">20</span>
      <div class="scale_num">
        <p>{{ droneInfo?.horizontal_speed.toFixed(1) || 0 }} </p>
        <span class="unit"
          ><p>SPD</p>
          <p>m/s</p></span
        >
      </div>
    </div>
    <!-- 左边高度 -->
    <div class="altitude_box">
      <div class="altitude_conten">
        <!-- 刻度线 -->
        <div class="altitude_speed_scale" ref="altitude_speed_scale"></div>
        <div class="top_altitude_box" :style="{ height: topBoxHight }"></div>
        <div
          class="bottom_altitude_box"
          :style="{ height: bottomBoxHight }"
        ></div>
        <!-- 高度刻度 -->
        <div class="altitude_scale">
          <div class="scale_item" v-for="item in 15" :key="item"></div>
        </div>
      </div>
      <div class="altitude_num">
        <p>{{ droneInfo?.elevation.toFixed(1) }}</p>
        <span class="unit"
          ><p>ALT</p>
          <p>m</p></span
        >
      </div>
    </div>
         <!-- 底部电池条 -->
    <div class="power_bar"  v-resize='powerResize' v-if="battery">
      <div
        class="show_layer"
        :style="{ width: `${battery.capacity_percent}%` }"
      >
        <div class="electric_line"></div>
        <div class="back_line" :style="{ width: `${backWidth}px` }"></div>
        <div
          class="compulsive_line"
          :style="{ width: `${compulsive}px` }"
        ></div>
        <div class="time_mod">
          {{ battery.remain_flight_time | formatSeconds }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      deg: 0,
      animation: true,
      topBoxHight: 0,
      bottomBoxHight: 0,
       domWidth: 0, //元素宽度
    };
  },
   filters: {
    formatSeconds(seconds) {
      const duration = moment.duration(seconds, "seconds");
      return moment.utc(duration.asMilliseconds()).format("mm:ss");
    },
  },
  computed: {
    // 无人机信息集合
    droneInfo() {
      return this.$store.getters.droneInfo;
    },
    // 速度百分比
    speedPercentage() {
      const num = this.droneInfo?.horizontal_speed?.toFixed(0) / 20;

      return num * 100 || 0;
    },
        //电池集合
    battery() {
      const battery = this.droneInfo.battery;

      if (!battery?.remain_flight_time || !battery?.capacity_percent)
        return null;

      return {
        landing_power: battery?.landing_power, //强制降落电量百分比
        remain_flight_time: battery?.remain_flight_time, //剩余飞行时间
        return_home_power: battery?.return_home_power, //返航所需电量百分比
        capacity_percent: battery?.capacity_percent, //剩余电量
      };
    },
    //计算返航电量长度
    backWidth() {
      return (this.battery.return_home_power / 100) * this.domWidth;
    },
    //计算强制返航电量长度
    compulsive() {
      return (this.battery.landing_power / 100) * this.domWidth;
    },
  },
  watch: {
    droneInfo: {
      handler(newVal, oldVal) {
        if (newVal?.attitude_head !== oldVal?.attitude_head) {
          // 计算罗盘角度
          const dom = this.$refs.instrumenti;
          this.animation = true;
          const difference = Math.abs(
            newVal?.attitude_head - oldVal?.attitude_head
          );
          if (difference > 180) {
            this.animation = false;
          }
          this.deg = -newVal?.attitude_head || 0;
          // this.$nextTick(() => {
          //   this.animation = true
          // });
        }

        //计算高度速度
        if (newVal?.vertical_speed !== oldVal?.vertical_speed) {
          const HeightNum = ((newVal?.vertical_speed / 6) * 100) / 2;
          if (HeightNum > 0) {
            this.bottomBoxHight = 0;
            this.topBoxHight = HeightNum + "%";
          } else if (HeightNum < 0) {
            this.bottomBoxHight = -HeightNum + "%";
            this.topBoxHight = 0;
          } else {
            this.bottomBoxHight = 0;
            this.topBoxHight = 0;
          }
          this.$nextTick(() => {
            const dom = this.$refs.altitude_speed_scale;
            if (newVal?.vertical_speed >= 4.9) {
              dom.style.bottom = "100%";
              this.bottomBoxHight = 0;
              this.topBoxHight = 100 + "%";
              return;
            }
            if (newVal?.vertical_speed <= -5) {
              dom.style.bottom = "0";
              this.bottomBoxHight = -100 + "%";
              this.topBoxHight = 0;
              return;
            }
            const num = ((newVal?.vertical_speed / 6) * 100) / 2;
            dom.style.bottom = +num.toFixed(1) + 50 + "%";
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
    methods:{
    // 计算dom变化
    powerResize(e){
      if(parseFloat(e.width.match(/\d+/))){
        this.domWidth = parseFloat(e.width.match(/\d+/))
        return
      }
      this.domWidth = 0
    }
  }
};
</script>

<style lang="less" scoped>
// 进度条背景色
/deep/ .el-progress-bar__outer {
  background: rgba(0, 0, 0, 0.49);
}
.live_start {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  background-image: url("@/assets/command/state_background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  width: 450px;
  pointer-events: none;
}
.instrument_box {
  position: absolute;
  left: 50%;
  transform: translateX(-52%);
  bottom: 21px;
  height: 79%;
  width: calc(79% / 3);
}
.instrumenti {
  height: 100%;
  width: 100%;
  background-image: url("@/assets/command/instrumenti.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%) translateY(-100%);
    width: 0px;
    height: 0px;
    border: 6px solid transparent;
    border-bottom: 6px solid #b92425;
  }
}
.animation {
  transition: transform 1.2s;
}
.pointer {
  position: absolute;
  left: 50%;
  top: 50%;
  background-image: url("@/assets/command/pointer.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 10px;
  height: 10px;
  transform: translateX(calc(-50% + 0.5px)) translateY(-50%);
}
.speed_box {
  margin-right: 5%;
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(90% / 3);
  height: 45%;
  // border: 1px solid #000;
  .scale {
    font-family: Bahnschrift-Bold, Bahnschrift;
    color: white;
    font-size: 11px;
    font-weight: bold;
    position: absolute;
    transform: translateY(-100%);
    left: 0;
    top: 0;
    &:nth-child(3) {
      left: 50%;
      transform: translateX(-50%) translateY(-100%);
    }
    &:nth-child(4) {
      left: auto;
      right: 0;
    }
  }
}
.scale_num {
  margin: 10px auto;
  width: 80px;
  height: 22px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px 4px 4px 4px;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  .unit {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    transform: scale(0.8);
    line-height: 8px;
  }
}
.altitude_box {
  width: calc(100% / 3);
  height: 60%;
  position: absolute;
  bottom: 0;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  padding-bottom: 20px;
  margin-left: 10px;

  .altitude_conten {
    width: 32px;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 2px 2px 2px 2px;
    position: relative;

    // 高度速度刻度线
    .altitude_speed_scale {
      position: absolute;
      transform: translateX(110%);
      width: 80%;
      height: 2px;
      background-color: #fff;
      z-index: 2;
      transition: bottom 2.5s;
      &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 4px;
        background: #ffffff;
        left: 0;
        transform: translateY(calc(-50% + 1px)) translateX(-200%);
      }
      &::before {
        content: "";
        position: absolute;
        border: 2px solid transparent;
        border-left: 4px solid #fff;
        left: 0;
        transform: translateY(calc(-50% + 1px)) translateX(-70%);
      }
    }
    .top_altitude_box {
      position: absolute;
      bottom: 50%;
      width: 100%;
      max-height: 50%;
      background-color: #1df02470;
      // background: linear-gradient(  #DB181A,#328C36);
      border-radius: 2px 2px 0 0;
      opacity: 0.8;
      overflow: hidden;
      transition: height 1.2s .5s;
    }
    .bottom_altitude_box {
      position: absolute;
      top: 50%;
      width: 100%;
      max-height: 50%;
      height: 0px;
      background-color: #1df02470;
      // background: linear-gradient( #DB181A,#328C36 );
      opacity: 0.8;
      border-radius: 0 0 2px 2px;
      transition: height 1.2s .5s;
    }
    .altitude_scale {
      position: absolute;
      right: 0;
      width: 15px;
      height: 100%;
      transform: translateX(calc(100% + 5px)) translateY(-1px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .scale_item {
        width: 9px;
        height: 1px;
        background: #ffffff;
        &:nth-child(4n) {
          width: 13px;
        }
        &:nth-child(4),
        &:nth-child(12) {
          position: relative;
          &::before {
            content: "";
            top: 50%;
            right: -5px;
            transform: translateX(100%) translateY(-50%);
            font-family: Bahnschrift-Bold, Bahnschrift;
            font-weight: bold;
            position: absolute;
            color: #fff;
            font-size: 12px;
          }
        }
      }
    }
  }
  .altitude_num {
    width: 70px;
    height: 22px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px 4px 4px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    .unit {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      transform: scale(0.8);
      line-height: 8px;
    }
  }
}
.power_bar {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 4px;
  background-color: #999;
  border-radius: 5px 5px 5px 5px;
  .show_layer {
    border-radius: 5px 0 0 5px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    // width: 50%;
    transition: width 0.3s;
    .electric_line,
    .back_line,
    .compulsive_line {
      position: absolute;
      left: 0;
      border-radius: 5px 5px 5px 5px;
      height: 100%;
    }
    .electric_line {
      width: 100%;
      background-color: #0adf91;
    }
    .back_line {
      background-color: #dfb21a;
    }
    .compulsive_line {
      background-color: #d2301d;
    }
    .time_mod {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) translateX(100%);
      // width: 20px;
      // height: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      font-family: Bahnschrift-SemiLight, Bahnschrift;
      font-weight: 700;
      color: #0adf91;
      padding: 2px;
      border: 0.5px solid #ffffff80;
      border-radius: 0 4px 4px 0;
      border-left: 2px solid #0adf91;
    }
  }
}
</style>