// 实现全局弹窗函数
import Vue from 'vue'
import store from '@/store'
import Modal from './index.vue';
let modalInstance = null; // 保存弹窗实例的变量

Vue.prototype.$showMediaModal = ({ type, src,sharing }) => {
    if (modalInstance) {
        // 如果已存在弹窗实例，则先移除之前的弹窗
        modalInstance.$el.remove();
        modalInstance.$destroy();
    }

    const ModalComponent = Vue.extend(Modal);
    modalInstance = new ModalComponent({
        store, // 将 store 实例注入到弹窗组件
        propsData: {
            type, // 弹窗类型 image/video
            src,
            sharing, // 是否为分享弹窗
        }
    });

    modalInstance.$mount(); // 手动挂载组件实例到 DOM
    document.body.appendChild(modalInstance.$el); // 将组件的根元素添加到 body 中
};

Vue.prototype.$removeMediaModal = () => {
    if (modalInstance) {
        modalInstance.$el.remove(); // 移除弹窗元素
        modalInstance.$destroy(); // 销毁弹窗实例
        modalInstance = null; // 重置弹窗实例变量
    }
};