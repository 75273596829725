import request from '@/utils/request.js'
// 登录接口
export function login(data) {
    return request({
        url: '/api/login',
        method: 'post',
        data
    })
}
// 登出
export function logout(data) {
    return request({
        url: '/api/logout',
        method: 'post',
        data
    })
}
// 获取个人信息
export function getInfo(params) {
    return request({
        url: '/api/manage/v1/profile/get',
        method: 'get',
        params
    })
}
// 编辑个人信息
export function update(data) {
    return request({
        url: '/api/manage/v1/profile/update',
        method: 'post',
        data
    })
}

// 发送验证码
export const getCode = phoneNumber => {
    return request({
      url: "/api/manage/v1/sms/send",
      method: "post",
      data: {
        phoneNumber
      }
    });
  };
  
  // 注册请求
  export const postRegister = data => {
    return request({ url: "/api/manage/v1/user/register", method: "post", data });
  };
  