<template>
  <ul class="menu-container">
    <el-tooltip
      v-for="(item, index) in menuList"
      :key="index"
      effect="dark"
      placement="right"
    >
      <li
        @click="jump(item, index)"
        class="iconfont-box pointer"
        :class="{ 'list-item__active': item.path === activePath }"
      >
        <i class="iconfont" :class="item.icon"></i>
      </li>

      <div class="tip-text" slot="content">
        <p>{{ item.text }}</p>
      </div>
    </el-tooltip>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      activePath: "",
      projectId: "",
      projectName: "",
    };
  },
  watch: {
    $route: {
      handler(routerInfo) {
        const { path } = routerInfo;

        if (path.indexOf("courseTasks") > -1) {
          this.activePath = "/task/index";
        } else if (path.indexOf("markTask") > -1) {
          this.activePath = "/task/index";
        } else if (path.indexOf("/results") > -1) {
          this.activePath = "/results/index";
        } else if (path.indexOf("/resultsInfo") > -1) {
          this.activePath = "/results/index";
        } else if (path.indexOf("/resourcesDetails") > -1) {
          this.activePath = "/command/index";
        } else {
          this.synchronization();
        }
      },
      immediate: true,
    },
  },
  computed: {
    // id
    userId() {
      return this.$store.getters.userId;
    },
    menuList() {
      let id = "913606337476829184";
      if (this.userId == id) {
        return [
          {
            icon: "el-icon-s-data",
            text: "数据大屏",
            path: "/statistics/index",
          },
          {
            icon: "icon-command",
            text: "指挥",
            path: "/command/index",
          },
          {
            icon: "icon-personnel",
            text: "人员",
            path: "/personnel/index",
          },
          {
            icon: "icon-equipment",
            text: "设备",
            path: "/equipment/index",
          },
          {
            icon: "icon-task",
            text: "任务",
            path: "/task/index",
          },
          {
            icon: "icon-algorithm",
            text: "算法",
            path: "/algorithm/index",
          },
          {
            icon: "icon-results",
            text: "成果",
            path: "/results/index",
          },
          {
            icon: "icon-split",
            text: "图片识别",
            path: "/imageRecognition/index",
          },
        ];
      } else if (this.userId && this.userId !== id) {
        return [
          {
            icon: "el-icon-s-data",
            text: "数据大屏",
            path: "/statistics/index",
          },
          {
            icon: "icon-command",
            text: "指挥",
            path: "/command/index",
          },
          {
            icon: "icon-personnel",
            text: "人员",
            path: "/personnel/index",
          },
          {
            icon: "icon-equipment",
            text: "设备",
            path: "/equipment/index",
          },
          {
            icon: "icon-task",
            text: "任务",
            path: "/task/index",
          },
          {
            icon: "icon-picture",
            text: "智图",
            path: "/dataShow/index",
          },
          {
            icon: "icon-algorithm",
            text: "算法",
            path: "/algorithm/index",
          },
          {
            icon: "icon-results",
            text: "成果",
            path: "/results/index",
          },
          //   {
          //     icon: "icon-digitaltwin",
          //     text: "数字孪生",
          //     path: "/twinNumbers/index",
          //   },
          {
            icon: "icon-split",
            text: "图片识别",
            path: "/imageRecognition/index",
          },
        ];
      }
    },
  },
  created() {},
  methods: {
    synchronization() {
      this.activePath = this.$route.path;
    },
    jump(item) {
      if (this.activePath === item.path) {
        this.$router.replace({
          path: item.path,
          query: {
            t: Date.now(),
          },
        });
      } else {
        this.activePath = item.path;
        this.$router.push(item.path);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.menu-container {
  width: 50px;
  background-color: var(--page-background-color);
  color: white;
  // border-radius: 0px 6px 0 0px;
  overflow: hidden;
  padding-top: 6vh;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  .iconfont-box {
    width: 42px;
    // height: 7vh;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    // margin-bottom: 20px;
    margin-bottom: 3vh;
    transition: transform 0.35s ease-in-out;
    position: relative;
    overflow: hidden;
    .iconfont {
      z-index: 1;
    }
    &::before {
      content: "";
      z-index: -1;
      position: absolute;
      top: 100%;
      right: 100%;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: var(--btn-hover-color);
      transform-origin: center;
      transform: translate3d(50%, -50%, 0) scale3d(0, 0, 0);
      transition: transform 0.35s ease-in-out;
      z-index: 1;
    }

    &:hover {
      transform: scale(1.1);
      &::before {
        transform: translate3d(50%, -50%, 0) scale3d(15, 15, 15);
      }
    }
  }
  // 激活状态
  .list-item__active {
    background-color: var(--btn-active-color) !important;
    transform: scale(1) !important;
    &::before {
      transform: translate3d(50%, -50%, 0) scale3d(0, 0, 0) !important;
    }
  }
  .iconfont {
    font-size: 24px;
  }
}
</style>
