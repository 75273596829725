<template>
  <section class="navbar-container">
    <p
      class="title_icon"
      v-if="workspaceId == 'e3dea0f5-37f2-4d79-ae58-490af3228072'"
    >
      广西产业园区一张图
    </p>
    <img class="title_icon" :src="titleIcon" alt="" v-else />
    <!-- 一级路由 -->
    <MenuMine />
    <div class="head-right">
      <span class="info-box pointer">
        <el-dropdown trigger="click">
          <img src="@/assets/layout/admin.png" />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="$emit('changePwd')">
              修改密码
            </el-dropdown-item>
            <el-dropdown-item @click.native="logout">
              <span style="display: block">退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </span>
      <span class="user">{{ name }}</span>
    </div>
  </section>
</template>

<script>
import screenfull from "screenfull";
import Moment from "moment";
import MenuMine from "./Menu-mine.vue";
export default {
  data() {
    return {
      time: "",
      week: "",
      year: "",
      timeId: null,
      // 当前页面是否全屏
      isFullscreen: false, // false非全屏状态、true全屏状态
      showTitleText: false,
    };
  },
  components: {
    MenuMine,
  },
  computed: {
    name() {
      return this.$store.getters.name;
    },
    avatar() {
      return this.$store.getters.avatar;
    },
    workspaceId() {
      const workspaceId = this.$store.getters.workspaceId;

      return workspaceId;
    },
    // 通过环境变量获取名称
    pageTitle() {
      return process.env.VUE_APP_TITLE;
    },
    // 当前环境变量 CURRENTMODE
    currentMode() {
      return process.env.VUE_APP_CURRENTMODE;
    },
    // @/assets/meines/title.svg
    titleIcon() {
      if (this.currentMode === "xiwan") {
        return require("@/assets/meines/title-xiwan.svg");
      } else {
        return require("@/assets/meines/title-zh.svg");
      }
    },
  },
  created() {
    window.addEventListener("resize", this.onresize);
    document.addEventListener("keydown", this.handleKeyDown);
  },
  mounted() {
    this.timeId = setInterval(() => {
      this.year = this.getYear();
      this.week = this.getWeek();
      this.time = this.getTime();
    }, 1000);
    this.$store.dispatch("user/getInfo");
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onresize);
    document.removeEventListener("keydown", this.handleKeyDown);
    clearInterval(this.timeId);
    this.timeId = null;
  },

  methods: {
    onresize(event) {
      // 利用屏幕分辨率和window对象的内高度来判断兼容IE
      let winFlag = window.innerHeight === window.screen.height;
      // 利用window全屏标识来判断 -- IE无效
      let isFull = window.fullScreen || document.webkitIsFullScreen;

      if (isFull === undefined) {
        this.isFullscreen = winFlag;
      } else {
        this.isFullscreen = winFlag || isFull;
      }
      console.log("winFlag || isFull", winFlag, isFull);
    },
    // 阻止F11默认事件
    keydown(event) {
      if (event.key === "F11") {
        event.preventDefault();
        event.returnValue = false;
      }
    },
    // 切换是否全屏
    toggleFullscreen(isFull) {
      if (!screenfull.isEnabled) {
        this.$notify.error({
          message: "您的浏览器不支持全屏模式",
          type: "warning",
        });
        return false;
      }

      screenfull.toggle();
      this.isFullscreen = isFull;
    },

    async logout() {
      await this.$store.dispatch("user/logout");

      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    getYear() {
      return Moment().format("YYYY.MM.DD");
    },
    getWeek() {
      let week = Moment().format("d");

      switch (week) {
        case "0":
          return "星期日";
          break;
        case "1":
          return "星期一";
          break;
        case "2":
          return "星期二";
          break;
        case "3":
          return "星期三";
          break;
        case "4":
          return "星期四";
          break;
        case "5":
          return "星期五";
          break;
        case "6":
          return "星期六";
          break;
      }
    },
    getTime() {
      return Moment().format("HH:mm:ss");
    },
  },
};
</script>

<style lang="less" scoped>
.navbar-container {
  background-color: var(--page-background-color);
  height: 64px;
  display: flex;
  position: relative;
  z-index: 2;
  color: white;
  justify-content: space-between;

  .title-box {
    height: 64px;
    display: flex;
    align-items: center;
    position: relative;

    .icon {
      width: 54px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
      img {
        object-fit: contain;
        width: 42px;
        height: 42px;
        border-radius: 50%;
      }
    }
    .icon--demo {
      width: 115px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      margin-right: 24px;
      img {
        object-fit: contain;
        width: 115px;
        height: 40px;
      }
    }

    .page-title {
      font-size: 38px;
      font-family: "YouSheBiaoTiHei-Regular";
      font-weight: 400;
      letter-spacing: 6px;
      padding-left: 12px;
      transition: opacity 0.3s ease-in-out;
    }
    .title_img {
      position: absolute;
      bottom: 5px;
      left: 108px;
    }
    .title_img--demo {
      left: 168px;
    }
  }

  .head-right {
    // flex: 1;
    text-align: right;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
    position: relative;
    .full-screen-box {
      // margin-right: 20px;
      .el-icon-full-screen {
        font-size: 24px;
      }
      .icon-exit-fullScreen {
        font-size: 24px;
      }
    }
    .time {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      .hour {
        font-size: 40px;
        font-weight: 300;
        line-height: 1;
        width: 160px;
        display: inline-flex;
      }

      .date {
        display: inline-flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 300;
        padding-right: 20px;
        padding-left: 20px;
        .week {
          text-align: left;
        }
      }
    }
    .info-box {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-left: 20px;
      margin-right: 10px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    .user {
      font-size: 20px;
      font-weight: 400;
    }
  }
  .line {
    width: 1px;
    background: white;
    height: 30px;
  }

  .title_icon {
    margin-left: 25px;
    width: 350px;
    height: 100%;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-family: "ALIMAMASHUHEITI-BOLD";
  }
}
</style>
