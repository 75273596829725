<template>
  <section
    id="mapContainer"
    class="map-container"
    :class="{
      'map-container--command': isCommand,
      'map-container_detail--drone': isDroneDetails,
      'map-container_detail--airport': isAirportDetails,
      'map-container--video': isShowVideo,
      'map-container--statistics': isStatistics,
    }"
  >
    <!-- 底部信息栏 -->
    <footerInfo v-if="footerInfoShow" :geographic="geographic" />
    <!-- 右侧工具栏 -->
    <controls
      @closeSearch="searchShow = false"
      @openSearch="searchShow = true"
    />
    <!-- 地点搜索 -->
    <searchBox v-if="searchShow" />
    <!-- 资源弹窗 -->
    <resPopUp @addTask="addTask" />
    <!-- 添加任务 -->
    <addTask
      v-if="addTaskShow"
      :resourceType="resourceType"
      @cancel="addTaskShow = false"
    />
    <pointLineOption :style="menuPosition" />
    <analysisTool v-if="analysisToolShow" />
    <suspiciousTarget v-if="suspiciousTargetShow" />
    <videoSurveillance v-if="videoSurveillanceShow" />
    <darkMark v-if="markShow" />
    <deleteNote
      v-if="suspiciousTargetShow"
      :deleteNodePos="deleteNodePos"
      @delNode="delNodeSuccess"
    />
    <trackSwitch v-if="trackSwitchShow" />
    <!-- <Zhilian v-if="trackSwitchShow"/> -->
  </section>
</template>

<script>
import base from "./base.js";
import componentsOption from "./componentsOption.js";
import resource from "./resource.js";
import airline from "./airline.js";
import mark from "./mark.js";
import resourceDetails from "./resourceDetails.js";
import wusixiaodu_ship_target from "./wusixiaodu_ship_target.js";
// 地图组件
import footerInfo from "./components/footerInfo.vue";
import controls from "./components/controls.vue";
import searchBox from "./components/searchBox.vue";
import analysisTool from "./components/analysisTool.vue";
// 业务组件
import resPopUp from "./components/resPopUps.vue";
import addTask from "./components/addTask.vue";
import pointLineOption from "./components/pointLineOption.vue";
import suspiciousTarget from "./components/suspiciousTarget.vue";
import videoSurveillance from "./components/videoSurveillance.vue";
import darkMark from "./components/darkMark.vue";
import deleteNote from "./components/deleteNote.vue";
import trackSwitch from "./components/trackSwitch.vue";
import Zhilian from "./components/zhiliang_yanshi.vue";
export default {
  mixins: [
    base,
    componentsOption,
    resource,
    airline,
    mark,
    resourceDetails,
    wusixiaodu_ship_target,
  ],
  components: {
    footerInfo,
    controls,
    searchBox,
    resPopUp,
    addTask,
    pointLineOption,
    analysisTool,
    suspiciousTarget,
    videoSurveillance,
    darkMark,
    deleteNote,
    trackSwitch,
    Zhilian,
  },
  computed: {
    isCommand() {
      //根据路由返回路径判断是否是指挥组件
      // return this.$route.path === "/command/index";
      return false;
    },
    isDroneDetails() {
      return (
        this.$route.path.includes("/resourcesDetails") &&
        this.$route.query.resourceType == 2
      );
    },
    // 机库详情：根据路由返回路径判断是否是详情组件 ,并且同时判断query是否有值resourceType,并且值为1
    isAirportDetails() {
      return (
        this.$route.path.includes("/resourcesDetails") &&
        this.$route.query.resourceType == 1
      );
    },
    //
    // isShowVideo
    isShowVideo() {
      return this.$store.getters.isShowVideo;
    },
    currentMode() {
      return process.env.VUE_APP_CURRENTMODE;
    },
    // /statistics/index
    isStatistics() {
      const pathList = [
        "/overview/index",
        "/radar/index",
        "/monitor/index",
        "/safety/index",
        "/statistics/index",
        "/riskAssessment/index",
      ];

      if (
        this.$route.path.includes("statistics") &&
        this.currentMode == "xiwan"
      ) {
        return false;
      }
      return pathList.includes(this.$route.path);
      // return this.$route.path === "/statistics/index";
    },
  },
  data() {
    return {
      geographic: { latitude: 0, longitude: 0, height: 0 }, //当前鼠标所指地区地理信息
      footerInfoShow: false, // 是否显示底部信息栏
      searchShow: false,
      addTaskShow: false,
      analysisToolShow: false,
      suspiciousTargetShow: false,
      videoSurveillanceShow: false,
      //是否显示mark绘制组件
      markShow: false,
      resourceType: 1,
      // centralPoint: [113.0607908, 22.5945830], // 默认中心点
      centralPoint: [102.1010206, 27.0367701],
      // 经度:110.0504648°
      // 纬度:38.6174102°
      posX: 0, //获取鼠标坐标点
      posY: 0, //获取鼠标坐标点
      deleteNodePos: {
        left: 0,
        right: 0,
        elementId: "",
      },
      //   是否显示
      trackSwitchShow: false,
    };
  },
  created() {
    this.$EventBus.$on("hideMenu", (value) => {
      this.hideMenu();
    });
    window.videoEntity = null; // 视追对象
  },

  beforeDestroy() {
    this.$EventBus.$off("hideMenu");
  },
  methods: {
    delNodeSuccess() {
      //初始化删除节点
      this.deleteNodePos = {
        left: 0,
        right: 0,
        elementId: "",
      };
    },
    addTask(type) {
      this.resourceType = type; //窗口类型
      this.addTaskShow = true; //打开窗口
      this.hideResLabel();
    },
    // 隐藏弹窗
    hideMenu() {
      if (this.posX != 0) this.posX = 0; //隐藏弹窗
      if (this.posY != 0) this.posY = 0; //隐藏弹窗
    },
  },
};
</script>

<style lang="less" scoped>
.map-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  //   宽度改变给与过渡效果
  transition: left 0.3s;
  /deep/.navigation-controls {
    display: none;
  }
}

//新增一个属性样式用于指挥界面
.map-container--command {
  left: 378px;
  top: 64px;
  border-radius: 6px 0 0 0;
}
//新增一个属性样式用于详情页面
.map-container_detail--drone {
  left: 372px;
  top: 48px;
  /deep/ .cesium-viewer {
    border-radius: 6px 0 0 0;
  }
}
.map-container_detail--airport {
  left: 422px;
  top: 48px;
  bottom: 264px;
  /deep/ .cesium-viewer {
    border-radius: 6px 0 0 6px;
  }
}
// 可显示视频组件
.map-container--video {
  left: 12px;
  top: 48px;
  bottom: 264px;
  /deep/ .cesium-viewer {
    border-radius: 6px 0 0 6px;
  }
}
.map-container--statistics {
  /deep/ .compass {
    display: none;
  }
}
</style>
