<template>
  <!-- 鼠标按下事件 -->
  <section
    :class="{ 'delete-node_mask': deleteNodePos.elementId != '' }"
    @click="onMenuBlur"
  >
    <!---->
    <section
      v-if="deleteNodePos.elementId != ''"
      class="delete-node_container pointer"
      @mouseout="deleteNodeBlur"
      :style="{
        left: deleteNodePos.left + 10 + 'px',
        top: deleteNodePos.top - 20 + 'px',
      }"
      @click.stop="delElement"
    >
      删除标注
    </section>
  </section>
</template>

<script>
import { delMark } from "@/api/dji";
export default {
  props: {
    deleteNodePos: {
      type: Object,
      default: {
        left: 0,
        top: 0,
        elementId: "",
      },
    },
  },

  methods: {
    onMenuBlur() {
      // this.up = false;
      this.$emit("delNode");
      // document.removeEventListener("mousedown", this.onMenuBlur);
    },
    deleteNodeBlur() {
      this.$emit("delNode");
    },
    async delElement() {
      const res = await delMark({ elementId: this.deleteNodePos.elementId });
      if (res) {
        //给与成功提示
        this.$message({
          message: "删除成功",
          type: "success",
        });
        //向外部发送删除节点的事件
        this.$emit("delNode");
        // 使用evetBus通知父组件删除节点
        this.$EventBus.$emit("clearResDetMark"); // 移除项目标注
        this.$nextTick(() => {
          this.$EventBus.$emit("getAllMark"); // 获取该项目的标注
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.delete-node_mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.delete-node_container {
  position: absolute;
  left: 1083px;
  top: 411px;
  width: 98px;
  height: 42px;
  border-radius: 4px;
  color: white;
  z-index: 1;
  text-align: center;
  line-height: 42px;
  background: url("@/assets/map/delete-node.png") no-repeat center;
  background-size: contain;
  z-index: 3;
}
</style>