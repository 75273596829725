<template>
  <section class="model-menu_list" @mousedown.stop @mousewheel.stop>
    <div
      class="parent-box pointer"
      :class="{ 'parent-box--active': selectedAiIndex > 0 }"
      @click="expandModelList"
    >
      <ul v-if="showModelList" class="mode-type">
        <li
          v-for="(item, index) in myModelList"
          :key="index"
          class="item pointer"
          :class="{ item_active: selectedAiIndex === index }"
          @click.stop="selectModel(index)"
        >
          <div class="inside-box" :title="item.model_name">
            <i
              v-if="item.model_name.includes('关闭')"
              class="icon-close-ai iconfont model-icon"
            ></i>
            <i v-else class="iconfont model-icon" :class="item.icon"></i>
            {{ item.model_name | stringCutting(2) }}
          </div>
        </li>
      </ul>
    </div>
    <p>AI模型</p>
  </section>
</template>

<script>
export default {
  name: "ModelMenuList",
  props: {
    myModelList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModelList: false,
    };
  },
  computed: {
    selectedAiIndex() {
      return this.$store.getters.selectedAiIndex;
    },
  },
  created() {
    this.$EventBus.$on("aiModuleClick", (index) => {
      this.selectModel(index);
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("aiModuleClick");
    // 初始化selectedAiIndex
    this.$store.dispatch("aiState/writeSelectedAiIndex", -1);
  },
  methods: {
    expandModelList() {
      this.showModelList = !this.showModelList;
      document.addEventListener("mousedown", this.onMenuBlur);
    },
    selectModel(index) {
      this.$store.dispatch("aiState/writeSelectedAiIndex", index).then(() => {
        this.showModelList = false;
        this.$emit("selectModel", index);
      });
    },
    // 隐藏菜单列表
    onMenuBlur() {
      this.showModelList = false;
      document.removeEventListener("mousedown", this.onMenuBlur);
    },
  },
};
</script>

<style lang="less" scoped>
.model-menu_list {
  position: absolute;
  left: 22px;
  top: 18px;
  width: 55px;
  height: 55px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 3;
  p {
    color: #fff;
    text-align: center;
    font-size: 14px;
  }
  .parent-box {
    position: relative;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    background: url("@/assets/resourcesDetails/ai_bg.png") no-repeat center;

    .mode-type {
      position: absolute;
      left: 74px;
      top: 0;
      width: 150px;
      height: 154px;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      background: black;
      border-radius: 6px;
      overflow-y: auto;
      border: 1px solid #93c8e4;
      z-index: 1;
      &::-webkit-scrollbar {
        width: 4px;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #d1d7dc;
      }
      .item {
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #a3ecfb;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        display: flex;
        .inside-box {
          flex: 1;
          height: inherit;
          border-bottom: 1px solid rgba(163, 236, 251, 0.28);
          display: flex;
          align-items: center;
          .model-icon {
            margin-right: 24px;
          }
          .iconfont {
            font-size: 32px;
            color: #fff;
          }
          .icon-speedboat {
            font-size: 16px;
          }
          .icon-swimming {
            font-size: 24px;
          }
        }
        &:hover {
          color: #40e9f2;
          background: rgba(64, 233, 242, 0.16);
        }
      }
      .item_active {
        color: #40e9f2;
        background: rgba(64, 233, 242, 0.16);
      }
    }
  }
  .parent-box--active {
    background: url("@/assets/resourcesDetails/ai_bg--active.png") no-repeat
      center;
  }
}
</style>
