<template>
  <section class="live-container">
    <div ref="player" :id="liveOptions.playerId" class="vcp-player"></div>
    <div class="sharing" v-if="channelInfo">
      <QrCodeLive :liveType="0" :livedata="channelInfo" />
    </div>
    <div class="mask_layer" v-if="show">
      <i
        v-if="state !== '加载中...'"
        class="el-icon-video-play pointer"
        @click="replay"
      >
      </i>
      <p>{{ state }}</p>
    </div>
  </section>
</template>

<script>
import AgoraRTC from "agora-rtc-sdk-ng";
import QrCodeLive from "@/components/CommonLive/QrCodeLive.vue";
import { playParams } from "@/api/dji";
import { debounce } from "@/utils/index";
// PostStartLive
import { PostStartLive } from "@/api/dji";
export default {
  components: {
    QrCodeLive,
  },
  data() {
    return {
      client: null, // 客户端对象
      channelInfo: null, // 频道信息
      firstDisabled: true,
      show: true,
      state: "加载中...",
      timeId: null,
    };
  },
  props: {
    liveOptions: {
      type: Object,
      require: true,
    },
  },
  created() {
    // SDK 日志输出级别。按照输出日志最全到最少排列：
    // 0: DEBUG。输出所有的 SDK 日志。
    // 1: INFO。输出 INFO、WARNING 和 ERROR 级别的日志。
    // 2: WARNING。输出 WARNING 和 ERROR 级别的日志。
    // 3: ERROR。输出 ERROR 级别的日志。
    // 4: NONE。不输出日志。

    AgoraRTC.setLogLevel(4); //关闭控制台输出
    this.getToken();
  },
  mounted() {},
  beforeDestroy() {
    this.leave();
  },
  methods: {
    // 重播
    async replay() {
      const { deviceSn } = this.liveOptions;
      await PostStartLive({ deviceSn });
    },
    // 获取token
    async getToken() {
      const { deviceSn, cameraType } = this.liveOptions;
      const res = await playParams({
        deviceSn,
        cameraType,
      });
      if (res) {
        this.channelInfo = res.data;

        this.initClient();
      }
    },
    // 客户端初始化
    async initClient() {
      // 把playerId从liveOptions中解构出来
      const { playerId } = this.liveOptions;

      // 构建配置信息
      let ClientConfig = {
        clientRoleOptions: { level: 1 },
        codec: "vp8",
        mode: "live",
        role: "audience",
      };

      this.client = AgoraRTC.createClient(ClientConfig); //构建直播对象

      // 执行join触发的回调
      this.client.on(
        "connection-state-change",
        (curState, revState, reason) => {}
      );

      // 获取到直播流
      this.client.on("user-published", async (user, mediaType) => {
        console.log("检测到直播流");

        await this.client.subscribe(user, "video");
        // await client.subscribe(user，"video");
        // user.audioTrack.play();

        const parentElement = document.getElementById(playerId);

        if (mediaType === "video" && !parentElement.hasChildNodes()) {
          console.log("检测到视频流");
          this.show = false;
          this.state = "加载中...";
          clearTimeout(this.timeId);
          this.timeId = null;
          const remoteVideoTrack = user.videoTrack;
          const remotePlayerContainer = document.createElement("div");
          remotePlayerContainer.id = playerId + "video";
          remotePlayerContainer.style.width = "100%";
          remotePlayerContainer.style.height = "100%";
          const player = this.$refs.player;
          player.append(remotePlayerContainer);
          remoteVideoTrack.play(remotePlayerContainer);

          this.$nextTick(() => {
            this.subscribePlayerEvents();
          });
        }

        if (mediaType === "audio") {
          console.log("存在音频");
          // user.audioTrack.play();
        }
      });

      // 主动离开房间或者网络连接中断后
      this.client.on("user-unpublished", (user) => {
        console.log("主动离开房间或者网络连接中断后");
        this.show = true;
        this.state = "视频中断，请检查设备网络情况，或点击重播按钮";
        const remotePlayerContainer = document.getElementById(
          playerId + "video"
        );

        remotePlayerContainer.remove();

        this.$EventBus.$emit("disableOrthoImage");
        this.$emit("removePlay");
      });

      this.client.on("stream-type-changed", (uid, streamType) => {
        console.log("视频画质发生变化");
      });

      this.client.on("user-info-updated", () => {
        // 状态变化通知
      });

      this.client.on("user-left", () => {
        console.log(`主播离开了直播间`);
        this.$EventBus.$emit("disableOrthoImage");
      });

      this.client.on("user-joined", (user) => {
        console.log(`加入频道的远程用户`);
      });

      await this.join(); //加入直播间

      this.timeId = setTimeout(() => {
        this.state = "未检测到视频源，请检查设备网络,或点击重播按钮";
      }, 5000);
    },
    // 加入房间，开始直播
    async join() {
      const { appid, channel, token } = this.channelInfo;

      await this.client.join(appid, channel, token);
    },
    // 离开，销毁直播间
    async leave() {
      clearTimeout(this.timeId);
      this.timeId = null;
      if (!this.client) return;

      this.client.remoteUsers.forEach((user) => {
        const playerContainer = document.getElementById(user.uid);
        playerContainer && playerContainer.remove();
      });
      await this.client.leave();
    },

    subscribePlayerEvents() {
      //把playerId从liveOptions中解构出来
      const { playerId } = this.liveOptions;
      const player = document.getElementById(playerId + "video").children[0]
        .children[0];

      player.addEventListener("playing", () => {
        this.$emit("canplay");
      });

      player.addEventListener(
        "resize",
        debounce(() => {
          if (this.firstDisabled) {
            this.firstDisabled = false;
            return false;
          }

        //   this.$emit("windowResize");
        }, 500)
      );

      player.addEventListener("pause", () => {
        console.log("播放器已暂停");
      });

      player.addEventListener("ended", () => {
        console.log("播放器已结束");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.live-container {
  flex: 1;
  display: flex;
  position: relative;
  .sharing {
    position: absolute;
    right: 5%;
    top: 45px;
    z-index: 3;
    font-size: 20px;
  }
  .vcp-player {
    width: 100%;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
  .mask_layer {
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 14px;
    z-index: 1;
    .el-icon-video-play {
      margin-bottom: 18px;
      font-size: 42px;
      &:hover {
        color: #4475ff;
      }
      &:active {
        color: #204fd6;
      }
    }
  }

  :deep(video) {
    object-fit: cover !important;
  }
}
</style>
