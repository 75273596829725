import { debounce } from "@/utils/index";
export default {
  computed: {
    // 航线类型airlineType
    airlineType() {
      return this.$store.getters.taskInfo.airlineType;
    },
    // 航线信息
    lineInfo() {
      return this.$store.getters.lineInfo;
    },
    loadSet() {
      return this.$store.getters.loadSet;
    },
    // 航点操作
    menuPosition() {
      return {
        visibility: this.posX === 0 ? "hidden" : "visible",
        left: `${this.posX + 90}px`,
        top: `${this.posY - 20}px`,
      };
    },

    actionList() {
      const { localRouteData, localRouteDataOid } = this.lineInfo;
      if (
        !localRouteData ||
        localRouteData.length == 0 ||
        localRouteDataOid < 0 ||
        localRouteDataOid == localRouteData.length
      )
        return [];
      const actionList = localRouteData[localRouteDataOid].actionList;
      return actionList;
    },
  },
  data() {
    return {
      // 航线显示模式 新建航线  航线显示  航线编辑模式
      displayMode: "new", //edit,display
    };
  },
  created() {
    this.$EventBus.$on("initLine", () => {
      this.initLine();
      setTimeout(() => {
        this.startDraw();
      }, 300);
    });

    this.$EventBus.$on("clearLine", () => {
      this.displayMode = "new";
      this.clearLine();
    });

    this.$EventBus.$on("infoUpdate", () => {
      this.infoUpdate();
    });

    this.$EventBus.$on("deleteNode", () => {
      this.deleteNode();
    });

    // resetAirline
    this.$EventBus.$on("resetAirline", () => {
      // this.deleteNode()
      this.displayMode = "new";
      // this.clearLine()
      this.initLine();
      this.startDraw();
      this.$EventBus.$emit("hideMenu");
      this.infoUpdate(); // 视图更新
    });
    // 高度变更
    this.$EventBus.$on("overallHeightChange", (height) => {
      this.overallHeightChange(height);
    });

    this.$EventBus.$on("updateFlyPoint", (info) => {
      this.updateFlyPoint(info);
    });

    this.$EventBus.$on("cdCalculate", () => {
      this.cdCalculate();
    });

    this.$EventBus.$on("updateStyle", (info) => {
      this.updateStyle(info);
    });

    this.$EventBus.$on("drawAirLine", (info) => {
      this.drawAirLine();
    });
    this.$EventBus.$on("editAirLine", () => {
      this.editAirLine();
    });

    // drawFlightRoutes
    this.$EventBus.$on("drawFlightRoutes", (localRouteData, arr) => {
      this.drawFlightRoutes(localRouteData, arr);
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("initLine");
    this.$EventBus.$off("clearLine");
    this.$EventBus.$off("infoUpdate");
    this.$EventBus.$off("deleteNode");
    this.$EventBus.$off("resetAirline");
    this.$EventBus.$off("overallHeightChange");
    this.$EventBus.$off("updateFlyPoint");
    this.$EventBus.$off("cdCalculate");
    this.$EventBus.$off("updateStyle");
    this.$EventBus.$off("drawAirLine");
    this.$EventBus.$off("editAirLine");
    this.$EventBus.$off("drawFlightRoutes");
  },
  methods: {
    // 航线初始化
    initLine() {
      this.clearLine();

      let typeLine = 0;

      switch (this.airlineType) {
        case "waypoint":
          typeLine = 0; // 0：点状航线 1：带状航线 2：面状航线
          break;
        case "mappingStrip":
          typeLine = 1; // 0：点状航线 1：带状航线 2：面状航线
          break;
        case "mapping2d":
          typeLine = 2; // 0：点状航线 1：带状航线 2：面状航线
          break;
      }

      airline = zr3d.flyLineCreate({
        type: typeLine, // 0：点状航线 1：带状航线 2：面状航线
        heightMode: 0, //飞机高度模式：0-相对飞机起点位置，1-绝对高度（海拔高度），2-相对地面高度
        airHomePosition: this.centralPoint, //飞机起点位置  flyHomePosition
        altitude: this.lineInfo.altitude, //高度--
        baseHeight: this.lineInfo.altitude, //基准高度--距离地面或者海拔高度
        selectedChangeCallback: this.selectedChangeCallback, //选择航点回调
        positionChangeCallback: this.positionChangeCallback, //航点位置变化回调
        mouseRightClickCallback: this.mouseRightClickCallback, //mark坐标右键点击事件
        drawFinishHandler: this.drawFinishHandler, //绘制完成事件
        guid: "globeDrawerLayer", //唯一ID
        dragIcon: require("@/assets/flyline/dragPoint.png"), //中间拖拽点图标-暂时不使用了
        flyPointIcon: require("@/assets/flyline/flyPoint.png"), //航点图标
        flyPointSelectIcon: require("@/assets/flyline/flyPoint_sel.png"), //航点高亮图标
        startIcon: require("@/assets/flyline/startPoint.png"), //起始点图标
        lineWidth: this.lineInfo.lineWidth,
        overlapNum: this.lineInfo.overlapNum, //航向重叠率
        sidelapNum: this.lineInfo.sidelapNum, //旁向重叠度
        courseAngle: this.lineInfo.courseAngle, //航向角度
      });
    },
    // 航线清除
    clearLine() {
      if (airline) {
        airline.clear();
        airline = null;
        if (this.displayMode == "new")
          this.$store.dispatch("airline/clearLineInfo");
      }
    },
    //更新航点视锥
    updatePosition(id) {
      if (id == -1) {
        airline.clearFrustum();
        return;
      }
      const { localRouteData, selectedActionNodeIndex, localRouteDataOid } =
        this.lineInfo;
      if (
        !localRouteData ||
        localRouteData.length == 0 ||
        localRouteData.length == localRouteDataOid
      )
        return;

      var posture = airline.getCameraPosture(
        localRouteData,
        id,
        selectedActionNodeIndex
      );
      airline.updateFrustum(posture);
    },
    //航点选择回调函数
    selectedChangeCallback(data) {
      console.log("航点选择回调函数", data);
      if (!data) return;
      const { oid } = data;
      this.$store.commit("airline/SET_LINE_INFO", { localRouteDataOid: oid });
      // 每次选中航点时，将选中的航点的actionIndex置为当前航点的actionList的长度
      this.$store.commit("airline/SET_LINE_INFO", {
        selectedActionNodeIndex: this.actionList.length - 1,
      });

      this.updatePosition(oid);
    },
    // 节点信息变动回调
    positionChangeCallback: debounce(function (e) {
      console.log("节点信息变动回调");
      console.log(e);
      const { oid, position } = e;
      // if (this.readOnly) return;
      if (this.airlineType !== "waypoint") return;

      this.$EventBus.$emit("hideMenu");
      // if (oid == 0) {
      //     this.localRouteDataInit();

      // }

      // airline.updateFlyPoint({
      //   x: position.x,
      //   y: position.y,
      //   z: position.z,
      //   oid: oid,
      // });
      this.localRouteDataInit();
      this.updatePosition(this.lineInfo.localRouteDataOid);
      this.infoUpdate(); // 更新展示信息
      // this.$store.commit("airline/SET_LOCAL_ROUTE_DATA_CHANGE", {
      //     height: position.z,
      //     lng: position.x,
      //     lat: position.y,
      // });

      // this.updatePosition(oid)
    }, 300),
    // mark坐标右键点击事件
    mouseRightClickCallback(data) {
      console.log("mark坐标右键点击事件");
      // if (this.readOnly) return;
      if (!data) return;

      var event = event || window.event;
      this.posX = event.pageX - 106;
      this.posY = event.pageY - 62;

      // 固定地图
      zr3d.mapOnInput({
        on: false,
      });
    },
    // 绘制完成回调
    drawFinishHandler(position, waypointInfo) {
      // 写入中心点列表
      if (this.airlineType !== "waypoint")
        this.$store.commit("airline/SET_LINE_INFO", {
          centerPointList: position,
        });

      this.localRouteDataInit(); // 本地航线数据的初始化
      this.infoUpdate(); //信息更新
    },
    // 开启航线绘制
    startDraw() {
      airline.startDraw();
    },

    // 更新部分展示信息
    infoUpdate() {
      let info = {
        routeLength: airline.getTotalLength().toFixed(1),
        waypointCount: airline.getPositions().length,
        flightTime: parseInt(airline.getTotalLength() / this.lineInfo.speed),
      };

      if (this.airlineType !== "waypoint") {
        info.photos = airline.getPhotoNum();
        info.pictureResolution = airline.getResolution().toFixed(2);
      } else {
        let num = 0;

        const { localRouteData } = this.lineInfo;

        for (let i = 0; i < localRouteData.length; i++) {
          const el = localRouteData[i];

          for (let j = 0; j < el.actionList.length; j++) {
            const item = el.actionList[j];
            if (item.action == 0) num += 1;
            if (item.action == 3) info.flightTime += item.hoverTime;
          }

          if (i == localRouteData.length - 1) info.photos = num;
        }
      }

      // 写入数据
      this.$store.commit("airline/SET_STATISTICS_INFO", info);

      if (this.airlineType == "waypoint") {
        this.updatePosition(this.lineInfo.localRouteDataOid);
      }
    },
    // 本地航线数据初始化
    localRouteDataInit() {
      let lineArr = [];
      const { localRouteData } = this.lineInfo;

      const copiedData = JSON.parse(JSON.stringify(localRouteData));

      airline.getPositions().forEach((item, index) => {
        // 缓存数据至本地
        if (this.airlineType == "waypoint") {
          if (copiedData.length < 1) {
            lineArr.push({
              lng: item.x,
              lat: item.y,
              altitude: item.altitude,
              height: Number(item.z.toFixed(0)), //第一次创建进行高度初始化
              waypointHeadingAngle: 0,
              actionList: [],
            });
          } else {
            // 获取旧的数据进行补充
            lineArr.push({
              lng: item.x,
              lat: item.y,
              altitude: item.altitude,
              height: Number(item.z.toFixed(0)),
              waypointHeadingAngle: copiedData[index].waypointHeadingAngle,
              actionList: copiedData[index].actionList,
            });
          }
        } else {
          lineArr.push({
            lng: item.x,
            lat: item.y,
            altitude: item.z,
            height: this.lineInfo.altitude, //第一次创建进行高度初始化
          });
        }
      });
      this.$store.commit("airline/SET_LINE_INFO", { localRouteData: lineArr });
    },
    // 航点删除
    deleteNode() {
      if (this.lineInfo.localRouteDataOid == -1) {
        // 未选择航点
        this.$message("请选择航点");
        return false;
      } else if (this.lineInfo.localRouteDataOid == 0 && this.lineInfo.localRouteData.length > 1) {
        // 选择的是第一个航点
        this.$message("基准航点不可删除");
        return false;
      } else if (this.lineInfo.localRouteData.length == 1) {
        // 长度为1
        this.initLine();
        this.startDraw();
      } else {
        airline.removeFlyPoint(this.lineInfo.localRouteDataOid);

        this.$store.commit(
          "airline/SET_DEL_LOCAL_ROUTE_DATA",
          this.lineInfo.localRouteDataOid
        );

        airline.selectFlyPoint(this.lineInfo.localRouteDataOid); //航点形式下锁定选中最后一个航点
        this.infoUpdate(); //信息更新
      }

      this.$EventBus.$emit("hideMenu");
      this.infoUpdate();
    },
    // 航线整体高度变更
    overallHeightChange: debounce(function (height) {
      airline.adjustHeight(height); //航线高度变更
      this.localRouteDataInit();
      if (this.airlineType !== "waypoint") this.cdCalculate();

      if (this.airlineType == "waypoint") {
        this.updatePosition(this.lineInfo.localRouteDataOid);
      }
    }, 300),
    // 拍摄间隔计算函数
    cdCalculate() {
      // debugger;
      if (this.airlineType == "waypoint") return;

      const { sensorHeight, equivalentFocalLength, minPhotoInterval } =
        this.loadSet.localPayloadModel;
      const { overlapNum, altitude, speed } = this.lineInfo;

      if (!sensorHeight || !equivalentFocalLength || !minPhotoInterval) return;

      let newShootCd =
        (((100 - overlapNum) / 100) * altitude * sensorHeight) /
        (equivalentFocalLength * speed);

      if (newShootCd < minPhotoInterval) {
        newShootCd = minPhotoInterval;
        let flightSpeed =
          (((100 - overlapNum) / 100) * altitude * sensorHeight) /
          equivalentFocalLength;

        if (flightSpeed > 15) flightSpeed = 15; // 限制最大值
        if (flightSpeed < 1) flightSpeed = 1;
      }

      this.$store.commit("airline/SET_LOAD_SET", { shootCd: newShootCd });
    },
    // 节点信息更新
    updateFlyPoint(pointInfo) {
      const { height, lat, lng } = pointInfo;

      airline.updateFlyPoint({
        x: lng,
        y: lat,
        z: height,
        oid: this.lineInfo.localRouteDataOid,
      });
      this.localRouteDataInit();
      this.updatePosition(this.lineInfo.localRouteDataOid);
    },
    // 航线样式更新
    updateStyle(info) {
      airline.updateStyle(info);
    },
    // 显示航线
    async drawAirLine() {
      this.displayMode = "edit";
      this.initLine();

      const {
        localRouteData,
        overlapNum,
        altitude,
        speed,
        sidelapNum,
        lineWidth,
        centerPointList,
        courseAngle,
        flyHomePosition,
      } = this.lineInfo;

      const { localPayloadModel } = this.loadSet;

      switch (this.airlineType) {
        case "waypoint":
          let arr = [];
          localRouteData.forEach((el) => {
            arr.push({
              x: el.lng,
              y: el.lat,
              z: el.height,
              oid: el.order,
            });
          });

          //飞机高度模式：0-相对飞机起点位置，1-绝对高度（海拔高度），2-相对地面高度
          airline.drawPolyline(arr, {
            heightMode: 0,
            flyHomePosition: flyHomePosition,
          }); //绘制航点航线

          this.lockCenterPoint(localRouteData);
          break;
        case "mappingStrip":
          airline.load(
            {
              options: {
                overlapNum: overlapNum, //航线重叠度
                sidelapNum: sidelapNum, //旁向重叠度
                lineWidth: lineWidth,
                altitude: altitude, //飞行高度
                speed: speed, //飞行速度
                cameraOptions: {
                  sensorwidth: localPayloadModel.sensorWidth, //传感器宽度
                  sensorheight: localPayloadModel.sensorHeight, //传感器高度
                  focallen: localPayloadModel.equivalentFocalLength, //焦距
                  imageheight: localPayloadModel.photoHeight,
                  imagewidth: localPayloadModel.photoWidth,
                },
              },
              positions: centerPointList, //图形中心点
            },
            { flyHomePosition: flyHomePosition }
          );
          this.lockCenterPoint(localRouteData);
          break;
        case "mapping2d":
          airline.load(
            {
              options: {
                courseAngle: courseAngle, //航向角度
                overlapNum: overlapNum, //航线重叠度
                sidelapNum: sidelapNum, //旁向重叠度
                altitude: altitude, //飞行高度
                speed: speed, //飞行速度
                cameraOptions: {
                  sensorwidth: localPayloadModel.sensorWidth, //传感器宽度
                  sensorheight: localPayloadModel.sensorHeight, //传感器高度
                  focallen: localPayloadModel.equivalentFocalLength, //焦距
                  imageheight: localPayloadModel.photoHeight,
                  imagewidth: localPayloadModel.photoWidth,
                },
              },
              positions: centerPointList, //图形中心点
            },
            { flyHomePosition: flyHomePosition }
          );
          this.lockCenterPoint(localRouteData);
          break;
      }
    },
    // 锁定中心点以及视距
    lockCenterPoint(pointList) {
      if (pointList.length === 0) return;
      var positions=[]
      pointList.forEach(item=>{
        positions.push({
          x:item.lng,
          y:item.lat,
          z:item.altitude!=null?item.altitude:null
        })
      })
      //根据一堆坐标点定位到地图位置
      zr3d.mapZoomToPoints({
        points:positions,
        options:{
            pitch:-45,  //俯仰角，-90是正射向下看，-45是倾斜45度角看
            flyTime:1,  //定位飞行动画时间，单位秒
            ratio:1.5   //半径倍数
          }
        }
      );
    },
    //航线编辑模式
    async editAirLine() {
      await this.drawAirLine();
      setTimeout(() => {
        airline.activeEdit(true); //开启编辑模式
        const { localRouteDataOid } = this.lineInfo;
        if (this.airlineType == "waypoint")
          airline.selectFlyPoint(localRouteDataOid); //航点形式下锁定选中最后一个航点
      }, 300);
    },

    drawFlightRoutes(localRouteData, arr) {
      airline.drawPolyline(arr, { heightMode: 0 });

      this.lockCenterPoint(localRouteData);
      const localRouteDataOid = localRouteData.length - 1;
      this.$store.commit("airline/SET_LINE_INFO", {
        localRouteData,
        localRouteDataOid,
        waypointHeadingMode: "smoothTransition", //飞行器偏航角模式
      });

      setTimeout(() => {
        airline.activeEdit(true); //开启编辑模式
        airline.selectFlyPoint(localRouteDataOid); //航点形式下锁定选中最后一个航点
        this.infoUpdate(); //信息更新
      }, 300);
    },
  },
};
