const getters = {
    // 登录信息
    avatar: (state) => state.user.avatar,
    name: (state) => state.user.name,
    token: (state) => state.user.token,
    roleId: (state) => state.user.roleId,
    userId: (state) => state.user.id,
    workspaceId: (state) => state.user.workspaceId,
    unitId: (state) => state.user.unitId,
    // routeList
    routeList: (state) => state.user.routeList,
    homepage: (state) => state.user.homepage,
    // 成果
    resultsProjectId: (state) => state.results.projectId,
    projectName: (state) => state.results.projectName,
    taskMark: (state) => state.results.taskMark,
    resultsTaskId: (state) => state.results.taskId,
    thumbInfo: (state) => state.results.thumbInfo,
    trace: (state) => state.results.trace,
    videoUrls: (state) => state.results.videoUrls,
    imageUrls: (state) => state.results.imageUrls,
    panoramaUrls: (state) => state.results.panoramaUrls,
    clampToGround: (state) => state.results.clampToGround,
    // mark标记任务
    basicInfo: (state) => state.mark.basicInfo,
    markInfo: (state) => state.mark.markInfo,
    markStrategy: (state) => state.mark.markStrategy,
    // 资源列表
    getAllResList: (state) => state.resource.resList,
    resId: (state) => state.resource.resId,
    currentComponent: (state) => state.resource.currentComponent,
    // 新的航线数据
    taskInfo: (state) => state.airline.taskInfo,
    statisticsInfo: (state) => state.airline.statisticsInfo,
    lineInfo: (state) => state.airline.lineInfo,
    loadSet: (state) => state.airline.loadSet,
    strategyInfo: (state) => state.airline.strategyInfo,
    userInputting: (state) => state.airline.userInputting,
    // 直播相关
    playResInfo: (state) => state.live.playResInfo,
    playList: (state) => state.live.playList,
    orthoImageLayerId: (state) => state.live.orthoImageLayerId,
    liveStatusList: (state) => state.live.liveStatus,
    // 指挥》机场相关
    // 无人机
    droneInfo: (state) => state.drone.droneInfo, //无人机数据集
    droneName: (state) => state.drone.droneName, //无人机名称
    // 机场
    airportInfo: (state) => state.airport.airportInfo,//机场数据搜集
    airportName: (state) => state.airport.airportName,//机场名称
    airportClass: (state) => state.airport.airportClass,//机场类型 自研机库：3999    大疆机库1：31    大疆机库2:  32
    airportState: state => state.airport.allState,//机场命令状态参数
    airportAirLineStatus: state => state.airport.airportAirlineManage.status,//机场执行航线状态
    airportAirLineInfo: state => state.airport.airportAirlineManage.info,//机场执行航线进度信息
    // isShowVideoWrit
    isShowVideo: (state) => state.airport.isShowVideo,
    //遥控器
    remoteInfo: (state) => state.remote.remoteInfo,
    // 地勤人员
    displayGround: (state) => state.ground.displayGround,
    infoGrounde: (state) => state.ground.infoGrounde,
    resourceStatus: (state) => state.ground.resourceStatus,
    groundeInfo: (state) => state.ground.groundeInfo,
    // 项目标注相关
    projectMarkInfo: state => state.projectMark.projectMarkInfo,
    // 地图相关
    viewpoint: (state) => state.map.viewpoint,
    viewpoints: (state) => state.map.viewpoints,
    //   轨迹
    isShowTrack: (state) => state.map.isShowTrack,
    // 行动状态
    isActionStarted: (state) => state.map.isActionStarted,
    // 统计
    droneStatus: (state) => state.statistics.droneStatus,
    pilotStatus: (state) => state.statistics.pilotStatus,
    policeStatus: (state) => state.statistics.policeStatus,
    dateType: (state) => state.statistics.dateType,
    // selectedAiIndex
    selectedAiIndex: (state) => state.aiState.selectedAiIndex,
    // 目标飞行经纬度信息
    targetLongitude: (state) => state.flightControl.longitude,
    targetLatitude: (state) => state.flightControl.latitude,
    targetHeight: (state) => state.flightControl.height,
    targetSpeed: (state) => state.flightControl.speed,
    targetDistance: (state) => state.flightControl.distance,
    targetTime: (state) => state.flightControl.time,
    showFlyTarget: (state) => state.flightControl.showFlyTarget,

    isShowTerrain: (state) => state.map.isShowTerrain,
    is3D: (state) => state.map.is3D,
    // alarmInterval
    alarmInterval: (state) => state.aiState.alarmInterval,
    // personId
    personId: (state) => state.aiState.personId,
    // isPush
    isPush: (state) => state.aiState.isPush,
    carCount: (state) => state.aiState.carCount,
    // aiImgList
    aiImgList: (state) => state.aiState.aiImgList,
    // airportFovHeight
    airportFovHeight: (state) => state.airport.airportFovHeight,

    radarTaskList: (state) => state.radar.taskList,
    // 雷达相关
    radarToken: (state) => state.radar.token,
    radarIndex: (state) => state.radar.radarIndex,
};
export default getters;
