<template>
  <section class="form-group-container">
    <label v-if="title" for="customRange">{{ title }} <slot></slot> </label>
    <div class="ranges">
      <input
        class="form-range"
        ref="range"
        type="range"
        id="customRange"
        :style="rangeStyle"
        @input="handleRangeChange"
        :min="min"
        :max="max"
      />
      <input
        type="number"
        ref="input"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @keydown="handleEnter"
        class="form-number"
      />
    </div>
  </section>
</template>

<script>
import { debounce } from "@/utils/index.js";
export default {
  data() {
    return {
      // 是否聚焦
      isFocus: false,
    };
  },
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    value: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
  },

  computed: {
    rangeStyle() {
      const percent = ((this.value - this.min) * 100) / (this.max - this.min);
      return {
        backgroundImage: `-webkit-linear-gradient(left,#40e9f2 0%,#40e9f2 ${percent}%,#fff ${percent}%,#fff 100%)`,
      };
    },
    // 获取props的值
    getPropsValue() {
      return this.value === null || this.value === undefined
        ? 0
        : Number(this.value);
    },
  },
  watch: {
    value: {
      handler(nv) {
        this.setValue();
      },
    },
  },
  mounted() {
    this.setValue();
  },
  methods: {
    // v的初始化
    setValue() {
      const input = this.$refs.input;
      const range = this.$refs.range;
      if (!input) return;
      if (!range) return;
      if (
        input.value === this.getPropsValue &&
        range.value === this.getPropsValue
      )
        return;

      input.value = this.getPropsValue;
      range.value = this.getPropsValue;
    },

    // input的值改变
    handleInput(event) {
      //  判断当前是否处于聚焦状态
      if (this.isFocus) {
        debounce(this.changeValue(event), 100);
        // 聚焦状态
      } else {
        this.changeValue(event);
      }
    },
    // 滑动条的值改变
    handleRangeChange(event) {
      this.changeValue(event);
    },
    // // 聚焦事件
    handleFocus(event) {
      this.isFocus = true;
    },
    // 失焦
    handleBlur(event) {
      this.isFocus = false;
      this.changeValue(event);
    },
    // 键盘回车事件
    handleEnter(event) {
      if (event.keyCode === 13) {
        // this.isFocus = false;
        this.changeValue(event);
      }
    },
    // 抽离修改数据的部分代码
    changeValue(event) {
      if (event.target.value === "")
        this.min > -1 ? (event.target.value = 0) : (event.target.value = 1);
      if (event.target.value > this.max) event.target.value = this.max;
      if (event.target.value < this.min) event.target.value = this.min;
      if (Number(event.target.value) === this.setValue) return;
      // 双向绑定
      this.$emit("input", Number(event.target.value));
      this.$emit("change", Number(event.target.value));
      this.$nextTick(this.setValue);
    },
  },
};
</script>

<style lang="less" scoped>
.form-group-container {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 26px;
  margin-bottom: 36px;
  label {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .ranges {
    display: flex;
    align-items: center;
    flex: 1;
    .form-number {
      width: 70px;
      height: 38px;
      border: 1px solid #93c8e4;
      outline: none;
      border-radius: 2px;
      background: transparent;
      text-align: center;
      font-size: 19px;
      font-weight: 500;
      color: #ffffff;
    }
  }

  input[type="range"] {
    flex: 1;
    -webkit-appearance: none;
    margin-right: 20px;
    border-radius: 2px;
    height: 4px;
    background-image: -webkit-linear-gradient(
      left,
      #40e9f2 0%,
      #40e9f2 50%,
      #fff 50%,
      #fff 100%
    );
    outline: none;
    transition: 0.1s;
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 22px;
    height: 22px;
    background: #40e9f2;
    border-radius: 50%;
    transition: 0.1s;
    cursor: pointer;
    border: 4px solid white;
  }

  input[type="range"]::-webkit-slider-thumb:hover,
  input[type="range"]::-webkit-slider-thumb:active {
    width: 26px;
    height: 26px;
  }
}
</style>
