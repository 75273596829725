const state = {
    viewpoint: {}, // 当前视口信息
    viewpoints: [], //视点数组
    isActionStarted: 0, //行动状态 4种状态 0：默认值 1：目标追踪任务 2：空 3：标注 4：路径规划
    // 是否显示轨迹
    isShowTrack: false,
    // 是否显示地形
    isShowTerrain: localStorage.getItem('isShowTerrain') == 'true' ? true : false,
    // 是否3d模式
    is3D: localStorage.getItem('is3D') == 'true' ? true : false,

}

const mutations = {
    // viewpoint0
    SET_VIEW_POINT: (state, viewpoint) => {
        state.viewpoint = viewpoint
    },
    //记录保存的视点
    SET_VIEW_POINTS: (state, viewpoints) => {
        state.viewpoints = viewpoints
    },
    //行动状态
    SET_IS_ACTION_STARTED: (state, isActionStarted) => {
        state.isActionStarted = isActionStarted
    },
    // 是否显示轨迹
    SET_IS_SHOW_TRACK: (state, isShowTrack) => {
        state.isShowTrack = isShowTrack
    },
    // 是否显示地形
    SET_IS_SHOW_TERRAIN: (state, isShowTerrain) => {
        state.isShowTerrain = isShowTerrain
    },
    // 是否3d模式
    SET_IS_3D: (state, is3D) => {
        state.is3D = is3D
    }


}

const actions = {
    //行动状态
    setIsActionStarted({ commit }, isActionStarted) {
        commit('SET_IS_ACTION_STARTED', isActionStarted)
    },
    // 轨迹
    setIsShowTrack({ commit }, isShowTrack) {
        commit('SET_IS_SHOW_TRACK', isShowTrack)
    },
    // 地形
    setIsShowTerrain({ commit }, isShowTerrain) {
        commit('SET_IS_SHOW_TERRAIN', isShowTerrain)
        localStorage.setItem('isShowTerrain', isShowTerrain)
    },
    // 3d模式
    setIs3D({ commit }, is3D) {
        commit('SET_IS_3D', is3D)
        localStorage.setItem('is3D', is3D)
    }


}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}