<template>
  <CommonDialog showMask>
    <section class="add-task_container">
      <slot />
      <div class="title">创建新任务</div>
      <div class="content">
        <div class="item-input">
          <label for=""><span class="mandatory">*</span>任务名称</label>
          <div class="input-box">
            <vInput
              v-model.trim="taskName"
              @blur="taskNameBlur()"
              :err="taskNameErr"
              :max="20"
              placeholder="请输入任务名称"
            />
          </div>
        </div>

        <!-- <div class="item-input">
          <label for=""><span class="mandatory">*</span>选择警种</label>
          <div class="input-box" :class="{ err: projectIdErr }">
            <el-select
              :disabled="isDisableProject"
              v-model="projectId"
              placeholder="请选择"
              @visible-change="projectVisibleChange"
              @change="projectIdChange"
            >
              <el-option
                v-for="item in projectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div> -->

        <div class="item-input">
          <label for=""><span class="mandatory">*</span>选择执行资源</label>
          <div class="input-box" :class="{ err: resourcesErr }">
            <el-select
              :disabled="isResource"
              v-model="executor"
              placeholder="请选择"
              @visible-change="resVisibleChange"
              @change="resourcesChange"
            >
              <el-option
                v-for="item in resourcesOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="item-input">
          <label for=""><span class="mandatory">*</span>选择航线类型</label>
          <ul class="class-list">
            <li
              class="list-item pointer"
              :class="{ is_active: item.type === airlineType }"
              v-for="(item, index) in typesList"
              :key="index"
              @click="chooseType(item)"
            >
              <i :class="item.icon"></i>
              <span>{{ item.text }}</span>
            </li>
          </ul>
        </div>

        <div class="btn-group">
          <button class="btn cancel-btn pointer" @click="cancel">
            <span>取消</span>
          </button>
          <button class="btn determine-btn pointer" @click="determine">
            <span>确定</span>
          </button>
        </div>
      </div>
    </section>
  </CommonDialog>
</template>

<script>
import { debounce } from "@/utils/index";
import { validTaskName } from "@/utils/validate";
import CommonDialog from "@/components/CommonDialog/index.vue";
import vInput from "@/components/CommonInput/index.vue";
import { list, getProjectList } from "@/api/project";
import { resourceList } from "@/api/resources.js";
// import { projectResourceList } from "@/api/resources";

export default {
  components: {
    CommonDialog,
    vInput,
  },
  computed: {
    // 获取任务相关信息
    taskInfo() {
      return this.$store.getters.taskInfo;
    },
  },
  data() {
    return {
      taskName: "", // 任务名称
      //   projectId: "", // 项目id
      executor: "", //任务执行人id
      airlineType: "", //航线类型
      jobExecType: NaN, //资源类型
      taskNameErr: false,
      projectIdErr: false,
      resourcesErr: false,
      projectOptions: [],
      resourcesOptions: [],
      typesList: [
        {
          icon: "iconfont icon-waypoint",
          text: "航点飞行",
          type: "waypoint",
        },
        {
          icon: "iconfont icon-strip",
          text: "航带飞行",
          type: "mappingStrip",
        },
        {
          icon: "iconfont icon-mapping",
          text: "建图航拍",
          type: "mapping2d",
        },
      ],
      //以下数据处理回显
      executor: "",
      resourceName: "",
      jobExecType: NaN,
      isResource: false, //判断是否为资源查询项目
      isDisableProject: false, //判断该资源是否有项目
    };
  },
  created() {
    const { executor, jobExecType, nickName } = this.taskInfo;
    // 判断是否为编辑
    if (executor !== "") {
      this.executor = executor;
      this.jobExecType = jobExecType;
      this.resourceName = nickName;
      this.isResource = true;
      this.getProjectItem();
    } else {
      this.getProjectList();
      this.$store.dispatch("airline/clearTaskInfo");
    }
  },
  mounted() {
    this.getProjectResourceList();
  },
  beforeDestroy() {},
  methods: {
    // 获取项目资源列表
    async getProjectResourceList() {
      let data = { resourceStatus: 0, resourceTypeList: [1, 2] };
      const res = await resourceList(data);
      if (res) {
        this.resourcesOptions = res.data.map((item) => {
          return {
            value: item.id,
            label: item.resourceName,
            jobExecType: item.resourceType,
          };
        });
      }
    },
    // 选择航线类型
    chooseType(item) {
      this.airlineType = item.type;
    },
    verify() {
      if (this.isDisableProject) {
        this.$notify({
          title: "消息",
          type: "info",
          message: "该资源暂无项目",
          offset: 150,
          duration: 3000,
        });
        return false;
      } else if (this.taskName === "") {
        this.$notify({
          title: "消息",
          type: "info",
          message: "任务名称不可为空",
          offset: 150,
          duration: 3000,
        });
        this.taskNameErr = true;
        return false;
      } else if (!validTaskName(this.taskName)) {
        this.$notify({
          title: "消息",
          type: "info",
          message: "请输入1~20位字符,仅支持中文、字母、数字",
          offset: 150,
          duration: 3000,
        });
        this.taskNameErr = true;
        return false;
      } else if (this.executor === "") {
        this.$notify({
          title: "消息",
          type: "info",
          message: "请选择资源",
          offset: 150,
          duration: 3000,
        });
        this.resourcesErr = true;
        return false;
      } else if (this.airlineType === "") {
        this.$notify({
          title: "消息",
          type: "info",
          message: "请选择航线类型",
          offset: 150,
          duration: 3000,
        });
        return false;
      } else {
        return true;
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    // 获取项目列表
    async getProjectList() {
      const res = await list({ pageNum: 1, pageSize: 100, projectStatus: 2 });
      if (res) {
        this.projectOptions = [];
        res.data.forEach((element) => {
          this.projectOptions.push({
            value: element.id,
            label: element.projectName,
          });
        });
      }
    },
    taskNameBlur() {
      this.taskName === ""
        ? (this.taskNameErr = true)
        : (this.taskNameErr = false);
    },
    // 下拉框出现时触发
    projectVisibleChange(value) {
      if (value) return;
      if (this.projectId == "") {
        this.projectIdErr = true;
      } else {
        this.projectIdErr = false;
      }
    },
    // 选择警种
    projectIdChange() {
      this.getProjectResourceList();
    },
    resVisibleChange(value) {
      if (value) return;
      if (this.executor === "") {
        this.resourcesErr = true;
      } else {
        this.resourcesErr = false;
      }
    },
    resourcesChange(value) {
      const resourcesObj = this.resourcesOptions.find(
        (item) => item.value === value
      );
      this.jobExecType = Number(resourcesObj.jobExecType);
    },
    determine: debounce(function () {
      if (!this.verify()) return;
      const info = {
        taskName: this.taskName, //任务名称
        // projectId: this.projectId, //项目id
        executor: this.executor, // 执行者|资源id
        jobExecType: this.jobExecType, //资源类型
        airlineType: this.airlineType, // 航线类型waypoint：航点飞行；mapping2d：建图航拍；mapping3d：倾斜摄影；mappingStrip：航带飞行
      };

      this.$store.dispatch("airline/addTaskInfo", info).then(() => {
        this.cancel();
        this.$router.push({
          path: "/courseTasks/index",
          query: {
            // 当前页面模式
            mode: "add",
          },
        });
      });
    }),
    // 获取指定项目资源
    async getProjectItem() {
      const { executor, nickName, jobExecType } = this.taskInfo;

      this.resourcesOptions = []; //资源列表
      this.resourcesOptions.push({
        value: executor,
        label: nickName,
        jobExecType,
      });
      const res = await getProjectList({ resourceId: executor }); //获取相关资源的项目

      if (res) {
        this.projectOptions = []; //置空项目列表

        if (res.data.length === 0) {
          this.projectOptions.push({
            value: 0,
            label: "该资源暂无项目",
          });

          this.projectId = 0;
          this.isDisableProject = true;
          return false;
        }

        // 资源所属项目赋值
        res.data.forEach((element) => {
          this.projectOptions.push({
            value: element.projectId,
            label: element.projectName,
          });
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.add-task_container {
  width: 590px;
  background: linear-gradient(
    1deg,
    rgba(8, 33, 75, 0.9) 0%,
    rgba(7, 10, 13, 0.9) 100%
  );
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  /deep/ .el-input__inner {
    background: transparent;
    color: white;
    font-size: 16px;
    height: 34px;
    line-height: 34px;
    border: 1px solid #989da5;
    &::placeholder {
      font-size: 16px;
      font-family: PingFang SC-Light, PingFang SC;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.59);
    }
  }
  /deep/ .el-select {
    display: flex;
  }
  .title {
    height: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 20px;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.31);
  }
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 94px;

    .item-input {
      color: #ffffff;
      font-size: 18px;
      font-weight: 300;
      padding-top: 16px;
      display: flex;

      flex-direction: column;
      label {
        margin-bottom: 10px;
      }
      .mandatory {
        color: #ef6767;
      }
      .input-box {
        height: 34px;
      }
    }
    .class-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .list-item {
        width: 106px;
        height: 116px;
        background: #100f0f;
        border-radius: 6px;
        border: 1px solid black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .iconfont {
          font-size: 40px;
          margin-bottom: 20px;
        }
        span {
          font-size: 10px;
          color: #ffffff;
        }
        &:hover {
          .iconfont {
            color: #a3ecfb;
          }
          span {
            color: #a3ecfb;
          }
        }
      }
      .is_active {
        border-color: #40e9f2;
        .iconfont,
        span {
          color: #40e9f2;
        }
      }
    }
    .btn-group {
      margin-bottom: 30px;
      display: flex;
      justify-content: flex-end;

      .btn {
        width: 116px;
        height: 42px;
        border-radius: 4px;
        font-size: 20px;
        color: #ffffff;
        border: none;
      }
      .cancel-btn {
        background: transparent;
        border: 1px solid #a2bac5;
        margin-right: 30px;
        &:hover {
          border-color: #a3ecfb;
          color: #a3ecfb;
        }
        &:active {
          border-color: #40e9f2;
          color: #40e9f2;
        }
      }
      .determine-btn {
        background: #05cdd8;
        &:hover {
          background-color: #26ecf6;
        }
        &:active {
          background-color: #00b1bb;
        }
      }
    }
  }
}
.err {
  /deep/ .el-input__inner {
    border-color: red;
  }
}
::v-deep .el-input.is-disabled .el-input__inner {
  background: transparent;
  color: white;
  font-size: 16px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #989da5;
}
</style>
