import { taskThumbInfo } from "@/api/results"
const state = {
    projectId: '', //项目id
    projectName: '', // 项目名称
    taskMark: [], //任务图标
    taskId: -1, //任务id
    thumbInfo: {}, //简略信息
    trace: [], //轨迹信息
    videoUrls: [], // 视频信息
    imageUrls: [], // 图片列表
    panoramaUrls: [], // 全景图列表
    clampToGround: false //是否为地勤人员
}

const mutations = {
    // id
    SET_PROJECT_ID: (state, projectId) => {
        state.projectId = projectId
    },
    SET_CL_TO_GR: (state, clampToGround) => {
        state.clampToGround = clampToGround
    },
    // 
    SET_PROJECT_NAME: (state, projectName) => {
        state.projectName = projectName
    },
    // taskMark
    SET_TASK_MARK: (state, taskMark) => {
        state.taskMark = taskMark
    },
    // taskId
    SET_TASK_ID: (state, taskId) => {
        state.taskId = taskId
    },
    SET_THUMB_INFO: (state, thumbInfo) => {
        state.thumbInfo = thumbInfo
    },
    // trace
    SET_TRACE: (state, trace) => {
        state.trace = trace
    },
    // videoUrls
    SET_VIDEO_URLS: (state, videoUrls) => {
        state.videoUrls = videoUrls
    },
    // imageUrls
    SET_IMAGE_URLS: (state, imageUrls) => {
        state.imageUrls = imageUrls
    },
    SET_PANORAMA_URLS: (state, panoramaUrls) => {
        state.panoramaUrls = panoramaUrls
    }
}

const actions = {
    // 获取任务简略信息
    getTaskThumbInfo({ commit }, id) {

        return new Promise((resolve, reject) => {
            taskThumbInfo({ jobId: id }).then(response => {
                // console.log({ ...response.data });
                commit('SET_THUMB_INFO', response.data);

                resolve();
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}