import request from '@/utils/request.js'

//获取一张图列表
export function map_list() {
    return request({
        url: '/api/manage/v1/map/list',
        method: 'get'
    })
}


/**
 * 获取图层列表
 * @param {*} params 
 * {
 *   id:""  //地图ID
 * }
 * @returns 
 */
export function layer_get(params) {
    return request({
        url: '/api/manage/v1/layer/get',
        method: 'get',
        params
    })
}

/**
 * 获取成果任务轨迹详情
 * @param {*} params 
 * {
 *   jobId:"" //任务ID
 * }
 * @returns 
 */
export function job_trace(params) {
    return request({
        url: '/api/manage/v1/achievement/jobTrace',
        method: 'get',
        params
    })
}

/**
 * 获取标绘列表
 * @param {*} params 
 * {
 *   mapId:"" //地图ID
 * }
 * @returns 
 */
export function plotting_get(params) {
    return request({
        url: '/api/manage/v1/plotting/list',
        method: 'get',
        params
    })
}

/**
 * 加载轨迹线
 * @param {*} zr3d 
 * @param {*} json 
 */
function loadTraceLayer(zr3d, json) {
    if (json.trace == null || json.trace.length < 1)
        return;
    var id = "trace_" + json.jobId
    zr3d.layerAdd({ name: "轨迹线", id: id, type: "onemap" });
    var points = [];
    json.trace.forEach(pos => {
        points.push(
            {
                x: pos.lng,
                y: pos.lat,
                z: pos.altitude
            }
        )
    });
    zr3d.primitiveAddPolyLine({
        layer: id,
        points: points,
        id: "trace",
        clampToGround: json.clampToGround,
        centerPointType: 0,
        centerPointSize: 1,
        centerPointColor: "#33333300",
        startPointType: 0,
        startPointSize: 12,
        startPointColor: "#00ff0000",
        endPointType: 0,
        endPointSize: 12,
        endPointColor: "#ffff0000",
        lineColor: "#0000ff",
        lineWidth: 4,
        lineMode: 0,
        simple: true,
        flow: true,
        texture: "Cesium/res/imgs/primitive/lineArrow3.png",
        // textureColor: "#00ffff",
        time: 10000,
    });
    //zr3d.layerControl({name:id,show:false})
    //  setTimeout(() => {
    //     zr3d.layerRemove({name:id})
    //  }, 2000);
}

/**
 * 加载航线规划
 * @param {*} zr3d 
 * @param {*} json 
 */
function loadPlanLayer(zr3d, json) {

}

/**
 * 加载拼图图层
 * @param {*} zr3d 
 * @param {*} json 
 */
 function loadPuzzleLayer(zr3d, json) {
    if(json.puzzleUrls==null) return;
    json.puzzleUrls.forEach((item)=>{
        zr3d.layerLoad({
            name:item.id,
            type:"tms",
            url:item.url,
            layerType:"onemap"
        });
    })

}

/**
 * 加载各类标注
 * @param {*} zr3d 
 * @param {*} json 
 */
function loadMarkLayer(zr3d, json) {
    if (json.imageUrls != null && json.imageUrls.length > 0) {
        var id = "image_" + json.jobId
        zr3d.layerAdd({ name: "照片", id: id, type: "onemap" });
        json.imageUrls.forEach(imgInfo => {
            var img = zr3d.markAdd({
                x: imgInfo.lng,
                y: imgInfo.lat,
                z: imgInfo.altitude,
                id: imgInfo.id,
                clampToGround: json.clampToGround,
                layer: id,
                color: "#adff2f",
                type: "onemap_image",
                labelTopOffset: -30,
                image: require("@/assets/map/img_mark.png"),
                selectImage: require("@/assets/map/achievement_active.png"),
                prop: {},
            });
            img.url = imgInfo.url
        });
    }
    if (json.videoUrls != null && json.videoUrls.length > 0) {
        var id = "video_" + json.jobId
        zr3d.layerAdd({ name: "视频", id: id, type: "onemap" });
        json.videoUrls.forEach(imgInfo => {
            var video = zr3d.markAdd({
                x: imgInfo.lng,
                y: imgInfo.lat,
                z: imgInfo.altitude,
                id: imgInfo.id,
                clampToGround: json.clampToGround,
                layer: id,
                color: "#adff2f",
                type: "onemap_video",
                labelTopOffset: -30,
                image: require("@/assets/map/video_mark.png"),
                selectImage: require("@/assets/map/achievement_active.png"),
                prop: {},
            });
            video.url = imgInfo.url

        });
    }
    if (json.aiImageUrls != null && json.aiImageUrls.length > 0) {
        var id = "aiimage_" + json.jobId
        zr3d.layerAdd({ name: "AI", id: id, type: "onemap" });
        json.aiImageUrls.forEach(imgInfo => {
            var ai = zr3d.markAdd({
                x: imgInfo.lng,
                y: imgInfo.lat,
                z: imgInfo.altitude,
                id: imgInfo.id,
                clampToGround: json.clampToGround,
                layer: id,
                color: "#adff2f",
                type: "onemap_image",
                labelTopOffset: -30,
                image: require("@/assets/map/img_mark.png"),
                selectImage: require("@/assets/map/achievement_active.png"),
            });
            ai.url = imgInfo.url
        });
    }
}

/**
 * 加载任务成果图层
 * @param {*} json 
 */
export function loadTaskLayer(zr3d, json) {
    json.clampToGround = json.jobExecType == "3";

    loadTraceLayer(zr3d, json);
    loadPlanLayer(zr3d, json);
    loadPuzzleLayer(zr3d, json);
    loadMarkLayer(zr3d, json);
}

/**
 * 加载导入的图层
 * @param {*} zr3d 
 * @param {*} json 
 */
export function loadImportLayer(zr3d, json) {

    json.layerType = "onemap"
    switch (json.type) {
        case "3dt":
            json.type = "3dtiles";
            if (json.position) {
                json.height = json.position.alt;
                //json.fly=true
            }
            zr3d.layerLoad(json);
            break
        case "geojson":

            json.type = "geojson"
            json.fillColor = "#0FB0FF80"
            zr3d.layerLoad(json);
            break
        case "kml":
        case "kmz":

            json.type = "kml"
            json.fillColor = "#0FB0FF80"
            zr3d.layerLoad(json);
            break
        case "xyz":
            //json.fly=true
            json.url = json.url;// + "/{z}/{x}/{y}.png"
            json.minimumLevel = 5
            json.maximumLevel = 24
            json.format = "png"
            json.type = "tms"
            json.mercator = true;//3857投影
            zr3d.layerLoad(json);
            break
    }

}

/**
 * 加载标绘图层
 * @param {*} zr3d 
 * @param {*} json 
 */
export function loadPlottingLayer(zr3d, json) {

    if (json == null || json.length < 1) return;
    var id = "loadPlottingLayer"
    zr3d.layerAdd({ name: "标绘", id: id, type: "onemap" });
    json.forEach(element => {
        var graphic = JSON.parse(element.graphic);
        graphic.id = element.id;
        convertGeojson2Entity(zr3d, id, graphic);

    });


}

/**
 * 将geojson格式的标注（mars3d产生的）转化为我们对应的实体对象
 * @param {*} graphic 
 */
function convertGeojson2Entity(zr3d, layerId, graphic) {
    const points = graphic.geometry.coordinates;
    const type = graphic.properties.type;
    const name = graphic.properties.name;
    const style = graphic.properties.style;
    const show = graphic.properties.show == null ? true : graphic.properties.show;
    switch (type) {
        case "billboard": //点标注
        console.log(`======================info`,graphic);
            var mark = {
                x: points[0],
                y: points[1],
                z: points[2],
                id: graphic.id,
                name: name,
                clampToGround: true,
                layer: layerId,
                color: style.label.color,
                showOutLine: style.label.outline,
                outlineColor: style.label.outlineColor,
                fontSize: style.label.font_size,
                disableDepth: !style.visibleDepth,
                text: style.label.text,
                type: type,
                image: style.image,
                outlineWidth: style.label.outlineWidth == null ? 2 : style.label.outlineWidth,
                show: show
            };
            if (style.label.pixelOffsetY) {
                mark.labelTopOffset = style.label.pixelOffsetY - 8;
            }
            if (style.label.pixelOffsetX) {
                mark.labelLeftOffset = style.label.pixelOffsetX;
            }
           const markIns =  zr3d.markAdd(mark);
           if(style.url && style.url.includes("mp4")){
                markIns.type = "onemap_video";
                markIns.url = style.url;
           }
            break;
        case "circle":  //圆形
            var opacity = parseInt(style.opacity * 255);
            var circle = {
                layer: layerId,
                mode: 2,
                center: {
                    x: points[0],
                    y: points[1],
                    z: points[2]
                },
                radius: style.radius,
                id: graphic.id,
                clampToGround: style.clampToGround,
                brush: style.color + opacity.toString(16),
                show: show
            }
            if (style.outline != null && style.outline) {
                circle.lineWidth = style.outlineWidth;
                circle.color = style.outlineColor;
            }
            zr3d.primitiveAddCircle(circle);
            break
        case "curve":  //曲线
            var positions = [];
            points.forEach(point => {
                positions.push({
                    x: point[0],
                    y: point[1],
                    z: point[2]
                });
            });
            var line = {
                layer: layerId,
                points: positions,
                id: graphic.id,
                clampToGround: style.clampToGround,
                lineColor: style.color,
                lineWidth: style.width,
                lineMode: 3,
                show: show
                //simple: true,
            }
            zr3d.primitiveAddPolyLine(line);
            break
        case "model":  //单个模型
            var model = {
                layer: layerId,
                center: {
                    x: points[0],
                    y: points[1],
                    z: points[2]
                },
                url: style.url,
                id: graphic.id,
                scale: style.scale,
                show: show
            }
            zr3d.primitiveAddModel(model);
            break
        case "label":   //文本
            var mark = {
                x: points[0],
                y: points[1],
                z: points[2],
                id: graphic.id,
                name: name,
                clampToGround: true,
                layer: layerId,
                color: style.color,
                showOutLine: style.outline,
                outlineColor: style.outlineColor,
                fontSize: style.font_size,
                disableDepth: !style.visibleDepth,
                text: style.text,
                type: type,
                outlineWidth: style.outlineWidth == null ? 2 : style.outlineWidth,
                show: show
            };
            zr3d.markAdd(mark);
            break
        case "polyline": //折线
            var positions = [];
            points.forEach(point => {
                positions.push({
                    x: point[0],
                    y: point[1],
                    z: point[2]
                });
            });
            var line = {
                layer: layerId,
                points: positions,
                id: graphic.id,
                clampToGround: style.clampToGround,
                lineColor: style.color,
                lineWidth: style.width,
                lineMode: 0,
                simple: true,
                show: show
            }
            zr3d.primitiveAddPolyLine(line);
            break
        case "polygon": //多边形
            var positions = [];
            points[0].forEach(point => {
                positions.push({
                    x: point[0],
                    y: point[1],
                    z: point[2]
                });
            });
            var opacity = parseInt(style.opacity * 255);
            var polygon = {
                layer: layerId,
                points: positions,
                id: graphic.id,
                clampToGround: style.clampToGround,
                brush: style.color + opacity.toString(16),
                show: show
            }
            if (style.outline != null && style.outline) {
                polygon.lineWidth = style.outlineWidth;
                polygon.color = style.outlineColor;
            }
            zr3d.primitiveAddPolygon(polygon);
            break
        case "wall":   //墙体
            var positions = [];
            points.forEach(point => {
                positions.push({
                    x: point[0],
                    y: point[1],
                    z: point[2]
                });
            });
            var opacity = parseInt(style.opacity * 255);
            var wall = {
                layer: layerId,
                points: positions,
                id: graphic.id,
                color: style.color + opacity.toString(16),
                close: style.closure,
                height: style.diffHeight,
                show: show
            }
            zr3d.primitiveAddWall(wall);
            break
    }
}



