<template>
  <div class="qr-box">
    <div class="qrcode_box" :class="{ qrcode_show_right: showQr }" v-if="start">
      <canvas
        id="QRCode_header"
        ref="QRCode_header"
        class="QRCode"
        :key="qrUrl"
      ></canvas>
      <!-- <div class="url_copy">
        <span>链接：</span>
        <span class="text">{{ qrUrl }}</span>
        <i class="el-icon-document-copy pointer" @click="copy"></i>
      </div> -->
      <div class="copy_btn" @click="copy">复制视频链接</div>
    </div>
    <el-tooltip effect="dark" placement="bottom">
      <i
        class="iconfont icon-share icon"
        @click="show"
        :class="{ openStart: start }"
      ></i>
      <div class="tip-text" slot="content">
        <p>分享直播</p>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import QRCode from "qrcode"; //引入生成二维码插件
export default {
  props: ["liveType", "livedata"],
  name: "lvieQrCode",
  data() {
    return {
      videoUrl: `http://${location.host}/liveVideo`,
      //   videoUrl: "https://jm.zxrob.com/xiaodu/player",
      qrUrl: "",
      start: false,
      showQr: false, //向右弹出
    };
  },
  inject: {
    msg: {
      default: () => () => "",
    },
  },
  watch: {
    start: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.getQrCode();
          });
        }
      },
    },
  },
  beforeDestroy() {
    this.start = false;
  },
  created() {
    let name = this.msg();
    let url = "";
    if (this.liveType == 1) {
      const TcPlayurl = encodeURIComponent(this.livedata.url);
      url =
        this.videoUrl +
        `/?live_type=${this.liveType}&url=${TcPlayurl}&name=${name}`;
    } else if (this.liveType == 0) {
      const appid = encodeURIComponent(this.livedata.appid);
      const channel = encodeURIComponent(this.livedata.channel);
      const token = encodeURIComponent(this.livedata.token);
      url =
        this.videoUrl +
        `/?live_type=${this.liveType}&appid=${appid}&channel=${channel}&token=${token}&name=${name}`;
    }
    this.qrUrl = url;
  },
  methods: {
    getQrCode() {
      let opts = {
        errorCorrectionLevel: "L", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 1, //二维码质量
        margin: 8, //二维码留白边距
        width: 200, //宽
        height: 200, //高
        text: "http://www.xxx.com", //二维码内容
        color: {
          dark: "#000", //前景色
          light: "#fff", //背景色
        },
      };

      let msg = this.$refs.QRCode_header;
      // 将获取到的数据(val)画到msg(canvas)上
      QRCode.toCanvas(msg, this.qrUrl, opts, function (error) {
        if (error) {
          console.log("二维码加载失败", error);
          this.$message.error("二维码加载失败");
        }
      });
    },
    copy() {
      const input = document.createElement("input"); //创建input
      input.setAttribute("value", this.qrUrl); //把input设置value
      document.body.appendChild(input); //添加这个dom对象
      input.select(); //选中该输入框
      document.execCommand("copy"); //复制该文本
      document.body.removeChild(input); //移除输入框
      this.$message({
        message: "直播链接复制成功",
        type: "success",
      });
    },
    show(e) {
      this.showQr = false;
      if (!this.start && e.pageX < 300) {
        this.showQr = true;
      }

      this.start = !this.start;
    },
  },
};
</script>

<style lang="less" scoped>
.qr-box {
  position: relative;
  width: 20px;
  height: 20px;
}
.qrcode_box {
  position: fixed;
  transform: translateX(-107%) translateY(-38%);
  width: 261px;
  height: 256px;
  background-color: var(--page-background-color);
  border-radius: 6px;
  // background: rgba(29, 83, 173,);
  border: 1px solid #93c8e4;
  z-index: 9;
  .QRCode {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
  }
  .url_copy {
    position: absolute;
    bottom: 0;
    font-size: 16px;
    padding-bottom: 10px;
    line-height: 20px;
    display: flex;
    align-items: center;
    .text {
      box-sizing: border-box;
      width: 75px;
      height: 20px;
      border: 1px solid #999;
      display: inline-block;
      margin-right: 4px;
      overflow: hidden;
      font-size: 12px;
    }
  }
  .copy_btn {
    box-sizing: border-box;
    position: absolute;
    bottom: 5px;
    width: 110px;
    height: 27px;
    font-size: 16px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
      background-color: var(--btn-active-color);
      border: none;
    }
  }
}
.qrcode_show_right {
  transform: translateX(11%) translateY(-38%);
}
.openStart {
  color: skyblue !important;
}
.icon {
  color: white;
  cursor: pointer;
  z-index: 2;
  font-size: 22px;
}
</style>
