import { Notification } from 'element-ui'
const state = {
    airportInfo: {},//信息
    airportName: '',//名称
    airportClass: '',//机场类型 自研机库：3999    大疆机库1：31    大疆机库2:  32
    allState: {//指令状态管理
        biz_code: '',//任务类型
        percent: '',//进度
        status: '',//状态、结果
    },
    // 是否显示视频播放器
    isShowVideo: false,
    // 机场航线状态管理
    airportAirlineManage: {
        status: false,
        info: {}
    },
    airportFovHeight: 0,// 当前机场视锥高度
}

const mutations = {
    // 写入机场信息
    SET_AIRPORT_INFO: (state, airportInfo) => {
        state.airportInfo = airportInfo
    },
    // 写入机场名字
    SET_AIRPORT_NAME: (state, airportName) => {
        state.airportName = airportName
    },
    //写入机场状态收集
    SET_AIRPORT_STATE: (state, data) => {
        state.allState = data
    },
    // 写入视频播放器状态
    SET_IS_SHOW_VIDEO: (state, isShowVideo) => {
        state.isShowVideo = isShowVideo
    },
    // 写入机场分类
    SET_AIRPORT_CLASS: (state, airportClass) => {
        state.airportClass = airportClass
    },
    // 写入机场航线状态
    SET_AIRPORT_AIRLINE_STATUS: (state, status) => {
        state.airportAirlineManage.status = status
    },
    // 写入机场航线进度信息
    SET_AIRPORT_AIRLINE_INFO: (state, info) => {
        state.airportAirlineManage.info = info
    },
    // 清除机场航线状态
    CLEAR_AIRPORT_AIRLINE: (state) => {
        state.airportAirlineManage.status = false
        state.airportAirlineManage.info = {}
    },
     // airportFovHeight
     SET_AIRPORT_FOV_HEIGHT: (state, height) => {
        state.airportFovHeight = height
    },
}

const actions = {
    // 机场数据
    airportInfoWrit({ commit }, info) {
        const { data, resource_name, device_class } = info
        // data.boxDevice = {
        //     modelId: 0, //当前使用模型id
        //     modelList: [
        //         {
        //             id: 0,
        //             name: '导流线停车识别'
        //         }
        //     ], // 模型列表
        //     inference: false, // 是否开启识别
        //     push: false, // 是否开启推送
        //     name: '', // 盒子名称
        //     version: '1.0.0', // 盒子版本
        // }
        commit('SET_AIRPORT_INFO', data)
        commit('SET_AIRPORT_NAME', resource_name)
        if (device_class) commit('SET_AIRPORT_CLASS', device_class)
    },
    // 清除机场数据
    clearAirportInfoAll({ commit }) {
        commit('SET_AIRPORT_INFO', {})
        commit('SET_AIRPORT_NAME', '')
        commit('SET_AIRPORT_CLASS', '')
    },
    // 机场状态
    airportStateWrit({ commit }, data) {
        const biz_code = data.method
        const percent = data.data?.output?.progress?.percent
        const status = data.data?.output?.status
        if (!biz_code || !percent || !status) return
        if (data.data.result !== 0) {
            Notification({
                message: '指令发送失败，请重新尝试',
                type: 'error',
                title: "错误",
                offset: 150,
                duration: 3000,
            });
            return
        }
        commit('SET_AIRPORT_STATE', { biz_code, percent, status })
        if (status === 'ok') {
            let timeId = setTimeout(() => {
                commit('SET_AIRPORT_STATE', {
                    biz_code: '',
                    percent: '',
                    status: '',
                })
                clearTimeout(timeId)
            }, 2000);
        }
        // 指令执行失败
        if (status === 'failed') {
            Notification({
                message: '指令执行失败，请重新尝试',
                type: 'error',
                title: "错误",
                offset: 150,
                duration: 2000,
            });
            setTimeout(() => {
                commit('SET_AIRPORT_STATE', {
                    biz_code: '',
                    percent: '',
                    status: '',
                })
            }, 2000);
        }


        // 清除状态存储 解决卡顿问题
        var timeId_1
        if (timeId_1) clearTimeout(timeId_1)
        timeId_1 = setTimeout(() => {
            commit('SET_AIRPORT_STATE', {
                biz_code: '',
                percent: '',
                status: '',
            })
            clearTimeout(timeId_1)
        }, 10000);

    },
    // isShowVideo
    isShowVideoWrit({ commit }, isShowVideo) {
        commit('SET_IS_SHOW_VIDEO', isShowVideo)
    },
    airportFovHeightWrit({ commit }, info) {
        // return new Promise((resolve, reject) => {
        //     getDeviceConfig(info).then(response => {

        //         const { visualHeight } = response.data
        //         commit('SET_AIRPORT_FOV_HEIGHT', visualHeight)

        //         resolve();

        //     }).catch(error => {
        //         reject(error)
        //     })
        // })

        // 不使用接口写法
        commit('SET_AIRPORT_FOV_HEIGHT', 0)
    },
    // 清除视锥高度
    clearAirportFovHeight({ commit }) {
        commit('SET_AIRPORT_FOV_HEIGHT', 0)
    },
    // 更新airportFovHeight
    updateAirportFovHeight({ commit }, info) {
        // return new Promise((resolve, reject) => {
        //     updateDeviceConfig(info).then(response => {

        //         // const { visualHeight } = response.data
        //         commit('SET_AIRPORT_FOV_HEIGHT', Number(info.visualHeight))
        //         resolve();
        //     }).catch(error => {
        //         reject(error)
        //     })
        // })

        // 不使用接口写法
        commit('SET_AIRPORT_FOV_HEIGHT', Number(info.visualHeight))
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
