<template>
  <el-dialog
    title="事件提醒"
    :visible="true"
    width="324px"
    :close-on-click-modal="false"
    :before-close="cancel"
    class="event-reminder_container"
  >
    <el-image
      style="width: 100%; height: 132px"
      :src="alarmInfo.url"
      fit="cover"
      :preview-src-list="[alarmInfo.url]"
    ></el-image>

     <ul class="content-list">
      <li class="list-item" >
        <label class="name">事件类型：</label>
        <span class="details">{{ alarmInfo.eventName}}</span>
      </li>
      <!-- 事件时间： -->
      <li class="list-item">
        <label class="name">事件时间：</label>
        <span class="details">{{ alarmInfo.eventTime | timeShift("YYYY-MM-DD HH:mm") }}</span>
      </li>
      <!-- 事件坐标 -->
      <li class="list-item">
        <label class="name">事件坐标：</label>
        <span class="details">{{ alarmInfo.longitude }}, {{ alarmInfo.latitude }}</span>
      </li>
    </ul>

     <div class="dialog-footer" >
      <div class="btn btn-cancel" @click="cancel">确认({{ countDown }}s)</div>
    </div>

  </el-dialog>
</template>

<script>
import Moment from "moment";
export default {
  data() {
    return {
      countDown: 0, // 倒计时
      timerId: null, // 定时器id
    };
  },
  props:{
    alarmInfo:{
      type:Object,
      required:true
    }
  },
  mounted(){
    this.setTimer()
  },
  filters: {
    timeShift(time,format) {
      return Moment(Number(time)).format(format);
    },
  },
  methods: {
    cancel() {
      if(this.timerId) clearInterval(this.timerId);
      this.$removeAlarmReminder();
    },
    // 设置一个定时器
    setTimer() {
      //   debugger;
      this.countDown = 10;
      this.timerId = setInterval(() => {
        // 第二次进入，等于0，不再执行
        if (this.countDown === 0) {
          this.cancel();
          clearInterval(this.timerId)
          return;
        }
        this.countDown--;
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.event-reminder_container {
  display: flex;
  align-items: center;
  justify-content: center;
  .content-list {
    .list-item {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      .name {
        color: #9c9ea1;
      }
      .details {
        color: white;
      }
      .el-input {
        width: 156px;
        height: 24px;
        border-radius: 2px;
        :deep(.el-input__inner) {
          height: 24px;
          line-height: 24px;
          background-color: transparent;
          color: white;
        }
      }

      .person_select {
        width: 156px;
        height: 24px;
        border-radius: 2px;

        :deep(.el-input__inner) {
          height: 24px;
          line-height: 24px;
          background-color: transparent;
          color: white;
        }
      }
    }
  }
  .dialog-footer {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .btn {
      width: 96px;
      height: 30px;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }
    .btn-cancel {
      background: #393c43;
      &:hover {
        background: var(--btn-hover-color);
      }
      &:active {
        background: var(--btn-active-color);
      }
    }
    .btn-confirm {
      background: #215bff;
      &:hover {
        background: var(--command-info-btn-hover-color);
      }
      &:active {
        background: var(--command-info-btn-active-color);
      }
    }
  }
}
:deep(.el-dialog) {
  pointer-events: auto;
  margin: 0 !important;
  background: #25272c;
  .el-dialog__header {
    padding: 0;
    height: 46px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 32px;

    &:after {
      content: "";
      position: absolute;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 17px;
      background: #215bff;
      border-radius: 4px;
      background: var(--page-theme-color);
    }

    .el-dialog__title {
      color: white;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
    }
    .el-dialog__headerbtn {
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
:deep(.el-dialog__body) {
  padding: 0 18px;
  display: flex;
  flex-direction: column;
}
:deep(.el-loading-spinner) {
  top: unset;
  margin-top: unset;
}
</style>
