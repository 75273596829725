// import { login, logout, getInfo } from '@/api/user'
// import { getToken, setToken, removeToken, getUserId, setUserId, removeUserId } from '@/utils/auth'
// import store from '@/store/index.js'
import router from "@/assets/json/router.json";
const state = {
    routeList: [...router.data], // 路由列表
}
const mutations = {
    // routeList
    SET_ROUTE_LIST: (state, routeList) => {
        state.routeList = routeList
    },
}

const actions = {
    // user login
    // login({ commit }, userInfo) {

    //     const { account, password } = userInfo

    //     let formData = new FormData();
    //     formData.append('username', account);
    //     formData.append('password', password);
    //     formData.append('userType', 0);
    //     return new Promise((resolve, reject) => {
    //         login(formData).then(response => {
    //             const { accessToken, workspaceId, id, nickName } = response.data
    //             // {
    //             //     "id": "913606337476829184",
    //             //     "username": "admin1",
    //             //     "workspaceId": "e3dea0f5-37f2-4d79-ae58-490af3228000",
    //             //     "userType": 1,
    //             //     "createTime": 1697987190470,
    //             //     "enabled": 1,
    //             //     "roleName": "ROLE_COMMAND",
    //             //     "accessToken": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJqaWFuZ21lbkRhc2kiLCJsb2ciOiJMb2dnZXJbY29tLnp4cm9iLnptZXRhLm1vZGVsLlRva2VuQ2xhaW1dIiwicm9sZUlkIjoiMyIsIm5pY2tOYW1lIjoi566h55CG5ZGYIiwiaXNzIjoiamlhbmdtZW4iLCJuYmYiOjE2OTgwMjkyMjEsInJvbGVOYW1lIjoiUk9MRV9DT01NQU5EIiwiaWQiOiI5MTM2MDYzMzc0NzY4MjkxODQiLCJ1c2VyVHlwZSI6IjEiLCJleHAiOjE2OTgxMTU2MjEsImlhdCI6MTY5ODAyOTIyMSwidXNlcm5hbWUiOiJhZG1pbjEiLCJ3b3Jrc3BhY2VJZCI6ImUzZGVhMGY1LTM3ZjItNGQ3OS1hZTU4LTQ5MGFmMzIyODAwMCJ9.e4IekTb92KHoTwPQSx31bvLfonVMEauYqvZrUexJQAA",
    //             //     "nickName": "管理员",
    //             //     "expireTime": 0
    //             // }
    //             // 写入vuex
    //             commit('SET_WORKSPACE_ID', workspaceId);
    //             commit('SET_NAME', nickName);
    //             commit('SET_ID', id);
    //             commit('SET_TOKEN', accessToken);
    //             // 写入cookie
    //             setToken(accessToken);
    //             setUserId(id);
    //             resolve();
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    // // user logout
    // logout({ commit }) {
    //     return new Promise((resolve, reject) => {
    //         logout().then(() => {
    //             store.dispatch("live/setPlayList", []);//清除直播存储信息
    //             commit('SET_TOKEN', '')
    //             commit('SET_NAME', '');
    //             commit('SET_AVATAR', '');
    //             commit('SET_WORKSPACE_ID', NaN);
    //             commit('SET_ROLE_ID', NaN);
    //             commit('SET_ID', '');
    //             removeToken()
    //             removeUserId()
    //             resolve()
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    // getInfo({ commit }) {
    //     return new Promise((resolve, reject) => {
    //         getInfo().then(response => {
    //             const { nickName, avatar, roleId, id, workspaceId } = response.data
    //             commit('SET_WORKSPACE_ID', workspaceId);
    //             commit('SET_NAME', nickName);
    //             commit('SET_AVATAR', avatar);
    //             commit('SET_ROLE_ID', roleId);
    //             commit('SET_ID', id);
    //             resolve()
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },
    // routeList
    setRouteList({ commit }, routeList) {
        commit('SET_ROUTE_LIST', routeList)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}