export function validUsername(str) {
    const reg = /^[a-zA-Z0-9]{5,18}$/
    return reg.test(str)
}

export function validPassword(str) {
    // const reg = /((?=.\d)(?=.\D)|(?=.[a-zA-Z])(?=.[^a-zA-Z]))(?!^.[\u4E00-\u9FA5].$)^\S{8,20}$/
    const reg = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{8,20}$/
    // const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@$!%*?&^#~])([a-zA-Z0-9@$!%*?&^#~]{8,20})$/
    // const reg = /^.*(?=.{8,20})((?=.*\d)(?=.*[A-Z]))|((?=.*\d)(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*? ]))|((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[!@#$%^&*? ]))|((?=.*[a-z])(?=.*[!@#$%^&*? ])).*$/;
    return reg.test(str)
}

export function validNickName(str) {
    const reg = /^[\u4E00-\u9FA5A-Za-z0-9]{2,18}$/
    return reg.test(str)
}

export function validTaskName(str) {
    const reg = /^[\u4E00-\u9FA5A-Za-z0-9-_:]{1,20}$/
    return reg.test(str)
}

export function validPhoneNumber(str){
    const reg = /^1[3,4,5,6,7,8,9][0-9]{9}$/
    return reg.test(str)
}