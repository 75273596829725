import request from "@/utils/request"

// 行动添加
export function add(data) {
    return request({
        url: 'api/manage/v1/action/add',
        method: 'post',
        data
    })
}

// 查询正在执行的行动列表
export function getCurrentActionList(params) {
    return request({
        url: "/api/manage/v1/action/getCurrentActionList",
        method: 'get',
        params
    })
}

// 
export function updateMemberActionId(data) {
    return request({
        url: '/api/manage/v1/action/updateMemberActionId',
        method: 'post',
        data
    })
}
// /api/manage/v1/action/get
export function actionGet(params) {
    return request({
        url: '/api/manage/v1/action/get',
        method: 'get',
        params
    })
}

// /api/manage/v1/action/update
export function actionUpdate(data) {
    return request({
        url: '/api/manage/v1/action/update',
        method: 'post',
        data
    })
}
// /manage/api/v1/action/updateTargetName
export function updateTargetName(data) {
    return request({
        url: '/api/manage/v1/action/updateTargetName',
        method: 'post',
        data
    })
}
