import store from "@/store";
import radarJson from '@/views/minesView/radar/radar.json'
import { debounce } from "@/utils/index";
export default {
  computed: {
    //
    isActionStarted() {
      return this.$store.state.map.isActionStarted;
    },
    // 获取resid
    resId() {
      return this.$store.getters.resId;
    },
    // 当前环境变量 CURRENTMODE
    currentMode() {
      return process.env.VUE_APP_CURRENTMODE;
    },
  },
  // 创建生命周期函数
  created() {
    this.$EventBus.$on("mapHighlighting", (type) => {
      if (type == 1) {
        showYangjian(zr3d);
        // showJiangMen(zr3d)

        //把这两组经纬度转化为对象
        const pointList = [
          {
            x: 113.1116091,
            y: 22.6074616,
          },
          {
            x: 113.0755369,
            y: 22.287674,
          },
          {
            x: 112.4286566,
            y: 21.873862,
          },
          {
            x: 111.9520327,
            y: 21.8348866,
          },
          // 111.9520327
          // 纬度: 21.8348866°
        ];
        // 根据上诉数据，在地图内添加坐标点
        pointList.forEach((item, index) => {
          zr3d.markAdd({
            id: `point${index}`,
            type: "point",
            x: item.x,
            y: item.y,
            z: 0,
            // id: "annotationMarker" + Date.now(), //唯一ID
            layer: "point",
            image: require("@/assets/map/selected_item.png"), //图标
            scale: 0.8,
            prop: {},
          });
        });
      } else {
        // showJiangMen(zr3d)
      }
    });
  },
  watch: {
    resId: {
      handler(newV, oldV) {
        if (newV === -1) {
          // 移除显示
          if (!oldV) return;
          this.showCanvasLabel(oldV, true);
        } else if (newV && oldV && oldV !== -1) {
          // 切换显示
          this.showCanvasLabel(newV, false);
          this.showCanvasLabel(oldV, true);
        } else if (newV && oldV === -1) {
          // 显示
          this.showCanvasLabel(newV, false);
        }
      },
    },
  },
  mounted() {
    this.initMap();
    this.initData();
  },
  beforeDestroy() {
    setTimeout(() => {
      this.destroyMap();
    }, 1000);
  },
  methods: {
    // 显示弹窗 隐藏 title
    showCanvasLabel(guid, show) {
      const mark =
        zr3d.markGet({ guid }) ||
        realTimeTrajectoryList.find((item) => item.id === guid)?.flyEntity;
      if (mark) mark.canvasLabel.show = show;
    },
    // 地图所需实例对象的初始化
    initData() {
      window.realTimeTrajectoryList = []; //实时轨迹集合
      window.divLabel = null; // 弹窗实例
      window.airline = null; //航线实例
      window.markExample = null; //示意性任务>当前实例对象
      window.markExampleList = []; //示意性任务>实例对象list
      window.resMarkList = [];
    },
    // 地图创建
    initMap() {
      //配置文件
      let json = window.MapJson;
      json.element = "mapContainer";
      window.zr3d = new ZR3D(); //地图实例
      //地图加载完成
      zr3d.init(json, () => {
        // 地形
        const isShowTerrain = localStorage.getItem("isShowTerrain");

        // window.showAllCityFun = showJiangMen(zr3d)

        if (isShowTerrain !== null) {
          zr3d.terrainControl({
            show: isShowTerrain === "true" ? true : false,
          });
        } else {
          this.$store.dispatch("map/setIsShowTerrain", true);
          zr3d.terrainControl({
            show: true,
          });
        }

        this.$emit("mapLoad");

        var screenSpaceCameraController =
          zr3d.engine.viewer.scene.screenSpaceCameraController;

        // 设置最大和最小缩放距离
        screenSpaceCameraController.minimumZoomDistance = 100; // 最小缩放距离
        //鼠标移动回调
        zr3d.mouseMovementAdd((data) => {
          this.mouseMove(data);
        });

        this.monitorBorderManager();

        // 地图点击事件
        zr3d.mapOnClick(() => {
          this.$EventBus.$emit("hideMenu"); // 隐藏右键菜单
          zr3d.mapOnInput({
            on: true,
          });
        });
        // mark点击事件
        zr3d.markClick((e, pos) => {
          console.log(`markClick`, e);
          if (!e) {
            this.deleteNodePos.left = 0;
            this.deleteNodePos.top = 0;
            this.deleteNodePos.elementId = "";
            //
            if (this.resId > -1 && divLabel) {
              divLabel.show = false;
              this.$store.commit("resource/SET_RES_ID", -1); // 清理选中值
            }

            this.$EventBus.$emit("hideResultsLabel"); // 隐藏右键菜单
            return;
          } // 点击空白处

          const { type, id, data, drawer } = e;

          if (drawer) {
            // if (projectMarkExample) return false;
            // 如果drawer不含有name属性，则不执行下面的代码
            if (!drawer.name) return false;
            // 如果在绘制过程中，不执行下面的代码
            if (store.getters.isActionStarted == 3) return false;
            const { x, y } = pos;
            this.deleteNodePos.left = x;
            this.deleteNodePos.top = y;
            this.deleteNodePos.elementId = drawer.id;
          } else if (data && data.type) {
            const {
              type,
              id,
              text,
              resourceType,
              resourceName,
              livePlayUrl,
              appPlayStreamUrl,
              unitName,
              subDeviceSn,
              lat,
              lng,
              deviceUserType,
              deviceSn,
            } = data;

            switch (type) {
              case "target":
                this.$EventBus.$emit("targetShow", data);
                break;
              case "resList":
                this.statisticsSkip(data);
                switch (this.isActionStarted) {
                  case 0:
                    this.$store.commit("resource/SET_RES_ID", id); // 清理选中值
                    break;
                  case 1:
                    // 目标追踪任务
                    let info = {
                      memberId: id, // 资源id
                      memberName: text, // 资源名称
                      resourceType, // 资源类型
                    };

                    this.$EventBus.$emit("selectRes", info);
                    break;
                  case 3:
                    //示意性任务
                    let info3 = {
                      memberId: id,
                      memberName: text,
                      resourceType,
                    };
                    this.$EventBus.$emit("actionPersonalLabel", info3);
                    break;
                  case 4:
                    // 路径规划
                    let info4 = {
                      memberId: id,
                      memberName: text,
                    };
                    this.$EventBus.$emit("pathPlanning", info4);
                    break;
                }
              case "typeLive":
                this.statisticsSkip(data, "typeLive");

                if (this.isActionStarted == 3) {
                  let info = {
                    memberId: id,
                    memberName: resourceName,
                    resourceType,
                  };
                  this.$EventBus.$emit("actionPersonalLabel", info);
                } else if (resourceType == 4) {
                  this.$EventBus.$emit("typeLiveShow", {
                    livePlayUrl,
                    resourceType,
                    text,
                    id,
                  });
                } else if (resourceType == 2) {
                  this.$EventBus.$emit("typeLiveShow", {
                    resourceName,
                    resourceType,
                    unitName,
                    subDeviceSn,
                    id,
                  });
                } else if (resourceType == 3) {
                  if (deviceUserType == 1) {
                    this.$EventBus.$emit("typeLiveShow", {
                      playStreamUrl: livePlayUrl,
                      resourceType,
                      resourceName,
                      id,
                    });
                  } else if (livePlayUrl) {
                    this.$EventBus.$emit("typeLiveShow", {
                      playStreamUrl: livePlayUrl,
                      resourceType,
                      resourceName,
                      id,
                    });
                  }


                  //#region 小程序携带链接版本 失去点击列表反选修复
                  switch (this.isActionStarted) {
                    case 0:
                      this.$store.commit("resource/SET_RES_ID", id); // 写入当前选中值
                      break;
                    case 1:
                      // 目标追踪任务
                      let info = {
                        memberId: id,
                        memberName: resourceName,
                        resourceType,
                      };
                      this.$EventBus.$emit("selectRes", info);
                      break;
                    case 3:
                      //示意性任务
                      let info3 = {
                        memberId: id,
                        memberName: resourceName,
                        resourceType,
                      };
                      this.$EventBus.$emit("actionPersonalLabel", info3);
                      break;

                    case 4:
                      // 路径规划
                      let info4 = {
                        memberId: id,
                        memberName: resourceName,
                      };
                      this.$EventBus.$emit("pathPlanning", info4);
                      break;
                  }
                  //#endregion
                } else if (resourceType == 1) {
                  this.$EventBus.$emit("typeLiveShow", {
                    subDeviceSn: deviceSn,
                    resourceType,
                    resourceName,
                    id,
                  });
                }

                break;
              case "wusixiaodu":
                // 执行资源定位
                this.resPosition({ lat, lng });
                break;
            }
          } else if (type) {
            // 一张图标注互动
            if (type === "onemap_image") {
              this.$showMediaModal({ type: "image", src: e.url });
              return;
            }
            if (type === "onemap_video") {
              this.$showMediaModal({ type: "video", src: e.url });
              return;
            }
            // 矿山演示交互
            if (type == "radarPro") {
              let currentMode = process.env.VUE_APP_CURRENTMODE

              const data = radarJson.find((v) => {
                return v.mode == currentMode
              })?.data

              if (!data) return

              const radarIndex = store.getters.radarIndex

              const radarData = data.find((v) => {
                return v.index == radarIndex
              })

              const radarUrl = radarData.url

              window.open(radarUrl, "_blank");

              // window.open("http://8.134.124.1:18080", "_blank");
              return;
            }
            // 矿山演示检测器交互
            if (type == "radarMarkpoint") {
              this.$EventBus.$emit("Map-clickRadarPoint", e.id);
              return;
            }

            const { prop, id } = e;

            if (!prop) return;
            const {
              resourceName,
              resourceType,
              subDeviceSn,
              unitName,
              deviceUserType,
              playStreamUrl,
              appPlayStreamUrl
            } = prop;
            switch (type) {
              case "resList":
                this.statisticsSkip(prop);
                switch (this.isActionStarted) {
                  case 0:
                    this.$store.commit("resource/SET_RES_ID", id); // 写入当前选中值
                    break;
                  case 1:
                    // 目标追踪任务
                    let info = {
                      memberId: id,
                      memberName: resourceName,
                      resourceType,
                    };
                    this.$EventBus.$emit("selectRes", info);
                    break;
                  case 3:
                    //示意性任务
                    let info3 = {
                      memberId: id,
                      memberName: resourceName,
                      resourceType,
                    };
                    this.$EventBus.$emit("actionPersonalLabel", info3);
                    break;

                  case 4:
                    // 路径规划
                    let info4 = {
                      memberId: id,
                      memberName: resourceName,
                    };
                    this.$EventBus.$emit("pathPlanning", info4);
                    break;
                }

                break;
              case "typeResult":
                this.$EventBus.$emit("typeResultShow", id);
                break;
              case "typeImg":
                this.$EventBus.$emit("typeImgShow", id);
                break;
              case "typeVideo":
                this.$EventBus.$emit("typeVideoShow", id);
                break;
              case "typeLive":
                this.statisticsSkip(prop, "typeLive");
                if (this.isActionStarted == 3) {
                  const newId = id.replace("plane", ""); // "he world"
                  let info = {
                    memberId: newId,
                    memberName: resourceName,
                    resourceType: resourceType,
                  };
                  this.$EventBus.$emit("actionPersonalLabel", info);
                } else if (resourceType == 2) {
                  const newId = id.replace("plane", ""); // "he world"
                  this.$EventBus.$emit("typeLiveShow", {
                    resourceName,
                    resourceType,
                    unitName,
                    subDeviceSn,
                    id: newId,
                  });
                } else if (resourceType == 3) {
                  if (deviceUserType == 1) {
                    this.$EventBus.$emit("typeLiveShow", {
                      playStreamUrl,
                      resourceType,
                      resourceName,
                      id,
                    }); // 携带直播的小程序
                  } else if (appPlayStreamUrl) {
                    this.$EventBus.$emit("typeLiveShow", {
                      playStreamUrl: appPlayStreamUrl,
                      resourceType,
                      resourceName,
                      id,
                    });
                  }

                  //#region 小程序直播链接版本 失去点击列表反选
                  switch (this.isActionStarted) {
                    case 0:
                      this.$store.commit("resource/SET_RES_ID", id); // 写入当前选中值
                      break;
                    case 1:
                      // 目标追踪任务
                      let info = {
                        memberId: id,
                        memberName: resourceName,
                        resourceType,
                      };
                      this.$EventBus.$emit("selectRes", info);
                      break;
                    case 3:
                      //示意性任务
                      let info3 = {
                        memberId: id,
                        memberName: resourceName,
                        resourceType,
                      };
                      this.$EventBus.$emit("actionPersonalLabel", info3);
                      break;

                    case 4:
                      // 路径规划
                      let info4 = {
                        memberId: id,
                        memberName: resourceName,
                      };
                      this.$EventBus.$emit("pathPlanning", info4);
                      break;
                  }
                  //#endregion
                } else if (resourceType == 1) {
                  const newId = id.replace("plane", ""); // "he world"
                  this.$EventBus.$emit("typeLiveShow", {
                    subDeviceSn,
                    resourceType,
                    resourceName,
                    id: newId,
                  });
                }

                break;
            }
          }
        });
        // 开启调试信息
        // zr3d.frameControl({
        //   show: true,
        // });

        // 启用导航罗盘
        zr3d.navigationControl();

        if (this.currentMode == "xiwan") {
          zr3d.viewpointSet({
            viewpoint: {
              x: 110.03953268728493,
              y: 38.60758321630546,
              z: 11911.4435730775,
              heading: 347.82768126321963,
              pitch: -89.65465476725699,
              roll: 0,
            },
          });
        } else {
          // 中禾定位
          zr3d.viewpointSet({
            viewpoint: {
              x: 102.10439644196535,
              y: 27.03632250007277,
              z: 3559.2262156650145,
              heading: 285.87638197209674,
              pitch: -83.78465569810477,
              roll: 0.03130647566108401,
            },
          });

          // 广西定位
          // zr3d.viewpointSet({
          //   viewpoint: {
          //     "x": 106.91412914551083,
          //     "y": 22.109212677341887,
          //     "z": 2000.0000000005723,
          //     "heading": 360,
          //     "pitch": -89.9999578266929,
          //     "roll": 0
          //   },
          // });
        }

        // zr3d.locationSet({
        //     x: this.centralPoint[0], //位置x [必填]
        //     y: this.centralPoint[1], //位置y  [必填]
        //     z: 0, //位置z  [必填]
        //     dis: 10000, //距离
        //     heading: 0, //偏转角
        //     pitch: -90, //俯仰角
        //     flyTime: 2,
        // });

        // 3d模式
        setTimeout(() => {
          const is3D = localStorage.getItem("is3D");
          if (is3D !== null) {
            if (is3D === "true")
              zr3d.sceneModeSet({
                mode: "3D",
              });
          } else {
            this.$store.dispatch("map/setIs3D", false);
            zr3d.sceneModeSet({
              mode: "2D",
            });
          }
        }, 2500);
      });
    },
    // 统计页定位跳转
    statisticsSkip(item, type = null) {
      const routerName = this.$router.history.current.name;
      if (routerName !== "Statistics") return;
      // 跳转路由
      this.$router.push("/command");
      // 定位点击图标位置
      const {
        subLat,
        subLng,
        lat,
        lng,
        id,
        text,
        resourceType,
        resourceName,
        livePlayUrl,
        unitName,
        subDeviceSn,
        deviceUserType,
        deviceSn,
      } = item;
      const centerX = (subLng + lng) / 2;
      const centerY = (subLat + lat) / 2;

      let x = Math.pow(subLng - lng, 2);
      let y = Math.pow(subLat - lat, 2);
      let z = Math.sqrt(x + y) * 111000 * 4;

      if (z < 2000 || !z) z = 2000;

      zr3d.locationSet({
        x: centerX || lng || subLng, //位置x [必填]
        y: centerY || lat || subLat, //位置y  [必填]
        z: 0, //位置z  [必填]
        dis: z, //距离
        heading: 0, //偏转角
        pitch: -90, //俯仰角
        flyTime: 2,
      });

      if (!type) return;

      // 打开图标直播操作
      setTimeout(() => {
        if (resourceType == 4) {
          this.$EventBus.$emit("typeLiveShow", {
            livePlayUrl,
            resourceType,
            text,
            id,
          });
        } else if (resourceType == 2) {
          this.$EventBus.$emit("typeLiveShow", {
            resourceName,
            resourceType,
            unitName,
            subDeviceSn,
            id,
          });
        } else if (resourceType == 3) {
          if (deviceUserType == 1)
            this.$EventBus.$emit("typeLiveShow", {
              playStreamUrl: livePlayUrl,
              resourceType,
              resourceName,
              id,
            }); // 携带直播的小程序
        } else if (resourceType == 1) {
          this.$EventBus.$emit("typeLiveShow", {
            subDeviceSn: deviceSn,
            resourceType,
            resourceName,
            id,
          });
        }
      }, 1000);
    },
    // 地图销毁
    destroyMap() {
      zr3d.destroy();
      zr3d = null;
    },
    // 管理边界线 监测点绘制 - 矿山雷达
    monitorBorderManager() {
      let line = [
        [
          {
            x: 110.05523269073554,
            y: 38.62615682183029,
            z: 0,
          },
          {
            x: 110.05257165277779,
            y: 38.62848559436397,
            z: 0,
          },
          {
            x: 110.04960969377694,
            y: 38.63112882908526,
            z: 0,
          },
          {
            x: 110.04516171021609,
            y: 38.63003123100466,
            z: 0,
          },
          {
            x: 110.04188140728037,
            y: 38.62687043271535,
            z: 0,
          },
          {
            x: 110.03788728231166,
            y: 38.62320499992977,
            z: 0,
          },
          {
            x: 110.0338559035154,
            y: 38.619514738040245,
            z: 0,
          },
          {
            x: 110.02927514164553,
            y: 38.61634433816654,
            z: 0,
          },
          {
            x: 110.02660263888302,
            y: 38.61437052747752,
            z: 0,
          },
        ],
        [
          {
            x: 110.02512140395113,
            y: 38.61143877680242,
            z: 0,
          },
          {
            x: 110.02822781026771,
            y: 38.609482918892006,
            z: 0,
          },
          {
            x: 110.0308190325636,
            y: 38.607592258374396,
            z: 0,
          },
          {
            x: 110.03333694684454,
            y: 38.605849022220454,
            z: 0,
          },
          {
            x: 110.03579362339578,
            y: 38.60428618208077,
            z: 0,
          },
          {
            x: 110.03860208949438,
            y: 38.60229704704909,
            z: 0,
          },
          {
            x: 110.0426809704267,
            y: 38.60495725211977,
            z: 0,
          },
        ],
        [
          {
            x: 110.04690288065379,
            y: 38.60549466761479,
            z: 0,
          },
          {
            x: 110.05074397742577,
            y: 38.60880635679022,
            z: 0,
          },
          {
            x: 110.05491886573698,
            y: 38.61215950068363,
            z: 0,
          },
          {
            x: 110.05886181759676,
            y: 38.61561776962891,
            z: 0,
          },
          {
            x: 110.06170509395346,
            y: 38.61454909685117,
            z: 0,
          },
          {
            x: 110.06393733742841,
            y: 38.61315999471524,
            z: 0,
          },
          {
            x: 110.0664978527477,
            y: 38.61515427197066,
            z: 0,
          },
        ],
      ];
      if (window.monitorBorder) {
        // 移除数据
        line.forEach((value, index) => {
          zr3d.primitiveRemove({
            guid: `monitorTrack-${index}`,
          });

          value.forEach((v, i) => {
            zr3d.markRemove({ guid: `monitorMark-${index}-${i}` });
          });
        });
        window.monitorBorder = null;
      }
      window.monitorBorder = true;
      line.forEach((value, index) => {
        zr3d.primitiveAddPolyLine({
          points: value,
          id: `monitorTrack-${index}`,
          lineColor: "#87ceeb",
          lineWidth: 7,
          lineMode: 3,
          clampToGround: true, // 贴地
        });

        value.forEach((v, i) => {
          let radarInfo = {
            x: v.x, // 经度
            y: v.y, // 纬度
            z: 0,
            id: `monitorMark-${index}-${i}`, // 资源id
            clampToGround: true, // 贴地
            layer: "resMark", // 图层
            type: "radarMark", // 类型
            image: require(`@/assets/meines/map-radar.png`), // 图标
            farScale: 0.5, // 最远显示比例
            farDis: 10000, // 最远显示距离
          };
          zr3d.markAdd(radarInfo);
        });
      });
    },
    // 鼠标移动信息
    mouseMove: debounce(function (data) {
      this.geographic = data;
    }, 300),
  },
};
