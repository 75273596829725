export default {
    methods: {
        // 移除图层
        removeLayer(name) {
            zr3d.layerRemove({
                name: name,
            });
        },
        // 新增图层
        addLayer(name) {
            zr3d.layerAdd({
                name: name,
            });
        },
    }
};