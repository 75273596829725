<template>
  <section class="layout-container">
    <Navbar @changePwd="changeShow = true" v-if="currentMode != 'yulong'"/>
    <div class="main">
      <div class="menu-box" v-if='isShowMenu'>
        <Menu />
      </div>
      <div class="content" :class="{ minH: showMinHeight }">
        <baseMap v-show="hide" />
        <!-- <transition name="el-fade-in"> -->
          <router-view />
        <!-- </transition> -->
      </div>
      <ChangePassword
        v-if="changeShow"
        @cancel="changeShow = false"
        @changeSuccess="changeShow = false"
      />
    </div>

    <CommonChat v-show="false" />
  </section>
</template>

<script>
import CommonChat from "@/components/CommonChat/index.vue";
import Navbar from "./components/Navbar.vue";
import Menu from "./components/Menu.vue";
import ChangePassword from "./components/ChangePassword";
import baseMap from "@/components/CommonMap/index.vue";
export default {
  components: {
    Navbar,
    Menu,
    ChangePassword,
    baseMap,
    CommonChat,
  },
  watch: {
    $route: "hideMap",
  },
  data() {
    return {
      changeShow: false,
      hide: true,
      showMinHeight: false,
      isChatVisible: false, // 聊天是否显示
      isShowMenu: true,
    };
  },
  computed:{
    // 当前环境变量 CURRENTMODE
    currentMode() {
      return process.env.VUE_APP_CURRENTMODE;
    },
  },
  created() {
    this.$EventBus.$on("changeChatVisible", (type) => {
      this.isChatVisible = type;
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("changeChatVisible");
  },
  mounted() {
    this.hideMap();
  },

  methods: {
    hideMap() {
      const pathList = [
        "/dataShow/index",
        "/algorithm/index",
        "/personnel/index",
        "/equipment/index",
        "/home/index",
        // 新增数字孪生
        "/twinNumbers/index",
        "/imageRecognition/index"
      ];
      
      const { resourceType } = this.$route.query;

      if (resourceType == 1) {
        if (!this.showMinHeight) this.showMinHeight = true;
      } else {
        if (this.showMinHeight) this.showMinHeight = false;
      }

      if (pathList.indexOf(this.$route.path) > -1) {
        this.hide = false;
      } else {
        this.hide = true;
      }

      // 判断是否系统融合
      const hideMenuList = [
        "/statistics/index",
        "/overview/index",
        "/radar/index",
        "/monitor/index",
        "/safety/index",
        "/riskAssessment/index"
      ];
      if (hideMenuList.indexOf(this.$route.path) > -1) {
        this.isShowMenu = false;
      } else {
        this.isShowMenu = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layout-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .main {
    flex: 1;
    display: flex;
    background-color: rgb(0, 0, 0);
    // height: calc(100% - 64px);
    .menu-box {
      display: flex;
      background-color: transparent;
    }
    .content {
      width: 100%;
      position: relative;
      background-color: var(--page-background-color);
      border-radius: 6px 0 0 0;
      overflow: hidden;
      display: flex;
    }
    .minH {
      min-height: 1000px;
    }
  }
}
</style>
