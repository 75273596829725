import resize from './resize';
import drag from './drag'

// 指令
const directives = {
    resize,
    drag
}

export default {
    install(Vue) {
        Object.keys(directives).forEach(key => {
            Vue.directive(key, directives[key])
        })
    }
}