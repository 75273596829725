<template>
  <ul class="action-menu" @contextmenu.prevent @mousedown.stop>
    <li class="item pointer" @click="addPoint(true)">向前添加</li>
    <li class="item pointer" @click="addPoint(false)">向后添加</li>
    <li class="item pointer" @click="deleteNode" v-if="showDeleteNode">删除</li>
  </ul>
</template>

<script>
export default {
  computed: {
    localRouteDataOid() {
      return this.$store.getters.lineInfo.localRouteDataOid;
    },
    altitude() {
      return this.$store.getters.lineInfo.altitude;
    },
    localRouteData(){
      return this.$store.getters.lineInfo.localRouteData;
    },
    // 当航线大于1个点时 且 选中第一个航点 则 不显示删除按钮
    showDeleteNode(){
      return !(this.localRouteDataOid == 0 && this.localRouteData.length > 1);
    }
  },
  methods: {
    // 航点添加
    addPoint(left) {
      airline.insertFlyPoint(this.localRouteDataOid, left); // 地图航点插入
      //关闭弹窗
      this.$EventBus.$emit("hideMenu");

      //   return;
      const { x, y, altitude, z } =
        airline.getPositions()[this.localRouteDataOid];

      // 更新vuex内数据
      let info = {
        index: left ? this.localRouteDataOid : this.localRouteDataOid,
        newDate: {
          lng: x,
          lat: y,
          altitude: altitude,
          height: Number(z.toFixed(0)), //第一次创建进行高度初始化
          waypointHeadingAngle: 0,
          actionList: [],
        },
      };

      this.$store.commit("airline/SET_INSERT_LOCAL_ROUTE_DATA", info);
      // 视图更新
      this.$EventBus.$emit("infoUpdate");
    },
    deleteNode() {
      this.$EventBus.$emit("deleteNode");
    },
    // 阻止右键默认事件
    stopDefault(e) {
      e.preventDefault();
    },
  },
};
</script>

<style lang="less" scoped>
.action-menu {
  position: absolute;
  left: 1181px;
  top: 210px;
  width: 98px;
  background: rgba(0, 0, 0, 1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  z-index: 1;

  .item {
    height: 40px;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;

    &:nth-child(2) {
      border-top: 1px solid rgba(170, 170, 170, 0.3);
      border-bottom: 1px solid rgba(170, 170, 170, 0.3);
    }

    &:first-child {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: -8px;
        top: 12px;
        background: rgba(0, 0, 0, 1);
        width: 16px;
        height: 16px;
        transform: rotate(45deg);
      }
    }
  }
}
</style>
